import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

function PermissionGuard(props) {
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // Check for permission before rendering the view
  useEffect(() => {
    if (props.permission) {
      setShowSplashScreen(false);
    }
  }, [props.permission]);
  return showSplashScreen ? null : <>{props.children}</>;
}
const mapStateToProps = ({ shared }) => {
  const { permission } = shared;
  return { permission };
};

export default connect(mapStateToProps, {})(PermissionGuard);
