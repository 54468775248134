export const GET_ALL_TENANT = "GET_ALL_TENANT";
export const GET_ALL_TENANT_SUCCESS = "GET_ALL_TENANT_SUCCESS";
export const GET_ALL_TENANT_ERROR = "GET_ALL_TENANT_ERROR";
export const GET_TENANT_LIST = "GET_TENANT_LIST";
export const GET_TENANT_LIST_SUCCESS = "GET_TENANT_LIST_SUCCESS";
export const GET_TENANT_LIST_ERROR = "GET_TENANT_LIST_ERROR";
export const ADD_TENANT = "ADD_TENANT";
export const ADD_TENANT_SUCCESS = "ADD_TENANT_SUCCESS";
export const ADD_TENANT_ERROR = "ADD_TENANT_ERROR";
export const GET_TENANT = "GET_TENANT";
export const GET_TENANT_SUCCESS = "GET_TENANT_SUCCESS";
export const GET_TENANT_ERROR = "GET_TENANT_ERROR";
export const EDIT_TENANT = "EDIT_TENANT";
export const EDIT_TENANT_SUCCESS = "EDIT_TENANT_SUCCESS";
export const EDIT_TENANT_ERROR = "EDIT_TENANT_ERROR";
export const DELETE_TENANT = "DELETE_TENANT";
export const DELETE_TENANT_SUCCESS = "DELETE_TENANT_SUCCESS";
export const DELETE_TENANT_ERROR = "DELETE_TENANT_ERROR";
export const DELETE_MULTIPLE_TENANT = "DELETE_MULTIPLE_TENANT";
export const DELETE_MULTIPLE_TENANT_SUCCESS = "DELETE_MULTIPLE_TENANT_SUCCESS";
export const DELETE_MULTIPLE_TENANT_ERROR = "DELETE_MULTIPLE_TENANT_ERROR";

export const UPDATE_SUBSIDY_PRICES = "UPDATE_SUBSIDY_PRICES";
export const UPDATE_SUBSIDY_PRICES_SUCCESS = "UPDATE_SUBSIDY_PRICES_SUCCESS";
export const UPDATE_SUBSIDY_PRICES_ERROR = "UPDATE_SUBSIDY_PRICES_ERROR";
export const RESET_TENANT = "RESET_TENANT";

export const getAllTenant = () => ({
  type: GET_ALL_TENANT,
  payload: {},
});

export const getAllTenantSuccess = (tenants) => ({
  type: GET_ALL_TENANT_SUCCESS,
  payload: tenants,
});

export const getAllTenantError = (error) => ({
  type: GET_ALL_TENANT_ERROR,
  payload: error,
});

export const getTenantList = (dbParam) => ({
  type: GET_TENANT_LIST,
  payload: { dbParam },
});

export const getTenantListSuccess = (tenantList) => ({
  type: GET_TENANT_LIST_SUCCESS,
  payload: tenantList,
});

export const getTenantListError = (error) => ({
  type: GET_TENANT_LIST_ERROR,
  payload: error,
});

export const addTenant = (tenantData, history, location) => ({
  type: ADD_TENANT,
  payload: { tenantData, history, location },
});

export const addTenantSuccess = (success, message) => ({
  type: ADD_TENANT_SUCCESS,
  payload: { success, message },
});

export const addTenantError = (error) => ({
  type: ADD_TENANT_ERROR,
  payload: error,
});

export const getTenant = (tenantId, organizationId) => ({
  type: GET_TENANT,
  payload: { tenantId, organizationId },
});

export const getTenantSuccess = (tenantData) => ({
  type: GET_TENANT_SUCCESS,
  payload: tenantData,
});

export const getTenantError = (error) => ({
  type: GET_TENANT_ERROR,
  payload: error,
});

export const editTenant = (tenantId, tenantData, history, location) => ({
  type: EDIT_TENANT,
  payload: { tenantId, tenantData, history, location },
});

export const editTenantSuccess = (success, message) => ({
  type: EDIT_TENANT_SUCCESS,
  payload: { success, message },
});

export const editTenantError = (error) => ({
  type: EDIT_TENANT_ERROR,
  payload: error,
});

export const deleteTenant = (tenantId) => ({
  type: DELETE_TENANT,
  payload: { tenantId },
});

export const deleteTenantSuccess = (success, message) => ({
  type: DELETE_TENANT_SUCCESS,
  payload: { success, message },
});

export const deleteTenantError = (error) => ({
  type: DELETE_TENANT_ERROR,
  payload: error,
});

export const deleteMultipleTenant = (tenantIds) => ({
  type: DELETE_MULTIPLE_TENANT,
  payload: { tenantIds },
});

export const deleteMultipleTenantSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_TENANT_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleTenantError = (error) => ({
  type: DELETE_MULTIPLE_TENANT_ERROR,
  payload: error,
});


export const updateSubsidyPrices = (subsidyData, callback) => ({
  type: UPDATE_SUBSIDY_PRICES,
  payload: { subsidyData, callback },
});

export const updateSubsidyPricesSuccess = (success, message, subsidyData) => ({
  type: UPDATE_SUBSIDY_PRICES_SUCCESS,
  payload: { success, message, subsidyData },
});

export const updateSubsidyPricesError = (error) => ({
  type: UPDATE_SUBSIDY_PRICES_ERROR,
  payload: error,
});


export const resetTenant = () => ({
  type: RESET_TENANT,
  payload: {},
});
