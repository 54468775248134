import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../configs/core";
import DashboardView from "./dashboard";
import ProfileView from "./profile";
import SettingView from "./setting";
import NotificationView from "./notification";
import OrganizationView from "./organization";

const UserManagementView = lazy(() => import("./user-management"));
const ProductManagementView = lazy(() => import("./product-management"));
const CanteenManagementView = lazy(() => import("./canteen-management"));
const ReportManagementView = lazy(() => import("./report-management"));
const ConfigurationView = lazy(() => import("./configuration"));

export default function Base() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardView} />
        <ContentRoute path="/profile" component={ProfileView} />
        <ContentRoute path="/setting" component={SettingView} />
        <ContentRoute path="/notification" component={NotificationView} />
        <ContentRoute path="/organization" component={OrganizationView} />
        <Route path="/user-management" component={UserManagementView} />
        <Route path="/product-management" component={ProductManagementView} />
        <Route path="/canteen-management" component={CanteenManagementView} />
        <Route path="/report-management" component={ReportManagementView} />
        <Route path="/configuration" component={ConfigurationView} />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}
