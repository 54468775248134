import {
  GET_ALL_BUILDING,
  GET_ALL_BUILDING_SUCCESS,
  GET_ALL_BUILDING_ERROR,
  GET_BUILDING_LIST,
  GET_BUILDING_LIST_SUCCESS,
  GET_BUILDING_LIST_ERROR,
  ADD_BUILDING,
  ADD_BUILDING_SUCCESS,
  ADD_BUILDING_ERROR,
  GET_BUILDING,
  GET_BUILDING_SUCCESS,
  GET_BUILDING_ERROR,
  EDIT_BUILDING,
  EDIT_BUILDING_SUCCESS,
  EDIT_BUILDING_ERROR,
  DELETE_BUILDING,
  DELETE_BUILDING_SUCCESS,
  DELETE_BUILDING_ERROR,
  DELETE_MULTIPLE_BUILDING,
  DELETE_MULTIPLE_BUILDING_SUCCESS,
  DELETE_MULTIPLE_BUILDING_ERROR,
  RESET_BUILDING,
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  buildings: null,
  buildingList: null,
  buildingData: null,
  buildingId: null,
  organizationId: null,
  buildingIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const buildingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_BUILDING:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_BUILDING_SUCCESS:
      return {
        ...state,
        buildings: action.payload,
        error: null,
      };
    case GET_ALL_BUILDING_ERROR:
      return {
        ...state,
        buildings: null,
        error: action.payload,
      };
    case GET_BUILDING_LIST:
      return {
        ...state,
        loading: true,
        buildingId: null,
        buildingData: null,
        buildingIds: null,
        error: null,
      };
    case GET_BUILDING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        buildingList: action.payload,
        error: null,
      };
    case GET_BUILDING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        buildingList: null,
        error: action.payload,
      };
    case ADD_BUILDING:
      return { ...state, loading: true, error: null };
    case ADD_BUILDING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_BUILDING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_BUILDING:
      return { ...state, error: null };
    case GET_BUILDING_SUCCESS:
      return {
        ...state,
        buildingData: action.payload,
        error: null,
      };
    case GET_BUILDING_ERROR:
      return {
        ...state,
        buildingData: null,
        error: action.payload,
      };
    case EDIT_BUILDING:
      return { ...state, loading: true, error: null };
    case EDIT_BUILDING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_BUILDING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_BUILDING:
      return { ...state, loading1: true, error: null };
    case DELETE_BUILDING_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_BUILDING_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_BUILDING:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_BUILDING_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_BUILDING_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_BUILDING:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default buildingReducer;
