import React from "react";

const IconBarcode = () => {
    return (
        <svg version="1.1" width="22" height="22" viewBox="0 0 508 508">
            <circle fill="#90DFAA" cx="254" cy="254" r="254" />
            <path
                fill="#FFFFFF"
                d="M429.6,368.8H78.4c-4,0-7.2-3.2-7.2-7.2V142.8c0-4,3.2-7.2,7.2-7.2h351.2c4,0,7.2,3.2,7.2,7.2v218.8
      C436.8,365.6,433.6,368.8,429.6,368.8z"
            />
            <g>
                <rect x="92.8" y="148.8" fill="#324A5E" width="19.6" height="203.6" />
                <rect x="130" y="148.8" fill="#324A5E" width="16.4" height="166" />
                <rect x="155.6" y="148.8" fill="#324A5E" width="16.4" height="166" />
                <rect x="181.2" y="148.8" fill="#324A5E" width="16.4" height="166" />
                <rect x="225.2" y="148.8" fill="#324A5E" width="12.8" height="166" />
                <rect x="249.2" y="148.8" fill="#324A5E" width="12.8" height="166" />
                <rect x="273.6" y="148.8" fill="#324A5E" width="12.8" height="166" />
                <rect x="315.6" y="148.8" fill="#324A5E" width="12.8" height="166" />
                <rect x="342.4" y="148.8" fill="#324A5E" width="10.4" height="166" />
                <rect x="367.6" y="148.8" fill="#324A5E" width="10.4" height="166" />
                <rect x="402.4" y="148.8" fill="#324A5E" width="12.8" height="203.6" />
                <path
                    fill="#324A5E"
                    d="M132.8,351.6v-17.2c-1.2,0-2-0.8-2-2s0.8-2,2-2h2c1.2,0,2,0.8,2,2v19.2c0,1.2-0.8,2-2,2
        C134,353.6,132.8,352.8,132.8,351.6z"
                />
                <path
                    fill="#324A5E"
                    d="M210,346c0,4-3.6,7.6-7.6,7.6s-7.6-3.6-7.6-7.6c0-1.2,0.8-2,2-2s2,0.8,2,2c0,2,1.6,3.6,3.6,3.6
        s4-1.6,4-3.6s-1.6-4-3.6-4c-1.2,0-2-0.8-2-2c0-2.8,4-1.2,4-4c0-1.2-0.8-2-2-2h-3.6c-1.2,0-2-0.8-2-2s0.8-2,2-2h3.6
        c3.2,0,5.6,2.4,5.6,5.6c0,1.2-0.4,2.4-1.2,3.6C208.8,341.2,210,343.2,210,346z"
                />
                <path
                    fill="#324A5E"
                    d="M278,346c0,4-3.6,7.6-7.6,7.6s-7.6-3.6-7.6-7.6c0-1.2,0.8-2,2-2s2,0.8,2,2c0,2,1.6,3.6,3.6,3.6
        s4-1.6,4-3.6s-1.6-4-3.6-4c-1.2,0-2-0.8-2-2c0-2.8,4-1.2,4-4c0-1.2-0.8-2-2-2h-3.6c-1.2,0-2-0.8-2-2s0.8-2,2-2h3.6
        c3.2,0,5.6,2.4,5.6,5.6c0,1.2-0.4,2.4-1.2,3.6C276.8,341.2,278,343.2,278,346z"
                />
                <path
                    fill="#324A5E"
                    d="M235.6,351.6v-17.2c-1.2,0-2-0.8-2-2s0.8-2,2-2h2c1.2,0,2,0.8,2,2v19.2c0,1.2-0.8,2-2,2
        C236.4,353.6,235.6,352.8,235.6,351.6z"
                />
                <path
                    fill="#324A5E"
                    d="M338,351.6v-17.2c-1.2,0-2-0.8-2-2s0.8-2,2-2h2c1.2,0,2,0.8,2,2v19.2c0,1.2-0.8,2-2,2
        S338,352.8,338,351.6z"
                />
                <path
                    fill="#324A5E"
                    d="M297.2,346c0-1.2,0.4-2.4,0.8-3.2l6-10.8c0.4-0.8,1.2-1.2,1.6-1.2c2.4,0.4,2,2.4,1.6,2.8l-2.4,4.8
        c0,0,0,0,0.4,0c4.4,0,7.6,3.6,7.6,7.6s-3.6,7.6-7.6,7.6C300.4,353.6,297.2,350,297.2,346z M300.8,346c0,2,1.6,3.6,3.6,3.6
        s4-1.6,4-3.6s-1.6-4-4-4C302.8,342,300.8,343.6,300.8,346z"
                />
                <path
                    fill="#324A5E"
                    d="M380.8,346c0,4-3.6,7.6-7.6,7.6s-7.6-3.6-7.6-7.6c0-2.4,1.2-4.8,3.2-6c-0.8-0.8-1.2-2-1.2-3.6
        c0-3.2,2.4-5.6,5.6-5.6s5.6,2.4,5.6,5.6c0,1.2-0.4,2.4-1.2,3.6C379.6,341.2,380.8,343.2,380.8,346z M376.8,346c0-2-1.6-4-4-4
        c-2,0-3.6,1.6-3.6,4c0,2,1.6,3.6,3.6,3.6C375.2,349.6,376.8,348,376.8,346z M373.2,338.4c1.2,0,2-0.8,2-2s-0.8-2-2-2s-2,0.8-2,2
        C371.2,337.2,372,338.4,373.2,338.4z"
                />
                <path
                    fill="#324A5E"
                    d="M175.6,346c0,4-3.6,7.6-7.6,7.6s-7.6-3.6-7.6-7.6c0-2.4,1.2-4.8,3.2-6c-0.8-0.8-1.2-2-1.2-3.6
        c0-3.2,2.4-5.6,5.6-5.6c3.2,0,5.6,2.4,5.6,5.6c0,1.2-0.4,2.4-1.2,3.6C174.4,341.2,175.6,343.2,175.6,346z M172,346c0-2-1.6-4-4-4
        c-2,0-3.6,1.6-3.6,4c0,2,1.6,3.6,3.6,3.6S172,348,172,346z M168,338.4c1.2,0,2-0.8,2-2s-0.8-2-2-2s-2,0.8-2,2
        C166,337.2,167.2,338.4,168,338.4z"
                />
            </g>
        </svg>
    );
};

export default IconBarcode;







