import * as Mui from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as Yup from "yup";
import AuthGraphicOne from "../../../assets/images/auth-graphics.png";
import { InputHiddenField, InputPasswordField } from "../../../components/form";
import InputField from "../../../components/form/input-field";
import { IconLogo, IconMail } from "../../../components/svg";
import { LayoutSplashScreen } from "../../../configs/core";
import LanguageDropdownAlt from "../../../layouts/topnav/language-dropdown-alt";
import {
  resetAuth,
  resetPassword,
  verifyResetToken,
} from "../../../reduxs/actions";
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.validationSchema = Yup.object().shape({
      email: Yup.string()
        .email(this.props.t("AUTH.VALIDATION.INVALID_EMAIL"))
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")),
      password: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          this.props.t("AUTH.VALIDATION.PASSWORD_CRITERIA")
        ),
      password_confirmation: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .oneOf(
          [Yup.ref("password"), null],
          this.props.t("AUTH.VALIDATION.NOT_MATCH")
        ),
    });
    this.props.resetAuth();
    this.props.verifyResetToken(
      this.props.match.params.token,
      this.props.history
    );
  }

  onResetPassword = (values) => {
    if (!this.props.loading) {
      this.props.resetPassword(values, this.props.history);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.loading1 ? (
          <LayoutSplashScreen />
        ) : (
          <Mui.Grid container justifyContent="space-between" className="auth-wrap">
            <Mui.Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className="auth-message-box p-5"
            >
              <Mui.Box>
                <Mui.Box className="auth-message-box mt-3">
                  <Mui.Hidden xsDown>
                    <Mui.Typography
                      component="h2"
                      variant="h2"
                      className="title mb-3 font-weight-medium"
                    >
                      {t("AUTH.RESET_PASSWORD.PASSWORD_REQUIREMENT")}
                    </Mui.Typography>
                    <Mui.Typography
                      component="h3"
                      variant="h3"
                      className="font-weight-normal"
                    >
                      {t("AUTH.RESET_PASSWORD.PASSWORD_MUST_CONTAIN")}
                    </Mui.Typography>
                    <ul className="password-reqirement">
                      <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_1")}</li>
                      <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_2")}</li>
                      <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_3")}</li>
                      <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_4")}</li>
                      <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_5")}</li>
                    </ul>
                  </Mui.Hidden>
                </Mui.Box>
              </Mui.Box>
              <Mui.Box className="auth-graphic-box">
                <img style={{ width: "100%" }} src={AuthGraphicOne} alt="" />
              </Mui.Box>
            </Mui.Grid>

            <Mui.Grid item xs={12} md={5} className="auth-form-box">
              <Mui.Card className="card-box">
                <Mui.Box className="logo-icon">
                  <LanguageDropdownAlt />
                  <IconLogo />
                </Mui.Box>
                <Mui.Typography
                  className="mb-3 font-weight-normal"
                  variant="h1"
                  component="h1"
                >
                  {t("AUTH.RESET_PASSWORD.TITLE")}
                </Mui.Typography>

                {this.props.error ? (
                  <Mui.Typography
                    className="d-block mt-2 text-center text-color-danger"
                    variant="body1"
                    component="span"
                  >
                    {this.props.error}
                  </Mui.Typography>
                ) : null}

                <Formik
                  initialValues={{
                    token: this.props.match.params.token,
                    email: "",
                    password: "",
                    password_confirmation: "",
                  }}
                  validationSchema={this.validationSchema}
                  onSubmit={this.onResetPassword}
                >
                  {(props) => (
                    <Form className="default-form center">
                      <InputHiddenField name="token" />
                      <Mui.Grid container spacing={3}>
                        <Mui.Grid className="form-group" item xs={12}>
                          <InputField
                            name="email"
                            type="text"
                            label={t("AUTH.INPUT.EMAIL")}
                            InputProps={{
                              startAdornment: (
                                <Mui.InputAdornment position="start">
                                  <IconMail />
                                </Mui.InputAdornment>
                              ),
                            }}
                          />
                        </Mui.Grid>
                        <Mui.Grid className="form-group" item xs={12}>
                          <InputPasswordField
                            name="password"
                            label={t("AUTH.INPUT.NEW_PASSWORD")}
                            showStartAdornment={true}
                          />
                        </Mui.Grid>
                        <Mui.Grid className="form-group" item xs={12}>
                          <InputPasswordField
                            name="password_confirmation"
                            label={t("AUTH.INPUT.CONFIRM_PASSWORD")}
                            showStartAdornment={true}
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Button
                        className="btn-default align-self-start"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={this.props.loading}
                      >
                        {t("AUTH.GENERAL.SUBMIT_BUTTON")}
                        {this.props.loading && (
                          <Mui.CircularProgress color="secondary" size={24} />
                        )}
                      </Mui.Button>
                    </Form>
                  )}
                </Formik>
              </Mui.Card>
            </Mui.Grid>
          </Mui.Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading1, loading, error } = auth;
  return { loading1, loading, error };
};
export default connect(mapStateToProps, {
  verifyResetToken,
  resetPassword,
  resetAuth,
})(withTranslation()(ResetPassword));
