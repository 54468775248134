export const GET_ALL_CANTEEN = "GET_ALL_CANTEEN";
export const GET_ALL_CANTEEN_SUCCESS = "GET_ALL_CANTEEN_SUCCESS";
export const GET_ALL_CANTEEN_ERROR = "GET_ALL_CANTEEN_ERROR";
export const GET_CANTEEN_LIST = "GET_CANTEEN_LIST";
export const GET_CANTEEN_LIST_SUCCESS = "GET_CANTEEN_LIST_SUCCESS";
export const GET_CANTEEN_LIST_ERROR = "GET_CANTEEN_LIST_ERROR";
export const ADD_CANTEEN = "ADD_CANTEEN";
export const ADD_CANTEEN_SUCCESS = "ADD_CANTEEN_SUCCESS";
export const ADD_CANTEEN_ERROR = "ADD_CANTEEN_ERROR";
export const GET_CANTEEN = "GET_CANTEEN";
export const GET_CANTEEN_SUCCESS = "GET_CANTEEN_SUCCESS";
export const GET_CANTEEN_ERROR = "GET_CANTEEN_ERROR";
export const EDIT_CANTEEN = "EDIT_CANTEEN";
export const EDIT_CANTEEN_SUCCESS = "EDIT_CANTEEN_SUCCESS";
export const EDIT_CANTEEN_ERROR = "EDIT_CANTEEN_ERROR";
export const DELETE_CANTEEN = "DELETE_CANTEEN";
export const DELETE_CANTEEN_SUCCESS = "DELETE_CANTEEN_SUCCESS";
export const DELETE_CANTEEN_ERROR = "DELETE_CANTEEN_ERROR";
export const DELETE_MULTIPLE_CANTEEN = "DELETE_MULTIPLE_CANTEEN";
export const DELETE_MULTIPLE_CANTEEN_SUCCESS =
  "DELETE_MULTIPLE_CANTEEN_SUCCESS";
export const DELETE_MULTIPLE_CANTEEN_ERROR = "DELETE_MULTIPLE_CANTEEN_ERROR";
export const RESET_CANTEEN = "RESET_CANTEEN";
export const GET_CANTEEN_PREDICTION = "GET_CANTEEN_PREDICTION";
export const GET_CANTEEN_PREDICTION_SUCCESS = "GET_CANTEEN_PREDICTION_SUCCESS";
export const GET_CANTEEN_PREDICTION_ERROR = "GET_CANTEEN_PREDICTION_ERROR";

export const ADD_CROCKERY = "ADD_CROCKERY";
export const ADD_CROCKERY_SUCCESS = "ADD_CROCKERY_SUCCESS";
export const ADD_CROCKERY_ERROR = "ADD_CROCKERY_ERROR";

export const DELETE_CROCKERY = "DELETE_CROCKERY";
export const DELETE_CROCKERY_SUCCESS = "DELETE_CROCKERY_SUCCESS";
export const DELETE_CROCKERY_ERROR = "DELETE_CROCKERY_ERROR";

export const EDIT_CROCKERY = "EDIT_CROCKERY";
export const EDIT_CROCKERY_SUCCESS = "EDIT_CROCKERY_SUCCESS";
export const EDIT_CROCKERY_ERROR = "EDIT_CROCKERY_ERROR";

export const GET_WEIGHTED_LIST = "GET_WEIGHTED_LIST";
export const GET_WEIGHTED_LIST_SUCCESS = "GET_WEIGHTED_LIST_SUCCESS";
export const GET_WEIGHTED_LIST_ERROR = "GET_WEIGHTED_LIST_ERROR";

export const EDIT_WEIGHTED_LIST = "EDIT_WEIGHTED_LIST";
export const EDIT_WEIGHTED_LIST_SUCCESS = "EDIT_WEIGHTED_LIST_SUCCESS";
export const EDIT_WEIGHTED_LIST_ERROR = "EDIT_WEIGHTED_LIST_ERROR";

export const EXPORT_CANTEEN_PRODUCTS = "EXPORT_CANTEEN_PRODUCTS";
export const EXPORT_CANTEEN_PRODUCTS_SUCCESS = "EXPORT_CANTEEN_PRODUCTS_SUCCESS";
export const EXPORT_CANTEEN_PRODUCTS_ERROR = "EXPORT_CANTEEN_PRODUCTS_ERROR";

export const GET_DEFAULT_CANTEEN_SETTINGS = "GET_DEFAULT_CANTEEN_SETTINGS";
export const GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS = "GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS";
export const GET_DEFAULT_CANTEEN_SETTINGS_ERROR = "GET_DEFAULT_CANTEEN_SETTINGS_ERROR";

export const SET_DEFAULT_CANTEEN_SETTINGS = "SET_DEFAULT_CANTEEN_SETTINGS";
export const SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS = "SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS";
export const SET_DEFAULT_CANTEEN_SETTINGS_ERROR = "SET_DEFAULT_CANTEEN_SETTINGS_ERROR";

export const GET_LIST_CANTEEN_SETTINGS = "GET_LIST_CANTEEN_SETTINGS";
export const GET_LIST_CANTEEN_SETTINGS_SUCCESS = "GET_LIST_CANTEEN_SETTINGS_SUCCESS";
export const GET_LIST_CANTEEN_SETTINGS_ERROR = "GET_LIST_CANTEEN_SETTINGS_ERROR";

export const DELETE_POS_CANTEEN_SETTINGS = "DELETE_POS_CANTEEN_SETTINGS";
export const DELETE_POS_CANTEEN_SETTINGS_SUCCESS = "DELETE_POS_CANTEEN_SETTINGS_SUCCESS";
export const DELETE_POS_CANTEEN_SETTINGS_ERROR = "DELETE_POS_CANTEEN_SETTINGS_ERROR";

export const SET_CANTEEN_LOCAL_PRICE = "SET_CANTEEN_LOCAL_PRICE";
export const SET_CANTEEN_LOCAL_PRICE_SUCCESS = "SET_CANTEEN_LOCAL_PRICE_SUCCESS";
export const SET_CANTEEN_LOCAL_PRICE_ERROR = "SET_CANTEEN_LOCAL_PRICE_ERROR";

export const RESET_TO_GLOBAL_PRICES = "RESET_TO_GLOBAL_PRICES";
export const RESET_TO_GLOBAL_PRICES_SUCCESS = "RESET_TO_GLOBAL_PRICES_SUCCESS";
export const RESET_TO_GLOBAL_PRICES_ERROR = "RESET_TO_GLOBAL_PRICES_ERROR";

export const UPDATE_CANTEEN_PRODUCT_PRICES = "UPDATE_CANTEEN_PRODUCT_PRICES";
export const UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS = "UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS";
export const UPDATE_CANTEEN_PRODUCT_PRICES_ERROR = "UPDATE_CANTEEN_PRODUCT_PRICES_ERROR";

export const getAllCanteen = () => ({
  type: GET_ALL_CANTEEN,
  payload: {},
});

export const getAllCanteenSuccess = (canteens) => ({
  type: GET_ALL_CANTEEN_SUCCESS,
  payload: canteens,
});

export const getAllCanteenError = (error) => ({
  type: GET_ALL_CANTEEN_ERROR,
  payload: error,
});

export const getCanteenList = (dbParam) => ({
  type: GET_CANTEEN_LIST,
  payload: { dbParam },
});

export const getCanteenListSuccess = (canteenList) => ({
  type: GET_CANTEEN_LIST_SUCCESS,
  payload: canteenList,
});

export const getCanteenListError = (error) => ({
  type: GET_CANTEEN_LIST_ERROR,
  payload: error,
});

export const addCanteen = (canteenData, history, location) => ({
  type: ADD_CANTEEN,
  payload: { canteenData, history, location },
});

export const addCanteenSuccess = (success, message) => ({
  type: ADD_CANTEEN_SUCCESS,
  payload: { success, message },
});

export const addCanteenError = (error) => ({
  type: ADD_CANTEEN_ERROR,
  payload: error,
});

export const getCanteen = (canteenId, organizationId) => ({
  type: GET_CANTEEN,
  payload: { canteenId, organizationId },
});

export const getCanteenSuccess = (canteenData) => ({
  type: GET_CANTEEN_SUCCESS,
  payload: canteenData,
});

export const getCanteenError = (error) => ({
  type: GET_CANTEEN_ERROR,
  payload: error,
});

export const editCanteen = (canteenId, canteenData, history, location) => ({
  type: EDIT_CANTEEN,
  payload: { canteenId, canteenData, history, location },
});

export const editCanteenSuccess = (success, message) => ({
  type: EDIT_CANTEEN_SUCCESS,
  payload: { success, message },
});

export const editCanteenError = (error) => ({
  type: EDIT_CANTEEN_ERROR,
  payload: error,
});

export const deleteCanteen = (canteenId) => ({
  type: DELETE_CANTEEN,
  payload: { canteenId },
});

export const deleteCanteenSuccess = (success, message) => ({
  type: DELETE_CANTEEN_SUCCESS,
  payload: { success, message },
});

export const deleteCanteenError = (error) => ({
  type: DELETE_CANTEEN_ERROR,
  payload: error,
});

export const deleteMultipleCanteen = (canteenIds) => ({
  type: DELETE_MULTIPLE_CANTEEN,
  payload: { canteenIds },
});

export const deleteMultipleCanteenSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_CANTEEN_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleCanteenError = (error) => ({
  type: DELETE_MULTIPLE_CANTEEN_ERROR,
  payload: error,
});

export const resetCanteen = () => ({
  type: RESET_CANTEEN,
  payload: {},
});

export const getCanteenPrediction = (dbParam) => ({
  type: GET_CANTEEN_PREDICTION,
  payload: dbParam,
});

export const getCanteenPredictionSuccess = (predictionData) => ({
  type: GET_CANTEEN_PREDICTION_SUCCESS,
  payload: predictionData,
});

export const getCanteenPredictionError = (error) => ({
  type: GET_CANTEEN_PREDICTION_ERROR,
  payload: error,
});



//Crockery
export const addCrockery = (crockeryData, history) => ({
  type: ADD_CROCKERY,
  payload: { crockeryData, history },
});

export const addCrockerySuccess = (success, message) => ({
  type: ADD_CROCKERY_SUCCESS,
  payload: { success, message },
});

export const addCrockeryError = (error) => ({
  type: ADD_CROCKERY_ERROR,
  payload: error,
});


export const deleteCrockery = (crockeryData) => ({
  type: DELETE_CROCKERY,
  payload: { crockeryData },
});

export const deleteCrockerySuccess = (success, message) => ({
  type: DELETE_CROCKERY_SUCCESS,
  payload: { success, message },
});

export const deleteCrockeryError = (error) => ({
  type: DELETE_CROCKERY_ERROR,
  payload: error,
});

export const editCrockery = (crockeryId, crockeryData) => ({
  type: EDIT_CROCKERY,
  payload: { crockeryId, crockeryData },
});

export const editCrockerySuccess = (success, message) => ({
  type: EDIT_CROCKERY_SUCCESS,
  payload: { success, message },
});

export const editCrockeryError = (error) => ({
  type: EDIT_CROCKERY_ERROR,
  payload: error,
});


//Crockery-product association
export const getWeightedList = (canteenId) => ({
  type: GET_WEIGHTED_LIST,
  payload: { canteenId },
});

export const getWeightedListSuccess = (canteenData) => ({
  type: GET_WEIGHTED_LIST_SUCCESS,
  payload: canteenData,
});

export const getWeightedListError = (error) => ({
  type: GET_WEIGHTED_LIST_ERROR,
  payload: error,
});

export const editWeightedList = (productId, crockeryData) => ({
  type: EDIT_WEIGHTED_LIST,
  payload: { productId, crockeryData },
});

export const editWeightedListSuccess = (success, message) => ({
  type: EDIT_WEIGHTED_LIST_SUCCESS,
  payload: { success, message },
});

export const editWeightedListError = (error) => ({
  type: EDIT_WEIGHTED_LIST_ERROR,
  payload: error,
});


export const exportCanteenProducts = (canteenId, canteenName) => ({
  type: EXPORT_CANTEEN_PRODUCTS,
  payload: { canteenId, canteenName },
});

export const exportCanteenProductsSuccess = (success, message) => ({
  type: EXPORT_CANTEEN_PRODUCTS_SUCCESS,
  payload: { success, message },
});

export const exportCanteenProductsError = (error) => ({
  type: EXPORT_CANTEEN_PRODUCTS_ERROR,
  payload: error,
});


export const getDefaultCanteenSettings = (canteenId) => ({
  type: GET_DEFAULT_CANTEEN_SETTINGS,
  payload: { canteenId },
});

export const getDefaultCanteenSettingsSuccess = (settingData) => ({
  type: GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS,
  payload: settingData,
});

export const getDefaultCanteenSettingsError = (error) => ({
  type: GET_DEFAULT_CANTEEN_SETTINGS_ERROR,
  payload: error,
});


export const getListCanteenSettings = (canteenId) => ({
  type: GET_LIST_CANTEEN_SETTINGS,
  payload: { canteenId },
});

export const getListCanteenSettingsSuccess = (settingData) => ({
  type: GET_LIST_CANTEEN_SETTINGS_SUCCESS,
  payload: settingData,
});

export const getListCanteenSettingsError = (error) => ({
  type: GET_LIST_CANTEEN_SETTINGS_ERROR,
  payload: error,
});

export const deletePOSCanteenSettings = (canteenId, posId) => ({
  type: DELETE_POS_CANTEEN_SETTINGS,
  payload: { canteenId, posId },
});

export const deletePOSCanteenSettingsSuccess = (success, message, posId) => ({
  type: DELETE_POS_CANTEEN_SETTINGS_SUCCESS,
  payload: { success, message, posId },
});

export const deletePOSCanteenSettingsError = (error) => ({
  type: DELETE_POS_CANTEEN_SETTINGS_ERROR,
  payload: error,
});

export const setDefaultCanteenSettings = (canteenId, settingData) => ({
  type: SET_DEFAULT_CANTEEN_SETTINGS,
  payload: { canteenId, settingData },
});

export const setDefaultCanteenSettingsSuccess = (success, message, settingData) => ({
  type: SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS,
  payload: { success, message, settingData },
});

export const setDefaultCanteenSettingsError = (error) => ({
  type: SET_DEFAULT_CANTEEN_SETTINGS_ERROR,
  payload: error,
});


// Canteen Local Price
export const setCanteenLocalPrice = (priceData, callback) => ({
  type: SET_CANTEEN_LOCAL_PRICE,
  payload: { priceData, callback },
});

export const setCanteenLocalPriceSuccess = (success, message, priceData) => ({
  type: SET_CANTEEN_LOCAL_PRICE_SUCCESS,
  payload: { success, message, priceData },
});

export const setCanteenLocalPriceError = (error) => ({
  type: SET_CANTEEN_LOCAL_PRICE_ERROR,
  payload: error,
});


export const resetToGlobalPricing = (resetData, callback) => ({
  type: RESET_TO_GLOBAL_PRICES,
  payload: { resetData, callback },
});

export const resetToGlobalPricingSuccess = (success, message, resetData) => ({
  type: RESET_TO_GLOBAL_PRICES_SUCCESS,
  payload: { success, message, resetData },
});

export const resetToGlobalPricingError = (error) => ({
  type: RESET_TO_GLOBAL_PRICES_ERROR,
  payload: error,
});

export const updateCanteenProductPrices = (canteenData, callback) => ({
  type: UPDATE_CANTEEN_PRODUCT_PRICES,
  payload: { canteenData, callback },
});

export const updateCanteenProductPricesSuccess = (success, message, canteenData) => ({
  type: UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS,
  payload: { success, message, canteenData },
});

export const updateCanteenProductPricesError = (error) => ({
  type: UPDATE_CANTEEN_PRODUCT_PRICES_ERROR,
  payload: error,
});
