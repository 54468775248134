import React from 'react'
import { Dialog } from "@material-ui/core";

const Modal = ({ open, onClose, children, ...props }) => {


    return <Dialog
        open={open}
        onClose={onClose}
        scroll='body'
        {...props}
    >
        {children}

    </Dialog>
}

export default Modal