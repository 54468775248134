import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../configs/core";
import { connect } from "react-redux";
import OrganizationList from "./list";
import EditOrganization from "./edit";
import ViewOrganization from "./view";

function Organization(props) {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute
          exact={true}
          path="/organization"
          data={{
            module: "organizationManagement",
            section: "organization",
            action: "list",
          }}
          permission={props.permission}
          component={OrganizationList}
        />
        <ContentRoute
          path="/organization/add"
          component={EditOrganization}
          data={{
            module: "organizationManagement",
            section: "organization",
            action: "add",
          }}
          permission={props.permission}
        />
        <ContentRoute
          path="/organization/edit/:id"
          component={EditOrganization}
          data={{
            module: "organizationManagement",
            section: "organization",
            action: "edit",
          }}
          permission={props.permission}
        />
        <ContentRoute
          path="/organization/view/:id"
          data={{
            module: "organizationManagement",
            section: "organization",
            action: "view",
          }}
          permission={props.permission}
          component={ViewOrganization}
        />
      </Switch>
    </Suspense>
  );
}
const mapStateToProps = ({ shared }) => {
  const { permission } = shared;
  return {
    permission,
  };
};
export default connect(mapStateToProps, {})(Organization);
