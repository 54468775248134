import React from "react";

const IconOwner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      type="stroke"
    >
      <path
        d="M16 1.0127C15.2044 1.0127 14.4413 1.32877 13.8787 1.89138C13.3161 2.45398 13 3.21705 13 4.0127V16.0127C13 16.8083 13.3161 17.5714 13.8787 18.134C14.4413 18.6966 15.2044 19.0127 16 19.0127C16.7956 19.0127 17.5587 18.6966 18.1213 18.134C18.6839 17.5714 19 16.8083 19 16.0127C19 15.217 18.6839 14.454 18.1213 13.8914C17.5587 13.3288 16.7956 13.0127 16 13.0127H4C3.20435 13.0127 2.44129 13.3288 1.87868 13.8914C1.31607 14.454 1 15.217 1 16.0127C1 16.8083 1.31607 17.5714 1.87868 18.134C2.44129 18.6966 3.20435 19.0127 4 19.0127C4.79565 19.0127 5.55871 18.6966 6.12132 18.134C6.68393 17.5714 7 16.8083 7 16.0127V4.0127C7 3.21705 6.68393 2.45398 6.12132 1.89138C5.55871 1.32877 4.79565 1.0127 4 1.0127C3.20435 1.0127 2.44129 1.32877 1.87868 1.89138C1.31607 2.45398 1 3.21705 1 4.0127C1 4.80834 1.31607 5.57141 1.87868 6.13402C2.44129 6.69663 3.20435 7.0127 4 7.0127H16C16.7956 7.0127 17.5587 6.69663 18.1213 6.13402C18.6839 5.57141 19 4.80834 19 4.0127C19 3.21705 18.6839 2.45398 18.1213 1.89138C17.5587 1.32877 16.7956 1.0127 16 1.0127Z"
        stroke="#808089"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconOwner;
