import Req from "../interceptors/token-interceptor";

const ReportService = (function () {
  function _downloadSaleReport(param) {
    let el = "";
    if (param.buildingId) {
      el += `&buildingId=${param.buildingId}`;
    }
    if (param.canteenId) {
      if (Array.isArray(param.canteenId)) {
        param.canteenId &&
          param.canteenId.forEach((element) => {
            el += `&canteenId[]=${element}`;
          });
      }
    }
    if (param.kioskId) {
      if (Array.isArray(param.kioskId)) {
        param.kioskId &&
          param.kioskId.forEach((element) => {
            el += `&kioskId[]=${element}`;
          });
      }
    }
    if (param.customFromDate) {
      el += `&customFromDate=${param.customFromDate}`;
    }
    if (param.customToDate) {
      el += `&customToDate=${param.customToDate}`;
    }
    return Req.get(
      `/api/report/sales?dateFilter=${param?.dateFilter || "today"}&type=${param?.type || "pdf"
      }&logHistory=${param?.logHistory || 0}${el}`,
      {
        responseType: "arraybuffer",
      }
    );
  }

  function _downloadZXReport(param) {
    let el = getEl(param)
    return Req.get(
      `/api/report/${param?.reportType}-report?dateFilter=${param?.dateFilter || "today"}&type=${param?.type || "pdf"
      }&logHistory=${param?.logHistory || 0}${el}`,
      {
        responseType: "arraybuffer",
      }
    );
  }
  function _downloadBulkZReport(param) {
    let el = getEl(param)
    return Req.get(
      `/api/report/history/download?${el}`,
      {
        responseType: "arraybuffer",
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }


  function _downloadZReport(reportId) {
    return Req.get(
      `/api/report/history/${reportId}`,
      {
        responseType: "arraybuffer",
      }
    );
  }

  function _exportVatSalesExcelReport(payload) {
    return Req.get(`/api/vat-sales/export${payload.queryParam}`, {
      responseType: "arraybuffer",
    });
  }

  function _getAggregatedReport(payload) {
    let el = getEl(payload.queryParam)
    return Req.get(`/api/agg-vat-codes?${el}`);
  }

  return {
    downloadSaleReport: _downloadSaleReport,
    downloadZXReport: _downloadZXReport,
    downloadZReport: _downloadZReport,
    downloadBulkZReport: _downloadBulkZReport,
    exportVatSalesExcelReport: _exportVatSalesExcelReport,
    getAggregatedReport: _getAggregatedReport,
  };
})();
export default ReportService;


const getEl = (param) => {
  let el = "";
  if (param.buildingId) {
    el += `&buildingId=${param.buildingId}`;
  }
  if (param.canteenId) {
    if (Array.isArray(param.canteenId)) {
      param.canteenId &&
        param.canteenId.forEach((element) => {
          el += `&canteenId[]=${element}`;
        });
    } else {
      el += `&canteenId=${param.canteenId}`;
    }
  }
  if (param.kioskId) {
    if (Array.isArray(param.kioskId)) {
      param.kioskId &&
        param.kioskId.forEach((element) => {
          el += `&kioskId[]=${element}`;
        });
    } else {
      el += `&kioskId=${param.kioskId}`;
    }
  }
  if (param.customFromDate) {
    el += `&fromDate=${param.customFromDate}`;
  }
  if (param.customToDate) {
    el += `&toDate=${param.customToDate}`;
  }

  return el
}