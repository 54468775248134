import React, { createContext, useContext, useEffect, useState } from "react";
import { SkeletonTheme } from "react-loading-skeleton";

export const ThemeContext = createContext();
export const ThemeConsumer = ThemeContext.Consumer

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
    localStorage.setItem("darkMode", !darkMode);
  };

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode");
    if (isDarkMode) {
      setDarkMode(JSON.parse(isDarkMode));
    }
  }, []);

  const theme = darkMode ? "dark" : "light";

  return (
    <ThemeContext.Provider value={{ theme, toggleDarkMode }}>
      <SkeletonTheme
        baseColor={darkMode ? "#000330" : "#ddd"}
        highlightColor={darkMode ? "#1A1D45" : "#F4F7FE"}>
        {children}
      </SkeletonTheme>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
