import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getOrganizationList,
  onboardOrganization,
  deleteOrganization,
  deleteMultipleOrganization,
  getConfigChoice,
  resetOrganization,
} from "../../../reduxs/actions";
import DataTable from "../../../components/datatable";
import Breadcrumb from "../../../components/breadcrumb";
import { withTranslation } from "react-i18next";
import { getPermission } from "../../../helpers/util";
import { Alert } from "../../../components/dialog";
import * as Mui from '@material-ui/core'
class OrganizationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteId: null,
      deleteIds: null,
      openAlert: false,
      openAlert1: false,
    };
    this.searchFields =
      "name:like;number:like;admin_user:like;statusConfigChoice.display_name:like;";
  }

  componentDidMount() {
    if (!this.props.location?.search) {
      this.props.getOrganizationList({
        searchFields: this.searchFields,
      });
    }
    this.props.getConfigChoice("organizationStatus");
  }

  onDelete = () => {
    if (!this.props.loading1) {
      this.props.deleteOrganization(this.state.deleteId);
    }
  };

  onDeleteAll = () => {
    if (!this.props.loading1) {
      this.props.deleteMultipleOrganization(this.state.deleteIds);
    }
  };

  handleOpenAlert = (id) => {
    this.setState({ openAlert: true, deleteId: id });
  };

  handleCloseAlert = () => {
    this.setState(
      { openAlert: false, deleteId: null },
      () => this.props.resetOrganization() // To reset error
    );
  };

  handleOpenAlert1 = (ids) => {
    this.setState({ openAlert1: true, deleteIds: ids });
  };

  handleCloseAlert1 = () => {
    this.setState(
      { openAlert1: false, deleteIds: null },
      () => this.props.resetOrganization() // To reset error
    );
  };

  onChange = (
    search,
    sortOrder,
    page,
    pageSize,
    activeCol,
    statusId = null
  ) => {
    this.props.getOrganizationList({
      search: search,
      searchFields: this.searchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
      statusId: statusId,
    });
  };

  // onOnboard = (id) => {
  //   if (!this.props.loading1) {
  //     this.props.onboardOrganization(id);
  //   }
  // };

  render() {
    const { permission, t } = this.props;
    const organizationPermission = getPermission(
      "organizationManagement",
      "organization",
      ["edit", "add", "delete", "onboard"],
      permission
    );
    organizationPermission.domain = 1;
    return (
      <>
        <Breadcrumb
          title={t("PERMISSIONS.modules.organizationManagement")}
          paths={[
            {
              title: t("PERMISSIONS.sections.organization"),
            },
          ]}
        />

        <DataTable
          title={t("ORGANIZATION.TITLE")}
          headCells={[
            {
              id: "name",
              sortKey: "name",
              label: t("ORGANIZATION.FORM.NAME"),
            },
            {
              id: "number",
              sortKey: "number",
              label: t("ORGANIZATION.FORM.ORGANIZATION_NO"),
            },
            {
              id: "adminUserEmail",
              sortKey: "admin_user",
              label: t("ORGANIZATION.FORM.ADMIN_EMAIL"),
              sortable: false
            },
            {
              id: "statusConfigChoice",
              sortKey: "status",
              label: t("COMMON.FORM.STATUS"),
              sortable: false
            },
            {
              id: "createdAt",
              sortKey: "created_at",
              label: t("PRODUCT.FORM.CREATED_DATE"),
            },
          ]}
          rows={this.props.organizationList?.data || []}
          totalPage={this.props.organizationList?.meta?.pages || 0}
          totalItem={this.props.organizationList?.meta?.total || 0}
          start={this.props.organizationList?.meta?.start || 0}
          end={this.props.organizationList?.meta?.end || 0}
          loading={this.props.loading}
          route={"/organization"}
          createLabel={t("ORGANIZATION.ADD_LABEL")}
          // handleCustomAction={this.onOnboard}
          handleOpenAlert={this.handleOpenAlert}
          handleOpenAlert1={this.handleOpenAlert1}
          onChange={this.onChange}
          permission={{ ...organizationPermission, onboard: 0 }}
          actionStyle="kebab"
          enableStatusFilter
          enableColumnFilter={false}
          enableCheckbox={false}
          initialState={{ locationSearch: this.props.location.search }}
          customActionComponents={(id, callback) =>
            <>
              {organizationPermission.onboard === 1
                && this.props.organizationList?.data?.find(org => org?.id === id)?.statusConfigChoice?.configChoice === 'inactive'
                && this.props.organizationList?.data?.find(org => org?.id === id)?.websiteId === null &&
                <Mui.MenuItem
                  onClick={async () => {
                    if (!this.props.loading1) {
                      this.props.onboardOrganization(id, callback);
                    }
                  }}
                >
                  <span>{t("COMMON.FORM.ACTION.ONBOARD")}</span>

                  <Mui.Box style={{ height: 20, width: 30 }}>
                    {this.props.loading1 && (
                      <Mui.CircularProgress style={{ marginLeft: 10 }} color="secondary" size={20} />
                    )}
                  </Mui.Box>
                </Mui.MenuItem>}
            </>}
        />

        <Alert
          open={this.state.openAlert}
          close={this.handleCloseAlert}
          action={this.onDelete}
          title={t("ORGANIZATION.DELETE_LABEL")}
          info={t("ORGANIZATION.DELETE_INFO_LABEL")}
          awaitingInfo={t("ORGANIZATION.DELETING_LABEL")}
          actionBtnLabel={t("COMMON.FORM.ACTION.DELETE")}
          loading={this.props.loading1}
          success={this.props.success}
          error={this.props.error}
          reset={this.props.resetOrganization}
        />

        <Alert
          open={this.state.openAlert1}
          close={this.handleCloseAlert1}
          action={this.onDeleteAll}
          title={t("ORGANIZATION.DELETE_LABEL")}
          info={t("ORGANIZATION.MULTIPLE_DELETE_INFO_LABEL")}
          awaitingInfo={t("ORGANIZATION.DELETING_LABEL")}
          actionBtnLabel={t("COMMON.FORM.ACTION.DELETE")}
          loading={this.props.loading1}
          success={this.props.success}
          error={this.props.error}
          reset={this.props.resetOrganization}
        />
      </>
    );
  }
}
const mapStateToProps = ({ organization, shared }) => {
  const { permission } = shared;
  const { organizationList, loading1, loading, success, error } = organization;
  return { permission, organizationList, loading1, loading, success, error };
};
export default connect(mapStateToProps, {
  getOrganizationList,
  onboardOrganization,
  deleteOrganization,
  deleteMultipleOrganization,
  getConfigChoice,
  resetOrganization,
})(withTranslation()(OrganizationList));
