/**
 * Entry application component used to compose providers and render Routes.
 * */
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React, { Suspense, useEffect } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Route from "./Route";
import { ThemeProvider } from "./ThemeContext";
import { LayoutSplashScreen } from "./configs/core";
import theme from "./configs/theme";
import { AuthGuard } from "./guards";
import store from "./reduxs/store";

export default function App() {

  useEffect(() => {
    const prefix = process.env.REACT_APP_API_BASE_URL.split('.')[1]
    let title = 'mAiFood'
    if (prefix.toLowerCase() !== 'maifood') {
      title = `${prefix[0].toUpperCase() + prefix.substring(1)} | ${title}`;
    }
    document.title = title;
  }, [])


  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter>
          <ThemeProvider>
            <MuiThemeProvider theme={theme}>
              <AuthGuard>
                <Route />
              </AuthGuard>
            </MuiThemeProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
}
