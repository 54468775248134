export const GET_ALL_TENANT_EMPLOYEES = "GET_ALL_TENANT_EMPLOYEES";
export const GET_ALL_TENANT_EMPLOYEES_SUCCESS = "GET_ALL_TENANT_EMPLOYEES_SUCCESS";
export const GET_ALL_TENANT_EMPLOYEES_ERROR = "GET_ALL_TENANT_ERROR";
export const GET_TENANT_EMPLOYEES_LIST = "GET_TENANT_EMPLOYEES_LIST";
export const GET_TENANT_EMPLOYEES_LIST_SUCCESS = "GET_TENANT_EMPLOYEES_LIST_SUCCESS";
export const GET_TENANT_EMPLOYEES_LIST_ERROR = "GET_TENANT_EMPLOYEES_LIST_ERROR";
export const ADD_TENANT_EMPLOYEES = "ADD_TENANT_EMPLOYEES";
export const ADD_TENANT_EMPLOYEES_SUCCESS = "ADD_TENANT_EMPLOYEES_SUCCESS";
export const ADD_TENANT_EMPLOYEES_ERROR = "ADD_TENANT_EMPLOYEES_ERROR";
export const GET_TENANT_EMPLOYEES = "GET_TENANT_EMPLOYEES";
export const GET_TENANT_EMPLOYEES_SUCCESS = "GET_TENANT_EMPLOYEES_SUCCESS";
export const GET_TENANT_EMPLOYEES_ERROR = "GET_TENANT_ERROR";
export const EDIT_TENANT_EMPLOYEES = "EDIT_TENANT_EMPLOYEES";
export const EDIT_TENANT_EMPLOYEES_SUCCESS = "EDIT_TENANT_EMPLOYEES_SUCCESS";
export const EDIT_TENANT_EMPLOYEES_ERROR = "EDIT_TENANT_EMPLOYEES_ERROR";
export const DELETE_TENANT_EMPLOYEES = "DELETE_TENANT_EMPLOYEES";
export const DELETE_TENANT_EMPLOYEES_SUCCESS = "DELETE_TENANT_EMPLOYEES_SUCCESS";
export const DELETE_TENANT_EMPLOYEES_ERROR = "DELETE_TENANT_EMPLOYEES_ERROR";
export const RESET_TENANT_EMPLOYEES = "RESET_TENANT_EMPLOYEES";
export const IMPORT_TENANT_EMPLOYEES = "IMPORT_TENANT_EMPLOYEES";
export const IMPORT_TENANT_EMPLOYEES_SUCCESS = "IMPORT_TENANT_EMPLOYEES_SUCCESS";
export const IMPORT_TENANT_EMPLOYEES_ERROR = "IMPORT_TENANT_EMPLOYEES_ERROR";
export const DOWNLOAD_QR = "DOWNLOAD_QR";
export const DOWNLOAD_QR_SUCCESS = "DOWNLOAD_QR_SUCCESS";
export const DOWNLOAD_QR_ERROR = "DOWNLOAD_QR_ERROR";

export const DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE = "DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE";
export const DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_SUCCESS = "DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_SUCCESS";
export const DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_ERROR = "DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_ERROR";

export const SEND_QR = "SEND_QR";
export const SEND_QR_SUCCESS = "SEND_QR_SUCCESS";
export const SEND_QR_ERROR = "SEND_QR_ERROR";


export const CLEAR_EMPLOYEES_ERROR = "CLEAR_EMPLOYEES_ERROR";

export const getAllTenantEmployees = (tenantId) => ({
  type: GET_ALL_TENANT_EMPLOYEES,
  payload: { tenantId },
});

export const getAllTenantEmployeesSuccess = (tenantEmployees) => ({
  type: GET_ALL_TENANT_EMPLOYEES_SUCCESS,
  payload: tenantEmployees,
});

export const getAllTenantEmployeesError = (error) => ({
  type: GET_ALL_TENANT_EMPLOYEES_ERROR,
  payload: error,
});

export const getTenantEmployeesList = (dbParam) => ({
  type: GET_TENANT_EMPLOYEES_LIST,
  payload: { dbParam },
});

export const getTenantEmployeesListSuccess = (tenantEmployeesList) => ({
  type: GET_TENANT_EMPLOYEES_LIST_SUCCESS,
  payload: tenantEmployeesList,
});

export const getTenantEmployeesListError = (error) => ({
  type: GET_TENANT_EMPLOYEES_LIST_ERROR,
  payload: error,
});

export const addTenantEmployees = (tenantEmployeesData, history, location) => ({
  type: ADD_TENANT_EMPLOYEES,
  payload: { tenantEmployeesData, history, location },
});

export const addTenantEmployeesSuccess = (success, message) => ({
  type: ADD_TENANT_EMPLOYEES_SUCCESS,
  payload: { success, message },
});

export const addTenantEmployeesError = (error) => ({
  type: ADD_TENANT_EMPLOYEES_ERROR,
  payload: error,
});

export const getTenantEmployees = (tenantEmployeeId, organizationId) => ({
  type: GET_TENANT_EMPLOYEES,
  payload: { tenantEmployeeId, organizationId },
});

export const getTenantEmployeesSuccess = (tenantEmployeesData) => ({
  type: GET_TENANT_EMPLOYEES_SUCCESS,
  payload: tenantEmployeesData,
});

export const getTenantEmployeesError = (error) => ({
  type: GET_TENANT_EMPLOYEES_ERROR,
  payload: error,
});


export const editTenantEmployees = (tenantEmployeesId, tenantEmployeesData, history, location) => ({
  type: EDIT_TENANT_EMPLOYEES,
  payload: { tenantEmployeesId, tenantEmployeesData, history, location },
});

export const editTenantEmployeesSuccess = (success, message) => ({
  type: EDIT_TENANT_EMPLOYEES_SUCCESS,
  payload: { success, message },
});

export const editTenantEmployeesError = (error) => ({
  type: EDIT_TENANT_EMPLOYEES_ERROR,
  payload: error,
});

export const deleteTenantEmployees = (tenantEmployeesId, tenantId) => ({
  type: DELETE_TENANT_EMPLOYEES,
  payload: { tenantEmployeesId, tenantId },
});

export const deleteTenantEmployeesSuccess = (success, message) => ({
  type: DELETE_TENANT_EMPLOYEES_SUCCESS,
  payload: { success, message },
});

export const deleteTenantEmployeesError = (error) => ({
  type: DELETE_TENANT_EMPLOYEES_ERROR,
  payload: error,
});

export const importTenantEmployees = (importParam) => ({
  type: IMPORT_TENANT_EMPLOYEES,
  payload: { importParam },
});

export const importTenantEmployeesSuccess = (success, message) => ({
  type: IMPORT_TENANT_EMPLOYEES_SUCCESS,
  payload: { success, message },
});

export const importTenantEmployeesError = (error) => ({
  type: IMPORT_TENANT_EMPLOYEES_ERROR,
  payload: error,
});

export const downloadQr = (tenantEmployeeId) => ({
  type: DOWNLOAD_QR,
  payload: { tenantEmployeeId },
});

export const downloadQrSuccess = (success, message) => ({
  type: DOWNLOAD_QR_SUCCESS,
  payload: { success, message },
});

export const downloadQrError = (error) => ({
  type: DOWNLOAD_QR_ERROR,
  payload: error,
});

export const sendQr = (tenantEmployeeId, callback) => ({
  type: SEND_QR,
  payload: { tenantEmployeeId, callback },
});

export const sendQrSuccess = (success, message) => ({
  type: SEND_QR_SUCCESS,
  payload: { success, message },
});

export const sendQrError = (error) => ({
  type: SEND_QR_ERROR,
  payload: error,
});


export const downloadEmployeeImportTemplate = () => ({
  type: DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE,
  payload: null,
});

export const downloadEmployeeImportTemplateSuccess = (success, message) => ({
  type: DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_SUCCESS,
  payload: { success, message },
});

export const downloadEmployeeImportTemplateError = (error) => ({
  type: DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_ERROR,
  payload: error,
});


export const resetTenantEmployees = () => ({
  type: RESET_TENANT_EMPLOYEES,
  payload: {},
});

export const clearEmployeesError = () => ({
  type: CLEAR_EMPLOYEES_ERROR,
  payload: null,
});