/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { SplashScreenProvider } from "./configs/core";
import App from "./App";
import "./assets/scss/main.scss";
import "./i18n";
import { MantineProvider } from '@mantine/core';

ReactDOM.render(
  <MantineProvider>
    <SplashScreenProvider>
      <App />
    </SplashScreenProvider>
  </MantineProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
