import React from "react";
import * as Mui from "@material-ui/core";
import { useField } from "formik";
import Skeleton from "react-loading-skeleton";

const InputField = ({ label, variant, placeholder, shrinkLabel = true, loading = false, ...props }) => {
  const [field, meta] = useField(props);

  return (
    loading ?
      <Mui.Box>
        <Skeleton height={40} style={{ marginBottom: "10px", width: "100%" }} />
      </Mui.Box> :
      <Mui.TextField
        label={label ? label : null}
        InputLabelProps={shrinkLabel ? { shrink: true } : {}}
        variant={variant ? variant : "outlined"}
        placeholder={placeholder ? placeholder : null}
        helperText={meta.touched && meta.error ? meta.error : null}
        error={meta.touched && meta.error ? true : false}
        {...field}
        {...props}
      />
  );
};

export default InputField;
