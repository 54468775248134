import React from "react";

const IconDashboard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M16.0756 2.0127H19.4616C20.8639 2.0127 22.0001 3.15855 22.0001 4.57266V7.98722C22.0001 9.40133 20.8639 10.5472 19.4616 10.5472H16.0756C14.6734 10.5472 13.5371 9.40133 13.5371 7.98722V4.57266C13.5371 3.15855 14.6734 2.0127 16.0756 2.0127Z"
        fill="#808089"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53852 2.0127H7.92449C9.32676 2.0127 10.463 3.15855 10.463 4.57266V7.98722C10.463 9.40133 9.32676 10.5472 7.92449 10.5472H4.53852C3.13626 10.5472 2 9.40133 2 7.98722V4.57266C2 3.15855 3.13626 2.0127 4.53852 2.0127ZM4.53852 13.4785H7.92449C9.32676 13.4785 10.463 14.6244 10.463 16.0385V19.453C10.463 20.8662 9.32676 22.013 7.92449 22.013H4.53852C3.13626 22.013 2 20.8662 2 19.453V16.0385C2 14.6244 3.13626 13.4785 4.53852 13.4785ZM19.4614 13.4785H16.0754C14.6731 13.4785 13.5369 14.6244 13.5369 16.0385V19.453C13.5369 20.8662 14.6731 22.013 16.0754 22.013H19.4614C20.8636 22.013 21.9999 20.8662 21.9999 19.453V16.0385C21.9999 14.6244 20.8636 13.4785 19.4614 13.4785Z"
        fill="#808089"
      />
    </svg>
  );
};

export default IconDashboard;
