import * as Mui from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const StatusCell = (props) => {
  const { data } = props;
  const { i18n, t } = useTranslation();


  return (
    <Mui.Box display={'flex'} alignItems={'center'} gridGap={10}>
      <span
        style={props.style}
        className={`status-bg ${setStatusColor(
          data["statusConfigChoice"]?.["configChoice"] || ""
        )}`}
      >
        {data["statusConfigChoice"]?.["displayName"]?.[i18n.language] || ""}
      </span>
      {data["total"] === 0 &&
        <Mui.Tooltip title={t('ORDER.FULLY_SUBSIDIZED')}>
          <Mui.Box style={{ height: '1rem', width: '1rem', borderRadius: '50%', background: 'green' }}></Mui.Box>
        </Mui.Tooltip>
      }
    </Mui.Box>

  );
};

export default StatusCell;

export const setStatusColor = (stat) => {
  if (stat === "pending" || stat === "onboarding") {
    return "warn";
  } else if (
    stat === "active" ||
    stat === "approved" ||
    stat === "paid" ||
    stat === "done" ||
    stat === "verified" ||
    stat === "training_completed" ||
    stat === "fully_subsidized"

  ) {
    return "success";
  } else if (stat === "inactive" || stat === "training_in_progress") {
    return "disabled";
  } else if (stat === "cancelled" || stat === "partially_refunded" || stat === "refunded"
  ) {
    return "danger";
  }
};
