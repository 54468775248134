import React from "react";

const IconEdit = () => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="20.788"
    //   height="20.945"
    //   viewBox="0 0 20.788 20.945"
    // >
    //   <g
    //     id="Icon_feather-edit"
    //     dataname="Icon feather-edit"
    //     transform="translate(-2.5 -2.161)"
    //   >
    //     <path
    //       id="Path_2752"
    //       dataname="Path 2752"
    //       d="M11.851,6H4.967A1.967,1.967,0,0,0,3,7.967V21.735A1.967,1.967,0,0,0,4.967,23.7H18.735A1.967,1.967,0,0,0,20.7,21.735V14.851"
    //       transform="translate(0 -1.096)"
    //       fill="none"
    //       stroke="#000"
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //       strokeWidth="1"
    //     />
    //     <path
    //       id="Path_2753"
    //       dataname="Path 2753"
    //       d="M22.326,3.429a2.086,2.086,0,0,1,2.95,2.95l-9.343,9.343L12,16.706l.983-3.934Z"
    //       transform="translate(-3.099)"
    //       fill="none"
    //       stroke="#000"
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //       strokeWidth="1"
    //     />
    //   </g>
    // </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //   <path d="M 46.574219 3.425781 C 45.625 2.476563 44.378906 2 43.132813 2 C 41.886719 2 40.640625 2.476563 39.691406 3.425781 C 39.691406 3.425781 39.621094 3.492188 39.53125 3.585938 C 39.523438 3.59375 39.511719 3.597656 39.503906 3.605469 L 4.300781 38.804688 C 4.179688 38.929688 4.089844 39.082031 4.042969 39.253906 L 2.035156 46.742188 C 1.941406 47.085938 2.039063 47.453125 2.292969 47.707031 C 2.484375 47.898438 2.738281 48 3 48 C 3.085938 48 3.171875 47.988281 3.257813 47.964844 L 10.746094 45.957031 C 10.917969 45.910156 11.070313 45.820313 11.195313 45.695313 L 46.394531 10.5 C 46.40625 10.488281 46.410156 10.472656 46.417969 10.460938 C 46.507813 10.371094 46.570313 10.308594 46.570313 10.308594 C 48.476563 8.40625 48.476563 5.324219 46.574219 3.425781 Z M 45.160156 4.839844 C 46.277344 5.957031 46.277344 7.777344 45.160156 8.894531 C 44.828125 9.222656 44.546875 9.507813 44.304688 9.75 L 40.25 5.695313 C 40.710938 5.234375 41.105469 4.839844 41.105469 4.839844 C 41.644531 4.296875 42.367188 4 43.132813 4 C 43.898438 4 44.617188 4.300781 45.160156 4.839844 Z M 5.605469 41.152344 L 8.847656 44.394531 L 4.414063 45.585938 Z"></path>
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
      <path d="M 18 2 L 15.585938 4.4140625 L 19.585938 8.4140625 L 22 6 L 18 2 z M 14.076172 5.9238281 L 3 17 L 3 21 L 7 21 L 18.076172 9.9238281 L 14.076172 5.9238281 z"></path>
    </svg>
  );
};

export default IconEdit;
