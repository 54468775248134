import PrimaryLogo from "../assets/images/logo.png";
import SecondaryLogo from "../assets/images/logo-small.png";
import PrimaryLogoLight from "../assets/images/logo.svg";
import SecondaryLogoLight from "../assets/images/logo.png";

export const toolBarConfig = {
  primaryLogo: PrimaryLogo,
  secondaryLogo: SecondaryLogo,
  style: "sticky", //option: static, sticky, fixed
  bgColor: "#ffffff",
  bgColorDark: "#1A1D45",
  textColor: "#555454",
  textColorDark: "#ffffff",
  iconColor: "#4B4A4A",
  iconColorDark: "#ffffff",
};

export const sideNavConfig = {
  primaryLogo: PrimaryLogoLight,
  secondaryLogo: SecondaryLogoLight,
  hoverSubMenu: false,
  collapseState: { md: false, lg: false },
  expandOnHover: false, //Expand side nav on hover if side nav is collapsed & hoversubmenu is false
  bgColor: "#ffffff",
  bgColorDark: "#1A1D45",
  textColor: "#63636b",
  textColorDark: "#9AA1A7",
  iconColor: "#4B4A4A",
  iconColorDark: "#ffffff",
  activeColor: "#ffffff",
  activeColorDark: "#1D0AAF",
  activeBgColor: "#392ED6",
  activeBgColorDark: "#392ED6",
  hoverSubmenubg: "#000330",
  hoverSubmenubgDark: "#303030",
};

export const defaultCurrency = {
  name: "Norwegian Krone",
  code: "NOK",
  symbol: "NOK",
  symbolPosition: "after",
};

export const defaultCountry = {
  id: 160,
  name: "Norway",
  code: "NO",
  isdCode: "+47",
};
