import Req from "../interceptors/token-interceptor";

const DashboardService = (function () {
  function _getOverview(param) {
    let el = "";
    if (param.buildingId) {
      el += `&buildingId[]=${param.buildingId}`;
    }
    if (param.canteenId) {
      param.canteenId &&
        param.canteenId.forEach((element) => {
          el += `&canteenId[]=${element}`;
        });
    }
    if (param.customFromDate) {
      el += `&customFromDate=${param.customFromDate}`;
    }
    if (param.customToDate) {
      el += `&customToDate=${param.customToDate}`;
    }
    return Req.get(
      `/api/dashboard/overview?dateFilter=${param?.dateFilter || "today"}${el}`
    );
  }
  function _getSale(param) {
    let el = "";
    if (param.buildingId) {
      el += `&buildingId[]=${param.buildingId}`;
    }
    if (param.canteenId) {
      param.canteenId &&
        param.canteenId.forEach((element) => {
          el += `&canteenId[]=${element}`;
        });
    }
    if (param.customFromDate) {
      el += `&customFromDate=${param.customFromDate}`;
    }
    if (param.customToDate) {
      el += `&customToDate=${param.customToDate}`;
    }
    return Req.get(
      `/api/dashboard/sales-chart?dateFilter=${
      // `/api/dashboard/salesChart?dateFilter=${
        param?.dateFilter || "today"
      }${el}`
    );
  }
  function _getBestProduct(param) {
    let el = "";
    if (param.buildingId) {
      el += `&buildingId[]=${param.buildingId}`;
    }
    if (param.canteenId) {
      param.canteenId &&
        param.canteenId.forEach((element) => {
          el += `&canteenId[]=${element}`;
        });
    }
    if (param.customFromDate) {
      el += `&customFromDate=${param.customFromDate}`;
    }
    if (param.customToDate) {
      el += `&customToDate=${param.customToDate}`;
    }
    return Req.get(
      `/api/dashboard/best-sales-product?dateFilter=${
      // `/api/dashboard/bestSalesProduct?dateFilter=${
        param?.dateFilter || "today"
      }${el}`
    );
  }
  function _getPurchaseTypeSale(param) {
    let el = "";
    if (param.buildingId) {
      el += `&buildingId[]=${param.buildingId}`;
    }
    if (param.canteenId) {
      param.canteenId &&
        param.canteenId.forEach((element) => {
          el += `&canteenId[]=${element}`;
        });
    }
    if (param.customFromDate) {
      el += `&customFromDate=${param.customFromDate}`;
    }
    if (param.customToDate) {
      el += `&customToDate=${param.customToDate}`;
    }
    return Req.get(
      `/api/dashboard/purchase-type-sales?dateFilter=${
      // `/api/dashboard/purchaseTypeSales?dateFilter=${
        param?.dateFilter || "today"
      }${el}`
    );
  }
  function _getRecentProduct(param) {
    let el = "";
    if (param.buildingId) {
      el += `&buildingId[]=${param.buildingId}`;
    }
    if (param.canteenId) {
      param.canteenId &&
        param.canteenId.forEach((element) => {
          el += `&canteenId[]=${element}`;
        });
    }
    if (param.customFromDate) {
      el += `&customFromDate=${param.customFromDate}`;
    }
    if (param.customToDate) {
      el += `&customToDate=${param.customToDate}`;
    }
    return Req.get(
      `/api/dashboard/recent-products?dateFilter=${
      // `/api/dashboard/recentProducts?dateFilter=${
        param?.dateFilter || "today"
      }${el}`
    );
  }
  function _getCustomerRepeatRate(param) {
    let el = "";
    if (param.buildingId) {
      el += `&buildingId[]=${param.buildingId}`;
    }
    if (param.canteenId) {
      param.canteenId &&
        param.canteenId.forEach((element) => {
          el += `&canteenId[]=${element}`;
        });
    }
    if (param.customFromDate) {
      el += `&customFromDate=${param.customFromDate}`;
    }
    if (param.customToDate) {
      el += `&customToDate=${param.customToDate}`;
    }
    return Req.get(
      `/api/dashboard/customer-repeats?dateFilter=${
      // `/api/dashboard/customerRepeats?dateFilter=${
        param?.dateFilter || "today"
      }${el}`
    );
  }
  function _getTopTenant(param) {
    let el = "";
    if (param.buildingId) {
      el += `&buildingId[]=${param.buildingId}`;
    }
    if (param.canteenId) {
      param.canteenId &&
        param.canteenId.forEach((element) => {
          el += `&canteenId[]=${element}`;
        });
    }
    if (param.customFromDate) {
      el += `&customFromDate=${param.customFromDate}`;
    }
    if (param.customToDate) {
      el += `&customToDate=${param.customToDate}`;
    }
    return Req.get(
      `/api/dashboard/active-tenant?dateFilter=${
      // `/api/dashboard/activeTenant?dateFilter=${
        param?.dateFilter || "today"
      }${el}`
    );
  }
  return {
    getOverview: _getOverview,
    getSale: _getSale,
    getBestProduct: _getBestProduct,
    getPurchaseTypeSale: _getPurchaseTypeSale,
    getRecentProduct: _getRecentProduct,
    getCustomerRepeatRate: _getCustomerRepeatRate,
    getTopTenant: _getTopTenant,
  };
})();
export default DashboardService;
