import Req from "../interceptors/token-interceptor";
// import AuthReq from "../interceptors/auth-interceptor";
const AuthReq = Req;
const AuthService = (function () {

  function _verifyUser(token) {
    return AuthReq.post(`/api/users/verify/${token}`);
  }

  function _login(data) {
    return AuthReq.post("/api/login", data);
  }

  function _verifyOtp(data) {
    return AuthReq.post(`/api/verify/otp`, { otp: data.otp });
  }

  function _resendOtp(data) {
    return AuthReq.post(`/api/resend/otp/`, data);
  }

  function _initialChangePassword(data) {
    return AuthReq.post("/api/initial-change-password", data);
  }

  function _forgotPassword(data) {
    return AuthReq.post("/api/recover-password", data);
  }

  function _verifyResetToken(token) {
    return AuthReq.get(`/api/verify/reset-token/${token}`);
  }

  function _resetPassword(data) {
    return AuthReq.post("/api/reset-password", data);
  }

  function _verifyEmail(token) {
    return AuthReq.post(`/api/users/verify/email/${token}`);
  }

  function _logout() {
    return Req.get("/api/logout");
  }
  function _getAnnotationUrl() {
    return AuthReq.get("/api/ai/get-training-endpoint");
  }

  return {
    verifyUser: _verifyUser,
    login: _login,
    verifyOtp: _verifyOtp,
    resendOtp: _resendOtp,
    initialChangePassword: _initialChangePassword,
    forgotPassword: _forgotPassword,
    verifyResetToken: _verifyResetToken,
    resetPassword: _resetPassword,
    verifyEmail: _verifyEmail,
    logout: _logout,
    getAnnotationUrl: _getAnnotationUrl,
  };
})();
export default AuthService;
