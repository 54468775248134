import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { verifyTenantToken } from '../../reduxs/actions';
import { ValidatingToken } from './loading';
import { TokenExpired } from './token-expired';
import { TermsConditions } from "./terms-conditions";
import { UserTermsConditions } from "./user-terms-conditions";


export default function TermsAndCondition() {

    const { token } = useParams()
    useEffect(() => {
        document.body.style.background = 'white'
    }, [])


    if (token !== undefined) {
        return <QRTermsAndCondition />
    }

    return <UserTermsAndCondition />


}


const QRTermsAndCondition = () => {


    const { token } = useParams()
    const dispatch = useDispatch()
    const { loading, termsConditions, error } = useSelector((state) => state.termsAndCondition)


    useEffect(() => {
        dispatch(verifyTenantToken(token))
    }, [token, dispatch])

    if (loading) return <ValidatingToken />

    if (error) return <TokenExpired />



    return (!loading && termsConditions) && <TermsConditions termsConditions={termsConditions} token={token} />
}

const UserTermsAndCondition = () => {

    const { loadingBasicSetting, basicSettingData } = useSelector((state) => state.setting)

    if (loadingBasicSetting) return <ValidatingToken />

    const termsConditions = { orgName: basicSettingData?.orgName ?? "", mtc: basicSettingData?.mtc ?? "", otc: basicSettingData?.termsConditions ?? "" }


    return <UserTermsConditions termsConditions={termsConditions} />
}