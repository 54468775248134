
import { SegmentedControl } from '@mantine/core';
import * as Mui from '@material-ui/core';
import { sanitize } from 'dompurify';
import { useCallback, useEffect, useRef, useState } from "react";
import { getHostName } from '../../helpers/util';

const segmentStyle = {
    label: {
        height: '100%',
        fontSize: 14,
        paddingLeft: 20,
        paddingRight: 20,
        whiteSpace: 'break-spaces',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    labelActive: {
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'

    }
}
const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const UserTermsConditions = ({ termsConditions }) => {

    const isSuperadmin = getHostName() === ""

    const orgContentRef = useRef(null)
    const systemScrollRef = useRef(false);

    const containerRef = useRef();

    const [value, setValue] = useState('admin');



    const adminContentRef = useRef(null)

    const handleScroll = useCallback(() => {
        if (orgContentRef.current) {
            const rect = orgContentRef.current.getBoundingClientRect();
            const isVisible = (
                rect.top <= 300
            );
            if (systemScrollRef?.current) return
            setValue(isVisible ? 'org' : 'admin')
        }
        // const scrollElement = containerRef.current;
        // if (scrollElement) {
        //     const { scrollTop, scrollHeight, clientHeight } = scrollElement;
        //     // Check if we've reached the bottom of the div
        //     if (scrollTop + clientHeight + 250 >= scrollHeight) {
        //         setInBottom(true)
        //     } else {
        //         setInBottom(false)
        //     }
        // }
    }, []);


    useEffect(() => {
        const containerReference = containerRef.current
        containerReference?.addEventListener('scroll', handleScroll);
        containerReference?.addEventListener('scrollend', () => {
            systemScrollRef.current = false
        });

        handleScroll();

        return () => {
            containerReference?.removeEventListener('scroll', handleScroll);
            containerReference?.removeEventListener('scrollend', () => { });
        };
    }, [handleScroll]);

    const segmentData = !isSuperadmin ? [
        { label: 'Izy AS vilkår og betingelser', value: 'admin' },
        { label: `${capitalize(termsConditions?.orgName)} vilkår og betingelser`, value: 'org' },
    ] : [
        { label: `Izy AS vilkår og betingelser`, value: 'admin' },
    ]



    return <Mui.Box style={{ height: '100vh', width: '100vw' }} >
        <Mui.Box style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Mui.Box style={{ display: 'flex', justifyContent: 'center', marginTop: '3rem', marginBottom: '3rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
                <Mui.Box>
                    <SegmentedControl
                        value={value}
                        onChange={(val) => {
                            systemScrollRef.current = true;
                            if (val === 'admin') {
                                adminContentRef.current?.scrollIntoView({ behavior: 'smooth' });
                            } else {
                                orgContentRef.current?.scrollIntoView({ behavior: 'smooth' });
                            }
                            setValue(val)
                        }}
                        data={segmentData}
                        radius={'md'}
                        styles={segmentStyle}
                    />
                </Mui.Box>
            </Mui.Box>
            <Mui.Box style={{ flex: 1, overflow: 'hidden' }}>
                <Mui.Box className='tc-scroll-container' ref={containerRef} style={{ height: '100%', overflow: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <Mui.Box className="app-container-spacing">
                        <Mui.Box ref={adminContentRef}>
                            <div
                                style={{ color: "black", fontFamily: 'Poppins' }}
                                dangerouslySetInnerHTML={{ __html: sanitize(termsConditions?.mtc) }}
                            ></div>
                        </Mui.Box>

                        {!isSuperadmin && <Mui.Box ref={orgContentRef} style={{ marginTop: '5rem' }}>
                            <div
                                style={{ color: "black", fontFamily: 'Poppins' }}
                                dangerouslySetInnerHTML={{ __html: sanitize(termsConditions?.otc) }}
                            ></div>
                        </Mui.Box>}
                    </Mui.Box>
                </Mui.Box>
            </Mui.Box>

        </Mui.Box>

    </Mui.Box >
}




