
import { toast } from "react-toastify";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../components/toast";
import TableDataService from "../../services/table-data-service";
import { ADD_PRODUCT_CATEGORY_ITEM, addProductCategoryItemError, addProductCategoryItemSuccess, DELETE_PRODUCT_CATEGORY_ITEM, deleteProductCategoryItemError, deleteProductCategoryItemSuccess, EDIT_PRODUCT_CATEGORY_ITEM, editProductCategoryItemError, editProductCategoryItemSuccess, GET_ALL_PRODUCT_CATEGORY, GET_PRODUCT_CATEGORY_ITEM, GET_PRODUCT_CATEGORY_LIST, getAllProductCategoryError, getAllProductCategorySuccess, getProductCategoryItemError, getProductCategoryItemSuccess, getProductCategoryList, getProductCategoryListError, getProductCategoryListSuccess } from "./action";
import ProductCategoryService from "../../services/product-category-service";
import { parseMessage } from "../../helpers/util";


export function* watchGetProductCategoryList() {
    yield takeEvery(GET_PRODUCT_CATEGORY_LIST, getProductCategoryListAc);
}

const getProductCategoryListAsync = async (dbParam) => {
    return TableDataService.getAllData(
        "products/categories",
        dbParam?.orgId || "",
        dbParam?.search || "",
        dbParam?.searchFields || "",
        dbParam?.sortOrder || "",
        dbParam?.page || 1,
        dbParam?.pageSize || 10,
        dbParam?.activeCol || "",
        dbParam?.statusId || null
    );
};

function* getProductCategoryListAc({ payload }) {
    try {
        const response = yield call(getProductCategoryListAsync, payload.dbParam);
        if (response.data.success) {
            yield put(getProductCategoryListSuccess(response.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getProductCategoryListError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getProductCategoryListError(error.response.data.message));
    }
}


export function* watchGetProductCategoryItem() {
    yield takeEvery(GET_PRODUCT_CATEGORY_ITEM, getProductCategoryItem);
}

const getProductCategoryItemAsync = async (id) => {
    return ProductCategoryService.getProductCategoryItem(id);
};

function* getProductCategoryItem({ payload }) {
    try {
        const response = yield call(getProductCategoryItemAsync, payload.categoryId);
        if (response.data.success) {
            yield put(getProductCategoryItemSuccess(response.data.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getProductCategoryItemError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getProductCategoryItemError(error.response.data.message));
    }
}


export function* watchAddProductCategoryItem() {
    yield takeEvery(ADD_PRODUCT_CATEGORY_ITEM, addProductCategoryItem);
}

const addProductCategoryItemAsync = async (data) => {
    return ProductCategoryService.addProductCategoryItem(data);
};

function* addProductCategoryItem({ payload }) {
    const { history, location } = payload;
    try {
        const response = yield call(addProductCategoryItemAsync, payload.categoryData);
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                addProductCategoryItemSuccess(response.data.success, response.data.message)
            );
            history.push(`/product-management/product-category${location?.state?.locationSearch ?? ''}`);
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(addProductCategoryItemError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(addProductCategoryItemError(error.response.data.message));
    }
}



export function* watchEditProductCategoryItem() {
    yield takeEvery(EDIT_PRODUCT_CATEGORY_ITEM, editProductCategoryItem);
}

const editProductCategoryItemAsync = async (data, id) => {
    return ProductCategoryService.editProductCategoryItem(data, id);
};

function* editProductCategoryItem({ payload }) {
    const { history, location } = payload;
    try {
        const response = yield call(
            editProductCategoryItemAsync,
            payload.categoryData,
            payload.categoryId
        );
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                editProductCategoryItemSuccess(response.data.success, response.data.message)
            );
            history.push(`/product-management/product-category${location?.state?.locationSearch ?? ''}`);
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(editProductCategoryItemError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(editProductCategoryItemError(error.response.data.message));
    }
}


export function* watchDeleteProductCategoryItem() {
    yield takeEvery(DELETE_PRODUCT_CATEGORY_ITEM, deleteProductCategoryItem);
}

const deleteProductCategoryItemAsync = async (id) => {
    return ProductCategoryService.deleteProductCategoryItem(id);
};

function* deleteProductCategoryItem({ payload }) {
    try {
        const response = yield call(
            deleteProductCategoryItemAsync,
            payload.categoryId
        );
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                deleteProductCategoryItemSuccess(response.data.success, response.data.message)
            );
            // Fetch updated organization list
            yield put(getProductCategoryList({}));
        } else {
            yield put(deleteProductCategoryItemError(response.data.message));
        }
    } catch (error) {
        yield put(deleteProductCategoryItemError(error.response.data.message));
    }
}


export function* watchGetAllProductCategory() {
    yield takeEvery(GET_ALL_PRODUCT_CATEGORY, getAllProductCategory);
}

const getAllProductCategoryAsync = async () => {
    return ProductCategoryService.getAllProductCategory();
};

function* getAllProductCategory() {
    try {
        const response = yield call(getAllProductCategoryAsync);
        if (response.data) {
            yield put(getAllProductCategorySuccess(response.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response?.data?.message ?? 'Something went wrong'} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getAllProductCategoryError(response?.data?.message ?? 'Something went wrong'));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error?.response?.data?.message ?? 'Something went wrong'} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getAllProductCategoryError(error?.response?.data?.message ?? 'Something went wrong'));
    }
}


export default function* rootSaga() {
    yield all([
        fork(watchGetProductCategoryList),
        fork(watchGetProductCategoryItem),
        fork(watchAddProductCategoryItem),
        fork(watchEditProductCategoryItem),
        fork(watchDeleteProductCategoryItem),
        fork(watchGetAllProductCategory),
    ]);
}