import {
  DOWNLOAD_SALE_REPORT,
  DOWNLOAD_SALE_REPORT_SUCCESS,
  DOWNLOAD_SALE_REPORT_ERROR,
  GET_SALE_REPORT_HISTORY,
  GET_SALE_REPORT_HISTORY_SUCCESS,
  GET_SALE_REPORT_HISTORY_ERROR,
  RESET_REPORT,
  DOWNLOAD_ZX_REPORT,
  DOWNLOAD_ZX_REPORT_SUCCESS,
  DOWNLOAD_ZX_REPORT_ERROR,
  GET_Z_REPORT_HISTORY,
  GET_Z_REPORT_HISTORY_SUCCESS,
  GET_Z_REPORT_HISTORY_ERROR,
  DOWNLOAD_Z_REPORT,
  DOWNLOAD_Z_REPORT_SUCCESS,
  DOWNLOAD_Z_REPORT_ERROR,
  DOWNLOAD_BULK_Z_REPORT,
  DOWNLOAD_BULK_Z_REPORT_SUCCESS,
  DOWNLOAD_BULK_Z_REPORT_ERROR,
  GET_VAT_SALES_REPORT,
  GET_VAT_SALES_REPORT_SUCCESS,
  GET_VAT_SALES_REPORT_ERROR,
  EXPORT_VAT_SALES_EXCEL_REPORT,
  EXPORT_VAT_SALES_EXCEL_REPORT_SUCCESS,
  EXPORT_VAT_SALES_EXCEL_REPORT_ERROR,
  GET_AGGREGATED_REPORT,
  GET_AGGREGATED_REPORT_SUCCESS,
  GET_AGGREGATED_REPORT_ERROR,
} from "../actions";

const INIT_STATE = {
  param: null,
  saleReportHistory: null,
  zReportHistory: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
  vatSalesReport: null,
  aggregatedReport: null,
};

const reportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOWNLOAD_SALE_REPORT:
      return { ...state, loading: true, error: null };
    case DOWNLOAD_SALE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_SALE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_REPORT:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: null,
      };
    case GET_SALE_REPORT_HISTORY:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case GET_SALE_REPORT_HISTORY_SUCCESS:
      return {
        ...state,
        loading1: false,
        saleReportHistory: action.payload,
        error: null,
      };
    case GET_SALE_REPORT_HISTORY_ERROR:
      return {
        ...state,
        loading1: false,
        saleReportHistory: null,
        error: action.payload,
      };
    case DOWNLOAD_ZX_REPORT:
      return { ...state, loading: true, error: null };
    case DOWNLOAD_ZX_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_ZX_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case GET_Z_REPORT_HISTORY:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case GET_Z_REPORT_HISTORY_SUCCESS:
      return {
        ...state,
        loading1: false,
        zReportHistory: action.payload,
        error: null,
      };
    case GET_Z_REPORT_HISTORY_ERROR:
      return {
        ...state,
        loading1: false,
        zReportHistory: null,
        error: action.payload,
      };

    case DOWNLOAD_Z_REPORT:
      return { ...state, loading: true, error: null };
    case DOWNLOAD_Z_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_Z_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case DOWNLOAD_BULK_Z_REPORT:
      return { ...state, loading: true, error: null };
    case DOWNLOAD_BULK_Z_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_BULK_Z_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case GET_VAT_SALES_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_VAT_SALES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        vatSalesReport: action.payload,
        error: null,
      };
    case GET_VAT_SALES_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        vatSalesReport: null,
        error: action.payload,
      };
    case EXPORT_VAT_SALES_EXCEL_REPORT:
      return { ...state, loading1: true, success: false, error: null };
    case EXPORT_VAT_SALES_EXCEL_REPORT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_VAT_SALES_EXCEL_REPORT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case GET_AGGREGATED_REPORT:
      return { ...state, loading: true, error: null };
    case GET_AGGREGATED_REPORT_SUCCESS:
      return { ...state, loading: false, aggregatedReport: action.payload, error: null };
    case GET_AGGREGATED_REPORT_ERROR:
      return { ...state, loading: false, aggregatedReport: null, error: action.payload };
    default:
      return { ...state };
  }
};
export default reportReducer;
