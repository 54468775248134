import Req from "../interceptors/token-interceptor";

const TenantEmployeesService = (function () {
  function _getAllTenantEmployees(tenantId) {
    return Req.get(`/api/tenant-user?tenantId=${tenantId}`);
  }
  function _getTenantEmployee(id, organizationId) {
    let el = "";
    if (organizationId) {
      el += `?organizationId=${organizationId}`;
    }
    return Req.get(`/api/tenant-user/${id}${el}`);
  }
  function _addTenantEmployee(data) {
    return Req.post("/api/tenant-user", data);
  }
  function _editTenantEmployee(data, id) {
    return Req.put(`/api/tenant-user/${id}`, data);
  }
  function _deleteTenantEmployee(id) {
    return Req.delete(`/api/tenant-user/${id}`);
  }
  function _importTenantEmployees(param, tenantId) {
    var res = new FormData();
    res.append("file", param.file);
    res.append("tenant_id", param.tenant_id);
    return Req.post("/api/tenant-user/import/user", res, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  function _downloadEmployeeQr(id) {
    return Req.get(`/api/tenant-user/qr/download/${id}`, {
      responseType: "arraybuffer",
    });
  }
  function _sendEmployeeQr(id) {
    return Req.get(`api/tenant-user/qr/resend/${id}`);
  }
  // function _deleteMultipleTenant(ids) {
  //   return Req.post("/api/tenants/delete", { ids: ids });
  // }

  function _downloadEmployeeImportTemplate() {
    return Req.get('api/tenant-user/sample-csv')
  }
  return {
    getAllTenantEmployees: _getAllTenantEmployees,
    getTenantEmployee: _getTenantEmployee,
    addTenantEmployee: _addTenantEmployee,
    editTenantEmployee: _editTenantEmployee,
    deleteTenantEmployee: _deleteTenantEmployee,
    importTenantEmployees: _importTenantEmployees,
    downloadEmployeeQr: _downloadEmployeeQr,
    sendEmployeeQr: _sendEmployeeQr,
    downloadEmployeeImportTemplate: _downloadEmployeeImportTemplate
    // deleteMultipleTenant: _deleteMultipleTenant,
  };
})();
export default TenantEmployeesService;
