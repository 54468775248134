import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import { connect } from "react-redux";
import { getNotificationList } from "../../../reduxs/actions";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/breadcrumb";
import { StyledChip } from "../../../components/styled";
import moment from "moment";
class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = { page: 1 };
  }

  componentDidMount() {
    // this.props.getNotificationList({ page: 1 });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.props.getNotificationList({
      page: newPage,
    });
  };

  setColor = (module) => {
    if (module === "user") {
      return "#44AF83";
    } else if (module === "role") {
      return "#ECB12C";
    } else if (module === "kiosk") {
      return "#3087CD";
    } else {
      return "#C1031B";
    }
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Breadcrumb
          title={t("NOTIFICATION.TITLE")}
          paths={[
            {
              title: t("NOTIFICATION.TITLE"),
            },
          ]}
        />

        <Mui.Card className="default-card spacing-md">
          <Mui.Box
            className="mb-4"
            width="100%"
            display="flex"
            alignItems="center"
            flexWrap="wrap"
          >
            <Mui.Typography
              component="h5"
              variant="h5"
              className="mr-auto font-weight-medium"
            >
              {t("NOTIFICATION.LIST_LABEL")}
            </Mui.Typography>
          </Mui.Box>

          <Mui.List
            subheader={<li />}
            style={{ maxHeight: "60vh", overflow: "auto" }}
          >
            <Mui.ListSubheader className="d-flex align-items-center p-0 pb-1 border-bottom-1 border-color-light-grey background-color-white">
              <Mui.Checkbox color="primary" className="mr-1" />
              <Mui.Typography>{t("NOTIFICATION.TITLE")}</Mui.Typography>

              <Mui.FormControlLabel
                className="ml-auto mr-0 flex-shrink-0"
                control={
                  <Mui.Checkbox
                    color="primary"
                    label={t("NOTIFICATION.MARK_ALL_READ")}
                  />
                }
                label={t("NOTIFICATION.MARK_ALL_READ")}
              />
            </Mui.ListSubheader>

            {this.props.notificationList?.slice(0, 10).map((item, index) => {
              return (
                <>
                  <Mui.ListItem className="mt-2 p-0">
                    <Mui.Checkbox
                      color="primary"
                      className="align-self-start mr-1 flex-shrink-0"
                    />
                    <Mui.Box
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      flexWrap={
                        Mui.isWidthDown("xs", this.props.width)
                          ? "wrap"
                          : "noWrap"
                      }
                    >
                      <Mui.Typography
                        component="h6"
                        variant="h6"
                        className={`font-weight-normal text-color-grey  ${
                          Mui.isWidthDown("xs", this.props.width)
                            ? "line-height-2x"
                            : "line-height-3x"
                        }`}
                      >
                        <StyledChip
                          bgcolor={this.setColor(item.module)}
                          label={item.module}
                          className="border-rad-4"
                        />
                        <Mui.Typography
                          component="span"
                          variant="h4"
                          className="d-block font-weight-normal text-color-default"
                        >
                          <Mui.Typography
                            component="span"
                            variant="h4"
                            className={`mr-1 font-weight-normal text-color-primary ${
                              Mui.isWidthDown("xs", this.props.width)
                                ? "d-block"
                                : ""
                            }`}
                          >
                            {item.module}:
                          </Mui.Typography>
                          {item.title}
                        </Mui.Typography>
                        {item.description}
                        <Mui.Typography
                          component="strong"
                          className="d-block font-weight-medium text-color-default"
                        >
                          {item.createdBy?.name || "-"}
                        </Mui.Typography>
                      </Mui.Typography>

                      <Mui.Typography
                        component="span"
                        variant="h6"
                        className={`font-weight-normal text-color-grey ${
                          Mui.isWidthDown("xs", this.props.width)
                            ? "w-100 mt-2"
                            : ""
                        }`}
                      >
                        {moment(item.createdAt).format("MMM DD, YYYY Hh:mm A")}
                      </Mui.Typography>
                    </Mui.Box>
                  </Mui.ListItem>
                  <Mui.Divider light={true} className="mt-2" />
                </>
              );
            })}

            <Mui.ListItem className="mt-2 p-0">
              <Mui.Checkbox
                color="primary"
                className="align-self-start mr-1 flex-shrink-0"
              />
              <Mui.Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                flexWrap={
                  Mui.isWidthDown("xs", this.props.width) ? "wrap" : "noWrap"
                }
              >
                <Mui.Typography
                  component="h6"
                  variant="h6"
                  className={`font-weight-normal text-color-grey  ${
                    Mui.isWidthDown("xs", this.props.width)
                      ? "line-height-2x"
                      : "line-height-3x"
                  }`}
                >
                  <StyledChip
                    bgcolor={this.setColor("blue")}
                    label="Item updated"
                    className="border-rad-4"
                  />
                  <Mui.Typography
                    component="span"
                    variant="h4"
                    className="d-block font-weight-normal text-color-default"
                  >
                    <Mui.Typography
                      component="span"
                      variant="h4"
                      className={`mr-1 font-weight-normal text-color-primary ${
                        Mui.isWidthDown("xs", this.props.width) ? "d-block" : ""
                      }`}
                    >
                      New food item:
                    </Mui.Typography>
                    Jonathan o’corner sent to you message
                  </Mui.Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod empor incididunt ut labore et dolore magna aliqua.
                  <Mui.Typography
                    component="strong"
                    className="d-block font-weight-medium text-color-default"
                  >
                    Jonathan o’corner
                  </Mui.Typography>
                </Mui.Typography>

                <Mui.Typography
                  component="span"
                  variant="h6"
                  className={`font-weight-normal text-color-grey ${
                    Mui.isWidthDown("xs", this.props.width) ? "w-100 mt-2" : ""
                  }`}
                >
                  Jun 12, 2021 at 2:45 pm
                </Mui.Typography>
              </Mui.Box>
            </Mui.ListItem>
            <Mui.Divider light={true} className="mt-2" />

            <Mui.ListItem className="mt-2 p-0">
              <Mui.Checkbox
                color="primary"
                className="align-self-start mr-1 flex-shrink-0"
              />
              <Mui.Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                flexWrap={
                  Mui.isWidthDown("xs", this.props.width) ? "wrap" : "noWrap"
                }
              >
                <Mui.Typography
                  component="h6"
                  variant="h6"
                  className={`font-weight-normal text-color-grey  ${
                    Mui.isWidthDown("xs", this.props.width)
                      ? "line-height-2x"
                      : "line-height-3x"
                  }`}
                >
                  <StyledChip
                    bgcolor={this.setColor("red")}
                    label="Account disabled"
                    className="border-rad-4"
                  />
                  <Mui.Typography
                    component="span"
                    variant="h4"
                    className="d-block font-weight-normal text-color-default"
                  >
                    <Mui.Typography
                      component="span"
                      variant="h4"
                      className={`mr-1 font-weight-normal text-color-primary ${
                        Mui.isWidthDown("xs", this.props.width) ? "d-block" : ""
                      }`}
                    >
                      Warning account:
                    </Mui.Typography>
                    Jonathan o’corner sent to you message
                  </Mui.Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod empor incididunt ut labore et dolore magna aliqua.
                  <Mui.Typography
                    component="strong"
                    className="d-block font-weight-medium text-color-default"
                  >
                    Jonathan o’corner
                  </Mui.Typography>
                </Mui.Typography>

                <Mui.Typography
                  component="span"
                  variant="h6"
                  className={`font-weight-normal text-color-grey ${
                    Mui.isWidthDown("xs", this.props.width) ? "w-100 mt-2" : ""
                  }`}
                >
                  Jun 12, 2021 at 2:45 pm
                </Mui.Typography>
              </Mui.Box>
            </Mui.ListItem>
            <Mui.Divider light={true} className="mt-2" />
          </Mui.List>
          {this.props.notificationList?.meta?.total > 0 ? (
            <Mui.Box
              className="mt-5 pt-5 pagination-datatable"
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <Mui.Box className="pagination-card">
                <MuiLab.Pagination
                  page={this.state.page}
                  onChange={this.handleChangePage}
                  count={this.props.notificationList?.meta?.pages || 0}
                  variant="outlined"
                  shape="rounded"
                  showFirstButton={true}
                  showLastButton={true}
                  className="m-0"
                />
              </Mui.Box>
            </Mui.Box>
          ) : null}
        </Mui.Card>
      </>
    );
  }
}
const mapStateToProps = ({ shared }) => {
  const { notificationList, loading } = shared;
  return {
    notificationList,
    loading,
  };
};

export default connect(mapStateToProps, {
  getNotificationList,
})(withTranslation()(Mui.withWidth()(NotificationList)));
