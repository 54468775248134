import React from "react";

const IconCanteen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      type="stroke"
    >
      <path
        d="M5.50781 7.68164H18.6764"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.6591V8.07812"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9946 19.0527H13.1894"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.913086 0.945312L3.36683 10.7592V18.6604"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.80985 18.6577V10.9902H3.36572"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.087 0.945312L20.6333 10.7592V18.6604"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1924 18.6577V10.9902H20.6365"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6399 6.11011C15.4475 5.28879 14.9833 4.55655 14.3226 4.03209C13.6619 3.50763 12.8435 3.22171 11.9999 3.2207C11.1564 3.22171 10.3379 3.50763 9.67717 4.03209C9.01647 4.55655 8.55227 5.28879 8.35986 6.11011"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.2226V2.20312"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0146 1.98633H12.9856"
        stroke="#959595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCanteen;
