export const GET_PRODUCT_CATEGORY_LIST = "GET_PRODUCT_CATEGORY_LIST";
export const GET_PRODUCT_CATEGORY_LIST_SUCCESS = "GET_PRODUCT_CATEGORY_LIST_SUCCESS";
export const GET_PRODUCT_CATEGORY_LIST_ERROR = "GET_PRODUCT_CATEGORY_LIST_ERROR";

export const GET_PRODUCT_CATEGORY_ITEM = "GET_PRODUCT_CATEGORY_ITEM";
export const GET_PRODUCT_CATEGORY_ITEM_SUCCESS = "GET_PRODUCT_CATEGORY_ITEM_SUCCESS";
export const GET_PRODUCT_CATEGORY_ITEM_ERROR = "GET_PRODUCT_CATEGORY_ITEM_ERROR";

export const ADD_PRODUCT_CATEGORY_ITEM = "ADD_PRODUCT_CATEGORY_ITEM";
export const ADD_PRODUCT_CATEGORY_ITEM_SUCCESS = "ADD_PRODUCT_CATEGORY_ITEM_SUCCESS";
export const ADD_PRODUCT_CATEGORY_ITEM_ERROR = "ADD_PRODUCT_CATEGORY_ITEM_ERROR";

export const EDIT_PRODUCT_CATEGORY_ITEM = "EDIT_PRODUCT_CATEGORY_ITEM";
export const EDIT_PRODUCT_CATEGORY_ITEM_SUCCESS = "EDIT_PRODUCT_CATEGORY_ITEM_SUCCESS";
export const EDIT_PRODUCT_CATEGORY_ITEM_ERROR = "EDIT_PRODUCT_CATEGORY_ITEM_ERROR";

export const DELETE_PRODUCT_CATEGORY_ITEM = "DELETE_PRODUCT_CATEGORY_ITEM";
export const DELETE_PRODUCT_CATEGORY_ITEM_SUCCESS = "DELETE_PRODUCT_CATEGORY_ITEM_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_ITEM_ERROR = "DELETE_PRODUCT_CATEGORY_ITEM_ERROR";

export const GET_ALL_PRODUCT_CATEGORY = "GET_ALL_PRODUCT_CATEGORY";
export const GET_ALL_PRODUCT_CATEGORY_SUCCESS = "GET_ALL_PRODUCT_CATEGORY_SUCCESS";
export const GET_ALL_PRODUCT_CATEGORY_ERROR = "GET_ALL_PRODUCT_CATEGORY_ERROR";

export const RESET_PRODUCT_CATEGORY_ITEM = "RESET_PRODUCT_CATEGORY_ITEM";


export const getProductCategoryList = (dbParam) => ({
    type: GET_PRODUCT_CATEGORY_LIST,
    payload: { dbParam },
});

export const getProductCategoryListSuccess = (categoryList) => ({
    type: GET_PRODUCT_CATEGORY_LIST_SUCCESS,
    payload: categoryList,
});

export const getProductCategoryListError = (error) => ({
    type: GET_PRODUCT_CATEGORY_LIST_ERROR,
    payload: error,
});


export const getProductCategoryItem = (categoryId) => ({
    type: GET_PRODUCT_CATEGORY_ITEM,
    payload: { categoryId },
});

export const getProductCategoryItemSuccess = (categoryData) => ({
    type: GET_PRODUCT_CATEGORY_ITEM_SUCCESS,
    payload: categoryData,
});

export const getProductCategoryItemError = (error) => ({
    type: GET_PRODUCT_CATEGORY_ITEM_ERROR,
    payload: error,
});



export const addProductCategoryItem = (categoryData, history, location) => ({
    type: ADD_PRODUCT_CATEGORY_ITEM,
    payload: { categoryData, history, location },
});

export const addProductCategoryItemSuccess = (success, message) => ({
    type: ADD_PRODUCT_CATEGORY_ITEM_SUCCESS,
    payload: { success, message },
});

export const addProductCategoryItemError = (error) => ({
    type: ADD_PRODUCT_CATEGORY_ITEM_ERROR,
    payload: error,
});


export const editProductCategoryItem = (
    categoryId,
    categoryData,
    history,
    location
) => ({
    type: EDIT_PRODUCT_CATEGORY_ITEM,
    payload: { categoryId, categoryData, history, location },
});

export const editProductCategoryItemSuccess = (success, message) => ({
    type: EDIT_PRODUCT_CATEGORY_ITEM_SUCCESS,
    payload: { success, message },
});

export const editProductCategoryItemError = (error) => ({
    type: EDIT_PRODUCT_CATEGORY_ITEM_ERROR,
    payload: error,
});


export const deleteProductCategoryItem = (categoryId) => ({
    type: DELETE_PRODUCT_CATEGORY_ITEM,
    payload: { categoryId },
});

export const deleteProductCategoryItemSuccess = (success, message) => ({
    type: DELETE_PRODUCT_CATEGORY_ITEM_SUCCESS,
    payload: { success, message },
});

export const deleteProductCategoryItemError = (error) => ({
    type: DELETE_PRODUCT_CATEGORY_ITEM_ERROR,
    payload: error,
});

export const getAllProductCategory = () => ({
    type: GET_ALL_PRODUCT_CATEGORY,
    payload: null,
});

export const getAllProductCategorySuccess = (categoryList) => ({
    type: GET_ALL_PRODUCT_CATEGORY_SUCCESS,
    payload: categoryList,
});

export const getAllProductCategoryError = (error) => ({
    type: GET_ALL_PRODUCT_CATEGORY_ERROR,
    payload: error,
});

export const resetProductCategoryItem = () => ({
    type: RESET_PRODUCT_CATEGORY_ITEM,
    payload: {},
});