import React from "react";

const IconUser = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M11.9488 14.5527C8.49884 14.5527 5.58789 15.1165 5.58789 17.2922C5.58789 19.4689 8.51765 20.0128 11.9488 20.0128C15.3988 20.0128 18.3098 19.4491 18.3098 17.2733C18.3098 15.0967 15.38 14.5527 11.9488 14.5527Z"
        fill="#808089"
      />
      <path
        opacity="0.4"
        d="M11.949 12.4797C14.2851 12.4797 16.1583 10.5958 16.1583 8.24621C16.1583 5.89576 14.2851 4.0127 11.949 4.0127C9.61293 4.0127 7.73975 5.89576 7.73975 8.24621C7.73975 10.5958 9.61293 12.4797 11.949 12.4797Z"
        fill="#808089"
      />
      <path
        opacity="0.4"
        d="M21.0881 9.23241C21.6925 6.85495 19.9205 4.71973 17.664 4.71973C17.4187 4.71973 17.1841 4.74674 16.9549 4.79267C16.9244 4.79988 16.8904 4.81519 16.8725 4.8422C16.8519 4.87642 16.8671 4.92235 16.8895 4.95207C17.5673 5.90846 17.9568 7.07288 17.9568 8.32285C17.9568 9.52059 17.5996 10.6373 16.9728 11.564C16.9083 11.6594 16.9656 11.7882 17.0793 11.808C17.2369 11.8359 17.3981 11.8503 17.5629 11.8548C19.2059 11.8981 20.6807 10.8345 21.0881 9.23241Z"
        fill="#808089"
      />
      <path
        d="M22.8094 14.8292C22.5086 14.1844 21.7824 13.7422 20.6783 13.5252C20.1572 13.3973 18.747 13.2172 17.4352 13.2415C17.4155 13.2442 17.4048 13.2577 17.403 13.2667C17.4003 13.2793 17.4057 13.3009 17.4316 13.3144C18.0378 13.6161 20.3811 14.9282 20.0865 17.6956C20.074 17.8154 20.1698 17.919 20.2888 17.901C20.8655 17.8181 22.3492 17.4975 22.8094 16.4988C23.0637 15.9711 23.0637 15.3578 22.8094 14.8292Z"
        fill="#808089"
      />
      <path
        opacity="0.4"
        d="M7.04459 4.79267C6.81626 4.74584 6.58077 4.71973 6.33543 4.71973C4.07901 4.71973 2.30701 6.85495 2.9123 9.23241C3.31882 10.8345 4.79355 11.8981 6.43661 11.8548C6.60136 11.8503 6.76343 11.835 6.92013 11.808C7.03384 11.7882 7.09115 11.6594 7.02668 11.564C6.3999 10.6364 6.04263 9.52059 6.04263 8.32285C6.04263 7.07198 6.43303 5.90756 7.11085 4.95207C7.13234 4.92235 7.14845 4.87642 7.12696 4.8422C7.10906 4.81428 7.07593 4.79988 7.04459 4.79267Z"
        fill="#808089"
      />
      <path
        d="M3.32156 13.5254C2.21752 13.7424 1.49225 14.1846 1.19139 14.8294C0.936203 15.358 0.936203 15.9713 1.19139 16.4999C1.65163 17.4977 3.13531 17.8192 3.71195 17.9012C3.83104 17.9192 3.92595 17.8165 3.91342 17.6959C3.61883 14.9294 5.9621 13.6173 6.56918 13.3156C6.59425 13.3012 6.59962 13.2804 6.59694 13.2669C6.59515 13.2579 6.5853 13.2444 6.5656 13.2426C5.25294 13.2174 3.84358 13.3975 3.32156 13.5254Z"
        fill="#808089"
      />
    </svg>
  );
};

export default IconUser;
