import React from "react";
import * as Mui from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CheckboxTable } from "../checkbox/checkbox-table";

const TableHeader = (props) => {
  const {
    onSelectAllClick,
    sortOrder,
    activeCol,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    enableCheckbox,
    hideActionColumn = false
  } = props;

  const createSortHandler = (property) => (event) => {

    if (property.sortable === false) return

    onRequestSort(event, property.sortKey);
  };

  const { t } = useTranslation();

  return (
    <Mui.TableHead>
      <Mui.TableRow>
        {enableCheckbox ? (
          <Mui.TableCell padding="checkbox">
            <CheckboxTable
              // color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </Mui.TableCell>
        ) : null}

        {headCells &&
          headCells.map((headCell) => (
            <Mui.TableCell
              key={headCell.sortKey}
              sortDirection={activeCol === headCell.sortKey ? sortOrder : false}
            >
              {headCell.sortable === false ?
                <Mui.Typography style={{ cursor: 'default' }} component="span" className="font-weight-medium ">
                  {headCell.label}
                  {headCell.subLabel ? (
                    <Mui.Typography
                      component="small"
                      variant="body2"
                      className="d-block "
                    >
                      {headCell.subLabel}
                    </Mui.Typography>
                  ) : null}
                </Mui.Typography> :
                <Mui.TableSortLabel
                  active={activeCol === headCell.sortKey}
                  direction={activeCol === headCell.sortKey ? sortOrder : "asc"}
                  onClick={createSortHandler(headCell)}
                  hideSortIcon={headCell.sortable === false}
                >
                  <Mui.Typography component="span" className="font-weight-medium">
                    {headCell.label}
                    {headCell.subLabel ? (
                      <Mui.Typography
                        component="small"
                        variant="body2"
                        className="d-block "
                      >
                        {headCell.subLabel}
                      </Mui.Typography>
                    ) : null}
                  </Mui.Typography>
                </Mui.TableSortLabel>
              }
            </Mui.TableCell>
          ))}
        {!hideActionColumn && <Mui.TableCell align={"left"} padding={"normal"}>
          {t("COMMON.FORM.ACTION.LABEL")}
        </Mui.TableCell>}
      </Mui.TableRow>
    </Mui.TableHead>
  );
};
export default TableHeader;
