import {
  GET_ALL_MENU,
  GET_ALL_MENU_SUCCESS,
  GET_ALL_MENU_ERROR,
  GET_MENU_LIST,
  GET_MENU_LIST_SUCCESS,
  GET_MENU_LIST_ERROR,
  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  EDIT_MENU,
  EDIT_MENU_SUCCESS,
  EDIT_MENU_ERROR,
  FETCH_MENU,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ERROR,
  ADD_MENU_CLASS,
  ADD_MENU_CLASS_SUCCESS,
  ADD_MENU_CLASS_ERROR,
  RESET_MENU,
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  param: null,
  menus: null,
  menuList: null,
  menuData: null,
  menuId: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const menuReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_MENU:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_MENU_SUCCESS:
      return {
        ...state,
        menus: action.payload,
        error: null,
      };
    case GET_ALL_MENU_ERROR:
      return {
        ...state,
        menus: null,
        error: action.payload,
      };
    case GET_MENU_LIST:
      return {
        ...state,
        loading: true,
        menuId: null,
        menuData: null,
        error: null,
      };
    case GET_MENU_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        menuList: action.payload,
        error: null,
      };
    case GET_MENU_LIST_ERROR:
      return {
        ...state,
        loading: false,
        menuList: null,
        error: action.payload,
      };
    case GET_MENU:
      return { ...state, error: null };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        menuData: action.payload,
        error: null,
      };
    case GET_MENU_ERROR:
      return {
        ...state,
        menuData: null,
        error: action.payload,
      };
    case EDIT_MENU:
      return { ...state, loading: true, error: null };
    case EDIT_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_MENU_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case FETCH_MENU:
      return { ...state, loading1: true, error: null };
    case FETCH_MENU_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case FETCH_MENU_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case ADD_MENU_CLASS:
      return { ...state, loading: true, error: null };
    case ADD_MENU_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_MENU_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_MENU:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default menuReducer;
