import 'intl';
import 'intl/locale-data/jsonp/en';
import { each, find } from "lodash";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
moment.suppressDeprecationWarnings = true;

export const getMonthlyWeekList = (year, month) => {
  const startDate = moment.utc([year, month]);
  // Get the first and last day of the month
  const firstDay = moment(startDate).startOf("month");
  const endDay = moment(startDate).endOf("month");
  // Create a range for the month we can iterate through
  const monthRange = moment.range(firstDay, endDay);
  const weeks = [];
  for (let mday of monthRange.by("days")) {
    if (weeks.indexOf(mday.week()) === -1) {
      weeks.push(mday.week());
    }
  }
  // Create a range for each week
  const calendar = [];
  for (let index = 0; index < weeks.length; index++) {
    var weeknumber = weeks[index];

    let firstWeekDay = moment().year(year).month(month).week(weeknumber).day(0);
    let lastWeekDay = moment().year(year).month(month).week(weeknumber).day(6);
    if (month === 11 && weeks.length - 1 === index) {
      firstWeekDay = moment()
        .year(year)
        .month(month)
        .week(weeks[index - 1])
        .day(0);
      firstWeekDay.add(7, "day");
      lastWeekDay = moment()
        .year(year)
        .month(month)
        .week(weeks[index - 1])
        .day(6);
      lastWeekDay.add(6, "day");
    }
    if (firstWeekDay.isBefore(firstDay)) {
      firstWeekDay = firstDay;
    }
    if (lastWeekDay.isAfter(endDay)) {
      lastWeekDay = endDay;
    }
    const weekRange = moment.range(firstWeekDay, lastWeekDay);
    calendar.push(weekRange);
  }
  return calendar;
};

export const getWeekStartDate = (year, month, weekIndex) => {
  const weeks = getMonthlyWeekList(year, month);
  return weeks[weekIndex]["start"];
};

export const getWeekEndDate = (year, month, weekIndex) => {
  const weeks = getMonthlyWeekList(year, month);
  return weeks[weekIndex]["end"];
};

export const getYearlyWeekList = (year) => {
  let weeks = [];
  let weeksInYear = moment(year).isoWeeksInYear();
  for (let weekNumber = 1; weekNumber < weeksInYear + 1; weekNumber++) {
    weeks.push(weekNumber);
  }
  return weeks;
};

export const getWeeklyDateList = (year, week) => {
  let start = moment().year(year).isoWeek(week).day(1);
  let end = moment().year(year).isoWeek(week).day(7);
  return getDates(start, end);
};

// Returns an array of dates between the two dates
export const getDates = (startDate, endDate) => {
  let date = [];
  while (moment(startDate) <= moment(endDate)) {
    date.push(startDate);
    startDate = moment(startDate).add(1, "days");
  }
  return date;
};

export const getWeekCurrentDate = (year, week, index) => {
  let start = moment().year(year).isoWeek(week).day(1);
  let end = moment().year(year).isoWeek(week).day(7);
  let dates = getDates(start, end);
  return dates[index];
};

export const getWeekDateIndex = (year, week, date) => {
  let start = moment().year(year).isoWeek(week).day(1);
  let end = moment().year(year).isoWeek(week).day(7);
  let dates = getDates(start, end);
  return dates.findIndex((el) => moment(el).isSame(date));
};

export const formatCurrency = (amount, language) => {

  if (amount === undefined || amount === null) return '-'

  const formatter = new Intl.NumberFormat('no-NO', {
    style: 'currency',
    currency: 'NOK'
  });
  let formatted = formatter.format(amount).replace('kr', '').replace('NOK', '').trim()
  if (language === 'en') {
    formatted = 'NOK ' + formatted.replace(',', '.')
  } else {
    formatted = formatted + ' kr'
  }

  return formatted


  // let sym = defaultCurrency.symbol || "";
  // let pos = defaultCurrency.symbolPosition || "after";
  // // let val = value ? numberWithCommas(value) : "0";
  // let val = value ?? "0";

  // if (defaultCurrency.code === "NOK") {
  //   val = val.toString().replace(".", ",");
  // }
  // //there must be always 2 decimals
  // if (val.indexOf(",") === -1) {
  //   val += ",00";
  // } else {
  //   let decimal = val.split(",")[1];
  //   if (decimal.length === 1) {
  //     val += "0";
  //   }
  // }


  // if (pos === "before") {
  //   return `${sym} ${val}`;
  // } else {
  //   return `${val} ${sym}`;
  // }
};

export const parseMessage = (message) => {
  var text = "";
  if (typeof message == "object") {
    text += "\n";
    for (let key in message) {
      if (Array.isArray(message[key])) {
        // eslint-disable-next-line no-loop-func
        message[key].forEach((elem) => {
          text += elem + "\n";
        });
      } else if (typeof message[key] == "string") {
        text += message[key] + "\n";
      } else {
        text += JSON.stringify(message[key]) + "\n";
      }
    }
    text += "\n";
  } else if (typeof message == "string") {
    text = message;
  } else {
    text += JSON.stringify(message);
  }
  return text;
};

export const bytesToHuman = (bytes) => {
  let units = ["B", "KB", "MB", "GB", "TB", "PB"];
  let i = 0;
  for (i = 0; bytes >= 1024; i++) {
    bytes /= 1024;
  }
  return Math.round(bytes, 2) + " " + units[`${i}`];
};

export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    : isoCode;
};

export const getOpeningDay = () => {
  let arr = [];
  for (var i = 1; i <= 7; i++) {
    var opening = {
      day: "",
      status: 22,
      openingTime: "",
      closingTime: "",
    };
    switch (i) {
      case 1:
        opening.day = "monday";
        break;
      case 2:
        opening.day = "tuesday";
        break;
      case 3:
        opening.day = "wednesday";
        break;
      case 4:
        opening.day = "thursday";
        break;
      case 5:
        opening.day = "friday";
        break;
      case 6:
        opening.day = "saturday";
        break;
      case 7:
        opening.day = "sunday";
        break;
      default:
        break;
    }
    arr.push(opening);
  }
  return arr;
};

export const getHostName = () => {
  if (
    window.location.hostname.split(".")[0] !== "localhost" &&
    window.location.hostname.split(".")[0] !== "test" &&
    window.location.hostname.split(".")[0] !== "staging" &&
    window.location.hostname.split(".")[0] !== "maifood" &&
    window.location.hostname.split(".")[0] !== "sandbox-client" &&
    window.location.hostname.split(".")[0] !== "sandbox" &&
    window.location.hostname.split(".")[0] !== "demo" &&
    window.location.hostname.split(".")[0] !== "app"
  ) {
    return window.location.hostname.split(".")[0];
  }
  return ''
}

export const getApiUrl = () => {
  // var apiBaseUrl = window.location.protocol + "//";
  var apiBaseUrl = "https://";
  if (getHostName() !== '') {
    apiBaseUrl += window.location.hostname.split(".")[0] + ".";
  }
  apiBaseUrl += process.env.REACT_APP_API_BASE_URL;
  return apiBaseUrl;
  // return (
  //   window.location.protocol +
  //   "//" +
  //   (window.location.hostname === process.env.REACT_APP_API_BASE_URL
  //     ? ""
  //     : window.location.hostname.split(".")[0] + ".") +
  //   "api." +
  //   process.env.REACT_APP_API_BASE_URL
  // );

  // if (process.env.REACT_APP_API_BASE_ENVIRONMENT === "test" || process.env.REACT_APP_API_BASE_ENVIRONMENT === "localhost") {
  //   return process.env.REACT_APP_API_BASE_URL;
  // }
  // if (process.env.REACT_APP_API_BASE_ENVIRONMENT === "test") {
  //   return "https://api.maifood.resimator.fi"
  // }
  // return (
  //   window.location.protocol +
  //   "//" +
  //   (window.location.hostname === process.env.REACT_APP_API_BASE_ENVIRONMENT
  //     ? ""
  //     : window.location.hostname.split(".")[0] + ".") +
  //   "api.maifood.test" + ( window.location.hostname === process.env.REACT_APP_API_BASE_ENVIRONMENT ? "" :
  //   (window.location.port ? ":" + window.location.port : "") )
  // );
};

export const getBroadcastUrl = () => {
  if (
    process.env.REACT_APP_API_BASE_ENVIRONMENT === "test" ||
    process.env.REACT_APP_API_BASE_ENVIRONMENT === "localhost"
  ) {
    return process.env.REACT_APP_API_BASE_URL;
  }

  if (process.env.REACT_APP_API_BASE_URL === "maifood.resimator.fi") {
    return "https://api.maifood.resimator.fi:6001";
  } else if (process.env.REACT_APP_API_BASE_URL === "api.maifood.test") {
    return window.location.protocol + "//" + process.env.REACT_APP_API_BASE_URL;
  }
  return (
    window.location.protocol +
    "//api." +
    process.env.REACT_APP_API_BASE_URL +
    ":6001"
  );
};

export const getClientUrl = () => {
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "")
  );
  // if (process.env.REACT_APP_API_BASE_ENVIRONMENT === "test" || process.env.REACT_APP_API_BASE_ENVIRONMENT === "localhost") {
  //   return process.env.REACT_APP_API_BASE_URL;
  // }

  // return (
  //   window.location.protocol +
  //   "//" +
  //   (window.location.hostname === process.env.REACT_APP_API_BASE_ENVIRONMENT
  //     ? ""
  //     : window.location.hostname.split(".")[0] + ".") +
  //   "api.maifood.test" + ( window.location.hostname === process.env.REACT_APP_API_BASE_ENVIRONMENT ? "" :
  //   (window.location.port ? ":" + window.location.port : "") )
  // );
};

export const numberWithCommas = (x) => {
  return x.toLocaleString();
};

export const ordinalSuffix = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

export const checkPermission = (module, section, action, permission) => {
  if (
    permission
      ?.find((item) => item.key === module)
      ?.section?.find((value) => value.key === section)
      ?.action?.find((data) => data.key === action)?.value
  ) {
    return true;
  }
  return false;
};

export const extractValue = (arr, prop) => {
  // extract value from property
  let extractedValue = arr.map((item) => item[prop]);

  return extractedValue;
};

export const getPermission = (_module, _section, _actions, permission) => {
  const result = {};
  let sectionGranted;
  let actionGranted;
  const moduleGranted = find(permission, (module) => {
    return module.key.toLocaleLowerCase() === _module.toLocaleLowerCase();
  });
  if (moduleGranted) {
    sectionGranted = find(moduleGranted.section, (section) => {
      return section.key.toLocaleLowerCase() === _section.toLocaleLowerCase();
    });
  }
  if (sectionGranted) {
    each(_actions, (_action) => {
      actionGranted = find(sectionGranted.action, (action) => {
        return action.key.toLocaleLowerCase() === _action.toLocaleLowerCase();
      });
      if (actionGranted) {
        result[actionGranted.key] = actionGranted.value;
      }
    });
  }
  return result;
};

export const notify = () => {
};


export const utcToLocal = (date, format = 'DD.MM.YYYY HH:mm') => {
  if (!date) return '-'
  return moment.utc(date).local().format(format)
}

export const convertWeight = (weight, unitConfig, lang = 'en') => {

  if (!unitConfig) return { label: `${weight} g`, weight, choice: 'g' }

  const choice = unitConfig?.configChoice

  let wt = +weight

  if (choice === 'hg') {
    wt = +weight / 100
  } else if (choice === 'kg') {
    wt = +weight / 1000
  }

  return { label: `${wt} ${unitConfig?.displayName?.[lang]}`, weight: wt, choice }
}

export const convertRow = (row, quantity, lang = 'en') => {

  let firstPart = quantity;


  let unitPrice = formatCurrency(+row.unitPriceIncludingVat, lang);
  let sellingPrice = formatCurrency(+row.unitSellingPrice, lang);


  if (+row?.productWeight !== 0) {
    const weightContext = convertWeight(row?.productWeight, row?.unitConfigChoice, lang)
    firstPart = weightContext.label
    unitPrice = formatCurrency((+row.unitPriceIncludingVat) / +weightContext.weight, lang)
    // sellingPrice = formatCurrency((+row.unitSellingPrice) / +weightContext.weight, lang)
    sellingPrice = formatCurrency((+row.unitPristinePriceIncludingVat - (+row.subsidiary)), lang)
  }


  return { firstPart, unitPrice, sellingPrice }
}