import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import OrderService from "../../services/order-service";
import TableDataService from "../../services/table-data-service";
import {
  GET_ALL_ORDER,
  GET_ORDER_LIST,
  ADD_ORDER,
  GET_ORDER,
  EDIT_ORDER,
  DELETE_ORDER,
  DELETE_MULTIPLE_ORDER,
  DOWNLOAD_INVOICE,
} from "../actions";
import {
  getAllOrderSuccess,
  getAllOrderError,
  getOrderList,
  getOrderListSuccess,
  getOrderListError,
  addOrderSuccess,
  addOrderError,
  getOrderSuccess,
  getOrderError,
  editOrderSuccess,
  editOrderError,
  deleteOrderSuccess,
  deleteOrderError,
  deleteMultipleOrderSuccess,
  deleteMultipleOrderError,
  downloadInvoiceSuccess,
  downloadInvoiceError,
  EXPORT_EXCEL_REPORT,
  // downloadTenantReportSuccess,
  // downloadTenantReportError,
  downloadRefundInvoiceSuccess,
  downloadRefundInvoiceError,
  DOWNLOAD_REFUND_INVOICE,
  exportExcelReportSuccess,
  exportExcelReportError,
} from "./action";
import { toast } from "react-toastify";
import { parseMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
import saveAs from "file-saver";
import moment from "moment";

export function* watchGetAllOrder() {
  yield takeEvery(GET_ALL_ORDER, getAllOrder);
}

const getAllOrderAsync = async () => {
  return OrderService.getAllOrder();
};

function* getAllOrder() {
  try {
    const response = yield call(getAllOrderAsync);
    if (response.data.success) {
      yield put(getAllOrderSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getAllOrderError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getAllOrderError(error.response.data.message));
  }
}

export function* watchGetOrderList() {
  yield takeEvery(GET_ORDER_LIST, getOrderListAc);
}

const getOrderListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "orders",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null,
    dbParam?.buildingId || null,
    dbParam?.canteenId || null,
    dbParam?.categoryId || null,
    null,
    dbParam?.fromDate || null,
    dbParam?.toDate || null,
    null,
    dbParam?.tenantId || null,
    dbParam?.kioskId || null
  );
};

function* getOrderListAc({ payload }) {
  try {
    const response = yield call(getOrderListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getOrderListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getOrderListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getOrderListError(error.response.data.message));
  }
}

export function* watchAddOrder() {
  yield takeEvery(ADD_ORDER, addOrder);
}

const addOrderAsync = async (data) => {
  return OrderService.addOrder(data);
};

function* addOrder({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(addOrderAsync, payload.orderData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addOrderSuccess(response.data.success, response.data.message));
      history.push(`/product-management/order`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addOrderError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(addOrderError(error.response.data.message));
  }
}

export function* watchGetOrder() {
  yield takeEvery(GET_ORDER, getOrder);
}

const getOrderAsync = async (id) => {
  return OrderService.getOrder(id);
};

function* getOrder({ payload }) {
  try {
    const response = yield call(getOrderAsync, payload.orderId);
    if (response.data.success) {
      yield put(getOrderSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getOrderError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getOrderError(error.response.data.message));
  }
}

export function* watchEditOrder() {
  yield takeEvery(EDIT_ORDER, editOrder);
}

const editOrderAsync = async (data, id) => {
  return OrderService.editOrder(data, id);
};

function* editOrder({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(
      editOrderAsync,
      payload.orderData,
      payload.orderId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editOrderSuccess(response.data.success, response.data.message));
      history.push(`/product-management/order`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editOrderError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(editOrderError(error.response.data.message));
  }
}

export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, deleteOrder);
}

const deleteOrderAsync = async (id) => {
  return OrderService.deleteOrder(id);
};

function* deleteOrder({ payload }) {
  try {
    const response = yield call(deleteOrderAsync, payload.orderId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteOrderSuccess(response.data.success, response.data.message)
      );
      // Fetch updated order list
      yield put(getOrderList({}));
    } else {
      yield put(deleteOrderError(response.data.message));
    }
  } catch (error) {
    yield put(deleteOrderError(error.response.data.message));
  }
}

export function* watchDeleteMultipleOrder() {
  yield takeEvery(DELETE_MULTIPLE_ORDER, deleteMultipleOrder);
}

const deleteMultipleOrderAsync = async (ids) => {
  return OrderService.deleteMultipleOrder(ids);
};

function* deleteMultipleOrder({ payload }) {
  try {
    const response = yield call(deleteMultipleOrderAsync, payload.orderIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteMultipleOrderSuccess(response.data.success, response.data.message)
      );
      // Fetch updated order list
      yield put(getOrderList({}));
    } else {
      yield put(deleteMultipleOrderError(response.data.message));
    }
  } catch (error) {
    yield put(deleteMultipleOrderError(error.response.data.message));
  }
}

export function* watchDownloadInvoice() {
  yield takeEvery(DOWNLOAD_INVOICE, downloadInvoice);
}

const downloadInvoiceAsync = async (id) => {
  return OrderService.downloadInvoice(id);
};

function* downloadInvoice({ payload }) {
  try {
    const response = yield call(downloadInvoiceAsync, payload.orderId);
    if (response && response.data) {
      yield put(downloadInvoiceSuccess(true, ""));
      const blob = new Blob([response.data], { type: "application/pdf" });
      saveAs(blob, `Order-Invoice.pdf`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(downloadInvoiceError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(downloadInvoiceError(err.message));
  }
}


export function* watchDownloadRefundInvoice() {
  yield takeEvery(DOWNLOAD_REFUND_INVOICE, downloadRefundInvoice);
}

const downloadRefundInvoiceAsync = async (id) => {
  return OrderService.downloadRefundInvoice(id);
};

function* downloadRefundInvoice({ payload }) {
  try {
    const response = yield call(downloadRefundInvoiceAsync, payload.orderId);
    if (response && response.data) {
      yield put(downloadRefundInvoiceSuccess(true, ""));
      const blob = new Blob([response.data], { type: "application/pdf" });
      saveAs(blob, `Order-Refund-Invoice.pdf`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(downloadRefundInvoiceError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(downloadRefundInvoiceError(err.message));
  }
}


export function* watchExportExcelReport() {
  yield takeEvery(EXPORT_EXCEL_REPORT, exportExcelReport);
}

const exportExcelReportAsync = async (payload) => {
  return OrderService.exportExcelReport(payload);
};

function* exportExcelReport({ payload }) {
  try {
    const response = yield call(exportExcelReportAsync, payload);
    if (response && response.data) {
      yield put(exportExcelReportSuccess(true, ""));
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const date = moment().format('YYYY.MM.DD HH:mm:ss')
      saveAs(blob, `Order-Report-${date}.xlsx`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(exportExcelReportError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(exportExcelReportError(err.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllOrder),
    fork(watchGetOrderList),
    fork(watchAddOrder),
    fork(watchGetOrder),
    fork(watchEditOrder),
    fork(watchDeleteOrder),
    fork(watchDeleteMultipleOrder),
    fork(watchDownloadInvoice),
    fork(watchDownloadRefundInvoice),
    fork(watchExportExcelReport),
  ]);
}
