import React from "react";

const IconFoodServe = () => {
  return (
    <svg id="Outline" viewBox="0 0 512 512" width="22" height="22">
      <path d="M48,272H90.523a23.832,23.832,0,0,0,6.393,25.692l65.678,60.2.006-.006c.055.05.1.106.161.154L266.554,448H264a8,8,0,0,0-8,8v32a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V456a8,8,0,0,0-8-8V435.313l4.284-4.284A39.735,39.735,0,0,0,376,402.745V353.733l29-63.8A23.884,23.884,0,0,0,405.776,272H464a24.028,24.028,0,0,0,24-24V232a8,8,0,0,0-8-8H448v-8c0-92.636-75.364-168-168-168V32h8a8,8,0,0,0,0-16H224a8,8,0,0,0,0,16h8V48C139.364,48,64,123.364,64,216v8H32a8,8,0,0,0-8,8v16A24.028,24.028,0,0,0,48,272Zm57.677,5.1a7.823,7.823,0,0,1,7.456-5.1H117.7a8.016,8.016,0,0,1,7.283,4.689l6.014,13.23,0,.012,13.48,29.655L107.727,285.9A7.823,7.823,0,0,1,105.677,277.1ZM352,480H272V464h80Zm37.878-204.331a7.864,7.864,0,0,1,.557,7.641l-29.718,65.379A8.013,8.013,0,0,0,360,352v50.745a23.847,23.847,0,0,1-7.029,16.971l-6.628,6.627A8,8,0,0,0,344,432v16H290.984L174.56,347.1l-29-63.789A8,8,0,0,1,152.848,272h5.263a7.956,7.956,0,0,1,7.156,4.422l27.578,55.156a8,8,0,0,0,4.184,3.85l80,32a8,8,0,0,0,4.911.333l32-8a8,8,0,0,0,5.083-3.93l43.452-79.662A8,8,0,0,1,369.5,272h13.654A7.864,7.864,0,0,1,389.878,275.669ZM180.944,272h165.58l-39.867,73.09-26.088,6.521L205.8,321.7ZM248,32h16V48H248ZM80,216c0-83.813,68.187-152,152-152h48c83.813,0,152,68.187,152,152v8H80ZM40,240H472v8a8.009,8.009,0,0,1-8,8H48a8.009,8.009,0,0,1-8-8Z" />
    </svg>
  );
};

export default IconFoodServe;
