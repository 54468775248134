import Req from "../interceptors/token-interceptor";


const TermsAndConditionService = (function () {
    function _verifyTenantToken(token) {
        return Req.post(`/api/tenant-user/${token}`);
    }
    function _acceptTenantTerms(token) {
        return Req.post(`/api/tenant-user/accept-terms/${token}`);
    }
    function _downloadTenantQr(token) {
        return Req.get(`/api/tenant-user/get-qr/${token}`, {
            responseType: "arraybuffer",
        });
    }
    function _sendQRToEmail(token) {
        return Req.get(`/api/tenant-user/send-qr-mail/${token}`);
    }

    return {
        verifyTenantToken: _verifyTenantToken,
        acceptTenantTerms: _acceptTenantTerms,
        downloadTenantQr: _downloadTenantQr,
        sendQRToEmail: _sendQRToEmail
    };
})();
export default TermsAndConditionService;