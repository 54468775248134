
import { SegmentedControl } from '@mantine/core';
import * as Mui from '@material-ui/core';
import { sanitize } from 'dompurify';
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Modal from "../../components/dialog/modal";
import { IconCheck } from '../../components/svg';
import IconClose2 from "../../components/svg/icon-close-2";
import { useWidth } from '../../hooks/common/useWidth';
import { acceptTenantTerms, downloadTenantQr, sendQRToEmail } from '../../reduxs/actions';

const segmentStyle = {
    label: {
        height: '100%',
        fontSize: 14,
        paddingLeft: 20,
        paddingRight: 20,
        whiteSpace: 'break-spaces',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    labelActive: {
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'

    }
}

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const TermsConditions = ({ termsConditions, token }) => {
    const { loading1, loading2, qrData, loading3, success3 } = useSelector((state) => state.termsAndCondition)


    // const [downloadLoading, setDownloadLoading] = useState(false)

    const dispatch = useDispatch()

    const orgContentRef = useRef(null)
    const systemScrollRef = useRef(false);

    const containerRef = useRef();

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [qrPopUp, setQrPopUp] = useState(false);
    const [value, setValue] = useState('admin');

    // const [inBottom, setInBottom] = useState(false)

    const width = useWidth()

    const adminContentRef = useRef(null)

    const handleScroll = useCallback(() => {
        if (orgContentRef.current) {
            const rect = orgContentRef.current.getBoundingClientRect();
            const isVisible = (
                rect.top <= 300
            );
            if (systemScrollRef?.current) return
            setValue(isVisible ? 'org' : 'admin')
        }
        const scrollElement = containerRef.current;
        if (scrollElement) {
            // const { scrollTop, scrollHeight, clientHeight } = scrollElement;
            // Check if we've reached the bottom of the div
            // if (scrollTop + clientHeight + 250 >= scrollHeight) {
            //     setInBottom(true)
            // } else {
            //     setInBottom(false)
            // }
        }
    }, []);


    useEffect(() => {
        const containerReference = containerRef.current
        containerReference?.addEventListener('scroll', handleScroll);
        containerReference?.addEventListener('scrollend', () => {
            systemScrollRef.current = false
        });

        handleScroll();

        return () => {
            containerReference?.removeEventListener('scroll', handleScroll);
            containerReference?.removeEventListener('scrollend', () => { });
        };
    }, [handleScroll]);


    const confirmTermsAndCondition = useCallback(() => {
        if (qrData === null) {
            dispatch(acceptTenantTerms(token))
            return
        }
        setQrPopUp(true)
    }, [qrData, token, dispatch])

    const downloadQR = useCallback(() => {
        dispatch(downloadTenantQr(token))
    }, [token, dispatch])

    const sendQREmail = useCallback(() => {
        dispatch(sendQRToEmail(token))
    }, [token, dispatch])

    useEffect(() => {
        setQrPopUp(qrData !== null);
    }, [qrData])


    // const downloadQRFromHtml = async () => {
    //     setDownloadLoading(true)
    //     const element = document.querySelector('#qr-component');
    //     const img = element.querySelector('img');
    //     if (img && img.src) {
    //         await fetch(getApiUrl() + `/api/tenant-user/get-qr/${token}`, {
    //             headers: {
    //                 responseType: 'arraybuffer'
    //             }
    //         })
    //             .then(response => response.blob())
    //             .then(blob => {
    //                 // Create a new image with the blob

    //                 const newImg = new Image();
    //                 newImg.style.height = '230px'
    //                 newImg.style.width = '230px'
    //                 newImg.src = URL.createObjectURL(blob);

    //                 newImg.onload = () => {
    //                     // Replace the original image
    //                     img.src = newImg.src;

    //                     // Now capture the entire component
    //                     html2canvas(element, {
    //                         useCORS: true,
    //                         scale: 2,
    //                         backgroundColor: null
    //                     }).then(canvas => {
    //                         const url = canvas.toDataURL('image/png');
    //                         const link = document.createElement('a');
    //                         link.href = url;
    //                         link.download = 'user-qr-code.png';
    //                         document.body.appendChild(link);
    //                         link.click();
    //                         document.body.removeChild(link);
    //                         URL.revokeObjectURL(newImg.src);
    //                     });
    //                 };
    //             })
    //             .catch(error => console.error('Error downloading image:', error));
    //     } else {
    //         console.error('QR image not found');
    //     }
    //     setDownloadLoading(false)
    // }


    return <Mui.Box style={{ height: '100vh', width: '100vw' }} >
        <Mui.Box style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Mui.Box style={{ display: 'flex', justifyContent: 'center', marginTop: '3rem', marginBottom: '3rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
                <Mui.Box>
                    <SegmentedControl
                        value={value}
                        onChange={(val) => {
                            systemScrollRef.current = true;
                            if (val === 'admin') {
                                adminContentRef.current?.scrollIntoView({ behavior: 'smooth' });
                            } else {
                                orgContentRef.current?.scrollIntoView({ behavior: 'smooth' });
                            }
                            setValue(val)
                        }}
                        data={[
                            { label: 'Izy AS vilkår og betingelser', value: 'admin' },
                            { label: `${capitalize(termsConditions?.orgName)} vilkår og betingelser`, value: 'org' },
                        ]}
                        radius={'md'}
                        styles={segmentStyle}
                    />
                </Mui.Box>
            </Mui.Box>
            <Mui.Box style={{ flex: 1, overflow: 'hidden' }}>
                <Mui.Box className='tc-scroll-container' ref={containerRef} style={{ height: '100%', overflow: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <Mui.Box className="app-container-spacing">
                        <Mui.Box ref={adminContentRef}>
                            <div
                                style={{ color: "black", fontFamily: 'Poppins' }}
                                dangerouslySetInnerHTML={{ __html: sanitize(termsConditions?.mtc) }}
                            ></div>
                        </Mui.Box>

                        <Mui.Box ref={orgContentRef} style={{ marginTop: '5rem' }}>
                            <div
                                style={{ color: "black", fontFamily: 'Poppins' }}
                                dangerouslySetInnerHTML={{ __html: sanitize(termsConditions?.otc) }}
                            ></div>
                        </Mui.Box>
                    </Mui.Box>
                </Mui.Box>
            </Mui.Box>
            <Mui.Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Mui.Box className="app-container-spacing positioned-bottom" style={{ background: "#d5d9e2", borderTop: '1px solid #9ba1a780', position: 'relative' }}>
                    {(loading1) && <Mui.Box style={{ position: 'absolute', height: '100%', width: '100%', background: '#ffffff70', zIndex: 200 }}></Mui.Box>}
                    <Mui.Box style={{ padding: '2rem 2rem 0 2rem' }}>
                        <Mui.Box onClick={() => { setTermsAccepted(prev => !prev) }} className="pointer" display={'flex'} alignItems={'center'} gridGap={5}>
                            <Mui.Checkbox
                                className="app-checkbox-style-classic"
                                color="primary"
                                checked={termsAccepted}
                            />
                            <Mui.Typography className="app-container-paragraph-1" style={{ userSelect: "none" }}>
                                Jeg godtar vilkår og betingelser
                            </Mui.Typography>
                        </Mui.Box>
                        <Mui.Box className='app-button-container' display={'flex'} justifyContent={'flex-end'} gridGap={8} style={{ marginTop: '2rem' }} >
                            <Mui.Button
                                className="app-button-secondary"
                                disabled={loading1}
                                onClick={() => { window.location.href = 'https://izy.no/maifood/' }}
                            >
                                Kansellere
                            </Mui.Button>
                            <Mui.Box style={{ position: 'relative' }}>
                                <Mui.Button onClick={() => { confirmTermsAndCondition() }} className="app-button" disabled={(!termsAccepted || loading1)} style={{ cursor: termsAccepted ? 'pointer' : 'no-drop' }}>
                                    Fortsette
                                </Mui.Button>
                                {loading1 && <Mui.Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                    <Mui.CircularProgress size={25} />
                                </Mui.Box>}
                            </Mui.Box>
                        </Mui.Box>
                    </Mui.Box>
                </Mui.Box>
            </Mui.Box>
        </Mui.Box>
        {qrData !== null && <Modal open={qrPopUp} maxWidth="sm" fullWidth>
            <Mui.Box style={{ padding: '2rem', position: 'relative' }}>
                <Mui.Box className="pointer" onClick={() => { setQrPopUp(false) }} style={{ position: 'absolute', top: '2rem', right: '2rem' }}>
                    <IconClose2 />
                </Mui.Box>
                <Mui.Typography className="app-container-heading-3" style={{ textAlign: 'center' }}>Last ned QR koden</Mui.Typography>
                <Mui.Typography className="app-container-paragraph-1" style={{ textAlign: 'center' }}>Du kan bruke denne QR koden på mAIfood terminalen.</Mui.Typography>
                <Mui.Box display={'flex'} justifyContent={'center'} style={{ background: '#413EE1', paddingTop: '1rem', paddingBottom: '1rem', marginTop: '2rem', marginBottom: '2rem', borderRadius: '1rem' }}>
                    <Mui.Box id='qr-component' style={{ background: '#413EE1', padding: '2rem', borderRadius: '1rem' }} display={'flex'} flexDirection={'column'} >
                        {/* <IconQRLogo />
                        <Mui.Box style={{ marginTop: '1rem' }}></Mui.Box> */}
                        <QRImage
                            src={qrData?.qr}
                            alt={"QR-code"}
                        />
                    </Mui.Box>
                </Mui.Box>
                <Mui.Box display={'flex'} flexDirection={Mui.isWidthDown("xs", width) ? 'column' : 'row'} gridGap={8}>
                    <Mui.Box style={{ position: 'relative', flex: 1, display: 'flex' }}>
                        <Mui.Button disabled={success3 || loading2} onClick={() => { if (!loading3) sendQREmail() }} style={{ flex: 1 }} className="app-button-secondary">Send til e-post</Mui.Button>
                        {loading3 && <Mui.Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Mui.CircularProgress size={25} />
                        </Mui.Box>}
                    </Mui.Box>
                    <Mui.Box style={{ position: 'relative', flex: 1, display: 'flex' }}>
                        <Mui.Button disabled={loading2} onClick={() => { if (!loading2) downloadQR() }} style={{ flex: 1 }} className="app-button">Last ned</Mui.Button>
                        {loading2 && <Mui.Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Mui.CircularProgress size={25} />
                        </Mui.Box>}
                    </Mui.Box>
                </Mui.Box>

                {success3 && <Mui.Box style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', color: '#198754', marginTop: '1rem' }}>
                    <Mui.Box component="span" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '1.5rem', height: '1.5rem', background: '#19875410', borderRadius: '50%', marginRight: '0.3rem' }}>
                        <IconCheck stroke="#198754" />
                    </Mui.Box>
                    <Mui.Typography className="app-container-paragraph-1" style={{ color: '198754' }}>Sendt til e-post</Mui.Typography>
                </Mui.Box>}
            </Mui.Box>
        </Modal>}
    </Mui.Box >
}





const imgPlaceholder = <Mui.Box style={{ borderRadius: '1rem', width: 230, height: 230, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#e9ecef' }}>
    <Mui.CircularProgress size={30} />
</Mui.Box>

const QRImage = memo(({ src, alt, placeholder = imgPlaceholder }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    return <div>
        {!isLoaded && <>{placeholder}</>}
        <img
            src={src}
            alt={alt}
            style={{ display: isLoaded ? 'block' : 'none', width: 260, borderRadius: '1rem' }}
            onLoad={handleImageLoad}
        />
    </div>

})