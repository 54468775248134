import {
  GET_SETTING,
  GET_SETTING_SUCCESS,
  GET_SETTING_ERROR,
  EDIT_SETTING,
  EDIT_SETTING_SUCCESS,
  EDIT_SETTING_ERROR,
  RESET_SETTING,
  GET_BASIC_SETTING,
  GET_BASIC_SETTING_SUCCESS,
  GET_BASIC_SETTING_ERROR,
  GET_POS_TERMINAL,
  GET_POS_TERMINAL_ERROR,
  GET_POS_TERMINAL_SUCCESS,
} from "../actions";

const INIT_STATE = {
  settingData: null,
  basicSettingData: null,
  success: false,
  message: null,
  loading: false,
  error: null,

  loadingBasicSetting: false,
  errorBasicSetting: null,

  posTerminals: null,
  loading1: false

};

const settingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BASIC_SETTING:
      return { ...state, loadingBasicSetting: true, errorBasicSetting: null };
    case GET_BASIC_SETTING_SUCCESS:
      return {
        ...state,
        basicSettingData: action.payload,
        loadingBasicSetting: false,
        errorBasicSetting: null,
      };
    case GET_BASIC_SETTING_ERROR:
      return {
        ...state,
        basicSettingData: null,
        loadingBasicSetting: false,
        errorBasicSetting: action.payload,
      };
    case GET_SETTING:
      return { ...state, error: null };
    case GET_SETTING_SUCCESS:
      return {
        ...state,
        settingData: action.payload,
        error: null,
      };
    case GET_SETTING_ERROR:
      return {
        ...state,
        settingData: null,
        error: action.payload,
      };
    case EDIT_SETTING:
      return { ...state, loading: true, error: null };
    case EDIT_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_POS_TERMINAL:
      return { ...state, error: null, loading1: true };
    case GET_POS_TERMINAL_SUCCESS:
      return {
        ...state,
        posTerminals: action.payload,
        error: null,
        loading1: false
      };
    case GET_POS_TERMINAL_ERROR:
      return {
        ...state,
        posTerminals: null,
        error: action.payload,
        loading1: false
      };
    case RESET_SETTING:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default settingReducer;
