import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb";
import { withTranslation } from "react-i18next";
import {
  getOrganization,
  getBuildingList,
  getBuilding,
  getCanteenList,
  getCanteen,
  getKioskList,
  getKiosk,
  getProductList,
  getProduct,
  getTenantList,
  getTenant,
} from "../../../reduxs/actions";
import {
  IconBuilding,
  IconCanteen,
  IconChevronLeft,
  IconClose,
  IconColorOrganization,
  IconColorCanteen,
  IconColorAllegens,
  IconColorDescription,
  IconId,
  IconKiosk,
  IconMail,
  IconOwner,
  IconPhone,
  IconPin,
  IconProduct,
  IconTenant,
  IconCalendar,
  IconFoodServe,
} from "../../../components/svg";
import orgGraphic from "../../../assets/images/organiation-graphic.svg";
import DataTable from "../../../components/datatable";
import { getPermission, formatCurrency, utcToLocal } from "../../../helpers/util";
import moment from "moment";
import defaultImg from "../../../assets/images/maifood-advantech-unit.png";
class ViewOrganization extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      tab: 0,
      openBuildingModal: false,
      openCanteenModal: false,
      openKioskModal: false,
      openProductModal: false,
      openTenantModal: false,
    };
    this.buildingSearchFields =
      "address:like;name:like;statusConfigChoice.display_name:like;";
    this.canteenSearchFields =
      "location_in_building:like;name:like;building.name:like;statusConfigChoice.display_name:like;";
    this.kioskSearchFields =
      "name:like;number:like;canteen.name:like;statusConfigChoice.display_name:like;";
    this.productSearchFields =
      "unique_id:like;slug:like;name:like;price:like;statusConfigChoice.display_name:like;created_at:like;productCategory.name:like;";
    this.tenantSearchFields =
      "address:like;name:like;izy_tenant_id:like;statusConfigChoice.display_name:like;";
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getOrganization(this.props.match.params.id);
      this.props.getBuildingList({ orgId: this.props.match.params.id });
      this.props.getCanteenList({ orgId: this.props.match.params.id });
      this.props.getKioskList({ orgId: this.props.match.params.id });
      this.props.getProductList({ orgId: this.props.match.params.id });
      this.props.getTenantList({ orgId: this.props.match.params.id });
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  toggleTab = (event, value) => {
    this.setState({ tab: value });
  };

  handleCloseModal = () => {
    this.setState({
      openBuildingModal: false,
      openCanteenModal: false,
      openKioskModal: false,
      openProductModal: false,
      openTenantModal: false,
    });
  };

  handleOpenBuildingModal = (id) => {
    this.setState({ openBuildingModal: true });
    this.props.getBuilding(id, this.props.match.params.id);
  };

  handleOpenCanteenModal = (id) => {
    this.setState({ openCanteenModal: true });
    this.props.getCanteen(id, this.props.match.params.id);
  };

  handleOpenKioskModal = (id) => {
    this.setState({ openKioskModal: true });
    this.props.getKiosk(id, this.props.match.params.id);
  };

  handleOpenProductModal = (id) => {
    this.setState({ openProductModal: true });
    this.props.getProduct(id, this.props.match.params.id);
  };

  handleOpenTenantModal = (id) => {
    this.setState({ openTenantModal: true });
    this.props.getTenant(id, this.props.match.params.id);
  };

  onBuildingChange = (
    search,
    sortOrder,
    page,
    pageSize,
    activeCol,
    statusId = null
  ) => {
    this.props.getBuildingList({
      orgId: this.props.match.params.id,
      search: search,
      searchFields: this.buildingSearchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
      statusId: statusId,
    });
  };

  onCanteenChange = (
    search,
    sortOrder,
    page,
    pageSize,
    activeCol,
    statusId = null,
    buildingId = null
  ) => {
    this.props.getCanteenList({
      orgId: this.props.match.params.id,
      search: search,
      searchFields: this.canteenSearchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
      statusId: statusId,
      buildingId: buildingId,
    });
  };

  onKioskChange = (
    search,
    sortOrder,
    page,
    pageSize,
    activeCol,
    statusId = null,
    buildingId = null,
    canteenId = null
  ) => {
    this.props.getKioskList({
      orgId: this.props.match.params.id,
      search: search,
      searchFields: this.kioskSearchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
      statusId: statusId,
      buildingId: buildingId,
      canteenId: canteenId,
    });
  };

  onProductChange = (
    search,
    sortOrder,
    page,
    pageSize,
    activeCol,
    statusId = null
  ) => {
    this.props.getProductList({
      orgId: this.props.match.params.id,
      search: search,
      searchFields: this.productSearchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
      statusId: statusId,
    });
  };

  onTenantChange = (
    search,
    sortOrder,
    page,
    pageSize,
    activeCol,
    statusId = null,
    buildingId = null,
    canteenId = null
  ) => {
    this.props.getTenantList({
      orgId: this.props.match.params.id,
      search: search,
      searchFields: this.tenantSearchFields,
      sortOrder: sortOrder,
      page: page,
      pageSize: pageSize,
      activeCol: activeCol,
      statusId: statusId,
      buildingId: buildingId,
      canteenId: canteenId,
    });
  };

  render() {
    const { permission, t, i18n } = this.props;
    const organizationPermission = getPermission(
      "organizationManagement",
      "organization",
      ["view"],
      permission
    );

    return (
      <>
        <Breadcrumb
          title={t("PERMISSIONS.modules.organizationManagement")}
          paths={[
            {
              title: t("PERMISSIONS.sections.organization"),
              page: `/organization`,
            },
            {
              title: t("PERMISSIONS.actions.view"),
            },
          ]}
        />

        <Mui.Card className="default-card spacing-md pos-relative pb-5 mb-5">
          <Mui.Box
            className="mb-4"
            width="100%"
            display="flex"
            alignItems="center"
            flexWrap="wrap"
          >
            <Mui.Tooltip
              title={t("ORGANIZATION.GO_BACK_LABEL")}
              arrow
              placement="bottom-start"
              PopperProps={{
                className:
                  "MuiTooltip-popper MuiTooltip-popperArrow tooltip-popover default-tooltip",
              }}
            >
              <Mui.IconButton
                className="border-1 border-color-grey"
                type="button"
                onClick={this.goBack}
                size="small"
                style={{ padding: 6, marginRight: 15 }}
              >
                <Mui.Box
                  width="1.2rem"
                  className="svg-color-grey svg-size-flexible line-height-null"
                >
                  <IconChevronLeft />
                </Mui.Box>
              </Mui.IconButton>
            </Mui.Tooltip>

            <Mui.Typography
              component="h5"
              variant="h5"
              className="mr-auto font-weight-medium"
            >
              {t("ORGANIZATION.INFO_LABEL")}
            </Mui.Typography>

            <Mui.Button
              className={`btn-default no-color-btn ${Mui.isWidthDown("sm", this.props.width) ? "mt-2" : "ml-2"
                }`}
              color="primary"
              type="button"
              variant="outlined"
              disableElevation
              component={Link}
              to={`/organization/edit/${this.props.match.params.id}`}
            >
              {t("COMMON.FORM.ACTION.EDIT")}
            </Mui.Button>
          </Mui.Box>

          <Mui.Box
            display="flex"
            flexDirection={
              Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
            }
          >
            {this.props.organizationData?.photo?.length > 0 &&
              this.props.organizationData?.photo[0]?.url ? (
              <Mui.Avatar
                className={`img-size-xl ${Mui.isWidthDown("sm", this.props.width)
                  ? "mb-5"
                  : "mr-5 ml-4 mb-5"
                  }`}
                src={this.props.organizationData?.photo[0]?.url}
                alt={this.props.organizationData?.name || ""}
              />
            ) : (
              <Mui.Box
                component="figure"
                bgcolor="#C9C9C9"
                className={`flex-shrink-0 img-round img-size-xl svg-size-flexible ${Mui.isWidthDown("xs", this.props.width)
                  ? "mb-5"
                  : "mr-5 ml-4 mb-5"
                  }`}
              >
                <Mui.Box
                  component="span"
                  display="flex"
                  justifyContent="center"
                  mt={4}
                >
                  <IconColorOrganization />
                </Mui.Box>
              </Mui.Box>
            )}

            <Mui.Box display="flex" flexWrap="wrap">
              <Mui.Typography
                component="h1"
                variant="h1"
                className={`mb-3 svg-color-primary font-weight-medium ${Mui.isWidthDown("sm", this.props.width)
                  ? "flex-direction-column"
                  : ""
                  }`}
              >
                {this.props.organizationData?.name || "-"}
              </Mui.Typography>

              <Mui.Typography
                component="h5"
                variant="h5"
                className="w-100 d-flex mb-2  svg-color-primary text-color-grey font-weight-normal"
              >
                <span className="flex-shrink-0 mr-2">
                  <IconId />
                </span>
                {this.props.organizationData?.number || "-"}
              </Mui.Typography>
              <Mui.Box display="flex" flexWrap="wrap">
                {this.props.organizationData?.adminUser?.map((item, index) => {
                  return (
                    <Mui.Box
                      display="flex"
                      flexDirection={
                        Mui.isWidthDown("sm", this.props.width)
                          ? "column"
                          : "row"
                      }
                      key={index}
                    >
                      <Mui.Typography
                        component="h5"
                        variant="h5"
                        className="d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
                      >
                        <span className="flex-shrink-0">
                          <IconOwner />
                        </span>
                        <span className="ml-2">
                          {item?.name || "-"}
                          <Mui.Typography
                            className="d-block text-color-muted"
                            variant="body1"
                            component="span"
                          >
                            {item?.email || "-"}
                          </Mui.Typography>
                        </span>
                      </Mui.Typography>
                    </Mui.Box>
                  );
                })}
              </Mui.Box>
              <Mui.Box
                display="flex"
                flexWrap="wrap"
                flexDirection={
                  Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
                }
                ml={5}
                width="100%"
              >
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="d-flex mb-2  mr-5 svg-color-primary font-weight-normal"
                >
                  <span>
                    <Mui.Typography
                      component="small"
                      variant="body2"
                      className="d-block text-color-grey text-transform-uppercase"
                    >
                      {t("COMMON.FORM.CREATED_DATE")}
                    </Mui.Typography>
                    {utcToLocal(this.props.organizationData?.createdAt) || "-"}
                  </span>
                </Mui.Typography>
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="d-flex mb-2  mr-5 svg-color-primary font-weight-normal"
                >
                  <span>
                    <Mui.Typography
                      component="small"
                      variant="body2"
                      className="d-block text-color-grey text-transform-uppercase"
                    >
                      {t("COMMON.FORM.CREATED_BY")}
                    </Mui.Typography>
                    {this.props.organizationData?.createdBy?.fullname || "-"}
                  </span>
                </Mui.Typography>
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="d-flex mb-2  mr-5 svg-color-primary font-weight-normal"
                >
                  <span>
                    <Mui.Typography
                      component="small"
                      variant="body2"
                      className="d-block text-color-grey text-transform-uppercase"
                    >
                      {t("COMMON.FORM.UPDATED_DATE")}
                    </Mui.Typography>
                    {this.props.organizationData?.updatedAt || "-"}
                  </span>
                </Mui.Typography>
                <Mui.Typography
                  component="h5"
                  variant="h5"
                  className="d-flex mb-2  mr-5 svg-color-primary font-weight-normal"
                >
                  <span>
                    <Mui.Typography
                      component="small"
                      variant="body2"
                      className="d-block text-color-grey text-transform-uppercase"
                    >
                      {t("COMMON.FORM.UPDATED_BY")}
                    </Mui.Typography>
                    {this.props.organizationData?.updatedBy?.fullname || "-"}
                  </span>
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
          </Mui.Box>

          <Mui.Hidden smDown>
            <img
              src={orgGraphic}
              alt=""
              className={`pos-absolute pos-bottom-per-10 pos-right-per-5 opacity-3`}
            />
          </Mui.Hidden>
        </Mui.Card>

        <Mui.Tabs
          value={this.state.tab}
          onChange={this.toggleTab}
          variant="scrollable"
          indicatorColor="primary"
          scrollButtons={Mui.isWidthUp("md", this.props.width) ? "auto" : "on"}
          className="tab-style-slanted"
        >
          <Mui.Tab
            icon={<IconBuilding />}
            label={
              <>
                {t("BUILDING.TITLE")}{" "}
                <Mui.Chip
                  label={this.props.buildingList?.meta?.total || 0}
                  className="h-auto border-rad-4 ml-1"
                  classes={{ label: "pl-1 pr-1" }}
                />
              </>
            }
            disableRipple
            className="text-transform-uppercase"
          />
          <Mui.Tab
            icon={<IconCanteen />}
            label={
              <>
                {t("CANTEEN.TITLE")}{" "}
                <Mui.Chip
                  label={this.props.canteenList?.meta?.total || 0}
                  className="h-auto border-rad-4 ml-1"
                  classes={{ label: "pl-1 pr-1" }}
                />
              </>
            }
            disableRipple
            className="text-transform-uppercase"
          />
          <Mui.Tab
            icon={<IconKiosk />}
            label={
              <>
                {t("KIOSK.TITLE")}{" "}
                <Mui.Chip
                  label={this.props.kioskList?.meta?.total || 0}
                  className="h-auto border-rad-4 ml-1"
                  classes={{ label: "pl-1 pr-1" }}
                />
              </>
            }
            disableRipple
            className="text-transform-uppercase"
          />
          <Mui.Tab
            icon={<IconProduct />}
            label={
              <>
                {t("PRODUCT.TITLE")}{" "}
                <Mui.Chip
                  label={this.props.productList?.meta?.total || 0}
                  className="h-auto border-rad-4 ml-1"
                  classes={{ label: "pl-1 pr-1" }}
                />
              </>
            }
            disableRipple
            className="text-transform-uppercase"
          />
          <Mui.Tab
            icon={<IconTenant />}
            label={
              <>
                {t("TENANT.TITLE")}{" "}
                <Mui.Chip
                  label={this.props.tenantList?.meta?.total || 0}
                  className="h-auto border-rad-4 ml-1"
                  classes={{ label: "pl-1 pr-1" }}
                />
              </>
            }
            disableRipple
            className="text-transform-uppercase"
          />
        </Mui.Tabs>

        <Mui.Card className={`slanted-tab-content`}>
          {this.state.tab === 0 && (
            <DataTable
              title={t("BUILDING.TITLE")}
              headCells={[
                {
                  id: "name",
                  sortKey: "name",
                  label: t("BUILDING.FORM.NAME"),
                },
                {
                  id: "departmentNumber",
                  sortKey: "department_number",
                  label: t("BUILDING.FORM.DEPARTMENT_NUMBER"),
                },
                {
                  id: "address",
                  sortKey: "address",
                  label: t("BUILDING.FORM.ADDRESS"),
                },
                {
                  id: "statusConfigChoice",
                  sortKey: "status",
                  label: t("COMMON.FORM.STATUS"),
                },
                {
                  id: "createdAt",
                  sortKey: "created_at",
                  label: t("COMMON.FORM.CREATED_DATE"),
                },
              ]}
              rows={this.props.buildingList?.data || []}
              totalPage={this.props.buildingList?.meta?.pages || 0}
              totalItem={this.props.buildingList?.meta?.total || 0}
              start={this.props.buildingList?.meta?.start || 0}
              end={this.props.buildingList?.meta?.end || 0}
              loading={this.props.buildingLoading}
              handleOpenModal={this.handleOpenBuildingModal}
              onChange={this.onBuildingChange}
              permission={organizationPermission}
              actionStyle="modal"
              tableStyle="default"
              enableColumnFilter={false}
              enableCheckbox={false}
            />
          )}
          {this.state.tab === 1 && (
            <DataTable
              title={t("CANTEEN.TITLE")}
              headCells={[
                {
                  id: "name",
                  sortKey: "name",
                  label: t("CANTEEN.FORM.NAME"),
                },
                {
                  id: "locationInBuilding",
                  sortKey: "location_in_building",
                  label: t("CANTEEN.FORM.LOCATION"),
                },
                {
                  id: "glnNumber",
                  sortKey: "gln_number",
                  label: t("CANTEEN.FORM.GLN_NUMBER"),
                },
                {
                  id: "building",
                  sortKey: "building_id",
                  label: t("CANTEEN.FORM.BUILDING"),
                },
                {
                  id: "statusConfigChoice",
                  sortKey: "status",
                  label: t("COMMON.FORM.STATUS"),
                },
                {
                  id: "createdAt",
                  sortKey: "created_at",
                  label: t("COMMON.FORM.CREATED_DATE"),
                },
              ]}
              rows={this.props.canteenList?.data || []}
              totalPage={this.props.canteenList?.meta?.pages || 0}
              totalItem={this.props.canteenList?.meta?.total || 0}
              start={this.props.canteenList?.meta?.start || 0}
              end={this.props.canteenList?.meta?.end || 0}
              loading={this.props.canteenLoading}
              handleOpenModal={this.handleOpenCanteenModal}
              onChange={this.onCanteenChange}
              permission={organizationPermission}
              actionStyle="modal"
              tableStyle="default"
              enableColumnFilter={false}
              enableCheckbox={false}
            />
          )}
          {this.state.tab === 2 && (
            <DataTable
              title={t("KIOSK.TITLE")}
              headCells={[
                {
                  id: "name",
                  sortKey: "name",
                  label: t("KIOSK.FORM.NAME"),
                },
                {
                  id: "number",
                  sortKey: "number",
                  label: t("KIOSK.FORM.KIOSK_ID"),
                },
                {
                  id: "canteen",
                  sortKey: "canteen_id",
                  label: t("KIOSK.FORM.CANTEEN"),
                },
                {
                  id: "statusConfigChoice",
                  sortKey: "status",
                  label: t("COMMON.FORM.STATUS"),
                },
                {
                  id: "createdAt",
                  sortKey: "created_at",
                  label: t("COMMON.FORM.CREATED_DATE"),
                },
              ]}
              rows={this.props.kioskList?.data || []}
              totalPage={this.props.kioskList?.meta?.pages || 0}
              totalItem={this.props.kioskList?.meta?.total || 0}
              start={this.props.kioskList?.meta?.start || 0}
              end={this.props.kioskList?.meta?.end || 0}
              loading={this.props.kioskLoading}
              handleOpenModal={this.handleOpenKioskModal}
              onChange={this.onKioskChange}
              permission={organizationPermission}
              actionStyle="modal"
              tableStyle="default"
              enableColumnFilter={false}
              enableCheckbox={false}
            />
          )}
          {this.state.tab === 3 && (
            <DataTable
              title={t("PRODUCT.TITLE")}
              headCells={[
                {
                  id: "nameObj",
                  sortKey: "name",
                  label: t("PRODUCT.FORM.NAME"),
                },
                {
                  id: "price",
                  sortKey: "price",
                  label: t("PRODUCT.FORM.PRICE"),
                  subLabel: `(${t("COMMON.EXCLUDING_VAT")})`,
                },
                {
                  id: "productCategory",
                  sortKey: "product_category_id",
                  label: t("PRODUCT.FORM.CATEGORY"),
                },
                {
                  id: "statusConfigChoice",
                  sortKey: "status",
                  label: t("COMMON.FORM.STATUS"),
                },
                {
                  id: "createdAt",
                  sortKey: "created_at",
                  label: t("PRODUCT.FORM.CREATED_DATE"),
                },
              ]}
              rows={this.props.productList?.data || []}
              totalPage={this.props.productList?.meta?.pages || 0}
              totalItem={this.props.productList?.meta?.total || 0}
              start={this.props.productList?.meta?.start || 0}
              end={this.props.productList?.meta?.end || 0}
              loading={this.props.productLoading}
              handleOpenModal={this.handleOpenProductModal}
              onChange={this.onProductChange}
              permission={organizationPermission}
              actionStyle="modal"
              tableStyle="default"
              enableColumnFilter={false}
              enableCheckbox={false}
            />
          )}
          {this.state.tab === 4 && (
            <DataTable
              title={t("TENANT.TITLE")}
              headCells={[
                {
                  id: "name",
                  sortKey: "name",
                  label: t("TENANT.FORM.NAME"),
                },
                {
                  id: "izyTenantId",
                  sortKey: "izy_tenant_id",
                  label: t("TENANT.FORM.IZY_TENANT_ID"),
                },
                {
                  id: "address",
                  sortKey: "address",
                  label: t("TENANT.FORM.ADDRESS"),
                },
                {
                  id: "statusConfigChoice",
                  sortKey: "status",
                  label: t("COMMON.FORM.STATUS"),
                },
                {
                  id: "createdAt",
                  sortKey: "created_at",
                  label: t("COMMON.FORM.CREATED_DATE"),
                },
              ]}
              rows={this.props.tenantList?.data || []}
              totalPage={this.props.tenantList?.meta?.pages || 0}
              totalItem={this.props.tenantList?.meta?.total || 0}
              start={this.props.tenantList?.meta?.start || 0}
              end={this.props.tenantList?.meta?.end || 0}
              loading={this.props.tenantLoading}
              handleOpenModal={this.handleOpenTenantModal}
              onChange={this.onTenantChange}
              permission={organizationPermission}
              actionStyle="modal"
              tableStyle="default"
              enableColumnFilter={false}
              enableCheckbox={false}
            />
          )}
        </Mui.Card>

        <Mui.Dialog
          open={this.state.openBuildingModal}
          onClose={this.handleCloseModal}
          maxWidth="sm"
          fullWidth={true}
          classes={{ paper: "pos-relative pt-3" }}
        >
          <Mui.IconButton
            className="pos-absolute pos-top-per-3 pos-right-per-3 svg-size-xs"
            onClick={this.handleCloseModal}
          >
            <IconClose />
          </Mui.IconButton>
          <Mui.Box display="flex" flexDirection="column" alignItems="center">
            <Mui.Box
              component="figure"
              bgcolor="#C9C9C9"
              className={`img-round img-size-xl svg-size-flexible ${Mui.isWidthDown("sm", this.props.width)
                ? "mb-5"
                : "mr-5 ml-4 mb-5"
                }`}
            >
              <Mui.Box
                component="span"
                display="flex"
                justifyContent="center"
                mt={4}
              >
                <IconColorOrganization />
              </Mui.Box>
            </Mui.Box>

            <Mui.Typography
              component="h1"
              variant="h1"
              className="text-center font-weight-medium"
            >
              {this.props.buildingData?.name || "-"}
            </Mui.Typography>

            <Mui.Divider
              variant="middle"
              className="mt-2 background-color-primary"
              style={{ width: 80 }}
            />

            <Mui.Box
              width="100%"
              display="flex"
              flexWrap="wrap"
              className={`background-color-grey-light ${Mui.isWidthDown("xs", this.props.width) ? "p-2" : "p-5"
                }`}
            >
              <Mui.Typography
                component="h6"
                variant="h6"
                className="w-100 d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconPin />
                </span>
                <span className="ml-2">
                  {this.props.buildingData?.address || "-"}
                </span>
              </Mui.Typography>
              <Mui.Typography
                component="h6"
                variant="h6"
                className="w-100 d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconId />
                </span>
                <span className="ml-2">
                  {this.props.buildingData?.departmentNumber || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="w-100 d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconCanteen />
                </span>
                <span className="ml-2">
                  {this.props.buildingData?.canteen?.map((value, i) => {
                    return (
                      <span key={i}>
                        {value?.name || ""}
                        {i + 1 < this.props.buildingData?.canteen?.length
                          ? ", "
                          : ""}
                      </span>
                    );
                  })}
                </span>
              </Mui.Typography>
              <Mui.Typography
                component="h6"
                variant="h6"
                className="w-100 d-flex mb-2 mr-5 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconOwner />
                </span>
                <span className="ml-2">
                  {this.props.buildingData?.buildingOwners[0]?.name || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5 mb-2 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconMail />
                </span>
                <span className="ml-2">
                  {this.props.buildingData?.buildingOwners[0]?.email || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5 mb-2 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconPin />
                </span>
                <span className="ml-2">
                  {this.props.buildingData?.buildingOwners[0]?.country?.name ||
                    "-"}
                </span>
              </Mui.Typography>
              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5 mb-2 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconPhone />
                </span>
                <span className="ml-2">
                  {this.props.buildingData?.buildingOwners[0]?.country
                    ?.isdCode || ""}{" "}
                  {this.props.buildingData?.buildingOwners[0]?.phone || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5 mb-2 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconCalendar />
                </span>
                <span className="ml-2">
                  {this.props.buildingData?.createdAt
                    ? moment(this.props.buildingData?.createdAt).format(
                      "MMM DD, YYYY"
                    )
                    : "-"}
                </span>
              </Mui.Typography>
            </Mui.Box>
          </Mui.Box>
        </Mui.Dialog>

        <Mui.Dialog
          open={this.state.openCanteenModal}
          onClose={this.handleCloseModal}
          maxWidth="sm"
          fullWidth={true}
          classes={{ paper: "pos-relative pt-3" }}
        >
          <Mui.IconButton
            className="pos-absolute pos-top-per-3 pos-right-per-3 svg-size-xs"
            onClick={this.handleCloseModal}
          >
            <IconClose />
          </Mui.IconButton>
          <Mui.Box display="flex" flexDirection="column" alignItems="center">
            <Mui.Box
              component="figure"
              bgcolor="#C9C9C9"
              className={`img-round img-size-xl svg-size-flexible ${Mui.isWidthDown("sm", this.props.width)
                ? "mb-5"
                : "mr-5 ml-4 mb-5"
                }`}
            >
              <Mui.Box
                component="span"
                display="flex"
                justifyContent="center"
                mt={4}
              >
                <IconColorCanteen />
              </Mui.Box>
            </Mui.Box>

            <Mui.Typography
              component="h1"
              variant="h1"
              className="text-center font-weight-medium"
            >
              {this.props.canteenData?.name || "-"}
            </Mui.Typography>

            <Mui.Divider
              variant="middle"
              className="mt-2 background-color-primary"
              style={{ width: 80 }}
            />

            <Mui.Box
              width="100%"
              display="flex"
              flexWrap="wrap"
              className={`background-color-grey-light ${Mui.isWidthDown("xs", this.props.width) ? "p-2" : "p-5"
                }`}
            >
              <Mui.Typography
                component="h6"
                variant="h6"
                className="w-100 d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconPin />
                </span>
                <span className="ml-2">
                  {this.props.canteenData?.locationInBuilding || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="w-100 d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconId />
                </span>
                <span className="ml-2">
                  {this.props.canteenData?.glnNumber || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5 mb-2 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconBuilding />
                </span>
                <span className="ml-2">
                  {this.props.canteenData?.building?.name || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5 mb-2 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconPin />
                </span>
                <span className="ml-2">
                  {this.props.canteenData?.building?.address || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconCalendar />
                </span>
                <span className="ml-2">
                  {this.props.canteenData?.createdAt
                    ? moment(this.props.canteenData?.createdAt).format(
                      "MMM DD, YYYY"
                    )
                    : "-"}
                </span>
              </Mui.Typography>
            </Mui.Box>
          </Mui.Box>
        </Mui.Dialog>

        <Mui.Dialog
          open={this.state.openKioskModal}
          onClose={this.handleCloseModal}
          maxWidth="sm"
          fullWidth={true}
          classes={{ paper: "pos-relative pt-3" }}
        >
          <Mui.IconButton
            className="pos-absolute pos-top-per-3 pos-right-per-3 svg-size-xs"
            onClick={this.handleCloseModal}
          >
            <IconClose />
          </Mui.IconButton>
          <Mui.Box display="flex" flexDirection="column" alignItems="center">
            <Mui.Box
              component="figure"
              bgcolor="#C9C9C9"
              className={`img-round img-size-xl svg-size-flexible ${Mui.isWidthDown("sm", this.props.width)
                ? "mb-5"
                : "mr-5 ml-4 mb-5"
                }`}
            >
              <Mui.Box
                component="span"
                display="flex"
                justifyContent="center"
                mt={4}
              >
                <img className="img-fluid" src={defaultImg} alt="" />
              </Mui.Box>
            </Mui.Box>

            <Mui.Typography
              component="h1"
              variant="h1"
              className="text-center font-weight-medium"
            >
              {this.props.kioskData?.name || "-"}
            </Mui.Typography>

            <Mui.Divider
              variant="middle"
              className="mt-2 background-color-primary"
              style={{ width: 80 }}
            />

            <Mui.Box
              width="100%"
              display="flex"
              flexWrap="wrap"
              className={`background-color-grey-light ${Mui.isWidthDown("xs", this.props.width) ? "p-2" : "p-5"
                }`}
            >
              <Mui.Typography
                component="h6"
                variant="h6"
                className="w-100 d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconId />
                </span>
                <span className="ml-2">
                  {this.props.kioskData?.number || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5 mb-2 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconBuilding />
                </span>
                <span className="ml-2">
                  {this.props.kioskData?.canteen?.building?.name || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5 mb-2 svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconCanteen />
                </span>
                <span className="ml-2">
                  {this.props.kioskData?.canteen?.name || "-"}
                </span>
              </Mui.Typography>
              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconCalendar />
                </span>
                <span className="ml-2">
                  {this.props.kioskData?.createdAt
                    ? moment(this.props.kioskData?.createdAt).format(
                      "MMM DD, YYYY"
                    )
                    : "-"}
                </span>
              </Mui.Typography>
            </Mui.Box>
          </Mui.Box>
        </Mui.Dialog>

        <Mui.Dialog
          open={this.state.openProductModal}
          onClose={this.handleCloseModal}
          maxWidth="sm"
          fullWidth={true}
          classes={{ paper: "pos-relative pt-3" }}
        >
          <Mui.IconButton
            className="pos-absolute pos-top-per-3 pos-right-per-3 svg-size-xs"
            onClick={this.handleCloseModal}
          >
            <IconClose />
          </Mui.IconButton>
          <Mui.Box display="flex" flexDirection="column" alignItems="center">
            <Mui.Box
              component="figure"
              bgcolor="#C9C9C9"
              className={`img-round img-size-xl svg-size-flexible ${Mui.isWidthDown("sm", this.props.width)
                ? "mb-5"
                : "mr-5 ml-4 mb-5"
                }`}
            >
              <Mui.Box
                component="span"
                display="flex"
                justifyContent="center"
                mt={4}
              >
                <img className="img-fluid" src={defaultImg} alt="" />
              </Mui.Box>
            </Mui.Box>

            <Mui.Typography
              component="h1"
              variant="h1"
              className="text-center font-weight-medium mb-2"
            >
              {this.props.productData?.name?.[i18n.language] || "-"}
            </Mui.Typography>

            <Mui.Divider
              variant="middle"
              className="mt-2 background-color-primary"
              style={{ width: 80 }}
            />

            <Mui.Box
              width="100%"
              display="flex"
              flexWrap="wrap"
              className={`background-color-grey-light ${Mui.isWidthDown("xs", this.props.width) ? "p-2" : "p-5"
                }`}
            >
              <Mui.Box width="100%" display="flex" flexWrap="wrap">
                <Mui.Typography
                  component="h6"
                  variant="h6"
                  className="d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
                >
                  <span className="flex-shrink-0">
                    <IconId />
                  </span>
                  <span className="ml-2">
                    {formatCurrency(this.props.productData?.price, i18n.language)} (
                    {t("COMMON.EXCLUDING_VAT")})
                  </span>
                </Mui.Typography>

                <Mui.Typography
                  component="h6"
                  variant="h6"
                  className="d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
                >
                  <span className="flex-shrink-0">
                    <IconProduct />
                  </span>
                  <span className="ml-2">
                    {this.props.productData?.productCategory?.name || "-"}
                  </span>
                </Mui.Typography>

                <Mui.Typography
                  component="h6"
                  variant="h6"
                  className="d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
                >
                  <span className="flex-shrink-0">
                    <IconFoodServe />
                  </span>
                  <span className="ml-1">
                    {this.props.productData?.purchaseTypeConfigChoice?.map(
                      (value, i) => {
                        return (
                          <span key={i}>
                            {value?.displayName?.[i18n.language] || ""}
                            {i + 1 <
                              this.props.productData?.purchaseTypeConfigChoice
                                ?.length
                              ? ", "
                              : ""}
                          </span>
                        );
                      }
                    )}
                  </span>
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box width="100%" display="flex" flexWrap="wrap">
                <Mui.Typography
                  component="h6"
                  variant="h6"
                  className="d-flex mb-2 mr-5  font-weight-normal"
                >
                  <span className="flex-shrink-0">
                    <IconColorAllegens />
                  </span>
                  <span className="ml-2">
                    {this.props.productData?.allergens?.map((value, i) => {
                      return (
                        <span key={i}>
                          {value?.name || ""}
                          {i + 1 < this.props.productData?.allergens?.length
                            ? ", "
                            : ""}
                        </span>
                      );
                    })}
                  </span>
                </Mui.Typography>
                <Mui.Typography
                  component="h6"
                  variant="h6"
                  className="d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
                >
                  <span className="flex-shrink-0">
                    <IconCalendar />
                  </span>
                  <span className="ml-2">
                    {this.props.productData?.createdAt
                      ? moment(this.props.productData?.createdAt).format(
                        "MMM DD, YYYY"
                      )
                      : "-"}
                  </span>
                </Mui.Typography>
              </Mui.Box>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="w-100 d-flex mr-5  font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconColorDescription />
                </span>
                <span className="ml-2">
                  {this.props.productData?.description ||
                    t("PRODUCT.FORM.NO_DESCRIPTION")}
                </span>
              </Mui.Typography>
            </Mui.Box>
          </Mui.Box>
        </Mui.Dialog>

        <Mui.Dialog
          open={this.state.openTenantModal}
          onClose={this.handleCloseModal}
          maxWidth="sm"
          fullWidth={true}
          classes={{ paper: "pos-relative pt-3" }}
        >
          <Mui.IconButton
            className="pos-absolute pos-top-per-3 pos-right-per-3 svg-size-xs"
            onClick={this.handleCloseModal}
          >
            <IconClose />
          </Mui.IconButton>
          <Mui.Box display="flex" flexDirection="column" alignItems="center">
            <Mui.Box
              component="figure"
              bgcolor="#C9C9C9"
              className={`img-round img-size-xl svg-size-flexible ${Mui.isWidthDown("sm", this.props.width)
                ? "mb-5"
                : "mr-5 ml-4 mb-5"
                }`}
            >
              <Mui.Box
                component="span"
                display="flex"
                justifyContent="center"
                mt={4}
              >
                <IconColorOrganization />
              </Mui.Box>
            </Mui.Box>

            <Mui.Typography
              component="h1"
              variant="h1"
              className="text-center font-weight-medium"
            >
              {this.props.tenantData?.name || "-"}
            </Mui.Typography>

            <Mui.Divider
              variant="middle"
              className="mt-2 background-color-primary"
              style={{ width: 80 }}
            />

            <Mui.Box
              width="100%"
              display="flex"
              flexWrap="wrap"
              className={`background-color-grey-light ${Mui.isWidthDown("xs", this.props.width) ? "p-2" : "p-5"
                }`}
            >
              <Mui.Typography
                component="h6"
                variant="h6"
                className="w-100 d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconCanteen />
                </span>
                <span className="ml-2">
                  {this.props.tenantData?.tenantCanteens?.map((value, i) => {
                    return (
                      <span key={i}>
                        {value?.name || ""}
                        {i + 1 < this.props.tenantData?.tenantCanteens?.length
                          ? ", "
                          : ""}
                      </span>
                    );
                  })}
                </span>
              </Mui.Typography>
              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mb-2 mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconId />
                </span>
                <span className="ml-2">
                  {this.props.tenantData?.izyTenantId || "-"}
                </span>
              </Mui.Typography>

              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconPin />
                </span>
                <span className="ml-2">
                  {this.props.tenantData?.address || "-"}
                </span>
              </Mui.Typography>
              <Mui.Typography
                component="h6"
                variant="h6"
                className="d-flex mr-5  svg-color-primary font-weight-normal"
              >
                <span className="flex-shrink-0">
                  <IconCalendar />
                </span>
                <span className="ml-2">
                  {this.props.tenantData?.createdAt
                    ? moment(this.props.tenantData?.createdAt).format(
                      "MMM DD, YYYY"
                    )
                    : "-"}
                </span>
              </Mui.Typography>
            </Mui.Box>
          </Mui.Box>
        </Mui.Dialog>
      </>
    );
  }
}
const mapStateToProps = ({
  organization,
  shared,
  building,
  canteen,
  kiosk,
  product,
  tenant,
}) => {
  const { permission } = shared;
  const { organizationData, loading, error } = organization;
  const { buildingList, buildingData, loading: buildingLoading } = building;
  const { canteenList, canteenData, loading: canteenLoading } = canteen;
  const { kioskList, kioskData, loading: kioskLoading } = kiosk;
  const { productList, productData, loading: productLoading } = product;
  const { tenantList, tenantData, loading: tenantLoading } = tenant;
  return {
    permission,
    organizationData,
    buildingList,
    buildingData,
    buildingLoading,
    canteenList,
    canteenData,
    canteenLoading,
    kioskList,
    kioskData,
    kioskLoading,
    productList,
    productData,
    productLoading,
    tenantList,
    tenantData,
    tenantLoading,
    loading,
    error,
  };
};
export default connect(mapStateToProps, {
  getOrganization,
  getBuildingList,
  getBuilding,
  getCanteenList,
  getCanteen,
  getKioskList,
  getKiosk,
  getProductList,
  getProduct,
  getTenantList,
  getTenant,
})(withTranslation()(Mui.withWidth()(ViewOrganization)));
