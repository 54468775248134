import * as Mui from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTheme } from "../../ThemeContext";
import { IconChevronDown, IconGlobe } from "../../components/svg";

export default function LanguageDropdownAlt() {
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const setLocale = (lng) => {
    i18n.changeLanguage(lng);
    onMenuClose();
  };
  const { theme } = useTheme()

  const { user } = useSelector(state => state.auth)

  return (
    <>
      <Mui.Button
        variant="outlined"
        onClick={onMenuOpen}
        className="ml-2 mr-1 svg-size-sm d-flex align-items-center"
        data-cy="change-language-button"
        style={{ alignSelf: 'center', border: 'none' }}
      >
        <IconGlobe fill={theme === 'dark' && user !== null ? 'white' : '#000330'} />
        <Mui.Typography style={{ color: '#000330', textTransform: 'none', fontWeight: 350, marginLeft: 5 }} className=" mr-1">{i18n.language === "en" ? t('COMMON.ENGLISH') : t('COMMON.NORWEGIAN')}</Mui.Typography>
        <IconChevronDown width="10" />
      </Mui.Button>
      <Mui.Popover
        className="kebab-dropdown"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
      >
        <Mui.MenuItem
          selected={i18n.language === "en"}
          onClick={() => setLocale("en")}
          data-cy="en-menu"
        >
          <span className="d-flex">
            {/* <img src={en} alt="" className="img-fluid mr-2" /> */}
            {t('COMMON.ENGLISH')}
          </span>
        </Mui.MenuItem>
        <Mui.MenuItem
          selected={i18n.language === "no"}
          onClick={() => setLocale("no")}
          data-cy="no-menu"
        >
          <span className="d-flex">
            {/* <img src={no} alt="" className="img-fluid mr-2" /> */}
            {t('COMMON.NORWEGIAN')}
          </span>
        </Mui.MenuItem>
      </Mui.Popover>
    </>
  );
}