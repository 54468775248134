import * as Mui from "@material-ui/core";
import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  IconDefaultProduct,
  IconInfo
} from "../../../components/svg";
import { formatCurrency } from "../../../helpers/util";

const BestProduct = (props) => {

  const { t, i18n } = useTranslation()
  return (
    <Mui.Paper className="widget-card">
      {/* <Mui.IconButton size="small" className="btn-menu">
        <IconMoreKebab />
      </Mui.IconButton> */}

      <Mui.Typography
        component="h3"
        variant="h3"
        className="mb-2 font-weight-normal title"
      >
        {t("DASHBOARD.MOST_POPULAR_PRODUCT")}
        <Mui.Tooltip
          title={t("DASHBOARD.MOST_POPULAR_PRODUCT_TOOLTIP")}
          arrow
          placement="bottom-start"
          PopperProps={{
            className:
              "MuiTooltip-popper MuiTooltip-popperArrow tooltip-popover default-tooltip",
          }}
        >
          <span className="ml-1 svg-color-grey">
            <IconInfo />
          </span>
        </Mui.Tooltip>
      </Mui.Typography>

      <Mui.Box maxHeight="25vh" overflow="auto" display={'flex'} flexDirection={'column'}>
        {props?.bestProductData?.map((item, index) => {
          return (

            <Mui.Box
              width="100%"
              display="flex"
              justifyContent={'space-between'}
              paddingRight={2}
              flexWrap={
                Mui.isWidthDown("xs", props.width) ? "wrap" : "nowrap"
              }
              style={{
                boxShadow: '0px 10px 13px 0px rgba(17, 38, 146, 0.05)',
                border: '1px solid #e0e0e0',
                borderRadius: 8,
                padding: 10,
                marginBottom: '0.5rem'
              }}
              key={index}
            >
              <Mui.Box display={'flex'} gridGap={10}>
                <Mui.Box height={60} width={75} style={{ backgroundColor: '#a6b2bf' }} border={'1px solid gray'} borderRadius={5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  {item.documentUrl ?
                    <Mui.Avatar
                      style={{ borderRadius: 5, height: 60, width: 75, objectFit: 'contain' }}
                      alt={item.name?.[i18n.language]}
                      src={item.documentUrl}
                    />
                    : <IconDefaultProduct stroke="#FFFFFF" />
                  }
                </Mui.Box>
                <Mui.Box>
                  <Mui.Typography
                    component={Mui.isWidthDown("sm", props.width) ? "h6" : "h5"}
                    variant={Mui.isWidthDown("sm", props.width) ? "h6" : "h5"}
                    className="mr-auto font-weight-normal"
                  >
                    {item?.name?.[i18n.language] || "-"}
                  </Mui.Typography>
                  <Mui.Typography
                    component="h6"
                    variant="h6"
                    className="font-weight-normal text-color-muted"
                  >
                    {t("DASHBOARD.QUANTITY_SOLD")}: {item?.totalQuantitySold}
                  </Mui.Typography>
                </Mui.Box>
              </Mui.Box>
              <Mui.Box >
                <Mui.Typography
                  component={Mui.isWidthDown("sm", props.width) ? "h6" : "h5"}
                  variant={Mui.isWidthDown("sm", props.width) ? "h6" : "h5"}
                  className="ml-auto text-right font-weight-normal"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {formatCurrency(item?.totalAmount, i18n.language)}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
          );
        })}

        {props.bestProductData?.length === 0 ? (
          <Mui.Box height={'20vh'} display={'flex'} justifyContent={'center'} alignItems={'center'} overflow="auto">
            <Mui.Typography
              component="h6"
              variant="h6"
              className="font-weight-normal text-center text-color-muted"
            >
              {t("COMMON.FORM.NO_RECORD_LABEL")}
            </Mui.Typography>
          </Mui.Box>
        ) : null}
      </Mui.Box>
    </Mui.Paper>
  );

}
const mapStateToProps = ({ dashboard }) => {
  const { bestProductData } = dashboard;
  return { bestProductData };
};
export default connect(
  mapStateToProps,
  {}
)(withTranslation()(Mui.withWidth()(BestProduct)));
