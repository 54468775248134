import * as Mui from '@material-ui/core';
import Lottie from 'lottie-react';
import loadingLottie from '../../components/lottie/loading.json';

export const ValidatingToken = () => {

    return <Mui.Box style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        {/* <Mui.CircularProgress /> */}
        <Lottie animationData={loadingLottie} loop={true} />
    </Mui.Box>
}