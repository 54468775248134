import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import DashboardService from "../../services/dashboard-service";
import {
  GET_OVERVIEW,
  GET_SALE,
  GET_BEST_PRODUCT,
  GET_PURCHASE_TYPE_SALE,
  GET_RECENT_PRODUCT,
  GET_CUSTOMER_REPEAT_RATE,
  GET_TOP_TENANT,
} from "../actions";
import {
  getOverviewSuccess,
  getOverviewError,
  getSaleSuccess,
  getSaleError,
  getBestProductSuccess,
  getBestProductError,
  getPurchaseTypeSaleSuccess,
  getPurchaseTypeSaleError,
  getRecentProductSuccess,
  getRecentProductError,
  getCustomerRepeatRateSuccess,
  getCustomerRepeatRateError,
  getTopTenantSuccess,
  getTopTenantError,
} from "./action";
import { toast } from "react-toastify";
import ToastElement from "../../components/toast";

export function* watchGetOverview() {
  yield takeEvery(GET_OVERVIEW, getOverview);
}

const getOverviewAsync = async (param) => {
  return DashboardService.getOverview(param);
};

function* getOverview({ payload }) {
  try {
    const response = yield call(getOverviewAsync, payload.param);
    if (response.data.success) {
      yield put(getOverviewSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getOverviewError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getOverviewError(error.response.data.message));
  }
}

export function* watchGetSale() {
  yield takeEvery(GET_SALE, getSale);
}

const getSaleAsync = async (param) => {
  return DashboardService.getSale(param);
};

function* getSale({ payload }) {
  try {
    const response = yield call(getSaleAsync, payload.param);
    if (response.data.success) {
      yield put(getSaleSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getSaleError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getSaleError(error.response.data.message));
  }
}

export function* watchGetBestProduct() {
  yield takeEvery(GET_BEST_PRODUCT, getBestProduct);
}

const getBestProductAsync = async (param) => {
  return DashboardService.getBestProduct(param);
};

function* getBestProduct({ payload }) {
  try {
    const response = yield call(getBestProductAsync, payload.param);
    if (response.data.success) {
      yield put(getBestProductSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getBestProductError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getBestProductError(error.response.data.message));
  }
}

export function* watchGetPurchaseTypeSale() {
  yield takeEvery(GET_PURCHASE_TYPE_SALE, getPurchaseTypeSale);
}

const getPurchaseTypeSaleAsync = async (param) => {
  return DashboardService.getPurchaseTypeSale(param);
};

function* getPurchaseTypeSale({ payload }) {
  try {
    const response = yield call(getPurchaseTypeSaleAsync, payload.param);
    if (response.data.success) {
      yield put(getPurchaseTypeSaleSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getPurchaseTypeSaleError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getPurchaseTypeSaleError(error.response.data.message));
  }
}

export function* watchGetRecentProduct() {
  yield takeEvery(GET_RECENT_PRODUCT, getRecentProduct);
}

const getRecentProductAsync = async (param) => {
  return DashboardService.getRecentProduct(param);
};

function* getRecentProduct({ payload }) {
  try {
    const response = yield call(getRecentProductAsync, payload.param);
    if (response.data.success) {
      yield put(getRecentProductSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getRecentProductError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getRecentProductError(error.response.data.message));
  }
}

export function* watchGetCustomerRepeatRate() {
  yield takeEvery(GET_CUSTOMER_REPEAT_RATE, getCustomerRepeatRate);
}

const getCustomerRepeatRateAsync = async (param) => {
  return DashboardService.getCustomerRepeatRate(param);
};

function* getCustomerRepeatRate({ payload }) {
  try {
    const response = yield call(getCustomerRepeatRateAsync, payload.param);
    if (response.data.success) {
      yield put(getCustomerRepeatRateSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getCustomerRepeatRateError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getCustomerRepeatRateError(error.response.data.message));
  }
}

export function* watchGetTopTenant() {
  yield takeEvery(GET_TOP_TENANT, getTopTenant);
}

const getTopTenantAsync = async (param) => {
  return DashboardService.getTopTenant(param);
};

function* getTopTenant({ payload }) {
  try {
    const response = yield call(getTopTenantAsync, payload.param);
    if (response.data.success) {
      yield put(getTopTenantSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getTopTenantError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getTopTenantError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetOverview),
    fork(watchGetSale),
    fork(watchGetBestProduct),
    fork(watchGetPurchaseTypeSale),
    fork(watchGetRecentProduct),
    fork(watchGetCustomerRepeatRate),
    fork(watchGetTopTenant),
  ]);
}
