export const RESET_CANTEEN_PRODUCT_MENU_LIST =
  "RESET_CANTEEN_PRODUCT_MENU_LIST";
export const UPDATE_CANTEEN_PRODUCT_LIST = "UPDATE_CANTEEN_PRODUCT_LIST";
export const UPDATE_MULTIPLE_CANTEEN_PRODUCT_LIST =
  "UPDATE_MULTIPLE_CANTEEN_PRODUCT_LIST";
export const DETACH_CANTEEN_PRODUCT_LIST = "DETACH_CANTEEN_PRODUCT_LIST";
export const SET_CANTEEN_PRODUCT_LIST = "SET_CANTEEN_PRODUCT_LIST";
export const GET_CANTEEN_PRODUCT_LIST = "GET_CANTEEN_PRODUCT_LIST";
export const GET_CANTEEN_PRODUCT_LIST_SUCCESS =
  "GET_CANTEEN_PRODUCT_LIST_SUCCESS";
export const GET_CANTEEN_PRODUCT_LIST_ERROR = "GET_CANTEEN_PRODUCT_LIST_ERROR";
export const UPDATE_CANTEEN_MENU_LIST = "UPDATE_CANTEEN_MENU_LIST";
export const UPDATE_MULTIPLE_CANTEEN_MENU_LIST =
  "UPDATE_MULTIPLE_CANTEEN_MENU_LIST";
export const DETACH_CANTEEN_MENU_LIST = "DETACH_CANTEEN_MENU_LIST";
export const SET_CANTEEN_MENU_LIST = "SET_CANTEEN_MENU_LIST";
export const GET_CANTEEN_MENU_LIST = "GET_CANTEEN_MENU_LIST";
export const GET_CANTEEN_MENU_LIST_SUCCESS = "GET_CANTEEN_MENU_LIST_SUCCESS";
export const GET_CANTEEN_MENU_LIST_ERROR = "GET_CANTEEN_MENU_LIST_ERROR";
export const SET_TENANT_CANTEEN_PRODUCT_LIST =
  "SET_TENANT_CANTEEN_PRODUCT_LIST";
export const SET_TENANT_CANTEEN_MENU_LIST = "SET_TENANT_CANTEEN_MENU_LIST";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_ERROR = "GET_ALL_PRODUCT_ERROR";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_ERROR = "GET_PRODUCT_LIST_ERROR";
export const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY";
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_ERROR = "GET_PRODUCT_CATEGORY_ERROR";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";
export const DELETE_MULTIPLE_PRODUCT = "DELETE_MULTIPLE_PRODUCT";
export const DELETE_MULTIPLE_PRODUCT_SUCCESS =
  "DELETE_MULTIPLE_PRODUCT_SUCCESS";
export const DELETE_MULTIPLE_PRODUCT_ERROR = "DELETE_MULTIPLE_PRODUCT_ERROR";
export const IMPORT_PRODUCT = "IMPORT_PRODUCT";
export const IMPORT_PRODUCT_SUCCESS = "IMPORT_PRODUCT_SUCCESS";
export const IMPORT_PRODUCT_ERROR = "IMPORT_PRODUCT_ERROR";
export const ADD_PRODUCT_CLASS = "ADD_PRODUCT_CLASS";
export const ADD_PRODUCT_CLASS_SUCCESS = "ADD_PRODUCT_CLASS_SUCCESS";
export const ADD_PRODUCT_CLASS_ERROR = "ADD_PRODUCT_CLASS_ERROR";
export const UPDATE_PRODUCT_NUTRITION = "UPDATE_PRODUCT_NUTRITION";
export const UPDATE_PRODUCT_NUTRITION_SUCCESS = "UPDATE_PRODUCT_NUTRITION_SUCCESS";
export const UPDATE_PRODUCT_NUTRITION_ERROR = "UPDATE_PRODUCT_NUTRITION_ERROR";

export const PRODUCT_BULK_STATUS_UPDATE = "PRODUCT_BULK_STATUS_UPDATE";
export const PRODUCT_BULK_STATUS_UPDATE_SUCCESS = "PRODUCT_BULK_STATUS_UPDATE_SUCCESS";
export const PRODUCT_BULK_STATUS_UPDATE_ERROR = "PRODUCT_BULK_STATUS_UPDATE_ERROR";

export const PULL_IMAGE_FROM_ANNOTATION = "PULL_IMAGE_FROM_ANNOTATION";
export const PULL_IMAGE_FROM_ANNOTATION_SUCCESS = "PULL_IMAGE_FROM_ANNOTATION_SUCCESS";
export const PULL_IMAGE_FROM_ANNOTATION_ERROR = "PULL_IMAGE_FROM_ANNOTATION_ERROR";

export const EXPORT_PRODUCTS = "EXPORT_PRODUCTS";
export const EXPORT_PRODUCTS_SUCCESS = "EXPORT_PRODUCTS_SUCCESS";
export const EXPORT_PRODUCTS_ERROR = "EXPORT_PRODUCTS_ERROR";

export const RESET_PRODUCT = "RESET_PRODUCT";

export const resetCanteenProductMenuList = () => ({
  type: RESET_CANTEEN_PRODUCT_MENU_LIST,
  payload: {},
});

export const updateMultipleCanteenProductList = (index, arr, value, key) => ({
  type: UPDATE_MULTIPLE_CANTEEN_PRODUCT_LIST,
  payload: { index, arr, value, key },
});

export const updateCanteenProductList = (indices, value, key) => ({
  type: UPDATE_CANTEEN_PRODUCT_LIST,
  payload: { indices, value, key },
});

export const detachCanteenProductList = (canteenIds) => ({
  type: DETACH_CANTEEN_PRODUCT_LIST,
  payload: canteenIds,
});

export const setCanteenProductList = (tenantCanteenProducts, canteenIdArr) => ({
  type: SET_CANTEEN_PRODUCT_LIST,
  payload: { tenantCanteenProducts, canteenIdArr },
});

export const getCanteenProductList = (canteenId) => ({
  type: GET_CANTEEN_PRODUCT_LIST,
  payload: canteenId,
});

export const getCanteenProductListSuccess = (canteenId, canteenProductArr) => ({
  type: GET_CANTEEN_PRODUCT_LIST_SUCCESS,
  payload: { canteenId, canteenProductArr },
});

export const getCanteenProductListError = (error) => ({
  type: GET_CANTEEN_PRODUCT_LIST_ERROR,
  payload: error,
});

export const updateMultipleCanteenMenuList = (index, arr, value, key) => ({
  type: UPDATE_MULTIPLE_CANTEEN_MENU_LIST,
  payload: { index, arr, value, key },
});

export const updateCanteenMenuList = (indices, value, key) => ({
  type: UPDATE_CANTEEN_MENU_LIST,
  payload: { indices, value, key },
});

export const detachCanteenMenuList = (canteenIds) => ({
  type: DETACH_CANTEEN_MENU_LIST,
  payload: canteenIds,
});

export const setCanteenMenuList = (tenantCanteenMenus, canteenIdArr) => ({
  type: SET_CANTEEN_MENU_LIST,
  payload: { tenantCanteenMenus, canteenIdArr },
});

export const getCanteenMenuList = (param) => ({
  type: GET_CANTEEN_MENU_LIST,
  payload: { param },
});

export const getCanteenMenuListSuccess = (canteenId, canteenMenuArr) => ({
  type: GET_CANTEEN_MENU_LIST_SUCCESS,
  payload: { canteenId, canteenMenuArr },
});

export const getCanteenMenuListError = (error) => ({
  type: GET_CANTEEN_MENU_LIST_ERROR,
  payload: error,
});

export const setTenantCanteenProductList = (
  tenantCanteenProducts,
  canteenIdArr
) => ({
  type: SET_TENANT_CANTEEN_PRODUCT_LIST,
  payload: { tenantCanteenProducts, canteenIdArr },
});

export const setTenantCanteenMenuList = (tenantCanteenMenus, canteenIdArr) => ({
  type: SET_TENANT_CANTEEN_MENU_LIST,
  payload: { tenantCanteenMenus, canteenIdArr },
});

export const getAllProduct = (param) => ({
  type: GET_ALL_PRODUCT,
  payload: { param },
});

export const getAllProductSuccess = (products) => ({
  type: GET_ALL_PRODUCT_SUCCESS,
  payload: products,
});

export const getAllProductError = (error) => ({
  type: GET_ALL_PRODUCT_ERROR,
  payload: error,
});

export const getProductList = (dbParam) => ({
  type: GET_PRODUCT_LIST,
  payload: { dbParam },
});

export const getProductListSuccess = (productList) => ({
  type: GET_PRODUCT_LIST_SUCCESS,
  payload: productList,
});

export const getProductListError = (error) => ({
  type: GET_PRODUCT_LIST_ERROR,
  payload: error,
});

export const getProductCategory = () => ({
  type: GET_PRODUCT_CATEGORY,
  payload: {},
});

export const getProductCategorySuccess = (productCategoryData) => ({
  type: GET_PRODUCT_CATEGORY_SUCCESS,
  payload: productCategoryData,
});

export const getProductCategoryError = (error) => ({
  type: GET_PRODUCT_CATEGORY_ERROR,
  payload: error,
});

export const addProduct = (productData, history, location) => ({
  type: ADD_PRODUCT,
  payload: { productData, history, location },
});

export const addProductSuccess = (success, message) => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: { success, message },
});

export const addProductError = (error) => ({
  type: ADD_PRODUCT_ERROR,
  payload: error,
});

export const getProduct = (productId, organizationId) => ({
  type: GET_PRODUCT,
  payload: { productId, organizationId },
});

export const getProductSuccess = (productData) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: productData,
});

export const getProductError = (error) => ({
  type: GET_PRODUCT_ERROR,
  payload: error,
});

export const editProduct = (productId, productData, history, location) => ({
  type: EDIT_PRODUCT,
  payload: { productId, productData, history, location },
});

export const editProductSuccess = (success, message) => ({
  type: EDIT_PRODUCT_SUCCESS,
  payload: { success, message },
});

export const editProductError = (error) => ({
  type: EDIT_PRODUCT_ERROR,
  payload: error,
});

export const deleteProduct = (productId, prevProductOptions) => ({
  type: DELETE_PRODUCT,
  payload: { productId, prevProductOptions },
});

export const deleteProductSuccess = (success, message) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: { success, message },
});

export const deleteProductError = (error) => ({
  type: DELETE_PRODUCT_ERROR,
  payload: error,
});

export const deleteMultipleProduct = (productIds) => ({
  type: DELETE_MULTIPLE_PRODUCT,
  payload: { productIds },
});

export const deleteMultipleProductSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_PRODUCT_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleProductError = (error) => ({
  type: DELETE_MULTIPLE_PRODUCT_ERROR,
  payload: error,
});

export const importProduct = (importParam) => ({
  type: IMPORT_PRODUCT,
  payload: { importParam },
});

export const importProductSuccess = (success, message) => ({
  type: IMPORT_PRODUCT_SUCCESS,
  payload: { success, message },
});

export const importProductError = (error) => ({
  type: IMPORT_PRODUCT_ERROR,
  payload: error,
});

export const addProductClass = (productId) => ({
  type: ADD_PRODUCT_CLASS,
  payload: { productId },
});

export const addProductClassSuccess = (success, message) => ({
  type: ADD_PRODUCT_CLASS_SUCCESS,
  payload: { success, message },
});

export const addProductClassError = (error) => ({
  type: ADD_PRODUCT_CLASS_ERROR,
  payload: error,
});

export const updateProductNutrition = (productIds, callback, prevProductOptions) => ({
  type: UPDATE_PRODUCT_NUTRITION,
  payload: { productIds, callback, prevProductOptions },
});

export const updateProductNutritionSuccess = (success, message) => ({
  type: UPDATE_PRODUCT_NUTRITION_SUCCESS,
  payload: { success, message },
});

export const updateProductNutritionError = (error) => ({
  type: UPDATE_PRODUCT_NUTRITION_ERROR,
  payload: error,
});

export const productBulkStatusUpdate = (payload, callback, prevProductOptions) => ({
  type: PRODUCT_BULK_STATUS_UPDATE,
  payload: { payload, callback, prevProductOptions },
});

export const productBulkStatusUpdateSuccess = (success, message) => ({
  type: PRODUCT_BULK_STATUS_UPDATE_SUCCESS,
  payload: { success, message },
});

export const productBulkStatusUpdateError = (error) => ({
  type: PRODUCT_BULK_STATUS_UPDATE_ERROR,
  payload: error,
});



export const pullImageFromAnnotation = (productId, callback) => ({
  type: PULL_IMAGE_FROM_ANNOTATION,
  payload: { productId, callback },
});

export const pullImageFromAnnotationSuccess = (success, message) => ({
  type: PULL_IMAGE_FROM_ANNOTATION_SUCCESS,
  payload: { success, message },
});

export const pullImageFromAnnotationError = (error) => ({
  type: PULL_IMAGE_FROM_ANNOTATION_ERROR,
  payload: error,
});

export const exportProducts = (queryParam) => ({
  type: EXPORT_PRODUCTS,
  payload: { queryParam },
});

export const exportProductsSuccess = (success, message) => ({
  type: EXPORT_PRODUCTS_SUCCESS,
  payload: { success, message },
});

export const exportProductsError = (error) => ({
  type: EXPORT_PRODUCTS_ERROR,
  payload: error,
});


export const resetProduct = () => ({
  type: RESET_PRODUCT,
  payload: {},
});
