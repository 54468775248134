import React, { useState, useEffect } from "react";
import { useField } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { IconChevronDown } from "../svg";

const InputDatepickerField = ({
  label,
  view = "date",
  shrinkLabel = true,
  classes = {},
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (field.value) {
      setSelectedDate(field.value);
    }
  }, [field.value]);

  const getFormat = (val) => {
    if (val === "month") {
      return "MMMM";
    }
    if (val === "year") {
      return "yyyy";
    }
    return "dd.MM.yyyy";
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    helpers.setValue(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={`date-time-picker`}
        autoOk
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format={getFormat(view)}
        margin="normal"
        label={label || null}
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
          className: "pl-0 pr-0 svg-size-xs",
        }}
        InputProps={{
          classes: classes,
        }}
        InputLabelProps={{
          shrink: shrinkLabel,
        }}
        PopoverProps={{
          classes: {
            paper: `${view === "year" ? "date-time-picker-yearly" : null}`,
          },
        }}
        views={[`${view}`]}
        openTo={`${view}`}
        keyboardIcon={<IconChevronDown />}
        helperText={meta.touched && meta.error ? meta.error : null}
        error={meta.touched && meta.error ? true : false}
      />
    </MuiPickersUtilsProvider>
  );
};

export default InputDatepickerField;
