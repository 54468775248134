import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getApiUrl } from "./helpers/util";

const fallbackLng = ["no"];
const availableLanguages = ["no", "en"];

i18n
  .use(ChainedBackend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.
    backend: {
      backends: [
        HttpBackend,
        HttpBackend
      ],
      backendOptions: [
        {
          loadPath: `${getApiUrl()}/swap/loco/{{lng}}`
        }, {
          loadPath: '/locales/{{lng}}.json'
        }]
    },
    // backend: {
    //   // for all available options read the backend's repository readme file
    //   loadPath: '/locales/{{lng}}.json'
    // },
    detection: {
      checkWhitelist: true, // options for language detection
    },
    // react: {
    //   useSuspense: false,
    // },

    debug: false,

    whitelist: availableLanguages,
  });

export default i18n;
