import Req from "../interceptors/token-interceptor";

const OrganizationService = (function () {
  function _getAllOrganization() {
    return Req.get(`/api/organizations`);
  }
  function _onboardOrganization(id) {
    return Req.post(`/api/organizations/onboard/${id}`);
    // return Req.get(`/api/organization/onboard/${id}`);
  }
  function _addOrganization(data) {
    return Req.post("/api/organizations", data);
  }
  function _getOrganization(id) {
    return Req.get(`/api/organizations/${id}`);
  }
  function _editOrganization(data, id) {
    return Req.put(`/api/organizations/${id}`, data);
  }
  function _deleteOrganization(id) {
    return Req.delete(`/api/organizations/${id}`);
  }
  function _deleteMultipleOrganization(ids) {
    return Req.post("/api/organizations/delete", { ids: ids });
  }
  function _resendEmail(data) {
    return Req.post("/api/organizations/onboard-user", data);
  }
  return {
    getAllOrganization: _getAllOrganization,
    getOrganization: _getOrganization,
    onboardOrganization: _onboardOrganization,
    addOrganization: _addOrganization,
    editOrganization: _editOrganization,
    deleteOrganization: _deleteOrganization,
    deleteMultipleOrganization: _deleteMultipleOrganization,
    resendEmail: _resendEmail,
  };
})();
export default OrganizationService;
