import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkPermission } from "../../helpers/util";

export function ContentRoute({
  children,
  component,
  render,
  data,
  permission,
  ...props
}) {
  return (
    <Route {...props}>
      {(routeProps) => {
        if (
          data &&
          permission &&
          !checkPermission(data.module, data.section, data.action, permission)
        ) {
          return <Redirect to="/403" />;
        }

        if (typeof children === "function") {
          return <>{children(routeProps)}</>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <>{children}</>;
        }

        if (component) {
          return <>{React.createElement(component, routeProps)}</>;
        }

        if (render) {
          return <>{render(routeProps)}</>;
        }

        return null;
      }}
    </Route>
  );
}
