import React, { useState } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import defaultAvatar from "../../assets/images/user-avatar.svg";
import { IconChevronDown, IconLogout, IconUsero } from "../../components/svg";

const UserInfoDropdown = (props) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const userName = props.currentUserData?.fullname.split(' ')[0] ?? '';

  return (
    <>
      <Mui.Box
        display="flex"
        alignItems="center"
        className="ml-2 p-0 user-info-dropdown"
        onClick={openMenu}
        component={Mui.Button}
      >
        <Mui.Avatar
          src={
            props.currentUserData?.documentUrl
              ? props.currentUserData?.documentUrl
              : defaultAvatar
          }
          alt={props.currentUserData?.fullname || ""}
          className="background-color-white"
        />
        {Mui.isWidthDown("sm", props.width) ? null : (
          <Mui.Box display="flex " className="flex-direction-column ">
            <Mui.Box display="flex" alignItems="center">
              <Mui.Typography className="font-weight-normal ml-2">
                {/* {props.currentUserData?.fullname} */}
                {/* capital  first letter */}
                {userName ?

                  (userName?.charAt(0).toUpperCase() +
                    userName?.slice(1)) : ""}
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box display="flex" alignItems="center">
              <Mui.Typography
                className="font-weight-normal ml-2 text-color-side-nav"
              >
                {props.currentUserData?.role?.name?.[i18n.language]}{" "}
              </Mui.Typography>
              <span className="ml-1 p-0 line-height-null svg-size-small svg-color-toolbar-icon">
                <IconChevronDown />
              </span>
            </Mui.Box>
          </Mui.Box>
        )}
      </Mui.Box>

      <Mui.Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeMenu}
        elevation={0}
        classes={{ paper: "user-dropdown" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Mui.MenuItem onClick={closeMenu} to={"/profile"} component={Link}>
          <span className="icon">
            <IconUsero />
          </span>
          {t("PROFILE.TITLE")}
        </Mui.MenuItem>

        <Mui.Divider light={true} />
        <Mui.MenuItem onClick={closeMenu} to={"/logout"} component={Link}>
          <span className="icon">
            <IconLogout />
          </span>
          {t("COMMON.LOGOUT")}
        </Mui.MenuItem>
      </Mui.Popover>
    </>
  );
};
const mapStateToProps = ({ shared }) => {
  const { currentUserData } = shared;
  return {
    currentUserData,
  };
};
export default connect(mapStateToProps, {})(Mui.withWidth()(UserInfoDropdown));
