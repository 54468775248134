/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/views/auth/index`, `src/app/Base`).
 */

import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PermissionGuard } from "./guards";
import Layout from "./layouts";
import BaseView from "./views/app";
import AuthView from "./views/auth";
import LogoutView from "./views/auth/logout";
import IconView from "./views/icon";
import NotAuthorizedView from "./views/not-authorized";
import NotFoundView from "./views/not-found";

import { useHistory } from 'react-router-dom';
import TermsAndCondition from "./views/terms-and-conditions";

export default function Routes() {
  // Initialize laravel-echo
  // window.io = io;
  // window.Echo = new Echo({
  //   broadcaster: "socket.io",
  //   host: getBroadcastUrl(),
  // });

  const history = useHistory()

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );



  useEffect(() => {
    if (!window.location.hostname.split(".").includes("localhost")) {
      const logoutUser = () => {
        history.push("/logout");
      }
      var wait = setTimeout(logoutUser, 14400000);

      document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = function () {
        clearTimeout(wait);
        wait = setTimeout(logoutUser, 14400000);
      };

      return () => {
        clearTimeout(wait)
      }
    }
  }, [history, isAuthorized])



  return (
    <>
      <Switch>
        <Route path="/icon" component={IconView} />
        <Route path="/tenant-user/:token" component={TermsAndCondition} />
        <Route path="/terms-and-conditions" component={TermsAndCondition} />

        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthView />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )}
        <Route path="/404" component={NotFoundView} />
        <Route path="/403" component={NotAuthorizedView} />
        <Route path="/logout" component={LogoutView} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <PermissionGuard>
              <BaseView />
            </PermissionGuard>
          </Layout>
        )}
      </Switch>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        enableMultiContainer
        containerId={"default"}
      />
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        closeButton={false}
        enableMultiContainer
        containerId={"custom"}
      />
    </>
  );
}
