import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  getOrganization,
  addOrganization,
  editOrganization,
  getConfigChoice,
  resetOrganization,
  resendEmail,
  getCountryList,
} from "../../../reduxs/actions";
import { Formik, Form, FieldArray } from "formik";
import { AutocompleteSelectField, InputField, SelectField } from "../../../components/form";
import { Link } from "react-router-dom";
import { IconTrash, IconAdd, IconRefresh } from "../../../components/svg";
import { Alert } from "../../../components/dialog";
class EditOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = { openAlert: false, resendEmailParam: null };
    this.schema = Yup.object().shape({
      name: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),
      number: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),
      adminUser: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
            .nullable(),
          email: Yup.string()
            .email(this.props.t("AUTH.VALIDATION.INVALID_EMAIL"))
            .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
            .nullable(),
        })
      ),
      // status: Yup.string()
      //   .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
      //   .nullable(),
      streetName: Yup.string()
        .max(100, this.props.t("AUTH.VALIDATION.MAX_LENGTH", {
          name: this.props.t("ORGANIZATION.FORM.PLACEHOLDER.STREET_NAME"),
          max: 100
        }))
        .nullable(),
      streetNumber: Yup.string()
        .max(50, this.props.t("AUTH.VALIDATION.MAX_LENGTH", {
          name: this.props.t("ORGANIZATION.FORM.PLACEHOLDER.STREET_NUMBER"),
          max: 50
        }))
        .nullable(),
      postalCode: Yup.string()
        .max(100, this.props.t("AUTH.VALIDATION.MAX_LENGTH", {
          name: this.props.t("ORGANIZATION.FORM.PLACEHOLDER.POSTAL_CODE"),
          max: 100
        }))
        .nullable(),
      city: Yup.string()
        .max(100, this.props.t("AUTH.VALIDATION.MAX_LENGTH", {
          name: this.props.t("ORGANIZATION.FORM.PLACEHOLDER.CITY"),
          max: 100
        }))
        .nullable(),
      region: Yup.string()
        .max(100, this.props.t("AUTH.VALIDATION.MAX_LENGTH", {
          name: this.props.t("ORGANIZATION.FORM.PLACEHOLDER.REGION"),
          max: 100
        }))
        .nullable(),
    });
  }

  componentDidMount() {
    this.props.getConfigChoice("organizationStatus");
    this.props.getCountryList();
    if (this.props.match.params.id) {
      this.props.getOrganization(this.props.match.params.id);
    }
  }

  handleOpenAlert = (param) => {
    this.setState({ openAlert: true, resendEmailParam: param });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false, resendEmailParam: null });
  };

  onResend = () => {
    if (!this.props.loading1) {
      this.props.resendEmail(this.state.resendEmailParam);
    }
  };

  onSubmit = (values) => {
    if (!this.props.loading) {
      if (this.props.match.params.id) {
        this.props.editOrganization(
          this.props.match.params.id,
          values,
          this.props.history,
          this.props.location
        );
      } else {
        this.props.addOrganization(values, this.props.history, this.props.location);
      }
    }
  };

  render() {
    const { t } = this.props;

    const isEditing = !!this.props.match.params.id
    return (
      <>
        <Breadcrumb
          title={t("PERMISSIONS.modules.organizationManagement")}
          paths={[
            {
              title: t("PERMISSIONS.sections.organization"),
              page: `/organization`,
            },
            {
              title: this.props.match.params.id
                ? t("PERMISSIONS.actions.edit")
                : t("PERMISSIONS.actions.add"),
            },
          ]}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            name: isEditing
              ? this.props.organizationData?.name || ""
              : "",
            number: isEditing
              ? this.props.organizationData?.number || ""
              : "",
            adminUser: isEditing
              ? this.props.organizationData?.adminUser || []
              : [{ name: "", email: "" }],
            status: isEditing
              ? this.props.organizationData?.status || ""
              : "",
            // websiteId: this.props.organizationData ? this.props.organizationData?.websiteId || "" : null
            streetName: isEditing
              ? this.props.organizationData?.streetName || ""
              : "",
            streetNumber: isEditing
              ? this.props.organizationData?.streetNumber || ""
              : "",
            postalCode: isEditing
              ? this.props.organizationData?.postalCode || ""
              : "",
            city: isEditing
              ? this.props.organizationData?.city || ""
              : "",
            region: isEditing
              ? this.props.organizationData?.region || ""
              : "",
            countryId: isEditing
              ? this.props.organizationData?.country?.id || 160
              : 160,
            // predictionClientId: isEditing
            //   ? this.props.organizationData?.predictionClientId || ""
            //   : ""
          }}
          validationSchema={this.schema}
          onSubmit={this.onSubmit}
        >
          {(props) => (
            <Form className="default-form">
              <Mui.Card className="default-card">
                <Mui.Typography
                  component="h4"
                  variant="h4"
                  className="mb-4 font-weight-normal"
                >
                  {t("ORGANIZATION.INFO_LABEL")}
                </Mui.Typography>

                <Mui.Grid container spacing={4}>
                  <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                    <InputField
                      name="name"
                      type="text"
                      label={t("ORGANIZATION.FORM.PLACEHOLDER.NAME")}
                    />
                  </Mui.Grid>

                  <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                    <InputField
                      name="number"
                      type="text"
                      label={t("ORGANIZATION.FORM.PLACEHOLDER.NUMBER")}
                    />
                  </Mui.Grid>
                  {/* <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                    <InputField
                      name="predictionClientId"
                      type="text"
                      label={"Prediction Client Id"}
                    />
                  </Mui.Grid> */}
                  <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                    <InputField
                      name="streetName"
                      type="text"
                      label={t("SETTING.FORM.STREET_ADDRESS")}
                    // placeholder={t("SETTING.FORM.PLACEHOLDER.STREET_ADDRESS")}
                    />
                  </Mui.Grid>
                  {/* <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                    <InputField
                      name="streetNumber"
                      type="text"
                      label={t("ORGANIZATION.FORM.PLACEHOLDER.STREET_NUMBER")}
                    />
                  </Mui.Grid> */}
                  <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                    <InputField
                      name="postalCode"
                      type="text"
                      label={t("ORGANIZATION.FORM.PLACEHOLDER.POSTAL_CODE")}
                    />
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                    <InputField
                      name="city"
                      type="text"
                      label={t("ORGANIZATION.FORM.PLACEHOLDER.CITY")}
                    />
                  </Mui.Grid>
                  {/* <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                    <InputField
                      name="region"
                      type="text"
                      label={t("ORGANIZATION.FORM.PLACEHOLDER.REGION")}
                    />
                  </Mui.Grid> */}
                  <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                    <AutocompleteSelectField
                      disabled={this.props.match.params.id}
                      name="countryId"
                      label={t("USER.FORM.PLACEHOLDER.COUNTRY")}
                      options={this.props.countryList}
                    />
                    <Mui.Typography
                      component="h6"
                      variant="body2"
                      className="font-weight-light text-color-danger"
                    >({t('ORGANIZATION.FORM.CANNOT_EDIT_COUNTRY')})</Mui.Typography>
                  </Mui.Grid>
                  {this.props.match.params.id &&
                    this.props.organizationData?.websiteId ? (
                    <Mui.Grid item xs={12} sm={6} md={4} className="form-group">
                      <SelectField
                        name="status"
                        label={t("ORGANIZATION.FORM.PLACEHOLDER.STATUS")}
                        options={
                          this.props.choiceList
                            ? this.props.choiceList?.filter(
                              (item) => item.config_choice !== "onboarding"
                            )
                            : []
                        }
                        optionLabel="display_name"
                      />
                    </Mui.Grid>
                  ) : null}
                </Mui.Grid>

                <Mui.Divider light={true} className="mt-3 mb-3" />

                <FieldArray
                  name="adminUser"
                  render={(arrayHelpers) => (
                    <>
                      <Mui.Box
                        width="100%"
                        display="flex"
                        flexDirection={
                          Mui.isWidthDown("xs", this.props.width)
                            ? "column"
                            : "row"
                        }
                        justifyContent="space-between"
                        alignItems={
                          Mui.isWidthDown("xs", this.props.width)
                            ? "flex-start"
                            : "center"
                        }
                        className="mt-5 mb-3"
                      >
                        <Mui.Typography
                          component="h4"
                          variant="h4"
                          className="font-weight-normal"
                        >
                          {t("ORGANIZATION.FORM.ADMIN_USER")}
                        </Mui.Typography>

                        <Mui.Button
                          // color="primary"
                          disableElevation
                          onClick={() => {
                            arrayHelpers.push({ name: "", email: "" });
                          }}
                          className={`font-weight-normal no-color-btn ${Mui.isWidthDown("xs", this.props.width)
                            ? "mt-2"
                            : ""
                            }`}
                        >
                          {t("ORGANIZATION.ADD_ADMIN_LABEL")}
                          <span className="ml-1 line-height-null">
                            <IconAdd />
                          </span>
                        </Mui.Button>
                      </Mui.Box>

                      {props.values?.adminUser &&
                        props.values.adminUser?.map((item, index) => (
                          <Mui.Grid container spacing={4} key={index}>
                            <Mui.Grid
                              item
                              xs={12}
                              sm={5}
                              md={4}
                              lg={3}
                              className="form-group"
                            >
                              <InputField
                                name={`adminUser.${index}.name`}
                                type="text"
                                label={t(
                                  "ORGANIZATION.FORM.PLACEHOLDER.ADMIN_NAME"
                                )}
                              />
                            </Mui.Grid>

                            <Mui.Grid
                              item
                              xs={12}
                              sm={5}
                              md={4}
                              lg={3}
                              className="form-group"
                            >
                              <InputField
                                name={`adminUser.${index}.email`}
                                type="text"
                                label={t(
                                  "ORGANIZATION.FORM.PLACEHOLDER.ADMIN_EMAIL"
                                )}
                              />
                            </Mui.Grid>
                            <Mui.Grid
                              item
                              xs={12}
                              sm={2}
                              md={4}
                              className="form-group"
                            >
                              {this.props.match.params.id ? (
                                <Mui.Button
                                  size="small"
                                  disableElevation
                                  onClick={() =>
                                    this.handleOpenAlert({
                                      orgId: this.props.match.params.id,
                                      name:
                                        props.values?.adminUser?.[index]
                                          ?.name || "",
                                      email:
                                        props.values?.adminUser?.[index]
                                          ?.email || "",
                                    })
                                  }
                                  className="font-weight-normal no-color-btn mr-2"
                                // color="primary"
                                >
                                  {t("COMMON.SEND_RESEND")}
                                  <span className="ml-1 line-height-null">
                                    <IconRefresh />
                                  </span>
                                </Mui.Button>
                              ) : null}

                              {index !== 0 ? (
                                <Mui.Button
                                  size="small"
                                  disableElevation
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="font-weight-normal no-color-btn"
                                >
                                  {t("COMMON.REMOVE")}
                                  <span className="ml-1 line-height-null">
                                    <IconTrash />
                                  </span>
                                </Mui.Button>
                              ) : null}
                            </Mui.Grid>
                          </Mui.Grid>
                        ))}
                    </>
                  )}
                />

                <Mui.Divider light={true} className="mt-3 mb-5" />

                <Mui.Box
                  width="100%"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection={
                    Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
                  }
                  className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"
                    }`}
                >
                  <Mui.Button

                    className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"
                      }`}
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={this.props.loading}
                    disableElevation
                  >
                    {t("COMMON.BUTTON.SAVE")}
                    {this.props.loading && (
                      <Mui.CircularProgress color="secondary" size={24} />
                    )}
                  </Mui.Button>
                  <Mui.Button
                    className={`btn-default no-color-btn ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"
                      }`}
                    color="primary"
                    type="button"
                    variant="outlined"
                    disableElevation
                    component={Link}
                    disabled={this.props.loading}
                    to={`/organization${this.props.location?.state?.locationSearch ?? ''}`}
                  >
                    {t("COMMON.BUTTON.BACK")}
                  </Mui.Button>
                  <Mui.Button
                    className="font-weight-normal no-border-btn text-transform-capitalize"
                    type="button"
                    variant="text"
                    disableElevation
                    disabled={this.props.loading}
                    onClick={props.resetForm}
                  >
                    {t("COMMON.BUTTON.RESET")}
                  </Mui.Button>
                </Mui.Box>
              </Mui.Card>
            </Form>
          )}
        </Formik>

        <Alert
          open={this.state.openAlert}
          close={this.handleCloseAlert}
          action={this.onResend}
          title={t("ORGANIZATION.RESEND_EMAIL_LABEL")}
          info={t("ORGANIZATION.RESEND_EMAIL_INFO_LABEL", {
            name: this.state.resendEmailParam?.name || "",
            organization: this.props.organizationData?.name || "",
          })}
          awaitingInfo={t("ORGANIZATION.RESEND_EMAIL_SENDING_LABEL")}
          actionBtnLabel={t("COMMON.YES")}
          loading={this.props.loading1}
          success={this.props.success}
          reset={this.props.resetOrganization}
        />
      </>
    );
  }
}
const mapStateToProps = ({ organization, shared }) => {
  const { permission, choiceList, countryList } = shared;
  const { organizationData, success, message, loading, loading1, error } =
    organization;
  return {
    countryList,
    permission,
    choiceList,
    organizationData,
    success,
    message,
    loading,
    loading1,
    error,
  };
};
export default connect(mapStateToProps, {
  getOrganization,
  addOrganization,
  editOrganization,
  getConfigChoice,
  resetOrganization,
  resendEmail,
  getCountryList
})(withTranslation()(Mui.withWidth()(EditOrganization)));
