import Req from "../interceptors/token-interceptor";

const MenuService = (function () {
  function _getAllMenu(param) {
    return Req.get(`/api/menus/list`);
  }
  function _getMenu(id, organizationId) {
    let el = "";
    if (organizationId) {
      el += `?organizationId=${organizationId}`;
    }
    return Req.get(`/api/menus/${id}${el}`);
  }
  function _editMenu(data, id) {
    return Req.put(`/api/menus/${id}`, data);
  }
  function _fetchMenu(canteenId) {
    return Req.get(`/api/menu/import?canteenId=${canteenId}`);
  }
  function _addMenuClass(id) {
    return Req.get(`/api/menu/addClass/${id}`);
  }
  return {
    getAllMenu: _getAllMenu,
    getMenu: _getMenu,
    editMenu: _editMenu,
    fetchMenu: _fetchMenu,
    addMenuClass: _addMenuClass,
  };
})();
export default MenuService;
