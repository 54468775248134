import React from "react";

const IconLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="69"
      viewBox="0 0 76 69"
      fill="none"
    >
      <path
        d="M60.6318 46.0449C60.4796 46.0449 60.3631 46.1632 60.3631 46.3181V47.0558C60.3094 49.6785 58.2138 51.7821 55.6255 51.7821C53.9507 51.7821 52.473 50.8987 51.6312 49.5509C51.5417 49.4143 51.3626 49.3779 51.2372 49.4872L47.1084 52.9021C47.001 52.9932 46.9741 53.148 47.0547 53.2665C48.9264 56.0713 52.0789 57.9108 55.6614 57.9108C61.3305 57.9108 65.9427 53.2938 66.0591 47.5474V46.3272C66.0591 46.1724 65.9427 46.0541 65.7903 46.0541H60.6318V46.0449Z"
        fill="#3A57E8"
      />
      <path
        d="M62.9158 22.751C62.7634 22.751 62.647 22.8693 62.647 23.0241V31.2564C62.5754 34.7898 59.7452 37.622 56.2615 37.622C52.7777 37.622 49.9386 34.7808 49.876 31.2564V23.0241C49.876 22.8693 49.7595 22.751 49.6073 22.751H44.4308C44.2786 22.751 44.1621 22.8693 44.1621 23.0241V32.1035H44.171C44.2964 38.7694 49.6521 44.1333 56.2345 44.1333C62.8171 44.1333 68.1728 38.7694 68.2982 32.1035V23.0241C68.2982 22.8693 68.1817 22.751 68.0296 22.751H62.9158Z"
        fill="#3A57E8"
      />
      <path
        d="M9.58313 43.9053H15.0104C15.1626 43.9053 15.2791 43.787 15.2791 43.6322V23.0331C15.2791 22.8782 15.1626 22.7598 15.0104 22.7598H9.58313C9.43087 22.7598 9.31445 22.8782 9.31445 23.0331V43.6322C9.31445 43.7779 9.43087 43.9053 9.58313 43.9053Z"
        fill="#3A57E8"
      />
      <path
        d="M12.3149 10.2104C14.9121 10.174 17.0615 12.4142 17.0078 14.9732C16.954 17.4683 14.8404 19.6722 12.3775 19.654C9.61914 19.6266 7.73841 17.5139 7.70259 14.9003C7.66677 12.2503 9.81617 10.174 12.3149 10.2104Z"
        fill="#3A57E8"
      />
      <path
        d="M19.4342 43.5772V43.6318C19.4342 43.7867 19.5507 43.905 19.703 43.905H41.027C41.1791 43.905 41.2956 43.7867 41.2956 43.6318V38.8325C41.2956 38.6779 41.1791 38.5594 41.027 38.5594H31.5247C36.1729 35.8456 39.9253 32.0572 40.812 28.0503C40.8299 27.9867 40.8388 27.9229 40.8477 27.8591C40.9731 27.1579 41.0537 26.1926 41.0537 25.1364C41.0537 24.3531 41.009 23.6154 40.9374 22.9962C40.9194 22.8687 40.8029 22.7686 40.6686 22.7686H39.7731H20.0612C19.9089 22.7686 19.7926 22.887 19.7926 23.0417V28.2052C19.7926 28.36 19.9089 28.4783 20.0612 28.4783H35.0981C34.4444 30.9463 28.1483 35.7455 19.6135 38.5139C19.5059 38.5503 19.4253 38.6596 19.4253 38.7689V43.5772H19.4342Z"
        fill="#3A57E8"
      />
    </svg>
  );
};

export default IconLogo;
