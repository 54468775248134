import Req from "../interceptors/token-interceptor";

const BuildingService = (function () {
  function _getAllBuilding() {
    return Req.get(`/api/buildings/list`);
  }
  function _getBuilding(id, organizationId) {
    let el = "";
    if (organizationId) {
      el += `?organizationId=${organizationId}`;
    }
    return Req.get(`/api/buildings/${id}${el}`);
  }
  function _addBuilding(data) {
    return Req.post("/api/buildings", data);
  }
  function _editBuilding(data, id) {
    return Req.put(`/api/buildings/${id}`, data);
  }
  function _deleteBuilding(id) {
    return Req.delete(`/api/buildings/${id}`);
  }
  function _deleteMultipleBuilding(ids) {
    return Req.post("/api/buildings/delete", { ids: ids });
  }
  return {
    getAllBuilding: _getAllBuilding,
    getBuilding: _getBuilding,
    addBuilding: _addBuilding,
    editBuilding: _editBuilding,
    deleteBuilding: _deleteBuilding,
    deleteMultipleBuilding: _deleteMultipleBuilding,
  };
})();
export default BuildingService;
