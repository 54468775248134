import * as Mui from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as Yup from "yup";
import AuthGraphicOne from "../../../assets/images/auth-graphics.png";
import { InputHiddenField, InputPasswordField } from "../../../components/form";
import { IconLogo } from "../../../components/svg";
import LanguageDropdownAlt from "../../../layouts/topnav/language-dropdown-alt";
import { initialChangePassword, resetAuth } from "../../../reduxs/actions";
class InitialChangePassword extends Component {
  constructor(props) {
    super(props);
    this.validationSchema = Yup.object().shape({
      old_password: Yup.string().required(
        this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")
      ),
      password: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          this.props.t("AUTH.VALIDATION.PASSWORD_CRITERIA")
        ),
      password_confirmation: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .oneOf(
          [Yup.ref("password"), null],
          this.props.t("AUTH.VALIDATION.NOT_MATCH")
        ),
      tc_accepted: Yup.boolean()
        .oneOf([true], this.props.t("AUTH.VALIDATION.ACCEPT_TERMS"))
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
    });
    this.props.resetAuth();
  }

  onSubmit = (values) => {
    if (!this.props.loading) {
      this.props.initialChangePassword(values, this.props.history);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Mui.Grid container justifyContent="space-between" className="auth-wrap">
        <Mui.Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={6}
          className="auth-message-box p-5"
        >
          <Mui.Box>
            <Mui.Typography
              component="h2"
              variant="h2"
              className="title mb-3 font-weight-medium"
            >
              {t("AUTH.INITIAL_CHANGE_PASSWORD.SETUP")}
            </Mui.Typography>

            <Mui.Typography
              component="h3"
              variant="h3"
              className="font-weight-normal"
            >
              {t("AUTH.INITIAL_CHANGE_PASSWORD.INFO")}
            </Mui.Typography>
          </Mui.Box>
          <Mui.Box className="auth-graphic-box">
            <img style={{ width: "100%" }} src={AuthGraphicOne} alt="" />
          </Mui.Box>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={5} className="auth-form-box">
          <Mui.Card className="card-box">
            <Mui.Box className="logo-icon">
              <LanguageDropdownAlt />
              <IconLogo />
            </Mui.Box>
            <Mui.Typography
              component="span"
              variant="h4"
              className="mb-3 font-weight-normal"
            >
              {t("AUTH.INITIAL_CHANGE_PASSWORD.TITLE")}
            </Mui.Typography>

            {this.props.error ? (
              <Mui.Typography
                className="d-block mt-2 text-center text-color-danger"
                variant="body1"
                component="span"
              >
                {this.props.error}
              </Mui.Typography>
            ) : null}

            <Formik
              initialValues={{
                userId: this.props.location?.state?.userId || "",
                email: this.props.location?.state?.email || "",
                old_password: "",
                password: "",
                password_confirmation: "",
                tc_accepted: false
              }}
              validationSchema={this.validationSchema}
              onSubmit={this.onSubmit}
            >
              {(props) => (
                <Form className="default-form center">
                  <InputHiddenField name="userId" />
                  <Mui.Grid container spacing={3} className="mb-2">
                    <Mui.Grid className="form-group" item xs={12}>
                      <InputPasswordField
                        name="old_password"
                        label={t("AUTH.INPUT.OLD_PASSWORD")}
                        showStartAdornment={true}
                      />
                    </Mui.Grid>

                    <Mui.Grid className="form-group" item xs={12}>
                      <InputPasswordField
                        name="password"
                        label={t("AUTH.INPUT.NEW_PASSWORD")}
                        showStartAdornment={true}
                      />
                    </Mui.Grid>

                    <Mui.Grid className="form-group" item xs={12}>
                      <InputPasswordField
                        name="password_confirmation"
                        label={t("AUTH.INPUT.CONFIRM_PASSWORD")}
                        showStartAdornment={true}
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Box onClick={() => { props.setFieldValue("tc_accepted", !props.values.tc_accepted) }} className="pointer" display={'flex'} alignItems={'center'} gridGap={5}>
                        <Mui.Checkbox
                          className="app-checkbox-style-classic"
                          color="primary"
                          checked={props.values.tc_accepted}
                        />
                        <Mui.Typography className="app-container-paragraph-1" style={{ userSelect: "none" }}>
                          <Mui.Typography component={'span'} variant={'body1'}>
                            {t("AUTH.INPUT.I_ACCEPT")}
                            <Mui.Typography
                              style={{ color: '#007bff' }}
                              className="pointer"
                              //use react router to navigate to terms and conditions page and open in new tab
                              onClick={() => { window.open("/terms-and-conditions", "_blank") }}
                              component={'span'}
                              variant={'body1'}>{t("AUTH.INPUT.TERMS_AND_CONDITIONS")}
                            </Mui.Typography>
                          </Mui.Typography>
                        </Mui.Typography>
                      </Mui.Box>
                      {/* Make style for error message */}
                      <Mui.Typography style={{ color: "#a05656", fontSize: "1.2rem" }}>
                        {props.errors.tc_accepted}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>

                  <Mui.Button
                    className="btn-default "
                    type="submit"
                    width="100%"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={this.props.loading}
                  >
                    {t("AUTH.GENERAL.SUBMIT_BUTTON")}
                    {this.props.loading && (
                      <Mui.CircularProgress color="secondary" size={24} />
                    )}
                  </Mui.Button>
                </Form>
              )}
            </Formik>
            <Mui.Box className="form-graphic-box">
              <img style={{ width: "100%" }} src={AuthGraphicOne} alt="" />
            </Mui.Box>
          </Mui.Card>
        </Mui.Grid>
      </Mui.Grid>

      //   <Mui.Container className="h-100 d-flex" fixed>
      //     <Mui.Grid container spacing={4} justifyContent="space-between">
      //       <Mui.Grid item xs={12} md={5}>
      //         <Mui.Box className="auth-message-box">
      //           <img src={Logo} alt="" />
      //           <Mui.Hidden xsDown>
      //             <Mui.Typography
      //               component="h2"
      //               variant="h2"
      //               className="title mb-3 font-weight-medium"
      //             >
      //               {t("AUTH.INITIAL_CHANGE_PASSWORD.SETUP")}
      //             </Mui.Typography>

      //             <Mui.Typography
      //               component="h3"
      //               variant="h3"
      //               className="font-weight-normal"
      //             >
      //               {t("AUTH.INITIAL_CHANGE_PASSWORD.INFO")}
      //             </Mui.Typography>
      //           </Mui.Hidden>
      //         </Mui.Box>
      //       </Mui.Grid>

      //     </Mui.Grid>
      //   </Mui.Container>
      // </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { success, message, loading, error, user } = auth;
  return { success, message, loading, error, user };
};
export default connect(mapStateToProps, {
  initialChangePassword,
  resetAuth,
})(withTranslation()(InitialChangePassword));
