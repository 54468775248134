export const GET_OVERVIEW = "GET_OVERVIEW";
export const GET_OVERVIEW_SUCCESS = "GET_OVERVIEW_SUCCESS";
export const GET_OVERVIEW_ERROR = "GET_OVERVIEW_ERROR";
export const GET_SALE = "GET_SALE";
export const GET_SALE_SUCCESS = "GET_SALE_SUCCESS";
export const GET_SALE_ERROR = "GET_SALE_ERROR";
export const GET_BEST_PRODUCT = "GET_BEST_PRODUCT";
export const GET_BEST_PRODUCT_SUCCESS = "GET_BEST_PRODUCT_SUCCESS";
export const GET_BEST_PRODUCT_ERROR = "GET_BEST_PRODUCT_ERROR";
export const GET_PURCHASE_TYPE_SALE = "GET_PURCHASE_TYPE_SALE";
export const GET_PURCHASE_TYPE_SALE_SUCCESS = "GET_PURCHASE_TYPE_SALE_SUCCESS";
export const GET_PURCHASE_TYPE_SALE_ERROR = "GET_PURCHASE_TYPE_SALE_ERROR";
export const GET_RECENT_PRODUCT = "GET_RECENT_PRODUCT";
export const GET_RECENT_PRODUCT_SUCCESS = "GET_RECENT_PRODUCT_SUCCESS";
export const GET_RECENT_PRODUCT_ERROR = "GET_RECENT_PRODUCT_ERROR";
export const GET_CUSTOMER_REPEAT_RATE = "GET_CUSTOMER_REPEAT_RATE";
export const GET_CUSTOMER_REPEAT_RATE_SUCCESS =
  "GET_CUSTOMER_REPEAT_RATE_SUCCESS";
export const GET_CUSTOMER_REPEAT_RATE_ERROR = "GET_CUSTOMER_REPEAT_RATE_ERROR";
export const GET_TOP_TENANT = "GET_TOP_TENANT";
export const GET_TOP_TENANT_SUCCESS = "GET_TOP_TENANT_SUCCESS";
export const GET_TOP_TENANT_ERROR = "GET_TOP_TENANT_ERROR";

export const getOverview = (param) => {
  return {
    type: GET_OVERVIEW,
    payload: { param },
  };
};

export const getOverviewSuccess = (overviewData) => {
  return {
    type: GET_OVERVIEW_SUCCESS,
    payload: overviewData,
  };
};

export const getOverviewError = (error) => {
  return {
    type: GET_OVERVIEW_ERROR,
    payload: error,
  };
};

export const getSale = (param) => ({
  type: GET_SALE,
  payload: { param },
});

export const getSaleSuccess = (saleData) => ({
  type: GET_SALE_SUCCESS,
  payload: saleData,
});

export const getSaleError = (error) => ({
  type: GET_SALE_ERROR,
  payload: error,
});

export const getBestProduct = (param) => ({
  type: GET_BEST_PRODUCT,
  payload: { param },
});

export const getBestProductSuccess = (bestProductData) => ({
  type: GET_BEST_PRODUCT_SUCCESS,
  payload: bestProductData,
});

export const getBestProductError = (error) => ({
  type: GET_BEST_PRODUCT_ERROR,
  payload: error,
});

export const getPurchaseTypeSale = (param) => ({
  type: GET_PURCHASE_TYPE_SALE,
  payload: { param },
});

export const getPurchaseTypeSaleSuccess = (purchaseTypeSaleData) => ({
  type: GET_PURCHASE_TYPE_SALE_SUCCESS,
  payload: purchaseTypeSaleData,
});

export const getPurchaseTypeSaleError = (error) => ({
  type: GET_PURCHASE_TYPE_SALE_ERROR,
  payload: error,
});

export const getRecentProduct = (param) => ({
  type: GET_RECENT_PRODUCT,
  payload: { param },
});

export const getRecentProductSuccess = (recentProductData) => ({
  type: GET_RECENT_PRODUCT_SUCCESS,
  payload: recentProductData,
});

export const getRecentProductError = (error) => ({
  type: GET_RECENT_PRODUCT_ERROR,
  payload: error,
});

export const getCustomerRepeatRate = (param) => ({
  type: GET_CUSTOMER_REPEAT_RATE,
  payload: { param },
});

export const getCustomerRepeatRateSuccess = (customerRepeatRateData) => ({
  type: GET_CUSTOMER_REPEAT_RATE_SUCCESS,
  payload: customerRepeatRateData,
});

export const getCustomerRepeatRateError = (error) => ({
  type: GET_CUSTOMER_REPEAT_RATE_ERROR,
  payload: error,
});

export const getTopTenant = (param) => ({
  type: GET_TOP_TENANT,
  payload: { param },
});

export const getTopTenantSuccess = (topTenantData) => ({
  type: GET_TOP_TENANT_SUCCESS,
  payload: topTenantData,
});

export const getTopTenantError = (error) => ({
  type: GET_TOP_TENANT_ERROR,
  payload: error,
});
