import React from "react";
import * as Mui from "@material-ui/core";
import { IconArrow } from "../../components/svg";

const SideNavTriggerCollapse = (props) => {
  return (
    <div className="btn-menu-collapse-wrapper">
      {/* Show Sidenav Open Collapse action in Large devices */}
      {Mui.isWidthUp("md", props.width) ? (
        <Mui.IconButton
          onClick={() => props.toggleSideNav()}
          size="small"
          className={`mr-3 svg-color-toolbar-icon btn-menu-collapse  ${
            props.drawerCollapse ? "open" : ""
          }`}
        >
          <IconArrow />
        </Mui.IconButton>
      ) : null}
    </div>
  );
};

export default Mui.withWidth()(SideNavTriggerCollapse);
