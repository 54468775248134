import {
  GET_ALL_ORDER,
  GET_ALL_ORDER_SUCCESS,
  GET_ALL_ORDER_ERROR,
  GET_ORDER_LIST,
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_ERROR,
  ADD_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_ERROR,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  EDIT_ORDER,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_ERROR,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_ERROR,
  DELETE_MULTIPLE_ORDER,
  DELETE_MULTIPLE_ORDER_SUCCESS,
  DELETE_MULTIPLE_ORDER_ERROR,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_ERROR,
  RESET_ORDER,
  EXPORT_EXCEL_REPORT,
  EXPORT_EXCEL_REPORT_SUCCESS,
  EXPORT_EXCEL_REPORT_ERROR,
  DOWNLOAD_REFUND_INVOICE,
  DOWNLOAD_REFUND_INVOICE_SUCCESS,
  DOWNLOAD_REFUND_INVOICE_ERROR,
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  orders: null,
  orderList: null,
  orderData: null,
  orderId: null,
  orderIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const orderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ORDER:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_ORDER_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        error: null,
      };
    case GET_ALL_ORDER_ERROR:
      return {
        ...state,
        orders: null,
        error: action.payload,
      };
    case GET_ORDER_LIST:
      return {
        ...state,
        loading: true,
        orderId: null,
        orderData: null,
        orderIds: null,
        error: null,
      };
    case GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderList: action.payload,
        error: null,
      };
    case GET_ORDER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        orderList: null,
        error: action.payload,
      };
    case ADD_ORDER:
      return { ...state, loading: true, error: null };
    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_ORDER:
      return { ...state, error: null };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        orderData: action.payload,
        error: null,
      };
    case GET_ORDER_ERROR:
      return {
        ...state,
        orderData: null,
        error: action.payload,
      };
    case EDIT_ORDER:
      return { ...state, loading: true, error: null };
    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_ORDER:
      return { ...state, loading1: true, error: null };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_ORDER_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_ORDER:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_ORDER_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_ORDER_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DOWNLOAD_INVOICE:
      return { ...state, loading1: true, error: null };
    case DOWNLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_INVOICE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DOWNLOAD_REFUND_INVOICE:
      return { ...state, loading1: true, error: null };
    case DOWNLOAD_REFUND_INVOICE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_REFUND_INVOICE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case EXPORT_EXCEL_REPORT:
      return { ...state, loading1: true, error: null };
    case EXPORT_EXCEL_REPORT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_EXCEL_REPORT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_ORDER:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default orderReducer;
