import {
  ADD_TENANT_EMPLOYEES,
  ADD_TENANT_EMPLOYEES_ERROR,
  ADD_TENANT_EMPLOYEES_SUCCESS,
  CLEAR_EMPLOYEES_ERROR,
  DELETE_TENANT_EMPLOYEES,
  DELETE_TENANT_EMPLOYEES_ERROR,
  DELETE_TENANT_EMPLOYEES_SUCCESS,
  DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE,
  DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_ERROR,
  DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_SUCCESS,
  DOWNLOAD_QR,
  DOWNLOAD_QR_ERROR,
  DOWNLOAD_QR_SUCCESS,
  EDIT_TENANT_EMPLOYEES,
  EDIT_TENANT_EMPLOYEES_ERROR,
  EDIT_TENANT_EMPLOYEES_SUCCESS,
  GET_ALL_TENANT_EMPLOYEES,
  GET_ALL_TENANT_EMPLOYEES_ERROR,
  GET_ALL_TENANT_EMPLOYEES_SUCCESS,
  GET_TENANT_EMPLOYEES,
  GET_TENANT_EMPLOYEES_ERROR,
  GET_TENANT_EMPLOYEES_LIST,
  GET_TENANT_EMPLOYEES_LIST_ERROR,
  GET_TENANT_EMPLOYEES_LIST_SUCCESS,
  GET_TENANT_EMPLOYEES_SUCCESS,
  IMPORT_TENANT_EMPLOYEES,
  IMPORT_TENANT_EMPLOYEES_ERROR,
  IMPORT_TENANT_EMPLOYEES_SUCCESS,
  RESET_TENANT_EMPLOYEES,
  SEND_QR,
  SEND_QR_ERROR,
  SEND_QR_SUCCESS
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  tenantEmployees: null,
  tenantEmployeesList: null,
  tenantEmployeesData: null,
  tenantEmployeeId: null,
  organizationId: null,
  tenantEmployeesIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  error: null,
};


const tenantEmployeesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TENANT_EMPLOYEES:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_TENANT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        tenantEmployees: action.payload,
        error: null,
      };
    case GET_ALL_TENANT_EMPLOYEES_ERROR:
      return {
        ...state,
        tenantEmployees: null,
        error: action.payload,
      };
    case GET_TENANT_EMPLOYEES_LIST:
      return {
        ...state,
        loading: true,
        tenantEmployeesData: null,
        tenantEmployeeId: null,
        tenantEmployeesIds: null,
        error: null,
      };
    case GET_TENANT_EMPLOYEES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantEmployeesList: action.payload,
        error: null,
      };
    case GET_TENANT_EMPLOYEES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        tenantEmployeesList: null,
        error: action.payload,
      };
    case ADD_TENANT_EMPLOYEES:
      return { ...state, loading: true, error: null };
    case ADD_TENANT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_TENANT_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case GET_TENANT_EMPLOYEES:
      return { ...state, error: null };
    case GET_TENANT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        tenantEmployeesData: action.payload,
        error: null,
      };
    case GET_TENANT_EMPLOYEES_ERROR:
      return {
        ...state,
        tenantEmployeesData: null,
        error: action.payload,
      };
    case EDIT_TENANT_EMPLOYEES:
      return { ...state, loading: true, error: null };
    case EDIT_TENANT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_TENANT_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_TENANT_EMPLOYEES:
      return { ...state, loading1: true, error: null };
    case DELETE_TENANT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_TENANT_EMPLOYEES_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_TENANT_EMPLOYEES:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    case IMPORT_TENANT_EMPLOYEES:
      return { ...state, loading1: true, error: null };
    case IMPORT_TENANT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case IMPORT_TENANT_EMPLOYEES_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DOWNLOAD_QR:
      return { ...state, loading1: true, error: null };
    case DOWNLOAD_QR_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_QR_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE:
      return { ...state, loading2: true, error: null };
    case DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading2: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE_ERROR:
      return {
        ...state,
        loading2: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case SEND_QR:
      return { ...state, loading1: true, error: null };
    case SEND_QR_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case SEND_QR_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case CLEAR_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default tenantEmployeesReducer;
