import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import SettingService from "../../services/setting-service";
import { GET_SETTING, EDIT_SETTING } from "../actions";
import {
  getSetting,
  getSettingSuccess,
  getSettingError,
  editSettingSuccess,
  editSettingError,
  getBasicSettingSuccess,
  getBasicSettingError,
  GET_BASIC_SETTING,
  getPOSTerminalSuccess,
  getPOSTerminalError,
  GET_POS_TERMINAL,
} from "./action";
import { toast } from "react-toastify";
import { parseMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";


export function* watchGetBasicSetting() {
  yield takeEvery(GET_BASIC_SETTING, getBasicSettingAc);
}

const getBasicSettingAsync = async (fields) => {
  return SettingService.getBasicSetting(fields);
};

function* getBasicSettingAc({ payload }) {
  try {
    const response = yield call(getBasicSettingAsync, payload.fields);
    if (response.data.success) {
      yield put(getBasicSettingSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getBasicSettingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getBasicSettingError(error.response.data.message));
  }
}

export function* watchGetSetting() {
  yield takeEvery(GET_SETTING, getSettingAc);
}

const getSettingAsync = async (fields) => {
  return SettingService.getSetting(fields);
};

function* getSettingAc({ payload }) {
  try {
    const response = yield call(getSettingAsync, payload.fields);
    if (response.data.success) {
      yield put(getSettingSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getSettingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getSettingError(error.response.data.message));
  }
}

export function* watchEditSetting() {
  yield takeEvery(EDIT_SETTING, editSetting);
}

const editSettingAsync = async (data) => {
  return SettingService.editSetting(data);
};

function* editSetting({ payload }) {
  try {
    const response = yield call(editSettingAsync, payload.settingData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        editSettingSuccess(response.data.success, response.data.message)
      );
      // Fetch updated setting
      yield put(getSetting());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editSettingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(editSettingError(error.response.data.message));
  }
}


export function* watchGetPOSTerminal() {
  yield takeEvery(GET_POS_TERMINAL, getPOSTerminal);
}

const getPOSTerminalAsync = async (fields) => {
  return SettingService.getPOSTerminal(fields);
};

function* getPOSTerminal() {
  try {
    const response = yield call(getPOSTerminalAsync);
    if (response.data.success) {
      yield put(getPOSTerminalSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getPOSTerminalError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getPOSTerminalError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSetting),
    fork(watchEditSetting),
    fork(watchGetBasicSetting),
    fork(watchGetPOSTerminal),
  ]);
}
