import React from "react";
import theme from "../../configs/theme";

const IconColorOrganization = (props) => {
  const color = props.color ? props.color : theme.palette.primary.main;
  return (
    <svg version="1.1" width="22" height="22" viewBox="0 0 50.8 38.1">
      <g id="Group_1978" transform="translate(6.292 7.831)">
        <g id="Group_1977" transform="translate(0 7)">
          <g id="Group_1965" transform="translate(0 10.954)">
            <path
              id="Path_9354"
              fill="#FCFCFC"
              d="M2.2,12.2h-8.5V-8l8.5-4.2V12.2z"
            />
            <g id="Group_1964" transform="translate(0.77 1.668)">
              <path
                id="Path_9355"
                fill="#D7DDE9"
                d="M0.4,10.4h-6.5v-19l6.5-3.1V10.4z"
              />
              <path
                id="Path_9356"
                d="M0.4,10.4h-6.5V-7.7l6.5-2.9V10.4z"
                opacity="0.3"
                fill="#B5B5B5"
                enableBackground="new"
              />
              <g id="Group_1963" transform="translate(0)">
                <path
                  id="Path_9357"
                  fill="#D7DDE9"
                  d="M0.4-11.7v0.7l-5.7,2.7v18.7h-0.7v-19L0.4-11.7z"
                />
                <path
                  id="Path_9358"
                  d="M0.4-11.7v0.7l-5.7,2.7v18.7h-0.7v-19L0.4-11.7z"
                  opacity="0.2"
                  fill="#FFF0D0"
                  enableBackground="new"
                />
              </g>
            </g>
          </g>
          <g id="Group_1967" transform="translate(6.838 10.954)">
            <g id="Group_1966">
              <rect
                id="Rectangle_1870"
                x="-4.6"
                y="-12.1"
                fill="#DBDBDB"
                width="7.5"
                height="24.2"
              />
              <rect
                id="Rectangle_1871"
                x="-4.6"
                y="-12.1"
                width="7.5"
                height="24.2"
                opacity="0.2"
                fill="#DBDBDB"
                enableBackground="new"
              />
            </g>
          </g>
          <rect
            id="Rectangle_1872"
            x="3.6"
            y="0.7"
            fill={color}
            width="4.9"
            height="1.5"
          />
          <rect
            id="Rectangle_1873"
            x="3.6"
            y="3.6"
            fill={color}
            width="4.9"
            height="1.5"
          />
          <rect
            id="Rectangle_1874"
            x="3.6"
            y="6.6"
            fill={color}
            width="4.9"
            height="1.5"
          />
          <rect
            id="Rectangle_1875"
            x="3.6"
            y="9.5"
            fill={color}
            width="4.9"
            height="1.5"
          />
          <rect
            id="Rectangle_1876"
            x="3.6"
            y="12.4"
            fill={color}
            width="4.9"
            height="1.5"
          />
          <rect
            id="Rectangle_1877"
            x="3.6"
            y="15.4"
            fill={color}
            width="4.9"
            height="1.5"
          />
          <rect
            id="Rectangle_1878"
            x="3.6"
            y="18.3"
            fill={color}
            width="4.9"
            height="1.5"
          />
          <rect
            id="Rectangle_1879"
            x="3.6"
            y="21.3"
            fill={color}
            width="4.9"
            height="1.5"
          />
          <path
            id="Path_9359"
            fill={color}
            d="M0.1,4.3l-3.8,2.5V6l3.8-2.5V4.3z"
          />
          <path
            id="Path_9360"
            fill={color}
            d="M0.1,6l-3.8,2.5V7.7l3.8-2.5V6z"
          />
          <path
            id="Path_9361"
            fill={color}
            d="M0.1,8.1l-3.8,2.5V9.8l3.8-2.5V8.1z"
          />
          <path
            id="Path_9362"
            fill={color}
            d="M0.1,10.2l-3.8,2.5v-0.7l3.8-2.5V10.2z"
          />
          <path
            id="Path_9363"
            fill={color}
            d="M0.1,12.3l-3.8,2.5V14l3.8-2.5V12.3z"
          />
          <path
            id="Path_9364"
            fill={color}
            d="M0.1,14.3l-3.8,2.5v-0.7l3.8-2.5V14.3z"
          />
          <path
            id="Path_9365"
            fill={color}
            d="M0.1,16.4l-3.8,2.5v-0.7l3.8-2.5V16.4z"
          />
          <path
            id="Path_9366"
            fill={color}
            d="M0.1,18.5L-3.8,21v-0.7l3.8-2.5V18.5z"
          />
          <path
            id="Path_9367"
            fill={color}
            d="M0.1,20.6l-3.8,2.5v-0.7l3.8-2.5V20.6z"
          />
          <path
            id="Path_9368"
            fill={color}
            d="M0.5,21.7L-1.4,23v-0.4l1.9-1.3V21.7z"
          />
          <g id="Group_1971" transform="translate(26.535 14.485)">
            <rect
              id="Rectangle_1882"
              x="0.3"
              y="-11.2"
              fill="#FFFFFF"
              width="9.3"
              height="19.8"
            />
            <g id="Group_1968" transform="translate(7.388)">
              <rect
                id="Rectangle_1883"
                x="2.1"
                y="-11.2"
                fill="#DBDBDB"
                width="4.7"
                height="19.8"
              />
            </g>
            <g id="Group_1969" transform="translate(1.518 1.568)">
              <rect
                id="Rectangle_1884"
                x="0.7"
                y="-10.8"
                fill={color}
                width="1.7"
                height="2.6"
              />
              <rect
                id="Rectangle_1885"
                x="4.1"
                y="-10.8"
                fill={color}
                width="1.7"
                height="2.6"
              />
              <rect
                id="Rectangle_1886"
                x="0.7"
                y="-6.7"
                fill={color}
                width="1.7"
                height="2.6"
              />
              <rect
                id="Rectangle_1887"
                x="4.1"
                y="-6.7"
                fill={color}
                width="1.7"
                height="2.6"
              />
              <rect
                id="Rectangle_1888"
                x="0.7"
                y="-2.6"
                fill={color}
                width="1.7"
                height="2.6"
              />
              <rect
                id="Rectangle_1889"
                x="4.1"
                y="-2.6"
                fill={color}
                width="1.7"
                height="2.6"
              />
              <rect
                id="Rectangle_1890"
                x="0.7"
                y="1.5"
                fill={color}
                width="1.7"
                height="2.6"
              />
              <rect
                id="Rectangle_1891"
                x="4.1"
                y="1.5"
                fill={color}
                width="1.7"
                height="2.6"
              />
              <rect
                id="Rectangle_1892"
                x="0.7"
                y="5.6"
                fill={color}
                width="1.7"
                height="1.3"
              />
              <rect
                id="Rectangle_1893"
                x="4.1"
                y="5.6"
                fill={color}
                width="1.7"
                height="1.3"
              />
            </g>
            <g id="Group_1970" transform="translate(8.081 1.568)">
              <rect
                id="Rectangle_1896"
                x="2.3"
                y="-10.8"
                fill={color}
                width="1"
                height="2.6"
              />
              <rect
                id="Rectangle_1897"
                x="4.3"
                y="-10.8"
                fill={color}
                width="1"
                height="2.6"
              />
              <rect
                id="Rectangle_1898"
                x="2.3"
                y="-6.7"
                fill={color}
                width="1"
                height="2.6"
              />
              <rect
                id="Rectangle_1899"
                x="4.3"
                y="-6.7"
                fill={color}
                width="1"
                height="2.6"
              />
              <rect
                id="Rectangle_1900"
                x="2.3"
                y="-2.6"
                fill={color}
                width="1"
                height="2.6"
              />
              <rect
                id="Rectangle_1901"
                x="4.3"
                y="-2.6"
                fill={color}
                width="1"
                height="2.6"
              />
              <rect
                id="Rectangle_1902"
                x="2.3"
                y="1.5"
                fill={color}
                width="1"
                height="2.6"
              />
              <rect
                id="Rectangle_1903"
                x="4.3"
                y="1.5"
                fill={color}
                width="1"
                height="2.6"
              />
              <rect
                id="Rectangle_1904"
                x="2.3"
                y="5.6"
                fill={color}
                width="1"
                height="1.3"
              />
              <rect
                id="Rectangle_1905"
                x="4.3"
                y="5.6"
                fill={color}
                width="1"
                height="1.3"
              />
            </g>
          </g>
          <g id="Group_1976" transform="translate(12.863)">
            <g id="Group_1973" transform="translate(8.626)">
              <rect
                id="Rectangle_1908"
                x="-1"
                y="-14.8"
                fill="#DBDBDB"
                width="6.3"
                height="37.8"
              />
              <path
                id="Path_9371"
                fill="#B7B2B2"
                d="M-1-12.2h6.3v0.5H-1V-12.2z"
              />
              <g id="Group_1972" transform="translate(0.52 3.62)">
                <rect
                  id="Rectangle_1909"
                  x="-0.8"
                  y="-13.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1910"
                  x="-0.8"
                  y="-10.5"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1911"
                  x="-0.8"
                  y="-7.1"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1912"
                  x="-0.8"
                  y="-3.7"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1913"
                  x="-0.8"
                  y="-0.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1914"
                  x="-0.8"
                  y="3.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1915"
                  x="-0.8"
                  y="6.6"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1916"
                  x="-0.8"
                  y="10"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1917"
                  x="-0.8"
                  y="13.4"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1918"
                  x="-0.8"
                  y="16.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1921"
                  x="-0.8"
                  y="-13.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1922"
                  x="-0.8"
                  y="-10.5"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1923"
                  x="-0.8"
                  y="-7.1"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1924"
                  x="-0.8"
                  y="-3.7"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1925"
                  x="-0.8"
                  y="-0.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1926"
                  x="-0.8"
                  y="3.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1927"
                  x="-0.8"
                  y="6.6"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1928"
                  x="-0.8"
                  y="10"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1929"
                  x="-0.8"
                  y="13.4"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1930"
                  x="-0.8"
                  y="16.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1933"
                  x="1.1"
                  y="-13.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1934"
                  x="1.1"
                  y="-10.5"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1935"
                  x="1.1"
                  y="-7.1"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1936"
                  x="1.1"
                  y="-3.7"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1937"
                  x="1.1"
                  y="-0.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1938"
                  x="1.1"
                  y="3.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1939"
                  x="1.1"
                  y="6.6"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1940"
                  x="1.1"
                  y="10"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1941"
                  x="1.1"
                  y="13.4"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1942"
                  x="1.1"
                  y="16.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1945"
                  x="1.1"
                  y="-13.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1946"
                  x="1.1"
                  y="-10.5"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1947"
                  x="1.1"
                  y="-7.1"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1948"
                  x="1.1"
                  y="-3.7"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1949"
                  x="1.1"
                  y="-0.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1950"
                  x="1.1"
                  y="3.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1951"
                  x="1.1"
                  y="6.6"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1952"
                  x="1.1"
                  y="10"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1953"
                  x="1.1"
                  y="13.4"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1954"
                  x="1.1"
                  y="16.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1957"
                  x="3"
                  y="-13.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1958"
                  x="3"
                  y="-10.5"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1959"
                  x="3"
                  y="-7.1"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1960"
                  x="3"
                  y="-3.7"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1961"
                  x="3"
                  y="-0.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1962"
                  x="3"
                  y="3.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1963"
                  x="3"
                  y="6.6"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1964"
                  x="3"
                  y="10"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1965"
                  x="3"
                  y="13.4"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1966"
                  x="3"
                  y="16.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1969"
                  x="3"
                  y="-13.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1970"
                  x="3"
                  y="-10.5"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1971"
                  x="3"
                  y="-7.1"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1972"
                  x="3"
                  y="-3.7"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1973"
                  x="3"
                  y="-0.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1974"
                  x="3"
                  y="3.2"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1975"
                  x="3"
                  y="6.6"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1976"
                  x="3"
                  y="10"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1977"
                  x="3"
                  y="13.4"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1978"
                  x="3"
                  y="16.9"
                  fill={color}
                  width="1.2"
                  height="2.7"
                />
              </g>
            </g>
            <g id="Group_1975">
              <rect
                id="Rectangle_1981"
                x="-3.1"
                y="-14.8"
                fill="#FFFFFF"
                width="10.8"
                height="37.8"
              />
              <rect
                id="Rectangle_1982"
                x="-3.1"
                y="-12.2"
                fill="#DBDBDB"
                width="10.8"
                height="0.5"
              />
              <g id="Group_1974" transform="translate(0.924 3.62)">
                <rect
                  id="Rectangle_1983"
                  x="-2.9"
                  y="-13.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1984"
                  x="-2.9"
                  y="-10.5"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1985"
                  x="-2.9"
                  y="-7.1"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1986"
                  x="-2.9"
                  y="-3.7"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1987"
                  x="-2.9"
                  y="-0.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1988"
                  x="-2.9"
                  y="3.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1989"
                  x="-2.9"
                  y="6.6"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1990"
                  x="-2.9"
                  y="10"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1991"
                  x="-2.9"
                  y="13.4"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1992"
                  x="-2.9"
                  y="16.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1995"
                  x="-2.9"
                  y="-13.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1996"
                  x="-2.9"
                  y="-10.5"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1997"
                  x="-2.9"
                  y="-7.1"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1998"
                  x="-2.9"
                  y="-3.7"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_1999"
                  x="-2.9"
                  y="-0.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2000"
                  x="-2.9"
                  y="3.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2001"
                  x="-2.9"
                  y="6.6"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2002"
                  x="-2.9"
                  y="10"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2003"
                  x="-2.9"
                  y="13.4"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2004"
                  x="-2.9"
                  y="16.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2007"
                  x="0.2"
                  y="-13.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2008"
                  x="0.2"
                  y="-10.5"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2009"
                  x="0.2"
                  y="-7.1"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2010"
                  x="0.2"
                  y="-3.7"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2011"
                  x="0.2"
                  y="-0.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2012"
                  x="0.2"
                  y="3.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2013"
                  x="0.2"
                  y="6.6"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2014"
                  x="0.2"
                  y="10"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2015"
                  x="0.2"
                  y="13.4"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2016"
                  x="0.2"
                  y="16.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2019"
                  x="0.2"
                  y="-13.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2020"
                  x="0.2"
                  y="-10.5"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2021"
                  x="0.2"
                  y="-7.1"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2022"
                  x="0.2"
                  y="-3.7"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2023"
                  x="0.2"
                  y="-0.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2024"
                  x="0.2"
                  y="3.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2025"
                  x="0.2"
                  y="6.6"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2026"
                  x="0.2"
                  y="10"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2027"
                  x="0.2"
                  y="13.4"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2028"
                  x="0.2"
                  y="16.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2031"
                  x="3.4"
                  y="-13.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2032"
                  x="3.4"
                  y="-10.5"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2033"
                  x="3.4"
                  y="-7.1"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2034"
                  x="3.4"
                  y="-3.7"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2035"
                  x="3.4"
                  y="-0.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2036"
                  x="3.4"
                  y="3.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2037"
                  x="3.4"
                  y="6.6"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2038"
                  x="3.4"
                  y="10"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2039"
                  x="3.4"
                  y="13.4"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2040"
                  x="3.4"
                  y="16.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2043"
                  x="3.4"
                  y="-13.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2044"
                  x="3.4"
                  y="-10.5"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2045"
                  x="3.4"
                  y="-7.1"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2046"
                  x="3.4"
                  y="-3.7"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2047"
                  x="3.4"
                  y="-0.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2048"
                  x="3.4"
                  y="3.2"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2049"
                  x="3.4"
                  y="6.6"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2050"
                  x="3.4"
                  y="10"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2051"
                  x="3.4"
                  y="13.4"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
                <rect
                  id="Rectangle_2052"
                  x="3.4"
                  y="16.9"
                  fill={color}
                  width="1.9"
                  height="2.7"
                />
              </g>
            </g>
          </g>
        </g>
        <rect
          id="Rectangle_2055"
          x="-6.3"
          y="28.7"
          fill="#D7DDE9"
          width="51"
          height="1.4"
        />
      </g>
    </svg>
  );
};

export default IconColorOrganization;
