import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import TableDataService from "../../services/table-data-service";
import TenantEmployeesService from "../../services/tenant-employees-service";

import {
  GET_ALL_TENANT_EMPLOYEES,
  GET_TENANT_EMPLOYEES_LIST,
} from "../actions";

import { toast } from "react-toastify";
import ToastElement from "../../components/toast";
import {
  ADD_TENANT_EMPLOYEES,
  DELETE_TENANT_EMPLOYEES,
  DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE,
  DOWNLOAD_QR,
  EDIT_TENANT_EMPLOYEES,
  GET_TENANT_EMPLOYEES,
  IMPORT_TENANT_EMPLOYEES,
  SEND_QR,
  addTenantEmployeesError,
  addTenantEmployeesSuccess,
  deleteTenantEmployeesError,
  deleteTenantEmployeesSuccess,
  downloadEmployeeImportTemplateError,
  downloadEmployeeImportTemplateSuccess,
  downloadQrError,
  downloadQrSuccess,
  editTenantEmployeesError,
  editTenantEmployeesSuccess,
  getAllTenantEmployeesError,
  getAllTenantEmployeesSuccess,
  getTenantEmployeesError,
  getTenantEmployeesList,
  getTenantEmployeesListError,
  getTenantEmployeesListSuccess,
  getTenantEmployeesSuccess,
  importTenantEmployeesError,
  importTenantEmployeesSuccess,
  sendQrError,
  sendQrSuccess
} from "./action";
import { parseMessage } from "../../helpers/util";
import { IconCheckO } from "../../components/svg";
import * as Mui from "@material-ui/core";
import saveAs from "file-saver";


export function* watchGetAllTenantEmployees() {
  yield takeEvery(GET_ALL_TENANT_EMPLOYEES, getAllTenantEmployees);
}

const getAllTenantEmployeesAsync = async (id) => {
  return TenantEmployeesService.getAllTenantEmployees(id);
};

function* getAllTenantEmployees({ paylod }) {
  try {
    const response = yield call(getAllTenantEmployeesAsync, paylod.tenantId);
    if (response.data.success) {
      yield put(getAllTenantEmployeesSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getAllTenantEmployeesError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getAllTenantEmployeesError(error.response.data.message));
  }
}

export function* watchGetTenantEmployeesList() {
  yield takeEvery(GET_TENANT_EMPLOYEES_LIST, getTenantEmployeesListAc);
}

const getTenantEmployeesListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "tenant-user",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null,
    dbParam?.buildingId || null,
    dbParam?.canteenId || null,
    null,
    null,
    null,
    null,
    null,
    dbParam?.tenantId || null,
  );
};

function* getTenantEmployeesListAc({ payload }) {
  try {
    const response = yield call(getTenantEmployeesListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getTenantEmployeesListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getTenantEmployeesListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getTenantEmployeesListError(error.response.data.message));
  }
}


export function* watchAddTenantEmployees() {
  yield takeEvery(ADD_TENANT_EMPLOYEES, addTenantEmployees);
}

const addTenantEmployeesAsync = async (data) => {
  return TenantEmployeesService.addTenantEmployee(data);
};

function* addTenantEmployees({ payload }) {
  const { history, tenantEmployeesData, location } = payload;
  try {
    const response = yield call(addTenantEmployeesAsync, tenantEmployeesData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addTenantEmployeesSuccess(response.data.success, response.data.message));
      history.push(`/canteen-management/tenant/${tenantEmployeesData.tenant_id}/employees${location?.state?.locationSearch ?? ''}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addTenantEmployeesError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      {
        containerId: "default", position: "bottom-right",
      }
    );
    yield put(addTenantEmployeesError(error.response.data.message));
  }
}


export function* watchGetTenantEmployees() {
  yield takeEvery(GET_TENANT_EMPLOYEES, getTenantEmployees);
}

const getTenantEmployeesAsync = async (id, organizationId) => {
  return TenantEmployeesService.getTenantEmployee(id, organizationId);
};

function* getTenantEmployees({ payload }) {
  try {
    const response = yield call(
      getTenantEmployeesAsync,
      payload.tenantEmployeeId,
      payload.organizationId
    );
    if (response.data.success) {
      yield put(getTenantEmployeesSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getTenantEmployeesError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getTenantEmployeesError(error.response.data.message));
  }
}


export function* watchEditTenantEmployees() {
  yield takeEvery(EDIT_TENANT_EMPLOYEES, editTenantEmployees);
}

const editTenantEmployeesAsync = async (data, id) => {
  return TenantEmployeesService.editTenantEmployee(data, id);
};

function* editTenantEmployees({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(
      editTenantEmployeesAsync,
      payload.tenantEmployeesData,
      payload.tenantEmployeesId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        editTenantEmployeesSuccess(response.data.success, response.data.message)
      );
      history.push(`/canteen-management/tenant/${payload.tenantEmployeesData.tenant_id}/employees${location?.state?.locationSearch ?? ''}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editTenantEmployeesError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      {
        containerId: "default", position: "bottom-right",
      }
    );
    yield put(editTenantEmployeesError(error.response.data.message));
  }
}

export function* watchDeleteTenantEmployees() {
  yield takeEvery(DELETE_TENANT_EMPLOYEES, deleteTenantEmployees);
}

const deleteTenantEmployeesAsync = async (id) => {
  return TenantEmployeesService.deleteTenantEmployee(id);
};

function* deleteTenantEmployees({ payload }) {
  try {
    const response = yield call(deleteTenantEmployeesAsync, payload.tenantEmployeesId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteTenantEmployeesSuccess(response.data.success, response.data.message)
      );
      // Fetch updated user list
      yield put(getTenantEmployeesList({ tenantId: payload.tenantId }));
    } else {
      yield put(deleteTenantEmployeesError(response.data.message));
    }
  } catch (error) {
    yield put(deleteTenantEmployeesError(error.response.data.message));
  }
}

export function* watchImportTenantEmployees() {
  yield takeEvery(IMPORT_TENANT_EMPLOYEES, importTenantEmployees);
}

const importProductAsync = async (param) => {
  return TenantEmployeesService.importTenantEmployees(param);
};

function* importTenantEmployees({ payload }) {
  const { importParam } = payload
  try {
    const response = yield call(importProductAsync, importParam);
    if (response.data.success) {
      toast.success(
        <>
          <span className="icon">
            <IconCheckO />
          </span>
          <Mui.Typography component="h5" variant="h5">
            {localStorage.getItem("i18nextLng") === "en"
              ? "Success"
              : "Velykket"}
            {response.data.message.split(".").map((item, i) => (
              <Mui.Typography
                component="span"
                variant="body1"
                className="d-block"
                key={i}
              >
                {item}
              </Mui.Typography>
            ))}
          </Mui.Typography>
        </>,
        { containerId: "default", position: "bottom-right", autoClose: 10000 }
      );
      yield put(
        importTenantEmployeesSuccess(response.data.success, response.data.message)
      );
      // Fetch updated product list
      yield put(getTenantEmployeesList({ tenantId: importParam.tenant_id }));
    } else {
      yield put(importTenantEmployeesError(response.data.message));
    }
  } catch (error) {
    yield put(
      importTenantEmployeesError(
        parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )
      )
    );
  }
}


export function* watchDownloadQr() {
  yield takeEvery(DOWNLOAD_QR, downloadQr);
}

const downloadQrAsync = async (id) => {
  return TenantEmployeesService.downloadEmployeeQr(id);
};

function* downloadQr({ payload }) {
  try {
    const response = yield call(downloadQrAsync, payload.tenantEmployeeId);
    if (response && response.data) {
      yield put(downloadQrSuccess(true, ""));
      const blob = new Blob([response.data], { type: "image/png" });
      saveAs(blob, `user-qr.png`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(downloadQrError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(downloadQrError(err.message));
  }
}



export function* watchDownloadEmployeeImportTemplate() {
  yield takeEvery(DOWNLOAD_EMPLOYEE_IMPORT_TEMPLATE, downloadEmployeeImportTemplate);
}

const downloadEmployeeImportTemplateAsync = async () => {
  return TenantEmployeesService.downloadEmployeeImportTemplate();
};

function* downloadEmployeeImportTemplate() {
  try {
    const response = yield call(downloadEmployeeImportTemplateAsync);
    if (response && response.data) {
      yield put(downloadEmployeeImportTemplateSuccess(true, ""));
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `EmployeeImportTemplate.csv`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(downloadEmployeeImportTemplateError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(downloadEmployeeImportTemplateError(err.message));
  }
}


export function* watchSendQr() {
  yield takeEvery(SEND_QR, sendQr);
}

const sendQrAsync = async (id) => {
  return TenantEmployeesService.sendEmployeeQr(id);
};

function* sendQr({ payload }) {
  try {
    const response = yield call(sendQrAsync, payload.tenantEmployeeId);
    if (response.data.success) {
      payload?.callback()
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(sendQrSuccess(true, ""));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(sendQrError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(sendQrError(error.response.data.message));
  }
}



export default function* rootSaga() {
  yield all([
    fork(watchGetAllTenantEmployees),
    fork(watchGetTenantEmployeesList),
    fork(watchAddTenantEmployees),
    fork(watchGetTenantEmployees),
    fork(watchEditTenantEmployees),
    fork(watchDeleteTenantEmployees),
    fork(watchImportTenantEmployees),
    fork(watchDownloadQr),
    fork(watchSendQr),
    fork(watchDownloadEmployeeImportTemplate),
  ]);
}