import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import ReportService from "../../services/report-service";
import TableDataService from "../../services/table-data-service";
import { DOWNLOAD_SALE_REPORT, GET_SALE_REPORT_HISTORY } from "../actions";
import {
  downloadSaleReportSuccess,
  downloadSaleReportError,
  getSaleReportHistory,
  getSaleReportHistorySuccess,
  getSaleReportHistoryError,
  DOWNLOAD_ZX_REPORT,
  downloadZXReportError,
  downloadZXReportSuccess,
  GET_Z_REPORT_HISTORY,
  getZReportHistorySuccess,
  getZReportHistoryError,
  downloadZReportError,
  downloadZReportSuccess,
  DOWNLOAD_Z_REPORT,
  getZReportHistory,
  DOWNLOAD_BULK_Z_REPORT,
  downloadBulkZReportSuccess,
  downloadBulkZReportError,
  GET_VAT_SALES_REPORT,
  getVatSalesReportSuccess,
  getVatSalesReportError,
  exportVatSalesExcelReportSuccess,
  exportVatSalesExcelReportError,
  EXPORT_VAT_SALES_EXCEL_REPORT,
  GET_AGGREGATED_REPORT,
  getAggregatedReportSuccess,
  getAggregatedReportError,
} from "./action";
import { toast } from "react-toastify";
import ToastElement from "../../components/toast";
import saveAs from "file-saver";
import moment from "moment";
import JSZip from 'jszip';

export function* watchDownloadSaleReport() {
  yield takeEvery(DOWNLOAD_SALE_REPORT, downloadSaleReport);
}

const downloadSaleReportAsync = async (param) => {
  return ReportService.downloadSaleReport(param);
};

function* downloadSaleReport({ payload }) {
  try {
    const response = yield call(downloadSaleReportAsync, payload.param);
    if (response && response.data) {
      yield put(downloadSaleReportSuccess(true, ""));
      const blob = new Blob([response.data], {
        type:
          payload.param?.type === "excel"
            ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            : "application/pdf",
      });
      saveAs(
        blob,
        payload.param?.type === "excel" ? `Sale-Report.xlsx` : `Sale-Report.pdf`
      );
      if (payload.param?.logHistory) {
        // Fetch updated product list
        yield put(getSaleReportHistory({}));
      }
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(downloadSaleReportError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(downloadSaleReportError(err.message));
  }
}


export function* watchDownloadZXReport() {
  yield takeEvery(DOWNLOAD_ZX_REPORT, downloadZXReport);
}

const downloadZXReportAsync = async (param) => {
  return ReportService.downloadZXReport(param);
};

function* downloadZXReport({ payload }) {
  try {
    const response = yield call(downloadZXReportAsync, payload.param);

    if (response && response.data) {
      payload.callback && payload?.callback()
      const reportType = payload?.param?.reportType
      yield put(downloadZXReportSuccess(true, ""));
      const blob = new Blob([response.data], {
        type:
          payload.param?.type === "xml"
            // ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ? "application/xml"
            : "application/pdf",
      });

      const date = moment().format('YYYY.MM.DD HH:mm:ss')
      saveAs(
        blob,
        payload.param?.type === "xml" ? `${reportType}-report-${date}.xml` : `${reportType}-Report-${date}.pdf`
      );
      // if (payload.param?.logHistory) {
      // Fetch updated product list
      if (reportType === 'z') {
        yield put(getZReportHistory({}));
      }
      // }
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(downloadZXReportError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(downloadZXReportError(err.message));
  }
}

export function* watchGetSaleReportHistory() {
  yield takeEvery(GET_SALE_REPORT_HISTORY, getSaleReportHistoryAc);
}

const getSaleReportHistoryAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "report/history",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || ""
  );
};

function* getSaleReportHistoryAc({ payload }) {
  try {
    const response = yield call(getSaleReportHistoryAsync, payload.param);
    if (response.data.success) {
      yield put(getSaleReportHistorySuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getSaleReportHistoryError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getSaleReportHistoryError(error.response.data.message));
  }
}


export function* watchGetZReportHistory() {
  yield takeEvery(GET_Z_REPORT_HISTORY, getZReportHistoryAc);
}

const getZReportHistoryAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "report/history",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    null,
    dbParam?.buildingId || null,
    dbParam?.canteenId || null,
    null,
    null,
    dbParam?.fromDate || null,
    dbParam?.toDate || null,
    null,
    null,
    dbParam?.kioskId || null
  );
};

function* getZReportHistoryAc({ payload }) {
  try {
    const response = yield call(getZReportHistoryAsync, payload.param);
    if (response.data.success) {
      yield put(getZReportHistorySuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getZReportHistoryError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getZReportHistoryError(error.response.data.message));
  }
}



export function* watchDownloadZReport() {
  yield takeEvery(DOWNLOAD_Z_REPORT, downloadZReport);
}

const downloadZReportAsync = async (param) => {
  return ReportService.downloadZReport(param);
};

function* downloadZReport({ payload }) {
  try {
    const response = yield call(downloadZReportAsync, payload.reportId);
    if (response && response.data) {
      yield put(downloadZReportSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });
      const date = moment().format('YYYY.MM.DD HH:mm:ss')
      saveAs(
        blob,
        `z-Report-${date}.pdf`
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(downloadZReportError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(downloadZReportError(err.message));
  }
}



export function* watchDownloadBulkZReport() {
  yield takeEvery(DOWNLOAD_BULK_Z_REPORT, downloadBulkZReport);
}

const downloadBulkZReportAsync = async (param) => {
  return ReportService.downloadBulkZReport(param);
};

function* downloadBulkZReport({ payload }) {
  try {
    const response = yield call(downloadBulkZReportAsync, payload.param);
    if (response && response.data) {
      yield put(downloadBulkZReportSuccess(true, ""));

      const zip = new JSZip();
      zip.loadAsync(response.data).then(zipContent => {

        const pdfPromises = [];
        zipContent.forEach((relativePath, file) => {
          if (!file.dir) {
            pdfPromises.push(
              file.async('blob').then(content => {
                const pdfBlob = new Blob([content], { type: 'application/pdf' });
                return { name: `${relativePath}.pdf`, content: pdfBlob };
              })
            );
          }
        });

        Promise.all(pdfPromises).then(pdfFiles => {

          const newZip = new JSZip();
          pdfFiles.forEach(file => {
            newZip.file(file.name, file.content);
          });

          const date = moment().format('YYYY.MM.DD HH:mm:ss')

          newZip.generateAsync({ type: 'blob' }).then(newZipBlob => {

            saveAs(newZipBlob, `z-Report-history-${date}.zip`);

          })
        });

      });
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(downloadBulkZReportError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(downloadBulkZReportError(err.message));
  }
}


export function* watchGetVatSalesReport() {
  yield takeEvery(GET_VAT_SALES_REPORT, getVatSalesReportAc);
}

const getVatSalesReportAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "vat-sales",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null,
    dbParam?.buildingId || null,
    dbParam?.canteenId || null,
    dbParam?.categoryId || null,
    null,
    dbParam?.fromDate || null,
    dbParam?.toDate || null,
    null,
    dbParam?.tenantId || null,
    dbParam?.kioskId || null
  );
};

function* getVatSalesReportAc({ payload }) {
  try {
    const response = yield call(getVatSalesReportAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getVatSalesReportSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getVatSalesReportError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getVatSalesReportError(error.response.data.message));
  }
}


export function* watchExportVatSalesExcelReport() {
  yield takeEvery(EXPORT_VAT_SALES_EXCEL_REPORT, exportVatSalesExcelReport);
}

const exportVatSalesExcelReportAsync = async (payload) => {
  return ReportService.exportVatSalesExcelReport(payload);
};

function* exportVatSalesExcelReport({ payload }) {
  try {
    const response = yield call(exportVatSalesExcelReportAsync, payload);
    if (response && response.data) {
      yield put(exportVatSalesExcelReportSuccess(true, ""));
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const date = moment().format('YYYY.MM.DD HH:mm:ss')
      saveAs(blob, `Vat-Sales-Report-${date}.xlsx`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(exportVatSalesExcelReportError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(exportVatSalesExcelReportError(err.message));
  }
}


export function* watchGetAggregatedReport() {
  yield takeEvery(GET_AGGREGATED_REPORT, getAggregatedReport);
}

const getAggregatedReportAsync = async (payload) => {
  return ReportService.getAggregatedReport(payload);
};

function* getAggregatedReport({ payload }) {
  try {
    const response = yield call(getAggregatedReportAsync, payload);
    if (response && response.data) {
      yield put(getAggregatedReportSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getAggregatedReportError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getAggregatedReportError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchDownloadSaleReport),
    fork(watchGetSaleReportHistory),
    fork(watchDownloadZXReport),
    fork(watchGetZReportHistory),
    fork(watchDownloadZReport),
    fork(watchDownloadBulkZReport),
    fork(watchGetVatSalesReport),
    fork(watchExportVatSalesExcelReport),
    fork(watchGetAggregatedReport),
  ]);
}
