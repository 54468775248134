import Req from "../interceptors/token-interceptor";

const KioskService = (function () {
  function _getAllKiosk() {
    return Req.get(`/api/kiosks`);
  }
  function _getKiosk(id, organizationId) {
    let el = "";
    if (organizationId) {
      el += `?organizationId=${organizationId}`;
    }
    return Req.get(`/api/kiosks/${id}${el}`);
  }
  function _getKioskLog(id, param) {
    return Req.get(
      // `/api/kiosks/activityLog/${id}?pagination[page]=${param?.page.toString()}&pagination[perpage]=10`
      `/api/kiosks/${id}/activity-log?pagination[page]=${param?.page.toString()}&pagination[perpage]=10`
    );
  }
  function _addKiosk(data) {
    return Req.post("/api/kiosks", data);
  }
  function _editKiosk(data, id) {
    return Req.put(`/api/kiosks/${id}`, data);
  }
  function _deleteKiosk(id) {
    return Req.delete(`/api/kiosks/${id}`);
  }
  function _deleteMultipleKiosk(ids) {
    return Req.post("/api/kiosks/delete", { ids: ids });
  }
  function _getNetsTerminals() {
    return Req.get("/api/get-terminals");
  }
  function _getTerminalStatus(kioskUuid) {
    return Req.get(`/api/check-terminal/${kioskUuid}`);
  }
  function _netsTerminalReconciliation(kioskId, terminalId) {
    return Req.get(`/api/reconcile/${kioskId}/${terminalId}`);
  }
  return {
    getAllKiosk: _getAllKiosk,
    getKiosk: _getKiosk,
    getKioskLog: _getKioskLog,
    addKiosk: _addKiosk,
    editKiosk: _editKiosk,
    deleteKiosk: _deleteKiosk,
    deleteMultipleKiosk: _deleteMultipleKiosk,
    getNetsTerminals: _getNetsTerminals,
    netsTerminalReconciliation: _netsTerminalReconciliation,
    getTerminalStatus: _getTerminalStatus,
  };
})();
export default KioskService;
