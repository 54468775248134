import Req from "../interceptors/token-interceptor";

const ProductService = (function () {
  function _getAllProduct(param) {
    return Req.get(`/api/products/?status=${param?.statusId || ""}`);
  }
  function _getCanteenProductList(canteenId) {
    return Req.get(`/api/products/?canteenId=${canteenId}`);
  }
  function _getCanteenMenuList(param) {
    return Req.get(
      // `/api/menu/list?canteenId=${param?.canteenId || ""}&fromDate=${
      `/api/menus/?canteenId=${param?.canteenId || ""}&fromDate=${param?.fromDate || ""
      }&toDate=${param?.toDate || ""}`
    );
  }
  function _getProduct(id, organizationId) {
    let el = "";
    if (organizationId) {
      el += `?organizationId=${organizationId}`;
    }
    return Req.get(`/api/products/${id}${el}`);
  }
  function _getProductCategory() {
    return Req.get("/api/products/categories");
  }
  function _addProduct(data) {
    return Req.post("/api/products", data);
    // var res = new FormData();
    // res.append("productImage", images);
    // res.append("data", JSON.stringify(data));
    // return Req.post("/api/products", res, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });

  }
  function _editProduct(data, id) {
    return Req.put(`/api/products/${id}`, data);
  }
  function _deleteProduct(id) {
    return Req.delete(`/api/products/${id}`);
  }
  function _deleteMultipleProduct(ids) {
    return Req.post("/api/products/delete", { ids: ids });
  }
  function _importProduct(param) {
    var res = new FormData();
    res.append("file", param.file);
    return Req.post("/api/product/import", res, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  function _addProductClass(id) {
    return Req.get(`/api/products/addClass/${id}`);
  }
  function _updateProductNutrition(ids) {
    return Req.post("/api/products/update-nutrition", { productId: ids });
  }
  function _productBulkStatusUpdate(payload) {
    return Req.post("/api/products/update-status", payload);
  }
  function _pullImageFromAnnotation(id) {
    return Req.get(`/api/products/update-image/${id}`);
  }
  function _exportProducts(payload) {
    return Req.get(`/api/products/export${payload.queryParam}`, {
      responseType: "arraybuffer",
    });
  }
  return {
    getAllProduct: _getAllProduct,
    getCanteenProductList: _getCanteenProductList,
    getCanteenMenuList: _getCanteenMenuList,
    getProduct: _getProduct,
    getProductCategory: _getProductCategory,
    addProduct: _addProduct,
    editProduct: _editProduct,
    deleteProduct: _deleteProduct,
    deleteMultipleProduct: _deleteMultipleProduct,
    importProduct: _importProduct,
    addProductClass: _addProductClass,
    updateProductNutrition: _updateProductNutrition,
    productBulkStatusUpdate: _productBulkStatusUpdate,
    pullImageFromAnnotation: _pullImageFromAnnotation,
    exportProducts: _exportProducts,
  };
})();
export default ProductService;
