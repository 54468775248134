import * as Mui from "@material-ui/core";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as Yup from "yup";
import Datepicker from "../../../components/datepicker";
import { Filter, MultipleFilter } from "../../../components/filter";
import { IconFilter } from "../../../components/svg";
import {
  getAllBuilding,
  getBestProduct,
  getCustomerRepeatRate,
  getOverview,
  getPurchaseTypeSale,
  getRecentProduct,
  getSale,
  getTopTenant,
} from "../../../reduxs/actions";
import BestProduct from "./best-product";
import BestTenant from "./best-tenant";
import CustomerRate from "./customer-rate";
import Overview from "./overview";
import PurchaseTypeSale from "./purchase-type-sale";
import RecentProduct from "./recent-product";
import Sale from "./sale";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.formikRef = React.createRef();
    this.state = {
      buildingId: "",
      canteenId: null,
      showFilter: false,
      filter: "today",
      customFromDate: null,
      customToDate: null,
    };
    this.schema = Yup.object().shape({
      filter: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),
      customFromDate: Yup.string().when("filter", {
        is: (value) => value === "custom",
        then: Yup.string()
          .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
      customToDate: Yup.string().when("filter", {
        is: (value) => value === "custom",
        then: Yup.string()
          .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
    });
  }

  componentDidMount() {
    if (window.location.hostname === process.env.REACT_APP_API_BASE_URL) {
    } else {
      this.props.getAllBuilding();
      this.props.getOverview({});
      this.props.getSale({});
      this.props.getBestProduct({});
      this.props.getRecentProduct({});
      this.props.getPurchaseTypeSale({});
      this.props.getCustomerRepeatRate({});
      this.props.getTopTenant({});
    }
  }

  handleBuildingFilter = (event) => {
    this.formikRef?.current?.setFieldValue("buildingId", event.target.value);
    this.formikRef?.current?.setFieldValue("canteenId", null);
    this.setState(
      { buildingId: event.target.value, canteenId: null }
      // () => this.refetch()
    );
  };

  handleCanteenFilter = (event) => {
    let val = event.target.value?.filter((el) => el !== "all");
    this.formikRef?.current?.setFieldValue("canteenId", val);
    this.setState(
      { canteenId: val }
      //  () => this.refetch()
    );
  };

  handleFilter = (event) => {
    this.formikRef?.current?.setFieldValue("filter", event.target.value);
    if (event.target.value !== "custom") {
      this.formikRef?.current?.setFieldValue("customFromDate", null);
      this.formikRef?.current?.setFieldValue("customToDate", null);
      this.setState(
        {
          filter: event.target.value,
          customFromDate: null,
          customToDate: null,
        }
        // () => this.refetch()
      );
    } else {
      this.setState({ filter: event.target.value });
    }
  };

  handleFromDateChange = (date) => {
    this.formikRef?.current?.setFieldValue("customFromDate", date);
    this.setState({ customFromDate: date }, () => {
      // if (this.state.customFromDate && this.state.customToDate) {
      //   this.refetch();
      // }
    });
  };

  handleToDateChange = (date) => {
    this.formikRef?.current?.setFieldValue("customToDate", date);
    this.setState({ customToDate: date }, () => {
      // if (this.state.customFromDate && this.state.customToDate) {
      //   this.refetch();
      // }
    });
  };

  onSubmit = (values) => {
    this.refetch();
  };

  refetch = () => {
    this.props.getOverview({
      buildingId: this.state.buildingId,
      canteenId: this.state.canteenId,
      dateFilter: this.state.filter,
      customFromDate: this.state.customFromDate
        ? moment(this.state.customFromDate).format("YYYY-MM-DD")
        : null,
      customToDate: this.state.customToDate
        ? moment(this.state.customToDate).format("YYYY-MM-DD")
        : null,
    });
    this.props.getSale({
      buildingId: this.state.buildingId,
      canteenId: this.state.canteenId,
      dateFilter: this.state.filter,
      customFromDate: this.state.customFromDate
        ? moment(this.state.customFromDate).format("YYYY-MM-DD")
        : null,
      customToDate: this.state.customToDate
        ? moment(this.state.customToDate).format("YYYY-MM-DD")
        : null,
    });
    this.props.getBestProduct({
      buildingId: this.state.buildingId,
      canteenId: this.state.canteenId,
      dateFilter: this.state.filter,
      customFromDate: this.state.customFromDate
        ? moment(this.state.customFromDate).format("YYYY-MM-DD")
        : null,
      customToDate: this.state.customToDate
        ? moment(this.state.customToDate).format("YYYY-MM-DD")
        : null,
    });
    this.props.getRecentProduct({
      buildingId: this.state.buildingId,
      canteenId: this.state.canteenId,
      dateFilter: this.state.filter,
      customFromDate: this.state.customFromDate
        ? moment(this.state.customFromDate).format("YYYY-MM-DD")
        : null,
      customToDate: this.state.customToDate
        ? moment(this.state.customToDate).format("YYYY-MM-DD")
        : null,
    });
    this.props.getPurchaseTypeSale({
      buildingId: this.state.buildingId,
      canteenId: this.state.canteenId,
      dateFilter: this.state.filter,
      customFromDate: this.state.customFromDate
        ? moment(this.state.customFromDate).format("YYYY-MM-DD")
        : null,
      customToDate: this.state.customToDate
        ? moment(this.state.customToDate).format("YYYY-MM-DD")
        : null,
    });
    this.props.getCustomerRepeatRate({
      buildingId: this.state.buildingId,
      canteenId: this.state.canteenId,
      dateFilter: this.state.filter,
      customFromDate: this.state.customFromDate
        ? moment(this.state.customFromDate).format("YYYY-MM-DD")
        : null,
      customToDate: this.state.customToDate
        ? moment(this.state.customToDate).format("YYYY-MM-DD")
        : null,
    });
    this.props.getTopTenant({
      buildingId: this.state.buildingId,
      canteenId: this.state.canteenId,
      dateFilter: this.state.filter,
      customFromDate: this.state.customFromDate
        ? moment(this.state.customFromDate).format("YYYY-MM-DD")
        : null,
      customToDate: this.state.customToDate
        ? moment(this.state.customToDate).format("YYYY-MM-DD")
        : null,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {window.location.hostname === process.env.REACT_APP_API_BASE_URL ? (
          <Mui.Typography
            component="h1"
            variant="h1"
            className="mb-4 font-weight-medium"
          >
            <Mui.Typography
              component="span"
              variant="h1"
              className="font-weight-normal"
            >
              {t("DASHBOARD.HELLO")} {this.props.user?.name || ""},
            </Mui.Typography>{" "}
            {t("DASHBOARD.WELCOME_BACK")}
          </Mui.Typography>
        ) : (
          <>
            <Mui.Box
              display="flex"
              flexDirection={
                Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
              }
              justifyContent="space-between"
            >
              <Mui.Typography
                component="h1"
                variant="h1"
                className="mb-4 font-weight-medium"
              >
                <Mui.Typography
                  component="span"
                  variant="h1"
                  className="font-weight-normal"
                >
                  {t("DASHBOARD.HELLO")} {this.props.user?.name || ""},
                </Mui.Typography>{" "}
                {t("DASHBOARD.WELCOME_BACK")}
              </Mui.Typography>

              <Mui.Button
                variant="outlined"
                className="mb-2 text-transform-none font-weight-normal align-self-end"
                onClick={() =>
                  this.setState((prevState) => ({
                    showFilter: !prevState.showFilter,
                  }))
                }
                data-cy="filter-button"
              >
                {t("COMMON.FILTER")}
                <span className="ml-1 svg-size-small line-height-null">
                  <IconFilter />
                </span>
              </Mui.Button>
            </Mui.Box>

            <Mui.Collapse in={this.state.showFilter}>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  filter: "today",
                  buildingId: "",
                  canteenId: null,
                  customFromDate: null,
                  customToDate: null,
                }}
                validationSchema={this.schema}
                innerRef={this.formikRef}
                onSubmit={this.onSubmit}
              >
                {(props) => (
                  <Form>
                    <Mui.Paper className="default-card spacing-md mb-3 dash-filter">
                      <Mui.Grid container spacing={4}>
                        <Mui.Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          lg={2}
                          className={`form-group`}
                        >
                          <Filter
                            label={t("COMMON.FILTER")}
                            value={this.state.filter}
                            onFilter={this.handleFilter}
                            options={[
                              { id: "today", name: t("DASHBOARD.TODAY") },
                              {
                                id: "thisWeek",
                                name: t("DASHBOARD.THIS_WEEK"),
                              },
                              {
                                id: "thisMonth",
                                name: t("DASHBOARD.THIS_MONTH"),
                              },
                              {
                                id: "thisYear",
                                name: t("DASHBOARD.THIS_YEAR"),
                              },
                              { id: "custom", name: t("DASHBOARD.CUSTOM") },
                            ]}
                          />
                        </Mui.Grid>

                        {this.state.filter === "custom" ? (
                          <>
                            <Mui.Grid
                              item
                              xs={12}
                              sm={4}
                              md={3}
                              lg={2}
                              className={`form-group`}
                            >
                              <Datepicker
                                label={t("COMMON.DATE_FROM")}
                                value={this.state.customFromDate}
                                onDateChange={this.handleFromDateChange}
                                touched={props.touched.customFromDate}
                                error={props.errors.customFromDate}
                              />
                            </Mui.Grid>

                            <Mui.Grid
                              item
                              xs={12}
                              sm={4}
                              md={3}
                              lg={2}
                              className={`form-group`}
                            >
                              <Datepicker
                                label={t("COMMON.DATE_TO")}
                                value={this.state.customToDate}
                                minDate={this.state.customFromDate}
                                onDateChange={this.handleToDateChange}
                                touched={props.touched.customToDate}
                                error={props.errors.customToDate}
                              />
                            </Mui.Grid>
                          </>
                        ) : null}
                        <Mui.Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          lg={2}
                          className={`form-group ${Mui.isWidthDown("sm", this.props.width)
                            ? "mb-3"
                            : ""
                            }`}
                        >
                          <Filter
                            label={t("BUILDING.TITLE")}
                            value={this.state.buildingId}
                            onFilter={this.handleBuildingFilter}
                            options={this.props.buildings}
                            displayEmpty={true}
                          />
                        </Mui.Grid>
                        {this.state.buildingId ? (
                          <Mui.Grid
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            lg={2}
                            className={`form-group`}
                          >
                            <MultipleFilter
                              label={t("CANTEEN.TITLE")}
                              value={this.state.canteenId}
                              onFilter={this.handleCanteenFilter}
                              options={
                                this.props.buildings?.find(
                                  (x) => x.id === this.state.buildingId
                                )?.canteen || []
                              }
                              displayEmpty={true}
                            />
                          </Mui.Grid>
                        ) : null}
                        <Mui.Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          lg={2}
                          className={`form-group`}
                        >
                          <Mui.Button
                            className={`btn-default`}
                            type="submit"
                            color="primary"
                            variant="contained"
                            disableElevation
                          >
                            {t("COMMON.FILTER")}
                            {this.props.loading && (
                              <Mui.CircularProgress
                                color="secondary"
                                size={24}
                              />
                            )}
                          </Mui.Button>
                        </Mui.Grid>

                        {this.state.showFilter && (
                          this.formikRef?.current && this.formikRef.current.dirty
                        ) && (
                            <Mui.Grid
                              item
                              xs={12}
                              sm={4}
                              md={3}
                              lg={2}
                              className={`form-group`}
                            >
                              <Mui.Button
                                className={`btn-default no-color-btn`}
                                color="primary"
                                type="button"
                                variant="outlined"
                                disableElevation
                                onClick={() => {
                                  this.formikRef?.current?.resetForm();
                                  this.setState({
                                    buildingId: "",
                                    canteenId: null,
                                    showFilter: true,
                                    filter: "today",
                                    customFromDate: null,
                                    customToDate: null,
                                  }, () => {
                                    this.refetch();
                                  });
                                }
                                }
                              >
                                {t("COMMON.RESET")}
                              </Mui.Button>
                            </Mui.Grid>
                          )}

                      </Mui.Grid>

                    </Mui.Paper>
                  </Form>
                )}
              </Formik>
            </Mui.Collapse>

            <Mui.Grid container spacing={4}>
              <Mui.Grid item xs={12} md={12}>
                <Overview filter={this.state.filter} />
              </Mui.Grid>

              {/* <Mui.Grid item xs={12} md={4}>
                <Confidence />
              </Mui.Grid> */}
            </Mui.Grid>

            <Mui.Grid container spacing={4}>
              <Mui.Grid item xs={12} md={8}>
                <Sale filter={this.state.filter} />
              </Mui.Grid>

              <Mui.Grid item xs={12} md={4}>
                <BestProduct />
                <RecentProduct />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container spacing={4}>
              <Mui.Grid item xs={12} md={4}>
                <PurchaseTypeSale />
              </Mui.Grid>

              <Mui.Grid item xs={12} md={4}>
                <BestTenant />
              </Mui.Grid>

              <Mui.Grid item xs={12} md={4}>
                <CustomerRate filter={this.state.filter} />
              </Mui.Grid>
            </Mui.Grid>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ auth, building, dashboard }) => {
  const { loading } = dashboard;
  const { user } = auth;
  const { buildings } = building;
  return {
    loading,
    user,
    buildings,
  };
};
export default connect(mapStateToProps, {
  getAllBuilding,
  getOverview,
  getSale,
  getBestProduct,
  getRecentProduct,
  getPurchaseTypeSale,
  getCustomerRepeatRate,
  getTopTenant,
})(withTranslation()(Mui.withWidth()(Dashboard)));
