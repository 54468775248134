import React from "react";

const IconXlsx = () => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
    >
      <g>
        <path d="m481.059 77.662-70.772-70.77c-4.445-4.444-10.354-6.892-16.64-6.892h-297.97c-12.975 0-23.532 10.557-23.532 23.532v232.984h-16.548c-17.396 0-31.548 14.153-31.548 31.548v144.29c0 17.396 14.152 31.549 31.548 31.549h248.5c12.976 0 23.532-10.557 23.532-23.532v-112.225c0-12.976-10.557-23.533-23.532-23.533h-248.5c-9.125 0-16.548-7.423-16.548-16.548s7.423-16.548 16.548-16.548h16.548v8.535c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-256.52c0-4.705 3.828-8.532 8.532-8.532h289.097v72.661c0 8.556 6.96 15.517 15.516 15.517h72.661v385.29c0 4.705-3.828 8.532-8.532 8.532h-368.742c-4.705 0-8.532-3.827-8.532-8.532 0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5c0 12.976 10.557 23.532 23.532 23.532h368.742c12.976 0 23.532-10.557 23.532-23.532v-394.166c.001-6.285-2.447-12.195-6.892-16.64zm-425.462 241.951h248.5c4.705 0 8.532 3.828 8.532 8.533v112.226c0 4.705-3.828 8.532-8.532 8.532h-248.5c-9.125 0-16.548-7.424-16.548-16.549v-117.44c4.813 2.979 10.483 4.698 16.548 4.698zm344.177-231.952v-70.068l70.587 70.585h-70.071c-.284 0-.516-.232-.516-.517z" />
        <path d="m167.837 423.66c.535.092.969.166 8.14.166 3.461 0 8.492-.017 15.859-.059 4.142-.022 7.481-3.399 7.458-7.542s-3.419-7.445-7.542-7.458c-5.238.029-10.862.05-15.148.054v-57.297c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v64.743c0 3.654 2.632 6.776 6.233 7.393z" />
        <path d="m243.934 423.822c13.365 0 24.583-8.506 26.676-20.225 1.57-8.796-2.453-20.297-18.454-26.199-8.059-2.973-15.555-6.212-17.749-7.174-2.068-1.608-2.001-3.879-1.867-4.819.181-1.258 1.085-4.328 5.575-5.681 9.448-2.847 18.143 4.038 18.384 4.232 3.17 2.633 7.877 2.215 10.532-.944 2.665-3.171 2.255-7.902-.916-10.567-.601-.505-14.93-12.323-32.327-7.083-8.719 2.626-14.887 9.49-16.096 17.911-1.132 7.888 2.286 15.475 8.92 19.8.332.216.679.406 1.041.567.375.167 9.28 4.131 19.311 7.831 2.982 1.1 9.818 4.227 8.878 9.489-.69 3.864-5.333 7.861-11.91 7.861-6.606 0-12.97-2.669-17.022-7.141-2.781-3.069-7.524-3.303-10.594-.521-3.069 2.781-3.303 7.524-.521 10.594 6.85 7.558 17.368 12.069 28.139 12.069z" />
        <path d="m83.18 422.454c1.313.925 2.82 1.369 4.313 1.369 2.357 0 4.677-1.108 6.138-3.182l18.309-25.99 18.281 25.986c1.46 2.076 3.782 3.186 6.141 3.186 1.491 0 2.997-.443 4.309-1.366 3.388-2.384 4.202-7.062 1.819-10.45l-21.373-30.381 18.162-25.781c2.386-3.386 1.574-8.065-1.812-10.45-3.386-2.386-8.065-1.574-10.451 1.812l-15.067 21.388-15.043-21.385c-2.383-3.388-7.062-4.202-10.45-1.819s-4.202 7.062-1.819 10.449l18.135 25.779-21.404 30.383c-2.386 3.388-1.574 8.066 1.812 10.452z" />
        <path d="m383.742 143.774c0-8.556-6.96-15.517-15.516-15.517h-176.355c-8.556 0-15.516 6.961-15.516 15.517v104.209c0 8.556 6.96 15.516 15.516 15.516h176.354c8.556 0 15.516-6.96 15.516-15.516v-104.209zm-128.258 64.645v-25.081h49.129v25.081zm49.129 15v25.081h-49.129v-25.081zm-113.258-40.08h49.129v25.081h-49.129zm64.129-15v-25.081h49.129v25.081zm64.129 15h49.129v25.081h-49.129zm49.129-39.565v24.564h-49.129v-25.081h48.613c.284.001.516.232.516.517zm-176.871-.516h48.613v25.081h-49.129v-24.564c0-.286.231-.517.516-.517zm-.516 104.726v-24.564h49.129v25.08h-48.613c-.285 0-.516-.231-.516-.516zm176.871.516h-48.613v-25.081h49.129v24.564c0 .286-.232.517-.516.517z" />
      </g>
    </svg>
  );
};

export default IconXlsx;
