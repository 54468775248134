import { createTheme } from "@material-ui/core/styles";
import { DarkPalette, LightPalette } from "./_palette";
import { Typography } from "./_typography";



const sourceSansPro = {
  fontFamily: "Source Sans Pro",
};

// A custom theme for this app
const Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1500,
      xl: 1920,
    },
  },
  typography: Typography,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [sourceSansPro],
        html: {
          WebkitFontSmoothing: "auto",
        },
      },
    },
  },
  palette: {
    DarkPalette: DarkPalette,
    LightPalette: LightPalette,
  },
});

export default Theme;
