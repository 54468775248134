import * as Mui from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { IconCollpasedMenu, IconExpandedMenu } from "../../components/svg";
import { sideNavConfig } from "../../configs/constant";

const Menu = (props) => {
  const { t, i18n } = useTranslation();
  const [currentPath, setCurrentPath] = useState("");
  const [activePath, setActivePath] = useState("");
  const [hover, setHover] = useState(false);

  const submenu = props?.item?.submenu

  useEffect(() => {
    const currentUrl =
      window.location.pathname.split("/")[1] || window.location.pathname;
    setCurrentPath(currentUrl);

    setActivePath(props.item?.path.split("/")[1]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location]);

  return (
    <Mui.Box
      component="li"
      className={`submenu-parent ${sideNavConfig.hoverSubMenu ? "hover-menu" : ""
        }`}
      onMouseEnter={() => currentPath !== activePath && setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Mui.Tooltip title={
        props.drawerCollapse ? t(props.item.translate) : ""
      } placement="right">
        <Mui.ListItem
          component={Link}
          to={props.item?.path}
          className={
            currentPath && activePath && currentPath === activePath
              ? "active"
              : "text-color-side-nav"
          }
          onClick={() => {
            Mui.isWidthDown("md", props.width) && props.closeDrawer();
            setHover(false);
          }}
        >
          <span className="icon-menu">{props.item.icon}</span>

          <span style={{ wordBreak: i18n.language === 'no' ? 'break-all' : 'break-word' }}>{t(props.item.translate)}</span>
          {props.sideAction ? (
            props.sideAction
          ) : (
            <span className="ml-auto pl-1 flex-shrink-0">
              {submenu && submenu?.length > 0 &&
                currentPath &&
                activePath &&
                currentPath === activePath ? (
                <IconExpandedMenu />
              ) : submenu && submenu?.length > 0 ? (
                <IconCollpasedMenu />
              ) : null}
            </span>
          )}
        </Mui.ListItem>
      </Mui.Tooltip>
      {props.item?.submenu?.length > 0 ? (
        <Mui.Collapse
          in={
            currentPath && activePath && currentPath === activePath
              ? true
              : false
          }
          className={`cms-drawer__list__submenu ${hover ? "open" : ""}`}
        >
          <Mui.List>
            {submenu?.map((val, j) => {
              return (
                <Mui.ListItem
                  key={j}
                  onClick={() => {
                    Mui.isWidthDown("md", props.width) && props.closeDrawer();
                    setHover(false);
                  }}
                  className="text-color-side-nav"
                >
                  <NavLink to={val.path}>
                    <figure>{val.icon}</figure>
                    <span>{t(val.translate)}</span>
                  </NavLink>
                </Mui.ListItem>
              );
            })}
          </Mui.List>
        </Mui.Collapse>
      ) : null}
    </Mui.Box>
  );
};

export default Mui.withWidth()(Menu);
