import Req from "../interceptors/token-interceptor";

const OrderService = (function () {
  function _getAllOrder() {
    return Req.get(`/api/order`);
  }
  function _getOrder(id) {
    return Req.get(`/api/orders/${id}`);
  }
  function _addOrder(data) {
    return Req.post("/api/orders", data);
  }
  function _editOrder(data, id) {
    return Req.put(`/api/orders/${id}`, data);
  }
  function _deleteOrder(id) {
    return Req.delete(`/api/orders/${id}`);
  }
  function _deleteMultipleOrder(ids) {
    return Req.post("/api/orders/delete", { ids: ids });
  }
  function _downloadInvoice(id) {
    return Req.get(`/api/orders/${id}/invoice`, {
      responseType: "arraybuffer",
    });
  }
  function _downloadRefundInvoice(id) {
    return Req.get(`/api/orders/${id}/refund-invoice`, {
      responseType: "arraybuffer",
    });
  }
  function _exportExcelReport(payload) {
    return Req.get(`/api/orders/export${payload.queryParam}`, {
      responseType: "arraybuffer",
    });
  }
  return {
    getAllOrder: _getAllOrder,
    getOrder: _getOrder,
    addOrder: _addOrder,
    editOrder: _editOrder,
    deleteOrder: _deleteOrder,
    deleteMultipleOrder: _deleteMultipleOrder,
    downloadInvoice: _downloadInvoice,
    exportExcelReport: _exportExcelReport,
    downloadRefundInvoice: _downloadRefundInvoice
  };
})();
export default OrderService;
