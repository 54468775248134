import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import CanteenService from "../../services/canteen-service";
import TableDataService from "../../services/table-data-service";
import {
  GET_ALL_CANTEEN,
  GET_CANTEEN_LIST,
  ADD_CANTEEN,
  GET_CANTEEN,
  EDIT_CANTEEN,
  DELETE_CANTEEN,
  DELETE_MULTIPLE_CANTEEN,
  GET_CANTEEN_PREDICTION,
} from "../actions";
import {
  getAllCanteenSuccess,
  getAllCanteenError,
  getCanteenList,
  getCanteenListSuccess,
  getCanteenListError,
  addCanteenSuccess,
  addCanteenError,
  getCanteenSuccess,
  getCanteenError,
  editCanteenSuccess,
  editCanteenError,
  deleteCanteenSuccess,
  deleteCanteenError,
  deleteMultipleCanteenSuccess,
  deleteMultipleCanteenError,
  getCanteenPredictionSuccess,
  getCanteenPredictionError,
  ADD_CROCKERY,
  addCrockerySuccess,
  addCrockeryError,
  getCanteen as getCanteenData,
  DELETE_CROCKERY,
  deleteCrockerySuccess,
  deleteCrockeryError,
  EDIT_CROCKERY,
  editCrockerySuccess,
  editCrockeryError,
  GET_WEIGHTED_LIST,
  getWeightedListSuccess,
  getWeightedListError,
  editWeightedListSuccess,
  editWeightedListError,
  getWeightedList as getWeightedListData,
  EDIT_WEIGHTED_LIST,
  exportCanteenProductsSuccess,
  EXPORT_CANTEEN_PRODUCTS,
  exportCanteenProductsError
} from "./action";
import { toast } from "react-toastify";
import { parseMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
import moment from "moment";
import saveAs from "file-saver";

export function* watchGetAllCanteen() {
  yield takeEvery(GET_ALL_CANTEEN, getAllCanteen);
}

const getAllCanteenAsync = async () => {
  return CanteenService.getAllCanteen();
};

function* getAllCanteen() {
  try {
    const response = yield call(getAllCanteenAsync);
    if (response.data.success) {
      yield put(getAllCanteenSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getAllCanteenError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getAllCanteenError(error.response.data.message));
  }
}

export function* watchGetCanteenList() {
  yield takeEvery(GET_CANTEEN_LIST, getCanteenListAc);
}

const getCanteenListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "canteens",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null,
    dbParam?.buildingId || null
  );
};

function* getCanteenListAc({ payload }) {
  try {
    const response = yield call(getCanteenListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getCanteenListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getCanteenListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getCanteenListError(error.response.data.message));
  }
}

export function* watchAddCanteen() {
  yield takeEvery(ADD_CANTEEN, addCanteen);
}

const addCanteenAsync = async (data) => {
  return CanteenService.addCanteen(data);
};

function* addCanteen({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(addCanteenAsync, payload.canteenData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        addCanteenSuccess(response.data.success, response.data.message)
      );
      history.push(`/canteen-management/canteen${location?.state?.locationSearch ?? ""}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addCanteenError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(addCanteenError(error.response.data.message));
  }
}

export function* watchGetCanteen() {
  yield takeEvery(GET_CANTEEN, getCanteen);
}

const getCanteenAsync = async (id, organizationId) => {
  return CanteenService.getCanteen(id, organizationId);
};

function* getCanteen({ payload }) {
  try {
    const response = yield call(
      getCanteenAsync,
      payload.canteenId,
      payload.organizationId
    );
    if (response.data.success) {
      yield put(getCanteenSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getCanteenError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getCanteenError(error.response.data.message));
  }
}

export function* watchEditCanteen() {
  yield takeEvery(EDIT_CANTEEN, editCanteen);
}

const editCanteenAsync = async (data, id) => {
  return CanteenService.editCanteen(data, id);
};

function* editCanteen({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(
      editCanteenAsync,
      payload.canteenData,
      payload.canteenId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        editCanteenSuccess(response.data.success, response.data.message)
      );
      history.push(`/canteen-management/canteen${location?.state?.locationSearch ?? ""}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editCanteenError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(editCanteenError(error.response.data.message));
  }
}

export function* watchDeleteCanteen() {
  yield takeEvery(DELETE_CANTEEN, deleteCanteen);
}

const deleteCanteenAsync = async (id) => {
  return CanteenService.deleteCanteen(id);
};

function* deleteCanteen({ payload }) {
  try {
    const response = yield call(deleteCanteenAsync, payload.canteenId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteCanteenSuccess(response.data.success, response.data.message)
      );
      // Fetch updated canteen list
      yield put(getCanteenList({}));
    } else {
      yield put(deleteCanteenError(response.data.message));
    }
  } catch (error) {
    yield put(deleteCanteenError(error.response.data.message));
  }
}

export function* watchDeleteMultipleCanteen() {
  yield takeEvery(DELETE_MULTIPLE_CANTEEN, deleteMultipleCanteen);
}

const deleteMultipleCanteenAsync = async (ids) => {
  return CanteenService.deleteMultipleCanteen(ids);
};

function* deleteMultipleCanteen({ payload }) {
  try {
    const response = yield call(deleteMultipleCanteenAsync, payload.canteenIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteMultipleCanteenSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated canteen list
      yield put(getCanteenList({}));
    } else {
      yield put(deleteMultipleCanteenError(response.data.message));
    }
  } catch (error) {
    yield put(deleteMultipleCanteenError(error.response.data.message));
  }
}

export function* watchGetCanteenPrediction() {
  yield takeEvery(GET_CANTEEN_PREDICTION, getCanteenPrediction);
}

const getCanteenPredictionAsync = async (data) => {
  return CanteenService.getCanteenPrediction(data);
};

function* getCanteenPrediction({ payload }) {
  try {
    const response = yield call(getCanteenPredictionAsync, payload);
    if (response.data.success) {
      yield put(getCanteenPredictionSuccess({ ...response.data.data, canteenId: payload.izyCanteenId }));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getCanteenPredictionError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getCanteenPredictionError(error.response.data.message));
  }
}



//Crockery

export function* watchAddCrockery() {
  yield takeEvery(ADD_CROCKERY, addCrockery);
}

const addCrockeryAsync = async (data) => {
  return CanteenService.addCrockery(data);
};

function* addCrockery({ payload }) {
  try {
    const response = yield call(addCrockeryAsync, payload.crockeryData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addCrockerySuccess(response.data.success, response.data.message));
      yield put(getCanteenData(payload.crockeryData.canteenId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addCrockeryError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(addCrockeryError(error.response.data.message));
  }
}



export function* watchDeleteCrockery() {
  yield takeEvery(DELETE_CROCKERY, deleteCrockery);
}

const deleteCrockeryAsync = async (id) => {
  return CanteenService.deleteCrockery(id);
};

function* deleteCrockery({ payload }) {
  try {
    const response = yield call(deleteCrockeryAsync, payload.crockeryData.crockeryId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteCrockerySuccess(response.data.success, response.data.message)
      );
      yield put(getCanteenData(payload.crockeryData.canteenId));
    } else {
      yield put(deleteCrockeryError(response.data.message));
    }
  } catch (error) {
    yield put(deleteCrockeryError(error.response.data.message));
  }
}

export function* watchEditCrockery() {
  yield takeEvery(EDIT_CROCKERY, editCrockery);
}

const editCrockeryAsync = async (data, id) => {
  return CanteenService.editCrockery(data, id);
};

function* editCrockery({ payload }) {
  try {
    const response = yield call(
      editCrockeryAsync,
      payload.crockeryData,
      payload.crockeryId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        editCrockerySuccess(response.data.success, response.data.message)
      );
      yield put(getCanteenData(payload.crockeryData.canteenId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editCrockeryError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(editCrockeryError(error.response.data.message));
  }
}


// Product-crockery association


export function* watchGetWeightedList() {
  yield takeEvery(GET_WEIGHTED_LIST, getWeightedList);
}

const getWeightedListAsync = async (id) => {
  return CanteenService.getWeightedList(id);
};

function* getWeightedList({ payload }) {
  try {
    const response = yield call(
      getWeightedListAsync,
      payload.canteenId,
    );
    if (response.data.success) {
      yield put(getWeightedListSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getWeightedListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getWeightedListError(error.response.data.message));
  }
}


export function* watchEditWeightedList() {
  yield takeEvery(EDIT_WEIGHTED_LIST, editWeightedList);
}

const editWeightedListAsync = async (data, id) => {
  return CanteenService.editWeightedList(data, id);
};

function* editWeightedList({ payload }) {
  try {
    const response = yield call(
      editWeightedListAsync,
      payload.crockeryData,
      payload.productId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        editWeightedListSuccess(response.data.success, response.data.message)
      );
      yield put(getWeightedListData(payload.crockeryData.canteenId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editWeightedListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(editCrockeryError(error.response.data.message));
  }
}


export function* watchExportCanteenProducts() {
  yield takeEvery(EXPORT_CANTEEN_PRODUCTS, exportCanteenProducts);
}

const exportCanteenProductsAsync = async (payload) => {
  return CanteenService.exportCanteenProducts(payload);
};

function* exportCanteenProducts({ payload }) {
  try {
    const response = yield call(exportCanteenProductsAsync, payload?.canteenId);
    if (response && response.data) {
      yield put(exportCanteenProductsSuccess(true, ""));
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const date = moment().format('YYYY.MM.DD HH:mm:ss')
      saveAs(blob, `(${payload?.canteenName}) Products-${date}.xlsx`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(exportCanteenProductsError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(exportCanteenProductsError(err.message));
  }
}



export default function* rootSaga() {
  yield all([
    fork(watchGetAllCanteen),
    fork(watchGetCanteenList),
    fork(watchAddCanteen),
    fork(watchGetCanteen),
    fork(watchEditCanteen),
    fork(watchDeleteCanteen),
    fork(watchDeleteMultipleCanteen),
    fork(watchGetCanteenPrediction),
    fork(watchAddCrockery),
    fork(watchDeleteCrockery),
    fork(watchEditCrockery),
    fork(watchGetWeightedList),
    fork(watchEditWeightedList),
    fork(watchExportCanteenProducts),
  ]);
}
