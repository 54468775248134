import React from "react";

const IconReport = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      type="stroke"
    >
      <ellipse
        cx="9"
        cy="17"
        rx="1"
        ry="1"
        transform="rotate(-180 9 17)"
        stroke="#808089"
        strokeWidth="2"
      />
      <path
        d="M11 7L9 7"
        stroke="#808089"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 10H9"
        stroke="#808089"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 13H9"
        stroke="#808089"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 19C5 20.1046 5.89543 21 7 21L14.6928 21C15.2106 21 15.7083 20.7991 16.0811 20.4397L18.3883 18.2149C18.7792 17.8379 19 17.3182 19 16.7752L19 5C19 3.89543 18.1046 3 17 3L7 3C5.89543 3 5 3.89543 5 5L5 19Z"
        stroke="#808089"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M15 21L15 18C15 17.4477 15.4477 17 16 17L18 17"
        stroke="#808089"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconReport;
