import React from "react";
import theme from "../../configs/theme";

const IconColorBuilding = (props) => {
  const color = props.color ? props.color : theme.palette.primary.main;
  return (
    <svg version="1.1" width="22" height="22" viewBox="0 0 40 51">
      <g id="Group_2016" transform="translate(-701.283 -225.021)">
        <g id="Mask_Group_28">
          <g id="Group_2015" transform="translate(-0.057 1.062)">
            <g id="Group_2011">
              <rect
                id="Rectangle_2431"
                x="725.1"
                y="227.4"
                fill="#E0E0E0"
                width="8.1"
                height="40.9"
              />
              <rect
                id="Rectangle_2432"
                x="725.1"
                y="227.4"
                width="8.1"
                height="40.9"
                opacity="0.2"
                fill="#E0E0E0"
                enableBackground="new"
              />
            </g>
            <rect
              id="Rectangle_2433"
              x="725.1"
              y="226"
              fill="#DEDFE0"
              width="8.6"
              height="1.3"
            />
            <rect
              id="Rectangle_2434"
              x="725.1"
              y="226"
              width="8.6"
              height="1.3"
              fill="#DEDFE0"
            />
            <g id="Group_2012">
              <rect
                id="Rectangle_2435"
                x="725.1"
                y="224"
                fill={color}
                width="7.3"
                height="2.1"
              />
              <rect
                id="Rectangle_2436"
                x="725.1"
                y="224"
                width="7.3"
                height="2.1"
                opacity="0.2"
                fill={color}
                enableBackground="new"
              />
            </g>
            <g id="Group_2013">
              <rect
                id="Rectangle_2437"
                x="725.8"
                y="228.9"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2438"
                x="725.8"
                y="234.8"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2439"
                x="725.8"
                y="240.7"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2440"
                x="725.8"
                y="246.5"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2441"
                x="725.8"
                y="252.4"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2442"
                x="725.8"
                y="258.2"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2443"
                x="727.7"
                y="228.9"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2444"
                x="727.7"
                y="234.8"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2445"
                x="727.7"
                y="240.7"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2446"
                x="727.7"
                y="246.5"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2447"
                x="727.7"
                y="252.4"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2448"
                x="727.7"
                y="258.2"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2449"
                x="729.5"
                y="228.9"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2450"
                x="729.5"
                y="234.8"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2451"
                x="729.5"
                y="240.7"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2452"
                x="729.5"
                y="246.5"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2453"
                x="729.5"
                y="252.4"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2454"
                x="729.5"
                y="258.2"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2455"
                x="731.3"
                y="228.9"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2456"
                x="731.3"
                y="234.8"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2457"
                x="731.3"
                y="240.7"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2458"
                x="731.3"
                y="246.5"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2459"
                x="731.3"
                y="252.4"
                fill={color}
                width="1.1"
                height="4.3"
              />
              <rect
                id="Rectangle_2460"
                x="731.3"
                y="258.2"
                fill={color}
                width="1.1"
                height="4.3"
              />
            </g>
            <rect
              id="Rectangle_2461"
              x="708.5"
              y="227.4"
              fill="#FFFFFF"
              width="16.6"
              height="40.9"
            />
            <rect
              id="Rectangle_2462"
              x="709.3"
              y="224"
              fill={color}
              width="15.8"
              height="2.1"
            />
            <rect
              id="Rectangle_2463"
              x="708"
              y="226.1"
              fill="#FFC727"
              width="17.1"
              height="1.3"
            />
            <rect
              id="Rectangle_2464"
              x="708"
              y="226.1"
              fill="#DEDFE0"
              width="17.1"
              height="1.3"
            />
            <rect
              id="Rectangle_2465"
              x="713.8"
              y="264.2"
              fill={color}
              width="6"
              height="4.1"
            />
            <g id="Group_2014">
              <rect
                id="Rectangle_2466"
                x="710.1"
                y="228.9"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2467"
                x="710.1"
                y="234.8"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2468"
                x="710.1"
                y="240.7"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2469"
                x="710.1"
                y="246.5"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2470"
                x="710.1"
                y="252.4"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2471"
                x="710.1"
                y="258.2"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2472"
                x="713.8"
                y="228.9"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2473"
                x="713.8"
                y="234.8"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2474"
                x="713.8"
                y="240.7"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2475"
                x="713.8"
                y="246.5"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2476"
                x="713.8"
                y="252.4"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2477"
                x="713.8"
                y="258.2"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2478"
                x="717.5"
                y="228.9"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2479"
                x="717.5"
                y="234.8"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2480"
                x="717.5"
                y="240.7"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2481"
                x="717.5"
                y="246.5"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2482"
                x="717.5"
                y="252.4"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2483"
                x="717.5"
                y="258.2"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2484"
                x="721.2"
                y="228.9"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2485"
                x="721.2"
                y="234.8"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2486"
                x="721.2"
                y="240.7"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2487"
                x="721.2"
                y="246.5"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2488"
                x="721.2"
                y="252.4"
                fill={color}
                width="2.2"
                height="4.3"
              />
              <rect
                id="Rectangle_2489"
                x="721.2"
                y="258.2"
                fill={color}
                width="2.2"
                height="4.3"
              />
            </g>
          </g>
          <rect
            id="Rectangle_2491"
            x="701.3"
            y="271.7"
            width="39.9"
            height="4.3"
          />
          <g id="Group_2558">
            <rect
              id="Rectangle_2490"
              x="703.4"
              y="269.3"
              fill="#FFFFFF"
              width="35.7"
              height="4.3"
            />
            <rect
              id="Rectangle_2492"
              x="701.3"
              y="271.1"
              fill="#FFFFFF"
              width="39.9"
              height="4.3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconColorBuilding;
