import * as Mui from "@material-ui/core";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import TableHeader from "./header";
import TableNoData from "./no-data";
import TablePagination from "./pagination";
import TableRow from "./table-row";
import TableToolbar from "./toolbar";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
// import moment from "moment";

export default function DataTable(props) {

  const history = useHistory();
  const location = useLocation();

  const updateURL = (newFilters) => {
    //Update this so that the existing params which are not in the newFilters are not overridden
    const existingFilters = queryString.parse(location.search);
    const query = queryString.stringify({ ...existingFilters, ...newFilters });
    history.push({
      pathname: location.pathname,
      search: `?${query}`,
    });
  };


  const { t } = useTranslation()
  // eslint-disable-next-line no-unused-vars
  const [tableStyle, setTableStyle] = React.useState(
    props?.tableStyle || "card" // option default, card
  );
  // eslint-disable-next-line no-unused-vars
  const [actionStyle, setActionStyle] = React.useState(
    props?.actionStyle || "icons"
  );
  const [sortOrder, setSortOrder] = React.useState("");
  const [activeCol, setActiveCol] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [statusFilter, setStatusFilter] = React.useState(null);
  const [buildingFilter, setBuildingFilter] = React.useState(null);
  const [canteenFilter, setCanteenFilter] = React.useState(null);
  const [kioskFilter, setKioskFilter] = React.useState(null);
  const [tenantFilter, setTenantFilter] = React.useState(null);
  const [categoryFilter, setCategoryFilter] = React.useState(null);
  // const [dateFilter, setDateFilter] = React.useState(null);
  const [typingTimeout, setTypingTimeout] = React.useState(0);
  const [actionMenu, setActionMenu] = React.useState(null);
  const [actionMenuId, setActionMenuId] = React.useState(null);

  const parseFilterFromUrl = () => {
    if (location.search) {
      const parsedFilters = queryString.parse(location.search);

      setSortOrder(parsedFilters.sortOrder || "");
      setActiveCol(parsedFilters.activeCol || "");
      setSelected(parsedFilters.selected ? JSON.parse(parsedFilters.selected) : []);
      setPage(parsedFilters.page ? parseInt(parsedFilters.page) : 1);
      setPageSize(parsedFilters.pageSize ? parseInt(parsedFilters.pageSize) : 10);
      setSearch(parsedFilters.search || "");
      setStatusFilter(parsedFilters.statusFilter || null);
      setBuildingFilter(parsedFilters.buildingFilter || null);
      setCanteenFilter(parsedFilters.canteenFilter || null);
      setKioskFilter(parsedFilters.kioskFilter || null);
      setTenantFilter(parsedFilters.tenantFilter || null);
      setCategoryFilter(parsedFilters.categoryFilter || null);

      props.onChange(
        parsedFilters.search || "",
        parsedFilters.sortOrder || "",
        parsedFilters.page ? parseInt(parsedFilters.page) : 1,
        parsedFilters.pageSize ? parseInt(parsedFilters.pageSize) : 10,
        parsedFilters.activeCol || "",
        parsedFilters.statusFilter || null,
        parsedFilters.buildingFilter || null,
        parsedFilters.canteenFilter || null,
        parsedFilters.categoryFilter || null,
        parsedFilters.tenantFilter || null,
        parsedFilters.kioskFilter || null
      )
    }
  }


  useEffect(() => {
    parseFilterFromUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleOpenAlert = (id) => {
    props.handleOpenAlert(id);
    setActionMenu(null);
    setActionMenuId(null);
  };

  const handleOpenAlert1 = () => {
    props.handleOpenAlert1(selected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.id);
      setSelected(newSelecteds);
      handleParamChange({ selected: newSelecteds })
      return;
    }
    setSelected([]);
    handleParamChange({ selected: null })

  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    handleParamChange({ selected: newSelected })
  };

  const handleParamChange = (keyVal) => {

    if (!keyVal) {
      history.push({
        pathname: location.pathname,
        search: ``,
      });
      return
    }

    const newFilters = {
      search,
      sortOrder,
      page,
      pageSize,
      activeCol,
      statusFilter,
      buildingFilter,
      canteenFilter,
      categoryFilter,
      tenantFilter,
      kioskFilter,
      selected: []
    }

    for (let key in keyVal) {
      newFilters[key] = keyVal[key];
    }

    for (let key in newFilters) {
      if (newFilters[key] === null || newFilters[key] === "") {
        delete newFilters[key];
      }
    }
    if (newFilters?.selected?.length <= 0) {
      delete newFilters['selected'];
    } else {
      newFilters.selected = JSON.stringify(newFilters.selected);
    }

    updateURL(newFilters)

  }

  const handleRequestSort = (event, property) => {
    const isAsc = activeCol === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setActiveCol(property);
    handleParamChange({ sortOrder: isAsc ? "desc" : "asc" })
    props.onChange(
      search,
      isAsc ? "desc" : "asc",
      page,
      pageSize,
      property,
      statusFilter,
      buildingFilter,
      canteenFilter,
      categoryFilter,
      tenantFilter,
      kioskFilter
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleParamChange({ page: newPage })
    props.onChange(
      search,
      sortOrder,
      newPage,
      pageSize,
      activeCol,
      statusFilter,
      buildingFilter,
      canteenFilter,
      categoryFilter,
      tenantFilter,
      kioskFilter
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  };

  const handleChangePageSize = (event) => {
    const newPageSize = parseInt(event.target.value, 10)
    setPageSize(newPageSize);
    setPage(1);
    handleParamChange({ page: 1, pageSize: newPageSize })
    props.onChange(
      search,
      sortOrder,
      1,
      newPageSize,
      activeCol,
      statusFilter,
      buildingFilter,
      canteenFilter,
      categoryFilter,
      tenantFilter,
      kioskFilter
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  };

  const handleSearch = (event) => {
    let val = event.target.value;
    setSearch(event.target.value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        handleParamChange({ search: val, page: 1 })
        props.onChange(
          val,
          sortOrder,
          1,
          pageSize,
          activeCol,
          statusFilter,
          buildingFilter,
          canteenFilter,
          categoryFilter,
          tenantFilter,
          kioskFilter
          // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
        );
      }, 1000)
    );
  };

  const handleStatusFilter = (event) => {
    let val = event.target.value;
    setStatusFilter(val);
    handleParamChange({ statusFilter: val })
    props.onChange(
      search,
      sortOrder,
      page,
      pageSize,
      activeCol,
      val,
      buildingFilter,
      canteenFilter,
      categoryFilter,
      tenantFilter,
      kioskFilter
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  };

  const handleBuildingFilter = (event) => {
    let val = event.target.value;
    setBuildingFilter(val);
    setCanteenFilter(null);
    handleParamChange({ buildingFilter: val })
    props.onChange(
      search,
      sortOrder,
      page,
      pageSize,
      activeCol,
      statusFilter,
      val,
      null,
      categoryFilter,
      tenantFilter,
      null
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  };

  const handleCanteenFilter = (event) => {
    let val;
    if (props.canteenFilterType === 'single') {
      val = event.target.value;
    } else {
      val = event.target.value?.filter((el) => el !== "all");
    }
    setCanteenFilter(val);
    setKioskFilter(null);
    handleParamChange({ canteenFilter: val })

    props.onChange(
      search,
      sortOrder,
      page,
      pageSize,
      activeCol,
      statusFilter,
      buildingFilter,
      val,
      categoryFilter,
      tenantFilter,
      null
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  };

  const handleKioskFilter = (event) => {
    const val = event.target.value;
    setKioskFilter(val);
    handleParamChange({ kioskFilter: val })

    props.onChange(
      search,
      sortOrder,
      page,
      pageSize,
      activeCol,
      statusFilter,
      buildingFilter,
      canteenFilter,
      categoryFilter,
      tenantFilter,
      val
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  };

  const handleBuiCanFilter = (buiId, canId) => {
    setBuildingFilter(buiId)
    if (props.canteenFilterType === 'single') {
      setCanteenFilter(canId);
    } else {
      // setCanteenFilter([canId]);
    }
    handleParamChange({ buildingFilter: buiId, canteenFilter: canId })

    props.onChange(
      search,
      sortOrder,
      page,
      pageSize,
      activeCol,
      statusFilter,
      buiId,
      canId,
      categoryFilter,
      tenantFilter,
      kioskFilter
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  }

  const handleTenantFilter = (event) => {
    let val = event.target.value
    setTenantFilter(val);
    handleParamChange({ tenantFilter: val })

    props.onChange(
      search,
      sortOrder,
      page,
      pageSize,
      activeCol,
      statusFilter,
      buildingFilter,
      canteenFilter,
      categoryFilter,
      val,
      kioskFilter
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  };


  const handleCategoryFilter = (event) => {
    let val = event.target.value;
    setCategoryFilter(val);
    handleParamChange({ categoryFilter: val })

    props.onChange(
      search,
      sortOrder,
      page,
      pageSize,
      activeCol,
      statusFilter,
      buildingFilter,
      canteenFilter,
      val,
      tenantFilter,
      kioskFilter
      // dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : null
    );
  };

  // const handleDateFilter = (date) => {
  //   setDateFilter(date);
  //   // Only if date valid and falls in range
  //   if (moment(date, "MM/DD/YYYY", true).isValid()) {
  //     let el = moment(date).format("YYYY-MM-DD");
  //     if (
  //       moment(el).isBetween(
  //         props.dateFilterMinDate,
  //         props.dateFilterMaxDate,
  //         null,
  //         "[]"
  //       )
  //     ) {
  //       handleParamChange({dateFilter: moment(date).format("YYYY-MM-DD")})
  //       props.onChange(
  //         search,
  //         sortOrder,
  //         page,
  //         pageSize,
  //         activeCol,
  //         statusFilter,
  //         buildingFilter,
  //         canteenFilter,
  //         categoryFilter,
  //         moment(date).format("YYYY-MM-DD")
  //       );
  //     }
  //   }
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleOpenActionMenu = (event, id) => {
    setActionMenu(event.currentTarget);
    setActionMenuId(id);
  };

  const handleCloseActionMenu = () => {
    setActionMenuId(null);
    setActionMenu(null);
  };

  const handleCustomAction = (id) => {
    props.handleCustomAction(id);
    setActionMenuId(null);
    setActionMenu(null);
  };

  const handleReset = () => {
    setSortOrder("");
    setActiveCol("");
    setSelected([]);
    setPage(1);
    setPageSize(10);
    setSearch("");
    setStatusFilter(null);
    setBuildingFilter(null);
    setCanteenFilter(null);
    setTenantFilter(null);
    setCategoryFilter(null);
    // setDateFilter(null);

    if (props?.onReset) {
      handleParamChange()
      props?.onReset()
    } else {
      handleParamChange()
      props.onChange(
        "",
        "",
        1,
        10,
        "",
        null,
        null,
        null,
        null
      );
    }
  }

  const showReset = () => {
    if (
      search ||
      sortOrder ||
      activeCol ||
      // selected.length !== 0 ||
      statusFilter ||
      (props?.enableBuildingFilter && buildingFilter) ||
      (props?.enableCanteenFilter && canteenFilter) ||
      categoryFilter ||
      (props?.enableTenantFilter && tenantFilter) ||
      props.displayReset
      // dateFilter
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Mui.Box className={`table-wrapper ${tableStyle}`} elevation={0}>
        <TableToolbar
          title={props.title}
          titleTrailing={props.titleTrailing}
          numSelected={selected?.length}
          search={search}
          customFilter={props?.customFilter}
          statusFilter={statusFilter}
          buildingFilter={buildingFilter}
          canteenFilter={canteenFilter}
          kioskFilter={kioskFilter}
          categoryFilter={categoryFilter}
          tenantFilter={tenantFilter}
          // dateFilter={dateFilter}
          handleSearch={handleSearch}
          handleStatusFilter={handleStatusFilter}
          handleBuildingFilter={handleBuildingFilter}
          handleCanteenFilter={handleCanteenFilter}
          handleKioskFilter={handleKioskFilter}
          handleCategoryFilter={handleCategoryFilter}
          handleTenantFilter={handleTenantFilter}
          handleBuiCanFilter={handleBuiCanFilter}
          // handleDateFilter={handleDateFilter}
          permission={props.permission}
          route={props.route}
          createLabel={props.createLabel}
          handleOpenAlert1={handleOpenAlert1}
          handleOpenImport={props.handleOpenImport}
          headCells={props.headCells}
          enableSearch={props.enableSearch}
          enableStatusFilter={props.enableStatusFilter}
          enableBuildingFilter={props.enableBuildingFilter}
          enableCanteenFilter={props.enableCanteenFilter}
          enableProductCategoryFilter={props.enableProductCategoryFilter}
          enableKioskFilter={props.enableKioskFilter}
          canteenFilterType={props.canteenFilterType}
          enableCategoryFilter={props.enableCategoryFilter}
          enableTenantFilter={props.enableTenantFilter}
          enableReset={(props.enableReset ?? true) && showReset()}
          handleReset={handleReset}



          // enableDateFilter={props.enableDateFilter}
          // dateFilterMinDate={props.dateFilterMinDate}
          // dateFilterMaxDate={props.dateFilterMaxDate}
          enableColumnFilter={props.enableColumnFilter}
          enableImport={props.enableImport && props.permission.add === 1}
          customChoiceList={props.choiceList}
          searchPlaceholder={props.searchPlaceholder}
        />
        {selected?.length > 0 &&
          <Mui.Box className="mb-2 ml-2 mr-2">
            <Mui.Typography className="mb-1 font-weight-medium text-color-black" variant="h6">
              {t('PRODUCT.BULK_ACTIONS')}
            </Mui.Typography>
            <Mui.Box style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '1rem' }} className=" pl-2 pr-2 pt-1 pb-1" display={'flex'} justifyContent={'space-between'} alignItems={"center"}>
              <Mui.Typography>
                {selected?.length} {t("COMMON.SELECTED")}
              </Mui.Typography>
              {props?.customBulkActions?.(selected, () => { setSelected([]) })}
            </Mui.Box>
          </Mui.Box>
        }
        <Mui.TableContainer className="default-datatable table-striped">
          <Mui.Table size={"medium"}>
            <TableHeader
              numSelected={selected?.length}
              sortOrder={sortOrder}
              activeCol={activeCol}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.rows?.length}
              headCells={props.headCells}
              enableCheckbox={props.enableCheckbox}
              hideActionColumn={props.hideActionColumn}
            />

            <Mui.TableBody>
              {props.loading ? (
                <>
                  {Array.from(Array(pageSize).keys()).map((i) => (
                    <Mui.TableRow key={i}>
                      <Mui.TableCell
                        className="skeleton-cell"
                        colSpan={props.headCells.length + (props.enableCheckbox ? 2 : 1)}
                        align="center"
                        width={"100%"}
                      >
                        <Skeleton height={30} />
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))}
                </>
              ) : (
                <>
                  {props.rows &&
                    props.rows?.length > 0 &&
                    props.rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          key={index}
                          isItemSelected={isItemSelected}
                          labelId={labelId}
                          row={row}
                          handleClick={handleClick}
                          headCells={props.headCells}
                          index={index}
                          handleOpenActionMenu={handleOpenActionMenu}
                          actionMenu={actionMenu}
                          actionMenuId={actionMenuId}
                          handleCloseActionMenu={handleCloseActionMenu}
                          handleOpenAlert={handleOpenAlert}
                          handleOpenModal={props.handleOpenModal}
                          handleCustomAction={handleCustomAction}
                          permission={props.permission}
                          route={props.route}
                          initialState={props.initialState}
                          actionStyle={actionStyle}
                          enableCheckbox={props.enableCheckbox}
                          customActionComponents={props.customActionComponents}
                          onRowPress={props.onRowPress}
                          hideActionColumn={props.hideActionColumn}
                        />
                      );
                    })}
                  {props.rows && props.rows.length === 0 && <TableNoData />}
                </>
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Box>
      <TablePagination
        page={page}
        totalPage={props.totalPage}
        totalItem={props.totalItem}
        start={props.start}
        end={props.end}
        handleChangePage={handleChangePage}
        pageSize={pageSize}
        handleChangePageSize={handleChangePageSize}
      />
    </>
  );
}
