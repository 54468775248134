import React from "react";

const IconRemove = () => {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.24755 0.740703V1.9727H0.863547V0.740703H7.24755Z"
        fill="#2F3256"
      />
    </svg>
  );
};

export default IconRemove;
