
import * as Mui from "@material-ui/core";
import { useTheme } from "../../ThemeContext";
export const CheckboxTable = (props) => {

    const { theme } = useTheme()

    return <Mui.Checkbox
        color="primary"
        className={theme === 'dark' ? "checkbox-style-dark" : "checkbox-style-light"}
        {...props}
    />;
}