import Req from "../interceptors/token-interceptor";

const TableDataService = (function () {
  function _getAllData(
    endpoint,
    orgId,
    search,
    searchFields,
    sortOrder,
    page,
    pageSize,
    activeCol,
    statusId = null,
    buildingId = null,
    canteenId = null,
    categoryId = null,
    productCategoryId = null,
    fromDate = null,
    toDate = null,
    date = null,
    tenantId = null,
    kioskId = null,
  ) {
    let el = "";
    if (orgId) {
      el += `&organizationId=${orgId}`;
    }
    if (statusId) {
      el += `&status=${statusId}`;
    }
    if (buildingId) {
      el += `&buildingId=${buildingId}`;
    }
    if (canteenId) {
      if (Array.isArray(canteenId)) {
        canteenId &&
          canteenId.forEach((element) => {
            el += `&canteenId[]=${element}`;
          });
      } else {
        el += `&canteenId=${canteenId}`;
      }
    }
    if (kioskId) {
      if (Array.isArray(kioskId)) {
        kioskId &&
          kioskId.forEach((element) => {
            el += `&kioskId[]=${element}`;
          });
      } else {
        el += `&kioskId=${kioskId}`;
      }
    }
    if (categoryId) {
      el += `&configChoiceCategoryId=${categoryId}`;
    }
    if (productCategoryId) {
      // el += `&productCategoryId=${productCategoryId}`;
      el += `&category_id=${productCategoryId}`;
    }
    if (fromDate) {
      el += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      el += `&toDate=${toDate}`;
    }
    if (date) {
      el += `&date=${date}`;
    }
    if (tenantId) {
      el += `&tenantId=${tenantId}`;
    }
    return Req.get(
      `/api/${endpoint}?pagination[page]=${page.toString()}&pagination[perpage]=${pageSize.toString()}&orderBy=${activeCol}&sortedBy=${sortOrder}&search=${search}&searchFields=${searchFields}${el}`
    );
  }
  return { getAllData: _getAllData };
})();
export default TableDataService;
