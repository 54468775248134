
import JoditEditor from 'jodit-react';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../ThemeContext';

export const MUIRichText = ({ value, onChange }) => {
    const editor = useRef(null);
    const { theme } = useTheme();
    const { t } = useTranslation()


    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: t('SETTING.START_TYPING'),
            theme: theme,
            controls: {
                font: {
                    list: {
                        "Poppins": "Poppins"
                    }
                }
            }


        }),
        [theme, t]
    );


    return (
        <div id='jodit-rte-start' data-theme={theme === 'light' ? 'light' : 'dark'}>
            <JoditEditor
                ref={editor}
                value={value}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => onChange(newContent)}
            // onChange={(newContent) => { }}
            />
        </div>
    );
}