import * as Mui from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ReferenceArea,
//   ResponsiveContainer,
// } from "recharts";
import { useTranslation, withTranslation } from "react-i18next";
import {
  IconArrowDown,
  IconArrowUp,
  IconInfo
} from "../../../components/svg";
import { formatCurrency } from "../../../helpers/util";
const BestTenant = (props) => {

  const { t, i18n } = useTranslation()
  return (
    <Mui.Paper className="widget-card">
      {/* <Mui.IconButton size="small" className="btn-menu">
        <IconMoreKebab />
      </Mui.IconButton> */}

      <Mui.Typography
        component="h3"
        variant="h3"
        className="mb-3 font-weight-normal title"
      >
        {t("DASHBOARD.MOST_ACTIVE_TENANT")}
        <Mui.Tooltip
          title={t("DASHBOARD.MOST_ACTIVE_TENANT_TOOLTIP")}
          arrow
          placement="bottom-start"
          PopperProps={{
            className:
              "MuiTooltip-popper MuiTooltip-popperArrow tooltip-popover default-tooltip",
          }}
        >
          <span className="ml-1 svg-color-grey">
            <IconInfo />
          </span>
        </Mui.Tooltip>
      </Mui.Typography>

      {/* <Mui.Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Mui.Typography
            component="h3"
            variant="h3"
            className="mb-3 font-weight-semi-bold title"
          >
            89,420 <span className="font-weight-light">NOK</span>
            <Mui.Typography
              component="span"
              variant="h6"
              className="d-block mt-1 text-color-grey font-weight-normal"
            >
              Sales Over Time
            </Mui.Typography>
          </Mui.Typography>

          <Mui.Typography
            component="h4"
            variant="h4"
            className="font-weight-medium text-color-success"
          >
            <span className="mr-1 svg-size-xs svg-color-success">
              <IconArrowUp />
            </span>
            4.5%
          </Mui.Typography>
        </Mui.Box> */}

      {/* <ResponsiveContainer height={180}>
          <LineChart
            data={timeData}
            margin={{
              left: -10,
            }}
          >
            <CartesianGrid strokeDasharray="8 8" />
            <XAxis dataKey="name" tick={{ fill: "#9F9F9F" }} />
            <YAxis tick={{ fill: "#9F9F9F" }} />
            <Tooltip />
            <Line type="monotone" dataKey="uv" stroke="#000330" />
            <ReferenceArea strokeOpacity={0.3} />
          </LineChart>
        </ResponsiveContainer> */}

      {props.topTenantData?.map((item, index) => {
        return (
          <Mui.Box width="100%" display="flex" className="mb-2" key={index}>
            <Mui.Typography
              component="h5"
              variant="h5"
              className="font-weight-normal mr-1"
            >
              #{item?.id || "-"}
            </Mui.Typography>

            <Mui.Typography
              component="h5"
              variant="h5"
              className="mr-auto font-weight-normal"
            >
              {item?.name || "-"}
            </Mui.Typography>

            <Mui.Typography
              component="h5"
              variant="h5"
              className="flex-shrink-0 ml-4 text-color-grey font-weight-medium"
            >
              {item?.comparison === "high" ? (
                <span className="svg-size-xs svg-color-success mr-1">
                  <IconArrowUp />
                </span>
              ) : null}
              {item?.comparison === "low" ? (
                <span className="svg-size-xs svg-color-danger mr-1">
                  <IconArrowDown />
                </span>
              ) : null}

              {formatCurrency(item.total, i18n.language)}
            </Mui.Typography>
          </Mui.Box>
        );
      })}

      {props.topTenantData?.length === 0 ? (
        <Mui.Typography
          component="h6"
          variant="h6"
          className="font-weight-normal text-center text-color-muted"
        >
          {t("COMMON.FORM.NO_RECORD_LABEL")}
        </Mui.Typography>
      ) : null}
    </Mui.Paper>
  );
}

const mapStateToProps = ({ dashboard }) => {
  const { topTenantData } = dashboard;
  return { topTenantData };
};
export default connect(mapStateToProps, {})(withTranslation()(BestTenant));
