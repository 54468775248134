export const GET_ALL_ORGANIZATION = "GET_ALL_ORGANIZATION";
export const GET_ALL_ORGANIZATION_SUCCESS = "GET_ALL_ORGANIZATION_SUCCESS";
export const GET_ALL_ORGANIZATION_ERROR = "GET_ALL_ORGANIZATION_ERROR";
export const GET_ORGANIZATION_LIST = "GET_ORGANIZATION_LIST";
export const GET_ORGANIZATION_LIST_SUCCESS = "GET_ORGANIZATION_LIST_SUCCESS";
export const GET_ORGANIZATION_LIST_ERROR = "GET_ORGANIZATION_LIST_ERROR";
export const ONBOARD_ORGANIZATION = "ONBOARD_ORGANIZATION";
export const ONBOARD_ORGANIZATION_SUCCESS = "ONBOARD_ORGANIZATION_SUCCESS";
export const ONBOARD_ORGANIZATION_ERROR = "ONBOARD_ORGANIZATION_ERROR";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const ADD_ORGANIZATION_SUCCESS = "ADD_ORGANIZATION_SUCCESS";
export const ADD_ORGANIZATION_ERROR = "ADD_ORGANIZATION_ERROR";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_ERROR = "GET_ORGANIZATION_ERROR";
export const EDIT_ORGANIZATION = "EDIT_ORGANIZATION";
export const EDIT_ORGANIZATION_SUCCESS = "EDIT_ORGANIZATION_SUCCESS";
export const EDIT_ORGANIZATION_ERROR = "EDIT_ORGANIZATION_ERROR";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_ERROR = "DELETE_ORGANIZATION_ERROR";
export const DELETE_MULTIPLE_ORGANIZATION = "DELETE_MULTIPLE_ORGANIZATION";
export const DELETE_MULTIPLE_ORGANIZATION_SUCCESS =
  "DELETE_MULTIPLE_ORGANIZATION_SUCCESS";
export const DELETE_MULTIPLE_ORGANIZATION_ERROR =
  "DELETE_MULTIPLE_ORGANIZATION_ERROR";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_ERROR = "RESEND_EMAIL_ERROR";
export const RESET_ORGANIZATION = "RESET_ORGANIZATION";

export const getAllOrganization = () => ({
  type: GET_ALL_ORGANIZATION,
  payload: {},
});

export const getAllOrganizationSuccess = (organizations) => ({
  type: GET_ALL_ORGANIZATION_SUCCESS,
  payload: organizations,
});

export const getAllOrganizationError = (error) => ({
  type: GET_ALL_ORGANIZATION_ERROR,
  payload: error,
});

export const getOrganizationList = (dbParam) => ({
  type: GET_ORGANIZATION_LIST,
  payload: { dbParam },
});

export const getOrganizationListSuccess = (organizationList) => ({
  type: GET_ORGANIZATION_LIST_SUCCESS,
  payload: organizationList,
});

export const getOrganizationListError = (error) => ({
  type: GET_ORGANIZATION_LIST_ERROR,
  payload: error,
});

export const onboardOrganization = (organizationId, callback) => ({
  type: ONBOARD_ORGANIZATION,
  payload: { organizationId, callback },
});

export const onboardOrganizationSuccess = (success, message) => ({
  type: ONBOARD_ORGANIZATION_SUCCESS,
  payload: { success, message },
});

export const onboardOrganizationError = (error) => ({
  type: ONBOARD_ORGANIZATION_ERROR,
  payload: error,
});

export const addOrganization = (organizationData, history, location) => ({
  type: ADD_ORGANIZATION,
  payload: { organizationData, history, location },
});

export const addOrganizationSuccess = (success, message) => ({
  type: ADD_ORGANIZATION_SUCCESS,
  payload: { success, message },
});

export const addOrganizationError = (error) => ({
  type: ADD_ORGANIZATION_ERROR,
  payload: error,
});

export const getOrganization = (organizationId) => ({
  type: GET_ORGANIZATION,
  payload: { organizationId },
});

export const getOrganizationSuccess = (organizationData) => ({
  type: GET_ORGANIZATION_SUCCESS,
  payload: organizationData,
});

export const getOrganizationError = (error) => ({
  type: GET_ORGANIZATION_ERROR,
  payload: error,
});

export const editOrganization = (
  organizationId,
  organizationData,
  history,
  location
) => ({
  type: EDIT_ORGANIZATION,
  payload: { organizationId, organizationData, history, location },
});

export const editOrganizationSuccess = (success, message) => ({
  type: EDIT_ORGANIZATION_SUCCESS,
  payload: { success, message },
});

export const editOrganizationError = (error) => ({
  type: EDIT_ORGANIZATION_ERROR,
  payload: error,
});

export const deleteOrganization = (organizationId) => ({
  type: DELETE_ORGANIZATION,
  payload: { organizationId },
});

export const deleteOrganizationSuccess = (success, message) => ({
  type: DELETE_ORGANIZATION_SUCCESS,
  payload: { success, message },
});

export const deleteOrganizationError = (error) => ({
  type: DELETE_ORGANIZATION_ERROR,
  payload: error,
});

export const deleteMultipleOrganization = (organizationIds) => ({
  type: DELETE_MULTIPLE_ORGANIZATION,
  payload: { organizationIds },
});

export const deleteMultipleOrganizationSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_ORGANIZATION_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleOrganizationError = (error) => ({
  type: DELETE_MULTIPLE_ORGANIZATION_ERROR,
  payload: error,
});

export const resendEmail = (param) => ({
  type: RESEND_EMAIL,
  payload: { param },
});

export const resendEmailSuccess = (success, message) => ({
  type: RESEND_EMAIL_SUCCESS,
  payload: { success, message },
});

export const resendEmailError = (error) => ({
  type: RESEND_EMAIL_ERROR,
  payload: error,
});

export const resetOrganization = () => ({
  type: RESET_ORGANIZATION,
  payload: {},
});
