import { toast } from "react-toastify";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../components/toast";
import { parseMessage } from "../../helpers/util";
import OrganizationService from "../../services/organization-service";
import TableDataService from "../../services/table-data-service";
import {
  ADD_ORGANIZATION,
  DELETE_MULTIPLE_ORGANIZATION,
  DELETE_ORGANIZATION,
  EDIT_ORGANIZATION,
  GET_ALL_ORGANIZATION,
  GET_ORGANIZATION,
  GET_ORGANIZATION_LIST,
  ONBOARD_ORGANIZATION,
  RESEND_EMAIL,
} from "../actions";
import {
  addOrganizationError,
  addOrganizationSuccess,
  deleteMultipleOrganizationError,
  deleteMultipleOrganizationSuccess,
  deleteOrganizationError,
  deleteOrganizationSuccess,
  editOrganizationError,
  editOrganizationSuccess,
  getAllOrganizationError,
  getAllOrganizationSuccess,
  getOrganizationError,
  getOrganizationList,
  getOrganizationListError,
  getOrganizationListSuccess,
  getOrganizationSuccess,
  onboardOrganizationError,
  onboardOrganizationSuccess,
  resendEmailError,
  resendEmailSuccess,
} from "./action";

export function* watchGetAllOrganization() {
  yield takeEvery(GET_ALL_ORGANIZATION, getAllOrganization);
}

const getAllOrganizationAsync = async () => {
  return OrganizationService.getAllOrganization();
};

function* getAllOrganization() {
  try {
    const response = yield call(getAllOrganizationAsync);
    if (response.data.success) {
      yield put(getAllOrganizationSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getAllOrganizationError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getAllOrganizationError(error.response.data.message));
  }
}

export function* watchGetOrganizationList() {
  yield takeEvery(GET_ORGANIZATION_LIST, getOrganizationListAc);
}

const getOrganizationListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "organizations",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null
  );
};

function* getOrganizationListAc({ payload }) {
  try {
    const response = yield call(getOrganizationListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getOrganizationListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getOrganizationListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getOrganizationListError(error.response.data.message));
  }
}

export function* watchOnboardOrganization() {
  yield takeEvery(ONBOARD_ORGANIZATION, onboardOrganization);
}

const onboardOrganizationAsync = async (id) => {
  return OrganizationService.onboardOrganization(id);
};

function* onboardOrganization({ payload }) {
  // const customToastId = toast.info(
  //   <ToastElement
  //     type="info"
  //     message={i18n.t("ORGANIZATION.ONBOARDING_INFO_LABEL")}
  //   />,
  //   {
  //     containerId: "custom",
  //   }
  // );
  try {
    const response = yield call(
      onboardOrganizationAsync,
      payload.organizationId
    );
    if (response.data.success) {
      payload?.callback && payload?.callback()
      // toast.dismiss(customToastId);
      yield put(
        onboardOrganizationSuccess(response.data.success, response.data.message)
      );
      // Fetch updated organization list
      yield put(getOrganizationList({}));
    } else {
      // toast.dismiss(customToastId);
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(onboardOrganizationError(response.data.message));
    }
  } catch (error) {
    // toast.dismiss(customToastId);
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default", position: "bottom-right"  }
    // );
    yield put(onboardOrganizationError(error.response.data.message));
  }
}

export function* watchAddOrganization() {
  yield takeEvery(ADD_ORGANIZATION, addOrganization);
}

const addOrganizationAsync = async (data) => {
  return OrganizationService.addOrganization(data);
};

function* addOrganization({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(addOrganizationAsync, payload.organizationData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        addOrganizationSuccess(response.data.success, response.data.message)
      );
      history.push(`/organization${location?.state?.locationSearch ?? ''}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addOrganizationError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(addOrganizationError(error.response.data.message));
  }
}

export function* watchGetOrganization() {
  yield takeEvery(GET_ORGANIZATION, getOrganization);
}

const getOrganizationAsync = async (id) => {
  return OrganizationService.getOrganization(id);
};

function* getOrganization({ payload }) {
  try {
    const response = yield call(getOrganizationAsync, payload.organizationId);
    if (response.data.success) {
      yield put(getOrganizationSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getOrganizationError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getOrganizationError(error.response.data.message));
  }
}

export function* watchEditOrganization() {
  yield takeEvery(EDIT_ORGANIZATION, editOrganization);
}

const editOrganizationAsync = async (data, id) => {
  return OrganizationService.editOrganization(data, id);
};

function* editOrganization({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(
      editOrganizationAsync,
      payload.organizationData,
      payload.organizationId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        editOrganizationSuccess(response.data.success, response.data.message)
      );
      history.push(`/organization${location?.state?.locationSearch ?? ''}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editOrganizationError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(editOrganizationError(error.response.data.message));
  }
}

export function* watchDeleteOrganization() {
  yield takeEvery(DELETE_ORGANIZATION, deleteOrganization);
}

const deleteOrganizationAsync = async (id) => {
  return OrganizationService.deleteOrganization(id);
};

function* deleteOrganization({ payload }) {
  try {
    const response = yield call(
      deleteOrganizationAsync,
      payload.organizationId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteOrganizationSuccess(response.data.success, response.data.message)
      );
      // Fetch updated organization list
      yield put(getOrganizationList({}));
    } else {
      yield put(deleteOrganizationError(response.data.message));
    }
  } catch (error) {
    yield put(deleteOrganizationError(error.response.data.message));
  }
}

export function* watchDeleteMultipleOrganization() {
  yield takeEvery(DELETE_MULTIPLE_ORGANIZATION, deleteMultipleOrganization);
}

const deleteMultipleOrganizationAsync = async (ids) => {
  return OrganizationService.deleteMultipleOrganization(ids);
};

function* deleteMultipleOrganization({ payload }) {
  try {
    const response = yield call(
      deleteMultipleOrganizationAsync,
      payload.organizationIds
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteMultipleOrganizationSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated organization list
      yield put(getOrganizationList({}));
    } else {
      yield put(deleteMultipleOrganizationError(response.data.message));
    }
  } catch (error) {
    yield put(deleteMultipleOrganizationError(error.response.data.message));
  }
}

export function* watchResendEmail() {
  yield takeEvery(RESEND_EMAIL, resendEmail);
}

const resendEmailAsync = async (param) => {
  return OrganizationService.resendEmail(param);
};

function* resendEmail({ payload }) {
  try {
    const response = yield call(resendEmailAsync, payload.param);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        resendEmailSuccess(response.data.success, response.data.message)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(resendEmailError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(resendEmailError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllOrganization),
    fork(watchGetOrganizationList),
    fork(watchOnboardOrganization),
    fork(watchAddOrganization),
    fork(watchGetOrganization),
    fork(watchEditOrganization),
    fork(watchDeleteOrganization),
    fork(watchDeleteMultipleOrganization),
    fork(watchResendEmail),
  ]);
}
