import { ACCEPT_TENANT_TERMS, ACCEPT_TENANT_TERMS_ERROR, ACCEPT_TENANT_TERMS_SUCCESS, DOWNLOAD_TENANT_QR, DOWNLOAD_TENANT_QR_ERROR, DOWNLOAD_TENANT_QR_SUCCESS, SEND_QR_TO_EMAIL, SEND_QR_TO_EMAIL_ERROR, SEND_QR_TO_EMAIL_SUCCESS, VERIFY_TENANT_TOKEN, VERIFY_TENANT_TOKEN_ERROR, VERIFY_TENANT_TOKEN_SUCCESS } from "./action";


const INIT_STATE = {
    termsConditions: null,
    success: false,
    message: null,
    loading: false,
    error: null,

    loading1: false,
    qrData: null,
    error1: null,

    loading2: false,
    error2: null,

    loading3: false,
    error3: null,
    success3: false,
};

const termsAndConditionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case VERIFY_TENANT_TOKEN:
            return {
                ...state,
                loading: true,
                success: false,
                error: null,
            };
        case VERIFY_TENANT_TOKEN_SUCCESS:
            return {
                ...state,
                termsConditions: action.payload,
                loading: false,
                success: true,
                error: null,
            };
        case VERIFY_TENANT_TOKEN_ERROR:
            return {
                ...state,
                termsConditions: null,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACCEPT_TENANT_TERMS:
            return {
                ...state,
                loading1: true,
                success: false,
                error1: null,
            };
        case ACCEPT_TENANT_TERMS_SUCCESS:
            return {
                ...state,
                qrData: action.payload,
                loading1: false,
                success: true,
                error1: null,
            };
        case ACCEPT_TENANT_TERMS_ERROR:
            return {
                ...state,
                qrData: null,
                loading1: false,
                success: false,
                error1: action.payload,
            };
        case DOWNLOAD_TENANT_QR:
            return { ...state, loading2: true, error2: null };
        case DOWNLOAD_TENANT_QR_SUCCESS:
            return {
                ...state,
                loading2: false,
                success: action.payload.success,
                message: action.payload.message,
                error2: null,
            };
        case DOWNLOAD_TENANT_QR_ERROR:
            return {
                ...state,
                loading2: false,
                success: false,
                message: null,
                error2: action.payload,
            };
        case SEND_QR_TO_EMAIL:
            return { ...state, loading3: true, error3: null };
        case SEND_QR_TO_EMAIL_SUCCESS:
            return {
                ...state,
                loading3: false,
                success3: true,
                message: action.payload.message,
                error3: null,
            };
        case SEND_QR_TO_EMAIL_ERROR:
            return {
                ...state,
                loading3: false,
                success3: false,
                message: null,
                error3: action.payload,
            };
        default:
            return { ...state };
    }

}

export default termsAndConditionReducer