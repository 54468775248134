import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../../reduxs/actions";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../configs/core";
class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return this.props.user != null ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};
export default connect(mapStateToProps, {
  logout,
})(Logout);
