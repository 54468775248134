import Req from "../interceptors/token-interceptor";

const ProductCategoryService = (function () {

  function _getProductCategoryItem(id) {
    return Req.get(`/api/products/categories/${id}`);
  }
  function _addProductCategoryItem(data) {
    return Req.post("/api/products/categories", data);
  }
  function _editProductCategoryItem(data, id) {
    return Req.put(`/api/products/categories/${id}`, data);
  }
  function _deleteProductCategoryItem(id) {
    return Req.delete(`/api/products/categories/${id}`);
  }
  function _getAllProductCategory(id) {
    return Req.get(`/api/products/categories/list`);
  }
  return {
    getProductCategoryItem: _getProductCategoryItem,
    addProductCategoryItem: _addProductCategoryItem,
    editProductCategoryItem: _editProductCategoryItem,
    deleteProductCategoryItem: _deleteProductCategoryItem,
    getAllProductCategory: _getAllProductCategory,
  };
})();
export default ProductCategoryService;
