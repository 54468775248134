import Req from "../interceptors/token-interceptor";

const SettingService = (function () {

  function _getBasicSetting(fields) {
    return Req.get(`/api/basic-settings?fields=${fields}`);
  }

  function _getSetting(fields) {
    if (fields) {
      return Req.get(`/api/basic-settings?fields=${fields}`);
    }
    return Req.get(`/api/settings`);
  }
  function _editSetting(data) {
    return Req.post(`/api/settings`, data);
  }
  function _getPOSTerminal(data) {
    return Req.get(`/api/get-pos-terminals`, data);
  }
  return {
    getSetting: _getSetting,
    getBasicSetting: _getBasicSetting,
    editSetting: _editSetting,
    getPOSTerminal: _getPOSTerminal,
  };
})();
export default SettingService;
