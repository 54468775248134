import * as Mui from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTheme } from "../../ThemeContext";
import { StyledSwitch } from "../../components/form";
import {
  IconAtSign,
  IconCollpasedMenu,
  IconDarkMode,
  IconReport
} from "../../components/svg";
import { sideNavConfig, toolBarConfig } from "../../configs/constant";
import Menu from "./menu";
import MenuSkeleton from "./menu-skeleton";


function Sidenav(props) {
  const { theme, toggleDarkMode } = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openPredDialog, setOpenPredDialog] = React.useState(false);
  // const [loadingAnnotation, setLoadingAnnotation] = React.useState(false);

  const { basicSettingData } = useSelector((state) => state.setting)

  const annotationUrl = basicSettingData?.annotationUrl
  const predictionUrl = basicSettingData?.predictionUrl

  const { t } = useTranslation()

  const { user: { organizations } } = useSelector(
    ({ shared }) => ({
      user: shared.currentUserData ?? {},
    }),
  );

  useEffect(() => {
    if (props.drawerCollapse === true) {
      document.body.classList.add("cms-drawer--collapsed");
    } else {
      document.body.classList.remove("cms-drawer--collapsed");
    }
  });

  // const getAnnotationLink = async () => {
  //   const response = await AuthService.getAnnotationUrl();
  //   if (response.status === 200) {
  //     return response.data.data.url
  //   }
  //   return ""
  // };

  return (
    <>
      {toolBarConfig.style === "fixed" ? (
        <Mui.Toolbar className="placeholder-toolbar" />
      ) : (
        <Mui.Toolbar className="cms-drawer__toolbar dark"></Mui.Toolbar>
      )}
      <figure className="toolbar-logo">
        <img
          src={
            theme === "light"
              ? sideNavConfig.primaryLogo
              : sideNavConfig.secondaryLogo
          }
          alt="Logo"
        />
        <img
          className="small-logo"
          src={
            theme === "light"
              ? sideNavConfig.primaryLogo
              : sideNavConfig.secondaryLogo
          }
          alt="Logo"
        />
      </figure>

      <Mui.List className="cms-drawer__list">
        <Mui.Box className="mt-3 mb-1" />
        <Mui.Typography
          component="h2"
          className="menu-title font-weight-bold text-color-side-nav ml-3 mb-1"
        >
          {organizations?.name?.toUpperCase()}
        </Mui.Typography>
        {props.loading === true ? (
          <>
            {Array.from({ length: 10 }).map((_, index) => (
              <MenuSkeleton key={index} />
            ))}
          </>
        ) : (
          <>
            {props.menuList?.filter((item) => item.key !== "siteSetting").map((item, index) => {
              return (
                <Menu
                  item={item}
                  key={index}
                  location={props.location}
                  drawerCollapse={props.drawerCollapse}
                  closeDrawer={props.closeDrawer}
                />
              );
            })}

            <Mui.Box component="li" className="submenu-parent pointer">
              <Mui.Tooltip
                title={props.drawerCollapse ? t('COMMON.ANNOTATION') : ""}
                placement="right"
              >
                <Mui.ListItem
                  to={""}
                  component={Mui.Link}
                  disabled={annotationUrl === null}
                  onClick={() => {
                    if (annotationUrl === null) return
                    setOpen(true);
                  }}
                  className="text-color-side-nav"
                >
                  <span className="icon-menu">
                    <IconAtSign />
                  </span>
                  <span>{t('COMMON.ANNOTATION')}</span>
                  {annotationUrl === null ? <Mui.CircularProgress /> : <span className="ml-auto pl-1 flex-shrink-0">
                    <IconCollpasedMenu />
                  </span>}
                </Mui.ListItem>
              </Mui.Tooltip>
            </Mui.Box>
            {!(predictionUrl === null || predictionUrl === "#") && <Mui.Box component="li" className="submenu-parent pointer">
              <Mui.Tooltip
                title={props.drawerCollapse ? t('COMMON.PREDICTION') : ""}
                placement="right"
              >
                <Mui.ListItem
                  to={""}
                  component={Mui.Link}
                  onClick={() => {
                    if (predictionUrl === null || predictionUrl === "" || predictionUrl === "#") return
                    setOpenPredDialog(true);
                  }}
                  className="text-color-side-nav"
                >
                  <span className="icon-menu">
                    <IconReport />
                  </span>
                  <span>{t('COMMON.PREDICTION')}</span>
                  <span className="ml-auto pl-1 flex-shrink-0">
                    <IconCollpasedMenu />
                  </span>
                </Mui.ListItem>
              </Mui.Tooltip>
            </Mui.Box>}
          </>
        )}

        <Mui.Divider className="cms-drawer__divider m-3" />
        <Mui.Typography
          component="h2"
          className="menu-title font-weight-bold text-color-side-nav ml-3 mt-3 mb-1"
        >
          {t("COMMON.SUPPORT").toUpperCase()}
        </Mui.Typography>

        {props.menuList
          ?.filter((item) => item.key === "siteSetting")
          .map((item, index) => {
            return (
              <Menu
                item={item}
                key={index}
                location={props.location}
                drawerCollapse={props.drawerCollapse}
                closeDrawer={props.closeDrawer}
              />
            );
          })}

        <Mui.Box component="li" className="submenu-parent pointer">
          <Mui.Tooltip
            title={props.drawerCollapse ? "Dark Mode" : ""}
            placement="right"
          >
            <Mui.ListItem
              to={""}
              component={Mui.Link}
              onClick={() => {
                toggleDarkMode();
              }}
              className="text-color-side-nav"
            >
              <span className="icon-menu">
                <IconDarkMode fill={theme === "dark" ? "#fff" : "none"} />
              </span>
              <span>{t('COMMON.DARK_MODE')}</span>
              <span className="ml-auto pl-1 flex-shrink-0">
                <StyledSwitch
                  checked={theme === "dark"}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </span>
            </Mui.ListItem>
          </Mui.Tooltip>
        </Mui.Box>
      </Mui.List>

      <Mui.Box className="cms-drawer__footer">
        <Mui.Typography
          component="h6"
          variant="body2"
          className="font-weight-light text-color-side-nav"
        >
          © {new Date().getFullYear()} {document.title} {t('COMMON.TEAM')}. <br /> {t('COMMON.ALL_RIGHT_RESERVED')}.
        </Mui.Typography>
      </Mui.Box>

      {/* dialog for asking uer if he wants to go to annotation platform */}
      <Mui.Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Mui.DialogTitle id="alert-dialog-title">
          {t('CANTEEN.ANNOTATION_DIALOG_TITLE')}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-description">

            {t('CANTEEN.ANNOTATION_DIALOG_DESC')}
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button
            onClick={handleCloseDialog}
            className={`btn-default`}
            variant="contained"
            disableElevation
          >
            {t('COMMON.NO')}
          </Mui.Button>
          <Mui.Button
            disabled={annotationUrl === null}
            onClick={() => {
              window.open(annotationUrl, '_blank');
              handleCloseDialog()
            }}
            // onClick={async () => {
            //   setLoadingAnnotation(true);
            //   const link = await getAnnotationLink()
            //   if (link !== "") {
            //     window.open(link, '_blank');
            //   }
            //   handleCloseDialog()

            //   setLoadingAnnotation(false);
            // }}
            className={`btn-default`}
            type="submit"
            color="primary"
            variant="contained"
            // disabled={loadingAnnotation}
            disableElevation
          >
            {t('COMMON.YES')}
            {/* {loadingAnnotation && (
              <Mui.CircularProgress color="secondary" size={24} />
            )} */}
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>

      {/* dialog for asking uer if he wants to go to prediction dashboard */}
      <Mui.Dialog
        open={openPredDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Mui.DialogTitle id="alert-dialog-title">
          {t('CANTEEN.PREDICTION_DIALOG_TITLE')}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-description">

            {t('CANTEEN.PREDICTION_DIALOG_DESC')}
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button
            onClick={handleClosePredDialog}
            className={`btn-default`}
            variant="contained"
            disableElevation
          >
            {t('COMMON.NO')}
          </Mui.Button>
          <Mui.Button
            onClick={() => {
              window.open(predictionUrl, '_blank');
              handleClosePredDialog()
            }}
            // onClick={async () => {
            //   setLoadingAnnotation(true);
            //   const link = await getAnnotationLink()
            //   if (link !== "") {
            //     window.open(link, '_blank');
            //   }
            //   handleCloseDialog()

            //   setLoadingAnnotation(false);
            // }}
            className={`btn-default`}
            type="submit"
            color="primary"
            variant="contained"
            // disabled={loadingAnnotation}
            disableElevation
          >
            {t('COMMON.YES')}
            {/* {loadingAnnotation && (
              <Mui.CircularProgress color="secondary" size={24} />
            )} */}
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );

  function handleCloseDialog() {
    setOpen(false);
  }

  function handleClosePredDialog() {
    setOpenPredDialog(false);
  }
}

//getAnnotationUrl

export default withTranslation()(Mui.withWidth()(Sidenav));
