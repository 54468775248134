export const DOWNLOAD_SALE_REPORT = "DOWNLOAD_SALE_REPORT";
export const DOWNLOAD_SALE_REPORT_SUCCESS = "DOWNLOAD_SALE_REPORT_SUCCESS";
export const DOWNLOAD_SALE_REPORT_ERROR = "DOWNLOAD_SALE_REPORT_ERROR";
export const GET_SALE_REPORT_HISTORY = "GET_SALE_REPORT";
export const GET_SALE_REPORT_HISTORY_SUCCESS =
  "GET_SALE_REPORT_HISTORY_SUCCESS";
export const GET_SALE_REPORT_HISTORY_ERROR = "GET_SALE_REPORT_HISTORY_ERROR";
export const RESET_REPORT = "RESET_REPORT";

export const DOWNLOAD_ZX_REPORT = "DOWNLOAD_ZX_REPORT";
export const DOWNLOAD_ZX_REPORT_SUCCESS = "DOWNLOAD_ZX_REPORT_SUCCESS";
export const DOWNLOAD_ZX_REPORT_ERROR = "DOWNLOAD_ZX_REPORT_ERROR";

export const GET_Z_REPORT_HISTORY = "GET_Z_REPORT";
export const GET_Z_REPORT_HISTORY_SUCCESS =
  "GET_Z_REPORT_HISTORY_SUCCESS";
export const GET_Z_REPORT_HISTORY_ERROR = "GET_Z_REPORT_HISTORY_ERROR";

export const DOWNLOAD_Z_REPORT = "DOWNLOAD_Z_REPORT";
export const DOWNLOAD_Z_REPORT_SUCCESS = "DOWNLOAD_Z_REPORT_SUCCESS";
export const DOWNLOAD_Z_REPORT_ERROR = "DOWNLOAD_Z_REPORT_ERROR";

export const DOWNLOAD_BULK_Z_REPORT = "DOWNLOAD_BULK_Z_REPORT";
export const DOWNLOAD_BULK_Z_REPORT_SUCCESS = "DOWNLOAD_BULK_Z_REPORT_SUCCESS";
export const DOWNLOAD_BULK_Z_REPORT_ERROR = "DOWNLOAD_BULK_Z_REPORT_ERROR";

export const GET_VAT_SALES_REPORT = "GET_VAT_SALES_REPORT";
export const GET_VAT_SALES_REPORT_SUCCESS = "GET_VAT_SALES_REPORT_SUCCESS";
export const GET_VAT_SALES_REPORT_ERROR = "GET_VAT_SALES_REPORT_ERROR";

export const EXPORT_VAT_SALES_EXCEL_REPORT = "EXPORT_VAT_SALES_EXCEL_REPORT";
export const EXPORT_VAT_SALES_EXCEL_REPORT_SUCCESS = "EXPORT_VAT_SALES_EXCEL_REPORT_SUCCESS";
export const EXPORT_VAT_SALES_EXCEL_REPORT_ERROR = "EXPORT_VAT_SALES_EXCEL_REPORT_ERROR";

export const GET_AGGREGATED_REPORT = "GET_AGGREGATED_REPORT";
export const GET_AGGREGATED_REPORT_SUCCESS = "GET_AGGREGATED_REPORT_SUCCESS";
export const GET_AGGREGATED_REPORT_ERROR = "GET_AGGREGATED_REPORT_ERROR";







export const downloadSaleReport = (param) => {
  return {
    type: DOWNLOAD_SALE_REPORT,
    payload: { param },
  };
};

export const downloadSaleReportSuccess = (success, message) => {
  return {
    type: DOWNLOAD_SALE_REPORT_SUCCESS,
    payload: { success, message },
  };
};

export const downloadSaleReportError = (error) => {
  return {
    type: DOWNLOAD_SALE_REPORT_ERROR,
    payload: error,
  };
};

export const getSaleReportHistory = (param) => ({
  type: GET_SALE_REPORT_HISTORY,
  payload: { param },
});

export const getSaleReportHistorySuccess = (saleReportHistory) => ({
  type: GET_SALE_REPORT_HISTORY_SUCCESS,
  payload: saleReportHistory,
});

export const getSaleReportHistoryError = (error) => ({
  type: GET_SALE_REPORT_HISTORY_ERROR,
  payload: error,
});

export const resetReport = () => ({
  type: RESET_REPORT,
  payload: {},
});

//X and Z reports
export const downloadZXReport = (param, callback) => {
  return {
    type: DOWNLOAD_ZX_REPORT,
    payload: { param, callback },
  };
};

export const downloadZXReportSuccess = (success, message) => {
  return {
    type: DOWNLOAD_ZX_REPORT_SUCCESS,
    payload: { success, message },
  };
};

export const downloadZXReportError = (error) => {
  return {
    type: DOWNLOAD_ZX_REPORT_ERROR,
    payload: error,
  };
};


export const getZReportHistory = (param) => ({
  type: GET_Z_REPORT_HISTORY,
  payload: { param },
});

export const getZReportHistorySuccess = (zReportHistory) => ({
  type: GET_Z_REPORT_HISTORY_SUCCESS,
  payload: zReportHistory,
});

export const getZReportHistoryError = (error) => ({
  type: GET_Z_REPORT_HISTORY_ERROR,
  payload: error,
});

export const downloadZReport = (reportId) => {
  return {
    type: DOWNLOAD_Z_REPORT,
    payload: { reportId },
  };
};

export const downloadZReportSuccess = (success, message) => {
  return {
    type: DOWNLOAD_Z_REPORT_SUCCESS,
    payload: { success, message },
  };
};

export const downloadZReportError = (error) => {
  return {
    type: DOWNLOAD_Z_REPORT_ERROR,
    payload: error,
  };
};

export const downloadBulkZReport = (param) => {
  return {
    type: DOWNLOAD_BULK_Z_REPORT,
    payload: { param },
  };
};

export const downloadBulkZReportSuccess = (success, message) => {
  return {
    type: DOWNLOAD_BULK_Z_REPORT_SUCCESS,
    payload: { success, message },
  };
};

export const downloadBulkZReportError = (error) => {
  return {
    type: DOWNLOAD_BULK_Z_REPORT_ERROR,
    payload: error,
  };
};


export const getVatSalesReport = (dbParam) => ({
  type: GET_VAT_SALES_REPORT,
  payload: { dbParam },
});

export const getVatSalesReportSuccess = (vatSalesReport) => ({
  type: GET_VAT_SALES_REPORT_SUCCESS,
  payload: vatSalesReport,
});

export const getVatSalesReportError = (error) => ({
  type: GET_VAT_SALES_REPORT_ERROR,
  payload: error,
});


export const exportVatSalesExcelReport = (queryParam) => ({
  type: EXPORT_VAT_SALES_EXCEL_REPORT,
  payload: { queryParam },
});

export const exportVatSalesExcelReportSuccess = (success, message) => ({
  type: EXPORT_VAT_SALES_EXCEL_REPORT_SUCCESS,
  payload: { success, message },
});

export const exportVatSalesExcelReportError = (error) => ({
  type: EXPORT_VAT_SALES_EXCEL_REPORT_ERROR,
  payload: error,
});


export const getAggregatedReport = (queryParam) => ({
  type: GET_AGGREGATED_REPORT,
  payload: { queryParam },
});

export const getAggregatedReportSuccess = (aggregatedReport) => ({
  type: GET_AGGREGATED_REPORT_SUCCESS,
  payload: aggregatedReport,
}); 

export const getAggregatedReportError = (error) => ({
  type: GET_AGGREGATED_REPORT_ERROR,
  payload: error,
});



