import React from "react";

const IconDarkMode = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={props.fill||"none"}
    >
      <path
        d="M21.4999 12.79C21.3426 14.4922 20.7038 16.1144 19.6581 17.4668C18.6125 18.8192 17.2034 19.8458 15.5956 20.4265C13.9878 21.0073 12.2479 21.1181 10.5794 20.7461C8.91092 20.3741 7.3829 19.5345 6.17413 18.3258C4.96536 17.117 4.12584 15.589 3.75381 13.9205C3.38178 12.252 3.49262 10.5121 4.07336 8.9043C4.65411 7.29651 5.68073 5.88737 7.03311 4.84175C8.3855 3.79614 10.0077 3.15731 11.7099 3C10.7133 4.34827 10.2338 6.00945 10.3584 7.68141C10.4831 9.35338 11.2038 10.9251 12.3893 12.1106C13.5748 13.2961 15.1465 14.0168 16.8185 14.1415C18.4905 14.2662 20.1516 13.7866 21.4999 12.79Z"
        stroke="#808089"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDarkMode;
