import * as Mui from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useTheme } from "../ThemeContext";
import {
  IconBuildingManagement,
  IconCanteen,
  IconConfiguration,
  IconDashboard,
  IconKiosk,
  IconOrder,
  IconOwner,
  IconProduct,
  IconReport,
  IconSiteSetting,
  IconTenant,
  IconUser,
} from "../components/svg";
import ToastElement from "../components/toast";
import { sideNavConfig, toolBarConfig } from "../configs/constant";
import { getOrganizationList } from "../reduxs/actions";
import Sidenav from "./sidenav";
import Topnav from "./topnav";
import SideNavTriggerCollapse from "./topnav/side-nav-trigger-collapse";

function Layout(props) {
  const { theme } = useTheme();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [drawerCollapse, setDrawerCollapse] = useState(false);
  const [drawerHover, setDrawerHover] = useState(false);
  const [menuList, setMenuList] = useState([]);



  const checkSmallDevices = useCallback(() => {
    if (Mui.isWidthUp("lg", props.width)) {
      setDrawerCollapse(sideNavConfig.collapseState.lg);
    } else if (Mui.isWidthUp("md", props.width)) {
      setDrawerCollapse(sideNavConfig.collapseState.md);
    } else {
      setDrawerCollapse(false);
    }
  }, [props.width]);

  useEffect(() => {
    checkSmallDevices();

    // Set Side Nav Layout Colors
    document.documentElement.style.setProperty(
      "--side-nav-bg",
      sideNavConfig.bgColor
    );
    document.documentElement.style.setProperty(
      "--side-nav-bg-dark",
      sideNavConfig.bgColorDark
    );
    document.documentElement.style.setProperty(
      "--side-nav-text-color",
      sideNavConfig.textColor
    );
    document.documentElement.style.setProperty(
      "--side-nav-text-color-dark",
      sideNavConfig.textColorDark
    );
    document.documentElement.style.setProperty(
      "--side-nav-icon-color-dark",
      sideNavConfig.iconColorDark
    );
    document.documentElement.style.setProperty(
      "--side-nav-icon-color",
      sideNavConfig.iconColor
    );
    document.documentElement.style.setProperty(
      "--side-nav-active-color-dark",
      sideNavConfig.activeColorDark
    );
    document.documentElement.style.setProperty(
      "--side-nav-active-color",
      sideNavConfig.activeColor
    );
    document.documentElement.style.setProperty(
      "--side-nav-active-bg-color",
      sideNavConfig.activeBgColor
    );
    document.documentElement.style.setProperty(
      "--side-nav-active-bg-color-dark",
      sideNavConfig.activeBgColorDark
    );
    document.documentElement.style.setProperty(
      "--side-nav-hover-submenu-color-dark",
      sideNavConfig.hoverSubmenubgDark
    );
    document.documentElement.style.setProperty(
      "--side-nav-hover-submenu-color",
      sideNavConfig.hoverSubmenubg
    );

    //for toolbar
    document.documentElement.style.setProperty(
      "--toolbar-bg",
      toolBarConfig.bgColor
    );
    document.documentElement.style.setProperty(
      "--toolbar-bg-dark",
      toolBarConfig.bgColorDark
    );
    document.documentElement.style.setProperty(
      "--toolbar-text-color",
      toolBarConfig.textColor
    );
    document.documentElement.style.setProperty(
      "--toolbar-text-color-dark",
      toolBarConfig.textColorDark
    );
    document.documentElement.style.setProperty(
      "--toolbar-icon-color",
      toolBarConfig.iconColor
    );
    document.documentElement.style.setProperty(
      "--toolbar-icon-color-dark",
      toolBarConfig.iconColorDark
    );

    // Set up event listeners or other side effects here
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    if (window.location.hostname === process.env.REACT_APP_API_BASE_URL) {
      // Organization Onboard Broadcast
      window.Echo.channel(
        `onboard-org-${currentUser?.orgId}-${currentUser?.id}`
      ).listen(".onboard_broadcast", (data) => {
        toast.success(
          <ToastElement type="success" message={data.message.message} />,
          { containerId: "default", position: "bottom-right", autoClose: 10000 }
        );
        // Fetch organization list
        props.getOrganizationList({});
      });
    }

    // Clean up any side effects in the return function if necessary
    return () => {
      // Clean up code
    };
  }, [checkSmallDevices, props]);

  const hanndelMobileDrawerOpenToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const hanndelDrawerCollapseToggle = () => {
    setDrawerCollapse(!drawerCollapse);
  };

  const onHoverDrawer = (hover) => {
    if (
      drawerCollapse &&
      sideNavConfig.expandOnHover &&
      !sideNavConfig.hoverSubMenu
    ) {
      if (hover === "enter") {
        setDrawerHover(true);
      } else if (hover === "leave") {
        setDrawerHover(false);
      }
    }
  };



  const matchChild = useCallback((section) => {
    if (section?.length > 0) {
      let match = section?.find(function (element) {
        return element.action.find((val) => val.key === "list")?.value === 1;
      });
      if (match?.key) {
        return matchPageUrl(match.key);
      } else {
        return "/";
      }
    } else {
      return "/";
    }
  }, []);

  const matchPageUrl = (pageName) => {
    switch (pageName) {
      case "user":
        return "/user-management/user";
      case "role":
        return "/user-management/role";
      case "product":
        return "/product-management/product";
      case "productCategory":
        return "/product-management/product-category";
      // case "order":
      //   return "/product-management/order";
      case "menu":
        return "/product-management/menu";
      case "organization":
        return "/organization";
      case "building":
        return "/canteen-management/building";
      case "canteen":
        return "/canteen-management/canteen";
      case "tenant":
        return "/canteen-management/tenant";
      case "kiosk":
        return "/canteen-management/pos";
      case "report":
        return "/report-management/reports";
      case "order":
        return "/report-management/order";
      case "setting":
        return "/setting";
      case "configChoice":
        return "/configuration/config-choice";
      case "configChoiceCategory":
        return "/configuration/config-choice-category";
      default:
        return "/dashboard";
    }
  };

  const matchPageIcon = (pageName) => {
    switch (pageName) {
      case "userManagement":
        return <IconUser />;
      case "productManagement":
        return <IconProduct />;
      case "orderManagement":
        return <IconOrder />;
      case "buildingManagement":
        return <IconBuildingManagement />;
      case "canteenManagement":
        return <IconCanteen />;
      case "tenantManagement":
        return <IconTenant />;
      case "kioskManagement":
        return <IconKiosk />;
      case "organizationManagement":
        return <IconOwner />;
      case "reportManagement":
        return <IconReport />;
      case "siteSetting":
        return <IconSiteSetting />;
      case "configuration":
        return <IconConfiguration />;
      default:
        return "";
    }
  };

  const constructMenu = useCallback(() => {
    const hideModule = [];
    const hideSubModule = ["tenantEmployee"];
    let menuIgniter = [];
    menuIgniter.push({
      key: "dashboard",
      title: "Dashboard",
      root: true,
      icon: <IconDashboard />,
      path: "/dashboard",
      translate: "COMMON.DASHBOARD",
    });
    props.permission?.forEach((module) => {
      if (hideModule?.includes(module?.key)) return
      let preparedMenu = {
        key: module.key,
        title: module.displayName,
        translate: "PERMISSIONS.modules." + module.key,
        root: true,
        icon: matchPageIcon(module.key),
        submenu: [],
        path: matchChild(module?.section || null),
        permitted: false,
      };
      module.section.forEach((section) => {
        if (hideSubModule.includes(section['key'])) return
        const sectionAllowed = section["action"].find(
          (act) => act.key === "list" && act.value
        )
          ? true
          : false;
        if (!preparedMenu.permitted) {
          preparedMenu["permitted"] = sectionAllowed;
        }
        if (sectionAllowed) {
          preparedMenu["submenu"].push({
            title: section.displayName,
            translate: "PERMISSIONS.sections." + section.key,
            icon: null,
            path: matchPageUrl(section.key),
          });
        }
      });
      if (preparedMenu.permitted) {
        menuIgniter.push(preparedMenu);
      }
    });
    setMenuList(menuIgniter);
  }, [matchChild, props.permission]);


  useEffect(() => {
    constructMenu();
  }, [constructMenu, props.permission]);

  return (
    <>
      <Mui.Drawer
        className={`cms-drawer ${theme}
        ${drawerCollapse ? "collapsed" : "expanded"} ${drawerHover ? "hover-expand" : ""
          }`}
        variant={Mui.isWidthUp("md", props.width) ? "permanent" : "temporary"}
        onMouseEnter={() => onHoverDrawer("enter")}
        onMouseLeave={() => onHoverDrawer("leave")}
        open={mobileDrawerOpen}
        onClose={hanndelMobileDrawerOpenToggle}
        anchor="left"
      >
        <SideNavTriggerCollapse
          toggleSideNav={hanndelDrawerCollapseToggle}
          drawerCollapse={drawerCollapse}
        />

        <Sidenav
          toggleSideNav={hanndelDrawerCollapseToggle}
          location={props.location}
          loading={props.loading}
          drawerCollapse={drawerCollapse}
          menuList={menuList}
          closeDrawer={hanndelMobileDrawerOpenToggle}
        />


      </Mui.Drawer>

      <main
        className={`cms-drawer-content ${theme} ${drawerCollapse ? "collapsed" : "expanded"
          }`}
      >
        <Topnav
          toggleSideNav={hanndelDrawerCollapseToggle}
          toggleMobileSideNav={hanndelMobileDrawerOpenToggle}
        />

        {toolBarConfig.style === "fixed" ? (
          <Mui.Toolbar className="placeholder-toolbar" />
        ) : null}

        <Mui.Container className="cms-body container-fluid" maxWidth={false}>
          {props.children}
        </Mui.Container>
      </main>
    </>
  );
}

const mapStateToProps = ({ shared }) => {
  const { permission, loading } = shared;
  return {
    permission,
    loading,
  };
};

export default Mui.withWidth()(
  connect(mapStateToProps, { getOrganizationList })(Layout)
);
