
import * as Mui from "@material-ui/core";
const useStyles = Mui.makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(0,0,0,.2), inset 0 -1px 0 rgba(0,0,0,.2)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#3f51b5',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
    },
});

// Inspired by blueprintjs
export default function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Mui.Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon} }`} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}
