import * as Mui from "@material-ui/core";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import AuthGraphicOne from "../../../assets/images/auth-graphics.png";
import { resendOtp, resetAuth, verifyOtp } from "../../../reduxs/actions";

import { IconLogo } from "../../../components/svg";
import LanguageDropdownAlt from "../../../layouts/topnav/language-dropdown-alt";
class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      loginData: {
        email: this.props.location?.state?.loginData?.email || "",
        password: this.props.location?.state?.loginData?.password || "",
      },
    };
    this.props.resetAuth();
  }

  onVerify = () => {
    if (!this.props.loading && this.state.otp) {
      this.props.verifyOtp(
        {
          otp: this.state.otp,
        },
        this.props.history
      );
    }
  };

  onResend = () => {
    if (!this.props.loading1) {
      this.props.resendOtp(this.state.loginData);
    }
  };

  handleChange = (otp) => this.setState({ otp });

  render() {
    const { t } = this.props;
    return (
      <Mui.Grid container justifyContent="space-between" className="auth-wrap">
        <Mui.Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={6}
          className="auth-message-box p-5"
        >
          <Mui.Box>
            <Mui.Hidden xsDown>
              <Mui.Typography
                component="h2"
                variant="h2"
                className="title mb-3 font-weight-medium mt-5"
              >
                {t("AUTH.OTP.ACCOUNT_SECURITY")}
              </Mui.Typography>

              <Mui.Typography
                component="h3"
                variant="h3"
                className="font-weight-normal"
              >
                {t("AUTH.OTP.INFO")}
              </Mui.Typography>
            </Mui.Hidden>
          </Mui.Box>
          <Mui.Box className="auth-graphic-box">
            <img style={{ width: "100%" }} src={AuthGraphicOne} alt="" />
          </Mui.Box>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={5} className="auth-form-box">
          <Mui.Card className="card-box">
            <Mui.Box className="logo-icon">
              <LanguageDropdownAlt />
              <IconLogo />
            </Mui.Box>

            <div className="default-form">
              <Mui.Typography
                className="card-box__head"
                component="span"
                variant="body1"
              >
                {t("AUTH.OTP.TITLE")}
                <Mui.Typography
                  component="span"
                  variant="body1"
                  className="d-block mt-1 font-weight-normal text-color-grey"
                >
                  {t("AUTH.OTP.SUBTITLE")}
                </Mui.Typography>
              </Mui.Typography>
              <div className="otp-ui">
                <OtpInput
                  className="otp-ui__field"
                  value={this.state.otp}
                  onChange={this.handleChange}
                  numInputs={6}
                  //   separator={<span>-</span>}
                  containerStyle={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  errorStyle={{}}
                  isInputNum={true}
                  shouldAutoFocus={true}
                />

                {this.props.error ? (
                  <Mui.Typography
                    className="d-block mt-2 text-center text-color-danger"
                    variant="body1"
                    component="span"
                  >
                    {this.props.error}
                  </Mui.Typography>
                ) : null}

                <Mui.Typography
                  className="mt-3 font-weight-normal text-color-grey mb-1 text-center"
                  variant="h6"
                  component="h6"
                >
                  {t("AUTH.OTP.NOT_RECEIVE_OTP")}
                </Mui.Typography>

                <Mui.Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={3}
                >
                  <Mui.Typography
                    className="font-weight-normal text-color-primary pointer text-decoration-underline"
                    variant="h6"
                    component="h6"
                    onClick={this.onResend}
                  >
                    {t("AUTH.OTP.RESEND_CODE")}
                  </Mui.Typography>
                  {this.props.loading1 && (
                    <Mui.CircularProgress color="secondary" size={20} />
                  )}
                </Mui.Box>
              </div>

              <Mui.Button
                className="btn-default"
                type="button"
                variant="contained"
                color="primary"
                disableElevation
                onClick={this.onVerify}
                disabled={this.props.loading}
                data-cy="submit-button"
              >
                {t("AUTH.GENERAL.SUBMIT_BUTTON")}
                {this.props.loading && (
                  <Mui.CircularProgress color="secondary" size={24} />
                )}
              </Mui.Button>
            </div>
            <Mui.Box className="form-graphic-box">
              <img style={{ width: "100%" }} src={AuthGraphicOne} alt="" />
            </Mui.Box>
          </Mui.Card>
        </Mui.Grid>
      </Mui.Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading1, loading, error, success, message } = auth;
  return { loading1, loading, error, success, message };
};
export default connect(mapStateToProps, { verifyOtp, resendOtp, resetAuth })(
  withTranslation()(Otp)
);
