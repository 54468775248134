import React, { Component } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../configs/core";
import VerifyView from "./verify";
import LoginView from "./login";
import OtpView from "./otp";
import ForgotPasswordView from "./forgot-password";
import ResetPasswordView from "./reset-password";
import InitialChangePasswordView from "./initial-change-password";
import VerifyEmailView from "./verify-email";

class Auth extends Component {
  render() {
    return (
      <Switch>
        <ContentRoute path="/auth/verify/:token" component={VerifyView} />
        <ContentRoute path="/auth/login" component={LoginView} />
        <ContentRoute path="/auth/otp" component={OtpView} />
        <ContentRoute
          path="/auth/forgot-password"
          component={ForgotPasswordView}
        />
        <ContentRoute
          path="/auth/reset-password/:token"
          component={ResetPasswordView}
        />
        <ContentRoute
          path="/auth/initial-change-password"
          component={InitialChangePasswordView}
        />
        <ContentRoute
          path="/auth/verify-email/:token"
          component={VerifyEmailView}
        />
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect to="/auth/login" />
      </Switch>
    );
  }
}

export default Auth;
