import { toast } from "react-toastify";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../components/toast";
import { parseMessage } from "../../helpers/util";
import KioskService from "../../services/kiosk-service";
import TableDataService from "../../services/table-data-service";
import {
  ADD_KIOSK,
  DELETE_KIOSK,
  DELETE_MULTIPLE_KIOSK,
  EDIT_KIOSK,
  GET_ALL_KIOSK,
  GET_KIOSK,
  GET_KIOSK_LIST,
  GET_KIOSK_LOG,
} from "../actions";
import {
  addKioskError,
  addKioskSuccess,
  deleteKioskError,
  deleteKioskSuccess,
  deleteMultipleKioskError,
  deleteMultipleKioskSuccess,
  editKioskError,
  editKioskSuccess,
  GET_NETS_TERMINALS,
  GET_TERMINAL_STATUS,
  getAllKioskError,
  getAllKioskSuccess,
  getKioskError,
  getKioskList,
  getKioskListError,
  getKioskListSuccess,
  getKioskLogError,
  getKioskLogSuccess,
  getKioskSuccess,
  getNetsTerminalsSuccess,
  getTerminalStatusError,
  getTerminalStatusSuccess,
  NETS_TERMINAL_RECONCILIATION,
  netsTerminalReconciliationError,
  netsTerminalReconciliationSuccess,
} from "./action";

export function* watchGetAllKiosk() {
  yield takeEvery(GET_ALL_KIOSK, getAllKiosk);
}

const getAllKioskAsync = async () => {
  return KioskService.getAllKiosk();
};

function* getAllKiosk() {
  try {
    const response = yield call(getAllKioskAsync);
    if (response.data.success) {
      yield put(getAllKioskSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getAllKioskError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getAllKioskError(error.response.data.message));
  }
}

export function* watchGetKioskList() {
  yield takeEvery(GET_KIOSK_LIST, getKioskListAc);
}

const getKioskListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "kiosks",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null,
    dbParam?.buildingId || null,
    dbParam?.canteenId || null
  );
};

function* getKioskListAc({ payload }) {
  try {
    const response = yield call(getKioskListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getKioskListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getKioskListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getKioskListError(error.response.data.message));
  }
}

export function* watchAddKiosk() {
  yield takeEvery(ADD_KIOSK, addKiosk);
}

const addKioskAsync = async (data) => {
  return KioskService.addKiosk(data);
};

function* addKiosk({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(addKioskAsync, payload.kioskData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addKioskSuccess(response.data.success, response.data.message));
      history.push(`/canteen-management/pos${location?.state?.locationSearch ?? ''}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addKioskError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(addKioskError(error.response.data.message));
  }
}

export function* watchGetKiosk() {
  yield takeEvery(GET_KIOSK, getKiosk);
}

const getKioskAsync = async (id, organizationId) => {
  return KioskService.getKiosk(id, organizationId);
};

function* getKiosk({ payload }) {
  try {
    const response = yield call(
      getKioskAsync,
      payload.kioskId,
      payload.organizationId
    );
    if (response.data.success) {
      yield put(getKioskSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getKioskError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getKioskError(error.response.data.message));
  }
}

export function* watchGetKioskLog() {
  yield takeEvery(GET_KIOSK_LOG, getKioskLog);
}

const getKioskLogAsync = async (id, param) => {
  return KioskService.getKioskLog(id, param);
};

function* getKioskLog({ payload }) {
  try {
    const response = yield call(
      getKioskLogAsync,
      payload.kioskId,
      payload.logParam
    );
    if (response.data.success) {
      yield put(getKioskLogSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getKioskLogError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getKioskLogError(error.response.data.message));
  }
}

export function* watchEditKiosk() {
  yield takeEvery(EDIT_KIOSK, editKiosk);
}

const editKioskAsync = async (data, id) => {
  return KioskService.editKiosk(data, id);
};

function* editKiosk({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(
      editKioskAsync,
      payload.kioskData,
      payload.kioskId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editKioskSuccess(response.data.success, response.data.message));
      history.push(`/canteen-management/pos${location?.state?.locationSearch ?? ''}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editKioskError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(editKioskError(error.response.data.message));
  }
}

export function* watchDeleteKiosk() {
  yield takeEvery(DELETE_KIOSK, deleteKiosk);
}

const deleteKioskAsync = async (id) => {
  return KioskService.deleteKiosk(id);
};

function* deleteKiosk({ payload }) {
  try {
    const response = yield call(deleteKioskAsync, payload.kioskId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteKioskSuccess(response.data.success, response.data.message)
      );
      // Fetch updated kiosk list
      yield put(getKioskList({}));
    } else {
      yield put(deleteKioskError(response.data.message));
    }
  } catch (error) {
    yield put(deleteKioskError(error.response.data.message));
  }
}

export function* watchDeleteMultipleKiosk() {
  yield takeEvery(DELETE_MULTIPLE_KIOSK, deleteMultipleKiosk);
}

const deleteMultipleKioskAsync = async (ids) => {
  return KioskService.deleteMultipleKiosk(ids);
};

function* deleteMultipleKiosk({ payload }) {
  try {
    const response = yield call(deleteMultipleKioskAsync, payload.kioskIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteMultipleKioskSuccess(response.data.success, response.data.message)
      );
      // Fetch updated user list
      yield put(getKioskList({}));
    } else {
      yield put(deleteMultipleKioskError(response.data.message));
    }
  } catch (error) {
    yield put(deleteMultipleKioskError(error.response.data.message));
  }
}

export function* watchGetNetsTerminals() {
  yield takeEvery(GET_NETS_TERMINALS, getNetsTerminals);
}

const getNetsTerminalsAsync = async () => {
  return KioskService.getNetsTerminals();
}

function* getNetsTerminals() {
  try {
    const response = yield call(getNetsTerminalsAsync);
    if (response.data.success) {
      yield put(getNetsTerminalsSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
  }
}


export function* watchNetsTerminalReconciliation() {
  yield takeEvery(NETS_TERMINAL_RECONCILIATION, netsTerminalReconciliation);
}

const netsTerminalReconciliationAsync = async (payload) => {
  return KioskService.netsTerminalReconciliation(payload.kioskId, payload.terminalId);
}

function* netsTerminalReconciliation({ payload }) {
  try {
    const response = yield call(netsTerminalReconciliationAsync, payload);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(netsTerminalReconciliationSuccess(response?.data?.meta?.reconcile_date ?? '-'));
    } else {
      yield put(netsTerminalReconciliationError(response.data.message));
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
    }
  } catch (error) {
    yield put(netsTerminalReconciliationError(error.response.data.message));
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
  }
}


export function* watchGetTerminalStatus() {
  yield takeEvery(GET_TERMINAL_STATUS, getTerminalStatus);
}

const getTerminalStatusAsync = async (kioskUuid) => {
  return KioskService.getTerminalStatus(kioskUuid);
}

function* getTerminalStatus({ payload }) {
  try {
    const response = yield call(getTerminalStatusAsync, payload?.kioskUuid);
    if (response.data.success) {
      yield put(getTerminalStatusSuccess(true));
    } else {
      yield put(getTerminalStatusError(false));
      // toast.error(
      //   <ToastElement type="error" message={response.data.message} />,
      //   { containerId: "default", position: "bottom-right" }
      // );
    }
  } catch (error) {
    yield put(getTerminalStatusError(false));
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default", position: "bottom-right" }
    // );
  }
}



export default function* rootSaga() {
  yield all([
    fork(watchGetAllKiosk),
    fork(watchGetKioskList),
    fork(watchAddKiosk),
    fork(watchGetKiosk),
    fork(watchGetKioskLog),
    fork(watchEditKiosk),
    fork(watchDeleteKiosk),
    fork(watchDeleteMultipleKiosk),
    fork(watchGetNetsTerminals),
    fork(watchNetsTerminalReconciliation),
    fork(watchGetTerminalStatus),
  ]);
}
