import {
  ADD_KIOSK,
  ADD_KIOSK_ERROR,
  ADD_KIOSK_SUCCESS,
  DELETE_KIOSK,
  DELETE_KIOSK_ERROR,
  DELETE_KIOSK_SUCCESS,
  DELETE_MULTIPLE_KIOSK,
  DELETE_MULTIPLE_KIOSK_ERROR,
  DELETE_MULTIPLE_KIOSK_SUCCESS,
  EDIT_KIOSK,
  EDIT_KIOSK_ERROR,
  EDIT_KIOSK_SUCCESS,
  GET_ALL_KIOSK,
  GET_ALL_KIOSK_ERROR,
  GET_ALL_KIOSK_SUCCESS,
  GET_KIOSK,
  GET_KIOSK_ERROR,
  GET_KIOSK_LIST,
  GET_KIOSK_LIST_ERROR,
  GET_KIOSK_LIST_SUCCESS,
  GET_KIOSK_LOG,
  GET_KIOSK_LOG_ERROR,
  GET_KIOSK_LOG_SUCCESS,
  GET_KIOSK_SUCCESS,
  GET_NETS_TERMINALS,
  GET_NETS_TERMINALS_ERROR,
  GET_NETS_TERMINALS_SUCCESS,
  GET_TERMINAL_STATUS,
  GET_TERMINAL_STATUS_ERROR,
  GET_TERMINAL_STATUS_SUCCESS,
  NETS_TERMINAL_RECONCILIATION,
  NETS_TERMINAL_RECONCILIATION_ERROR,
  NETS_TERMINAL_RECONCILIATION_SUCCESS,
  RESET_KIOSK
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  logParam: null,
  kiosks: null,
  kioskList: null,
  kioskData: null,
  kioskLogData: null,
  kioskId: null,
  organizationId: null,
  kioskIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  error: null,
  netsTerminals: null,
  isTerminalActive: false
};

const kioskReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_KIOSK:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_KIOSK_SUCCESS:
      return {
        ...state,
        kiosks: action.payload,
        error: null,
      };
    case GET_ALL_KIOSK_ERROR:
      return {
        ...state,
        kiosks: null,
        error: action.payload,
      };
    case GET_KIOSK_LIST:
      return {
        ...state,
        loading: true,
        kioskId: null,
        kioskIds: null,
        kioskData: null,
        error: null,
      };
    case GET_KIOSK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        kioskList: action.payload,
        error: null,
      };
    case GET_KIOSK_LIST_ERROR:
      return {
        ...state,
        loading: false,
        kioskList: null,
        error: action.payload,
      };
    case ADD_KIOSK:
      return { ...state, loading: true, error: null };
    case ADD_KIOSK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_KIOSK_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_KIOSK:
      return { ...state, error: null };
    case GET_KIOSK_SUCCESS:
      return {
        ...state,
        kioskData: action.payload,
        error: null,
      };
    case GET_KIOSK_ERROR:
      return {
        ...state,
        kioskData: null,
        error: action.payload,
      };
    case GET_KIOSK_LOG:
      return { ...state, error: null };
    case GET_KIOSK_LOG_SUCCESS:
      return {
        ...state,
        kioskLogData: action.payload,
        error: null,
      };
    case GET_KIOSK_LOG_ERROR:
      return {
        ...state,
        kioskLogData: null,
        error: action.payload,
      };
    case EDIT_KIOSK:
      return { ...state, loading: true, error: null };
    case EDIT_KIOSK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_KIOSK_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_KIOSK:
      return { ...state, loading1: true, error: null };
    case DELETE_KIOSK_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_KIOSK_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_KIOSK:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_KIOSK_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_KIOSK_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_KIOSK:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };

    case GET_NETS_TERMINALS:
      return {
        ...state,
        loading1: true,
        error: null,
      };

    case GET_NETS_TERMINALS_SUCCESS:
      return {
        ...state,
        loading1: false,
        netsTerminals: action.payload,
        error: null,
      };

    case GET_NETS_TERMINALS_ERROR:
      return {
        ...state,
        loading1: false,
        // netsTerminals: null,
        error: action.payload,
      };


    case NETS_TERMINAL_RECONCILIATION:
      return {
        ...state,
        loading1: true,
        error: null,
      };

    case NETS_TERMINAL_RECONCILIATION_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: true,
        error: null,
        kioskData: { ...state.kioskData, lastReconciledDate: action.payload }
      };

    case NETS_TERMINAL_RECONCILIATION_ERROR:
      return {
        ...state,
        loading1: false,
        error: action.payload,
      };

    case GET_TERMINAL_STATUS:
      return {
        ...state,
        loading2: true,
        error: null,
      };

    case GET_TERMINAL_STATUS_SUCCESS:
      return {
        ...state,
        loading2: false,
        isTerminalActive: action.payload,
        error: null,
      };

    case GET_TERMINAL_STATUS_ERROR:
      return {
        ...state,
        loading2: false,
        isTerminalActive: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};
export default kioskReducer;
