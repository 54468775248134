import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { IconCheck, IconChevronDown } from "../svg";
import { withTranslation } from "react-i18next";

class GroupFilter extends Component {
  render() {
    const { t } = this.props;

    const renderSelectGroup = (data) => {
      const items = data?.kiosk?.map((item) => {
        return (
          <Mui.MenuItem key={item.id} value={item.id}>
            {item.name}
            <span className="icon-selected">
              <IconCheck />
            </span>
          </Mui.MenuItem>
        );
      });
      return [
        <Mui.ListSubheader style={{ pointerEvents: "none" }}>
          {data.name}
        </Mui.ListSubheader>,
        items,
      ];
    };
    return (
      <Mui.FormControl
        variant="outlined"
        className={`default-form-field tbl-filter-field ${
          Mui.isWidthDown("xs", this.props.width) ? "mb-2" : "ml-2"
        }`}
      >
        <Mui.TextField
          select
          variant="outlined"
          InputProps={
            this.props.label
              ? {
                  startAdornment: (
                    <Mui.InputAdornment>{this.props.label}:</Mui.InputAdornment>
                  ),
                }
              : null
          }
          onChange={this.props.onFilter}
          value={
            this.props.value
              ? this.props.value
              : this.props.displayEmpty
              ? ["all"]
              : []
          }
          SelectProps={{
            multiple: true,
            renderValue: (selected) => (
              <>
                {selected?.map((value, index) => (
                  <span key={index}>
                    {this.props.displayEmpty
                      ? value === "all"
                        ? t("COMMON.ALL")
                        : this.props.kiosks?.find((x) => x.id === value)?.[
                            "name"
                          ]
                      : this.props.kiosks?.find((x) => x.id === value)?.[
                          "name"
                        ]}
                    {index + 1 < selected?.length ? ", " : ""}
                  </span>
                ))}
              </>
            ),
            MenuProps: {
              className: "select-dropdown",
              getContentAnchorEl: null,

              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              classes: this.props.classes || {},
            },
            IconComponent: IconChevronDown,
          }}
        >
          {this.props.displayEmpty ? (
            <Mui.MenuItem disabled value="all">
              {t("COMMON.ALL")}
            </Mui.MenuItem>
          ) : null}

          {this.props.options?.map((option) => renderSelectGroup(option))}
        </Mui.TextField>
      </Mui.FormControl>
    );
  }
}
export default withTranslation()(Mui.withWidth()(GroupFilter));
