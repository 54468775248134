import { ADD_PRODUCT_CATEGORY_ITEM, ADD_PRODUCT_CATEGORY_ITEM_ERROR, ADD_PRODUCT_CATEGORY_ITEM_SUCCESS, DELETE_PRODUCT_CATEGORY_ITEM, DELETE_PRODUCT_CATEGORY_ITEM_ERROR, DELETE_PRODUCT_CATEGORY_ITEM_SUCCESS, EDIT_PRODUCT_CATEGORY_ITEM, EDIT_PRODUCT_CATEGORY_ITEM_ERROR, EDIT_PRODUCT_CATEGORY_ITEM_SUCCESS, GET_ALL_PRODUCT_CATEGORY, GET_ALL_PRODUCT_CATEGORY_ERROR, GET_ALL_PRODUCT_CATEGORY_SUCCESS, GET_PRODUCT_CATEGORY_ITEM, GET_PRODUCT_CATEGORY_ITEM_ERROR, GET_PRODUCT_CATEGORY_ITEM_SUCCESS, GET_PRODUCT_CATEGORY_LIST, GET_PRODUCT_CATEGORY_LIST_ERROR, GET_PRODUCT_CATEGORY_LIST_SUCCESS, RESET_PRODUCT_CATEGORY_ITEM } from "./action";


const INIT_STATE = {
    dbParam: null,
    param: null,
    productCategories: null,
    productCategoryList: null,
    productCategory: null,
    success: false,
    message: null,
    loading: false,
    loading1: false,
    error: null,
};


const productCategoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCT_CATEGORY_LIST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_PRODUCT_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                productCategoryList: action.payload,
                error: null,
            };
        case GET_PRODUCT_CATEGORY_LIST_ERROR:
            return {
                ...state,
                loading: false,
                productCategoryList: null,
                error: action.payload,
            };
        case GET_PRODUCT_CATEGORY_ITEM:
            return { ...state, error: null, loading: true };
        case GET_PRODUCT_CATEGORY_ITEM_SUCCESS:
            return {
                ...state,
                productCategory: action.payload,
                loading: false,
                error: null,
            };
        case GET_PRODUCT_CATEGORY_ITEM_ERROR:
            return {
                ...state,
                productCategory: null,
                loading: false,
                error: action.payload,
            };


        case ADD_PRODUCT_CATEGORY_ITEM:
            return { ...state, loading1: true, error: null };
        case ADD_PRODUCT_CATEGORY_ITEM_SUCCESS:
            return {
                ...state,
                loading1: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case ADD_PRODUCT_CATEGORY_ITEM_ERROR:
            return {
                ...state,
                loading1: false,
                success: false,
                message: null,
                error: action.payload,
            };

        case EDIT_PRODUCT_CATEGORY_ITEM:
            return { ...state, loading1: true, error: null };
        case EDIT_PRODUCT_CATEGORY_ITEM_SUCCESS:
            return {
                ...state,
                loading1: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case EDIT_PRODUCT_CATEGORY_ITEM_ERROR:
            return {
                ...state,
                loading1: false,
                success: false,
                message: null,
                error: action.payload,
            };
        case DELETE_PRODUCT_CATEGORY_ITEM:
            return { ...state, loading1: true, error: null };
        case DELETE_PRODUCT_CATEGORY_ITEM_SUCCESS:
            return {
                ...state,
                loading1: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case DELETE_PRODUCT_CATEGORY_ITEM_ERROR:
            return {
                ...state,
                loading1: false,
                success: false,
                message: null,
                error: action.payload,
            };
        case GET_ALL_PRODUCT_CATEGORY:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_ALL_PRODUCT_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                productCategories: action.payload,
                error: null,
            };
        case GET_ALL_PRODUCT_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                productCategories: null,
                error: action.payload,
            };
        case RESET_PRODUCT_CATEGORY_ITEM:
            return {
                ...state,
                loading: false,
                loading1: false,
                success: false,
                message: null,
                error: null,
            };
        default:
            return { ...state };
    }

}

export default productCategoryReducer