
export const restrictSubmenu = [
    {
        module: 'productManagement',
        submenu: [
            'menu'
        ]
    },
    {
        module: 'canteenManagement',
        submenu: [
            // 'tenantEmployee'
        ]
    }
]