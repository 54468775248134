export const VERIFY_TENANT_TOKEN = "VERIFY_TENANT_TOKEN";
export const VERIFY_TENANT_TOKEN_SUCCESS = "VERIFY_TENANT_TOKEN_SUCCESS";
export const VERIFY_TENANT_TOKEN_ERROR = "VERIFY_TENANT_TOKEN_ERROR";

export const ACCEPT_TENANT_TERMS = "ACCEPT_TENANT_TERMS";
export const ACCEPT_TENANT_TERMS_SUCCESS = "ACCEPT_TENANT_TERMS_SUCCESS";
export const ACCEPT_TENANT_TERMS_ERROR = "ACCEPT_TENANT_TERMS_ERROR";

export const DOWNLOAD_TENANT_QR = "DOWNLOAD_TENANT_QR";
export const DOWNLOAD_TENANT_QR_SUCCESS = "DOWNLOAD_TENANT_QR_SUCCESS";
export const DOWNLOAD_TENANT_QR_ERROR = "DOWNLOAD_TENANT_QR_ERROR";

export const SEND_QR_TO_EMAIL = "SEND_QR_TO_EMAIL";
export const SEND_QR_TO_EMAIL_SUCCESS = "SEND_QR_TO_EMAIL_SUCCESS";
export const SEND_QR_TO_EMAIL_ERROR = "SEND_QR_TO_EMAIL_ERROR";


export const verifyTenantToken = (token) => ({
    type: VERIFY_TENANT_TOKEN,
    payload: { token },
});

export const verifyTenantTokenSuccess = (data) => ({
    type: VERIFY_TENANT_TOKEN_SUCCESS,
    payload: data,
});

export const verifyTenantTokenError = (error) => ({
    type: VERIFY_TENANT_TOKEN_ERROR,
    payload: error,
});

export const acceptTenantTerms = (token) => ({
    type: ACCEPT_TENANT_TERMS,
    payload: { token },
});

export const acceptTenantTermsSuccess = (data) => ({
    type: ACCEPT_TENANT_TERMS_SUCCESS,
    payload: data,
});

export const acceptTenantTermsError = (error) => ({
    type: ACCEPT_TENANT_TERMS_ERROR,
    payload: error,
});


export const downloadTenantQr = (token) => ({
    type: DOWNLOAD_TENANT_QR,
    payload: { token },
});

export const downloadTenantQrSuccess = (success, message) => ({
    type: DOWNLOAD_TENANT_QR_SUCCESS,
    payload: { success, message },
});

export const downloadTenantQrError = (error) => ({
    type: DOWNLOAD_TENANT_QR_ERROR,
    payload: error,
});

export const sendQRToEmail = (token) => ({
    type: SEND_QR_TO_EMAIL,
    payload: { token },
});

export const sendQRToEmailSuccess = (success, message) => ({
    type: SEND_QR_TO_EMAIL_SUCCESS,
    payload: { success, message },
});

export const sendQRToEmailError = (error) => ({
    type: SEND_QR_TO_EMAIL_ERROR,
    payload: error,
});