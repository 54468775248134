import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import TenantService from "../../services/tenant-service";
import TableDataService from "../../services/table-data-service";
import {
  GET_ALL_TENANT,
  GET_TENANT_LIST,
  ADD_TENANT,
  GET_TENANT,
  EDIT_TENANT,
  DELETE_TENANT,
  DELETE_MULTIPLE_TENANT,
} from "../actions";
import {
  getAllTenantSuccess,
  getAllTenantError,
  getTenantList,
  getTenantListSuccess,
  getTenantListError,
  addTenantSuccess,
  addTenantError,
  getTenantSuccess,
  getTenantError,
  editTenantSuccess,
  editTenantError,
  deleteTenantSuccess,
  deleteTenantError,
  deleteMultipleTenantSuccess,
  deleteMultipleTenantError,
  UPDATE_SUBSIDY_PRICES,
  updateSubsidyPricesSuccess,
  updateSubsidyPricesError,
} from "./action";
import { toast } from "react-toastify";
import { parseMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";

export function* watchGetAllTenant() {
  yield takeEvery(GET_ALL_TENANT, getAllTenant);
}

const getAllTenantAsync = async () => {
  return TenantService.getAllTenant();
};

function* getAllTenant() {
  try {
    const response = yield call(getAllTenantAsync);
    if (response.data) {
      yield put(getAllTenantSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getAllTenantError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getAllTenantError(error.response.data.message));
  }
}

export function* watchGetTenantList() {
  yield takeEvery(GET_TENANT_LIST, getTenantListAc);
}

const getTenantListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "tenants",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null,
    dbParam?.buildingId || null,
    dbParam?.canteenId || null
  );
};

function* getTenantListAc({ payload }) {
  try {
    const response = yield call(getTenantListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getTenantListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getTenantListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getTenantListError(error.response.data.message));
  }
}

export function* watchAddTenant() {
  yield takeEvery(ADD_TENANT, addTenant);
}

const addTenantAsync = async (data) => {
  return TenantService.addTenant(data);
};

function* addTenant({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(addTenantAsync, payload.tenantData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addTenantSuccess(response.data.success, response.data.message));
      history.push(`/canteen-management/tenant${location?.state?.locationSearch ?? ""}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addTenantError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      {
        containerId: "default", position: "bottom-right",
      }
    );
    yield put(addTenantError(error.response.data.message));
  }
}

export function* watchGetTenant() {
  yield takeEvery(GET_TENANT, getTenant);
}

const getTenantAsync = async (id, organizationId) => {
  return TenantService.getTenant(id, organizationId);
};

function* getTenant({ payload }) {
  try {
    const response = yield call(
      getTenantAsync,
      payload.tenantId,
      payload.organizationId
    );
    if (response.data.success) {
      yield put(getTenantSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getTenantError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getTenantError(error.response.data.message));
  }
}

export function* watchEditTenant() {
  yield takeEvery(EDIT_TENANT, editTenant);
}

const editTenantAsync = async (data, id) => {
  return TenantService.editTenant(data, id);
};

function* editTenant({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(
      editTenantAsync,
      payload.tenantData,
      payload.tenantId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        editTenantSuccess(response.data.success, response.data.message)
      );
      history.push(`/canteen-management/tenant${location?.state?.locationSearch ?? ""}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editTenantError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      {
        containerId: "default", position: "bottom-right",
      }
    );
    yield put(editTenantError(error.response.data.message));
  }
}

export function* watchDeleteTenant() {
  yield takeEvery(DELETE_TENANT, deleteTenant);
}

const deleteTenantAsync = async (id) => {
  return TenantService.deleteTenant(id);
};

function* deleteTenant({ payload }) {
  try {
    const response = yield call(deleteTenantAsync, payload.tenantId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteTenantSuccess(response.data.success, response.data.message)
      );
      // Fetch updated user list
      yield put(getTenantList({}));
    } else {
      yield put(deleteTenantError(response.data.message));
    }
  } catch (error) {
    yield put(deleteTenantError(error.response.data.message));
  }
}

export function* watchDeleteMultipleTenant() {
  yield takeEvery(DELETE_MULTIPLE_TENANT, deleteMultipleTenant);
}

const deleteMultipleTenantAsync = async (ids) => {
  return TenantService.deleteMultipleTenant(ids);
};

function* deleteMultipleTenant({ payload }) {
  try {
    const response = yield call(deleteMultipleTenantAsync, payload.tenantIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteMultipleTenantSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated user list
      yield put(getTenantList({}));
    } else {
      yield put(deleteMultipleTenantError(response.data.message));
    }
  } catch (error) {
    yield put(deleteMultipleTenantError(error.response.data.message));
  }
}


export function* watchUpdateSubsidyPrices() {
  yield takeEvery(UPDATE_SUBSIDY_PRICES, updateSubsidyPrices);
}

const updateSubsidyPricesAsync = async (data) => {
  return TenantService.updateSubsidyPrices(data);
};

function* updateSubsidyPrices({ payload }) {

  try {
    const response = yield call(
      updateSubsidyPricesAsync,
      payload.subsidyData,
    );
    if (response.data.success) {
      payload?.callback()
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        updateSubsidyPricesSuccess(response.data.success, response.data.message)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(updateSubsidyPricesError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      {
        containerId: "default", position: "bottom-right",
      }
    );
    yield put(updateSubsidyPricesError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllTenant),
    fork(watchGetTenantList),
    fork(watchAddTenant),
    fork(watchGetTenant),
    fork(watchEditTenant),
    fork(watchDeleteTenant),
    fork(watchDeleteMultipleTenant),
    fork(watchUpdateSubsidyPrices)
  ]);
}
