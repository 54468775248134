import DateFnsUtils from "@date-io/date-fns";
import * as Mui from '@material-ui/core';
import { TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useRef } from "react";
import { IconCalendar } from "../svg";
import { useTheme } from "../../ThemeContext";
import { setDefaultOptions } from "date-fns";
setDefaultOptions({ weekStartsOn: 1 })

function Datepicker(props) {
  const { theme } = useTheme();
  const keyboardButtonRef = useRef(null);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={"date-time-picker " + props.className}
        disabled={props.disabled || false}
        autoOk
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="dd.MM.yyyy"
        margin="normal"
        emptyLabel=""
        invalidDateMessage=""
        invalidLabel=""
        maxDateMessage=""
        minDateMessage=""
        TextFieldComponent={(p) => {
          return props.tooltip ? (
            <Mui.Tooltip title={props.tooltip}>
              <TextField {...p} />
            </Mui.Tooltip>
          ) :
            <TextField
              {...p}
              onClick={(e) => {
                if (keyboardButtonRef.current) {
                  keyboardButtonRef.current?.click()
                }
              }}
            />
        }}
        InputProps={{
          classes: { input: "text-color-default" },
          readOnly: true,
        }}
        inputProps={props.inputProps}
        minDate={
          props.minDate
            ? new Date(props.minDate)
            : new Date("1900-01-01")
        }
        maxDate={
          props.maxDate
            ? new Date(props.maxDate)
            : new Date("2100-01-01")
        }
        placeholder={props.label || null}
        value={props.value || null}
        onChange={props.onDateChange}
        KeyboardButtonProps={{
          ref: keyboardButtonRef,
          "aria-label": "change date",
        }}
        keyboardIcon={<IconCalendar fill={theme === "dark" ? "#FFFFFF" : "#000000"} />}
        helperText={props.touched && props.error ? props.error : null}
        error={props.touched && props.error ? true : false}
      />
    </MuiPickersUtilsProvider>
  );

}

export default Datepicker;
