const IconExpired = () => {

    return <svg width="188" height="98" viewBox="0 0 188 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M171.96 58.3201C172.26 56.6501 172.42 54.9301 172.42 53.1701C172.42 37.1101 159.39 24.0801 143.33 24.0801C139.21 24.0801 135.28 24.9401 131.72 26.5001V56.5501C131.72 57.1701 131.38 57.7401 130.84 58.0401C130.58 58.1901 130.29 58.2601 130 58.2601C129.71 58.2601 129.42 58.1901 129.16 58.0401L122.68 54.3901C120.85 53.3601 118.62 53.3601 116.79 54.3901L112.41 56.8601C110.58 57.8901 108.35 57.8901 106.52 56.8601L102.14 54.3901C100.32 53.3601 98.0802 53.3601 96.2502 54.3901L91.8802 56.8601C90.0502 57.8901 87.8102 57.8901 85.9802 56.8601L81.6102 54.3901C79.7802 53.3601 77.5402 53.3601 75.7102 54.3901L69.2102 58.0501C68.7002 58.3401 68.0802 58.3401 67.5702 58.0501C67.0502 57.7601 66.7202 57.2001 66.7202 56.6001V14.0801C60.7702 20.6801 57.1302 29.3901 57.0602 38.9401C26.2902 41.3501 1.88018 66.3801 0.430176 97.4001H186.65C187.25 94.7801 187.57 92.0901 187.57 89.3401C187.57 77.4601 181.68 66.6101 171.96 58.3201ZM131.72 81.1501C131.72 84.6601 128.87 87.5001 125.37 87.5001H73.0702C69.5602 87.5001 66.7202 84.6601 66.7202 81.1501V72.6701C66.7202 70.5301 67.8602 68.5601 69.7102 67.4801L75.3502 64.2001C77.1902 63.1301 79.4502 63.1201 81.3102 64.1601L85.6402 66.6101C87.4702 67.6401 89.7002 67.6401 91.5302 66.6101L95.9102 64.1401C97.7402 63.1101 99.9702 63.1101 101.8 64.1401L106.18 66.6101C108 67.6401 110.24 67.6401 112.07 66.6101L116.26 64.2401C118.19 63.1601 120.56 63.2201 122.43 64.4101L128.94 68.5601C130.67 69.6601 131.72 71.5701 131.72 73.6201V81.1501Z" fill="#D9D9D9" />
        <path d="M131.72 15.4996V56.5496C131.72 57.1696 131.39 57.7396 130.85 58.0396C130.33 58.3296 129.69 58.3296 129.17 58.0396L122.69 54.3896C120.86 53.3596 118.63 53.3596 116.8 54.3896L112.42 56.8596C110.59 57.8896 108.36 57.8896 106.53 56.8596L102.15 54.3896C100.32 53.3596 98.09 53.3596 96.26 54.3896L91.88 56.8596C90.05 57.8896 87.82 57.8896 85.99 56.8596L81.61 54.3896C79.78 53.3596 77.55 53.3596 75.72 54.3896L69.22 58.0496C68.71 58.3396 68.09 58.3396 67.58 58.0496C67.05 57.7496 66.73 57.1996 66.73 56.5896V7.94961C66.73 4.43961 69.57 1.59961 73.08 1.59961H117.83M117.83 1.59961L131.73 15.4996H123.83C120.52 15.4996 117.83 12.8096 117.83 9.49961V1.59961Z" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M125.37 87.4999C128.88 87.4999 131.72 84.6599 131.72 81.1499V73.6199C131.72 71.5699 130.67 69.6599 128.94 68.5599L122.43 64.4099C120.56 63.2199 118.19 63.1599 116.26 64.2399L112.07 66.5999C110.24 67.6299 108.01 67.6299 106.18 66.5999L101.8 64.1299C99.9702 63.0999 97.7402 63.0999 95.9102 64.1299L91.5302 66.5999C89.7002 67.6299 87.4702 67.6299 85.6402 66.5999L81.3102 64.1599C79.4602 63.1199 77.1902 63.1299 75.3502 64.1999L69.7102 67.4799C67.8602 68.5499 66.7202 70.5299 66.7202 72.6699V81.1499C66.7202 84.6599 69.5602 87.4999 73.0702 87.4999H125.37Z" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M86.6606 14.6494L94.7406 22.7394" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M94.7504 14.6494L86.6704 22.7394" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M109.16 19.8694C110.6 19.8694 111.77 18.6994 111.77 17.2594C111.77 15.8194 110.6 14.6494 109.16 14.6494C107.72 14.6494 106.55 15.8194 106.55 17.2594C106.55 18.6994 107.72 19.8694 109.16 19.8694Z" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M88.4302 40.0801C88.4302 35.6601 93.1302 32.0801 98.9302 32.0801C104.73 32.0801 109.43 35.6601 109.43 40.0801" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M135.99 87.5596H145.02" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M147.911 87.5596H151.431" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M56.73 87.5596H130.84" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M35.4302 87.5596H46.2602" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M59.1606 81.9893H66.6306" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M132.01 81.9893H139.48" stroke="#9AA1A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
    </svg>

}

export default IconExpired