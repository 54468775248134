
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ACCEPT_TENANT_TERMS, acceptTenantTermsError, acceptTenantTermsSuccess, DOWNLOAD_TENANT_QR, downloadTenantQrError, downloadTenantQrSuccess, SEND_QR_TO_EMAIL, sendQRToEmailError, sendQRToEmailSuccess, VERIFY_TENANT_TOKEN, verifyTenantTokenError, verifyTenantTokenSuccess } from "./action";
import TermsAndConditionService from "../../services/terms-and-conditions-service";
import ToastElement from "../../components/toast";
import { toast } from "react-toastify";
import saveAs from "file-saver";

export function* watchVerifyTenantToken() {
    yield takeEvery(VERIFY_TENANT_TOKEN, getVerifyTenantToken);
}

const getVerifyTenantTokenAsync = async (token) => {
    return TermsAndConditionService.verifyTenantToken(token)
};

function* getVerifyTenantToken({ payload }) {
    try {
        const response = yield call(getVerifyTenantTokenAsync, payload.token);
        if (response.data.success) {
            yield put(verifyTenantTokenSuccess(response.data.data));
        } else {
            yield put(verifyTenantTokenError(response.data.message));
        }
    } catch (error) {
        yield put(verifyTenantTokenError(error.response.data.message));
    }
}


export function* watchAcceptTenantTerms() {
    yield takeEvery(ACCEPT_TENANT_TERMS, acceptTenantTerms);
}

const acceptTenantTermsAsync = async (token) => {
    return TermsAndConditionService.acceptTenantTerms(token)
};

function* acceptTenantTerms({ payload }) {
    try {
        const response = yield call(acceptTenantTermsAsync, payload.token);
        if (response.data.success) {
            yield put(acceptTenantTermsSuccess(response.data.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(acceptTenantTermsError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(acceptTenantTermsError(error.response.data.message));
    }
}


export function* watchDownloadTenantQr() {
    yield takeEvery(DOWNLOAD_TENANT_QR, downloadTenantQr);
}

const downloadTenantQrAsync = async (id) => {
    return TermsAndConditionService.downloadTenantQr(id);
};

function* downloadTenantQr({ payload }) {
    try {
        const response = yield call(downloadTenantQrAsync, payload.token);
        if (response && response.data) {
            yield put(downloadTenantQrSuccess(true, ""));
            const blob = new Blob([response.data], { type: "image/png" });
            saveAs(blob, `user-qr.png`);
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(downloadTenantQrError(response.data.message));
        }
    } catch (error) {
        const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
        toast.error(
            <ToastElement type="error" message={err.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(downloadTenantQrError(err.message));
    }
}


export function* watchSendQRToEmail() {
    yield takeEvery(SEND_QR_TO_EMAIL, sendQRToEmail);
}

const sendQRToEmailAsync = async (token) => {
    return TermsAndConditionService.sendQRToEmail(token)
};

function* sendQRToEmail({ payload }) {
    try {
        const response = yield call(sendQRToEmailAsync, payload.token);
        if (response.data.success) {
            yield put(sendQRToEmailSuccess(response.data.data));
        } else {
            yield put(sendQRToEmailError(response.data.message));
        }
    } catch (error) {
        yield put(sendQRToEmailError(error.response.data.message));
    }
}



export default function* rootSaga() {
    yield all([
        fork(watchVerifyTenantToken),
        fork(watchAcceptTenantTerms),
        fork(watchDownloadTenantQr),
        fork(watchSendQRToEmail),
    ]);
}