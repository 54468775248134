import React from "react";

const IconFoodScan = () => {
  return (
    <svg version="1.1" width="22" height="22" viewBox="0 0 33 33">
      <g id="Group_336" transform="translate(0)">
        <path
          id="Path_3416"
          d="M5.1,1.5c0.1-0.1,0.2-0.4,0-0.5L4.3,0.1C4.2,0,3.9,0,3.8,0.1s-0.2,0.4,0,0.5l0.8,0.9
		c0.1,0.1,0.2,0.1,0.3,0.1C5,1.6,5.1,1.6,5.1,1.5z"
        />
        <path
          id="Path_3417"
          d="M6.7,3.8L6.3,4.2c-0.1,0.1-0.1,0.4,0,0.5l5.3,5.6l-0.9,0.9c-0.1,0.1-0.1,0.3-0.1,0.4l1.3,2.8l0,0
		c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0l0,0l0,0l0,0l0,0c0,0,0.1,0,0.1,0.1l0,0l0,0l0,0h0l1,0.1c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.1,1.2-0.4
		l4.4-3.6c0.7-0.6,1-1.5,0.8-2.4v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0l0,0L16.7,6
		c-0.3-0.2-0.8-0.2-1.1,0.1l0,0L12,9.8l-5-5.3l2.4-2.3l4.2,4.1c0.1,0.1,0.4,0.1,0.5,0c0.1-0.1,0.1-0.4,0-0.5L9.7,1.4
		c-0.1-0.1-0.4-0.1-0.5,0L8.6,2L7.8,1.2C7.7,1,7.5,1,7.3,1.1c-0.1,0.1-0.2,0.4,0,0.5l0.8,0.8L7.3,3.3L5.9,1.8
		c-0.1-0.1-0.4-0.2-0.5,0c-0.1,0.1-0.2,0.4,0,0.5L6.7,3.8z M18.6,10.2l-4.4,3.6c-0.3,0.2-0.6,0.3-0.9,0.2l-0.2,0l6.1-4.8
		C19.1,9.6,18.9,10,18.6,10.2z M16.2,6.6c0,0,0.1,0,0.2,0l2.6,1.9l-6.5,5.2l-1-2.1L16.2,6.6z"
        />
        <path
          id="Path_3418"
          d="M17.6,16.7c-0.2,0-0.4,0.1-0.4,0.3c0,0,0,0,0,0c0,0.2,0.1,0.4,0.3,0.4c0.1,0,0.1,0,0.2,0
		c1,0,1.9-0.4,2.6-1.1c0.7-0.7,1.1-1.6,1.1-2.6c0-0.2-0.2-0.4-0.3-0.4h0c-0.2,0-0.4,0.2-0.4,0.3c0,0.8-0.3,1.6-0.9,2.1
		c-0.6,0.6-1.3,0.9-2.2,0.9C17.7,16.7,17.6,16.7,17.6,16.7z"
        />
        <path
          id="Path_3419"
          d="M17.7,17.8c0,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.4,0.3,0.4c0.1,0,0.1,0,0.2,0
		c1.3,0,2.5-0.5,3.4-1.4c0.9-0.9,1.4-2.1,1.4-3.4c0-0.2-0.2-0.4-0.3-0.4l0,0c-0.2,0-0.4,0.2-0.4,0.3c0,0,0,0,0,0
		c0,1.1-0.5,2.1-1.2,2.9C19.9,17.3,18.8,17.8,17.7,17.8z"
        />
        <path
          id="Path_3420"
          d="M17.7,19.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.4,0.3,0.4c0.1,0,0.1,0,0.2,0
		c1.6,0,3.2-0.6,4.3-1.8c1.2-1.1,1.8-2.7,1.8-4.3c0-0.2-0.2-0.4-0.3-0.4l0,0c-0.2,0-0.4,0.2-0.4,0.3c0,1.4-0.6,2.8-1.6,3.8
		C20.6,18.5,19.2,19.1,17.7,19.1z"
        />
        <path
          id="Path_3421"
          d="M8,26.9h1.3c0.2,0,0.4-0.2,0.4-0.4l0,0c0-0.2-0.2-0.4-0.4-0.4l0,0H8c-0.2,0-0.4,0.2-0.4,0.4l0,0
		C7.6,26.8,7.8,26.9,8,26.9L8,26.9z"
        />
        <path
          id="Path_3422"
          d="M30.1,23c0-0.2-0.2-0.4-0.4-0.4h0H9.2c-0.2,0-0.4,0.2-0.4,0.4c0,1.4,1.1,2.5,2.5,2.5h2.6l-0.9,0.8l0,0
		c-0.1,0-0.1-0.1-0.2-0.1h-2.2c-0.2,0-0.4,0.2-0.4,0.4l0,0c0,0.2,0.2,0.4,0.4,0.4l0,0h1.9v5.3H5.2c-0.2,0-0.4,0.2-0.4,0.4l0,0
		C4.9,32.8,5,33,5.2,33l0,0h7.5c0.2,0,0.4-0.2,0.4-0.4l0,0v-1.1h8.4c0.6,0,1.1-0.2,1.6-0.6l4.3-3.5c0.6-0.4,0.8-1.2,0.4-1.8
		c0,0,0,0,0-0.1C29,25.5,30.1,24.4,30.1,23z M26.9,26.9l-4.3,3.6c-0.3,0.3-0.7,0.4-1.1,0.4h-8.4v-3.7l1.6-1.3
		c0.2-0.2,0.4-0.2,0.7-0.2H20c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-2.3c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h4.2
		c0.1,0,0.1,0,0.2-0.1l4-2.9c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0.1,0.4,0.3c0,0,0,0,0,0C27.3,26.3,27.2,26.7,26.9,26.9z M21.2,26
		c0-0.2,0-0.3-0.1-0.5h4.2L21.8,28h-4.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4l0,0H20C20.7,27.3,21.2,26.7,21.2,26
		C21.2,26.1,21.2,26,21.2,26L21.2,26z M27.6,24.8H11.3c-0.8,0-1.6-0.6-1.7-1.4h19.7C29.1,24.2,28.4,24.8,27.6,24.8L27.6,24.8z"
        />
      </g>
    </svg>
  );
};

export default IconFoodScan;
