import { combineReducers } from "redux";
import auth from "./auth/reducer";
import media from "./media/reducer";
import shared from "./shared/reducer";
import user from "./user/reducer";
import role from "./role/reducer";
import organization from "./organization/reducer";
import setting from "./setting/reducer";
import product from "./product/reducer";
import productCategory from "./productCategory/reducer";
import menu from "./menu/reducer";
import building from "./building/reducer";
import order from "./order/reducer";
import canteen from "./canteen/reducer";
import tenant from "./tenant/reducer";
import tenantEmployees from "./tenant-employees/reducer";
import kiosk from "./kiosk/reducer";
import dashboard from "./dashboard/reducer";
import report from "./report/reducer";
import configChoice from "./config-choice/reducer";
import configChoiceCategory from "./config-choice-category/reducer";
import termsAndCondition from "./terms-and-conditions/reducer";
const reducers = combineReducers({
  auth,
  media,
  shared,
  user,
  role,
  organization,
  setting,
  product,
  productCategory,
  menu,
  building,
  order,
  canteen,
  tenant,
  tenantEmployees,
  kiosk,
  dashboard,
  report,
  configChoice,
  configChoiceCategory,
  termsAndCondition
});

export default reducers;
