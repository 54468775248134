import React from "react";

const IconConfiguration = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="27"
      viewBox="0 0 25 27"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M14.0665 4.67149V4.89114C13.4777 4.87957 12.8889 4.87957 12.3001 4.87957V4.68305C12.3001 3.88539 11.6288 3.23801 10.828 3.23801H9.66218C8.33146 3.23801 7.24805 2.17447 7.24805 0.879717C7.24805 0.405745 7.64844 0.0126953 8.13127 0.0126953C8.62587 0.0126953 9.01448 0.405745 9.01448 0.879717C9.01448 1.22652 9.30889 1.50397 9.66218 1.50397H10.828C12.6062 1.51553 14.0547 2.93745 14.0665 4.67149Z"
        fill="#8A92A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2968 4.91283C16.6749 4.91801 17.0539 4.92321 17.4342 4.9271C21.5206 4.9271 24.5 7.84029 24.5 11.8633V17.0307C24.5 21.0537 21.5206 23.9669 17.4342 23.9669C15.7974 24.0016 14.1605 24.0131 12.5118 24.0131C10.8631 24.0131 9.20265 24.0016 7.56575 23.9669C3.47939 23.9669 0.5 21.0537 0.5 17.0307V11.8633C0.5 7.84029 3.47939 4.9271 7.57753 4.9271C9.12022 4.90398 10.6982 4.88086 12.2998 4.88086C12.8886 4.88086 13.4774 4.88086 14.0662 4.89242C14.8086 4.89242 15.551 4.9026 16.2968 4.91283ZM11.6344 15.8121H10.3979V17.0374C10.3979 17.5114 9.99753 17.9045 9.51471 17.9045C9.02011 17.9045 8.63149 17.5114 8.63149 17.0374V15.8121H7.38321C6.90038 15.8121 6.49999 15.4306 6.49999 14.945C6.49999 14.4711 6.90038 14.078 7.38321 14.078H8.63149V12.8642C8.63149 12.3902 9.02011 11.9972 9.51471 11.9972C9.99753 11.9972 10.3979 12.3902 10.3979 12.8642V14.078H11.6344C12.1173 14.078 12.5177 14.4711 12.5177 14.945C12.5177 15.4306 12.1173 15.8121 11.6344 15.8121ZM16.6391 13.8601H16.7568C17.2397 13.8601 17.64 13.4786 17.64 12.9931C17.64 12.5191 17.2397 12.1261 16.7568 12.1261H16.6391C16.1445 12.1261 15.7558 12.5191 15.7558 12.9931C15.7558 13.4786 16.1445 13.8601 16.6391 13.8601ZM18.653 17.8349H18.7708C19.2536 17.8349 19.654 17.4534 19.654 16.9679C19.654 16.4939 19.2536 16.1009 18.7708 16.1009H18.653C18.1584 16.1009 17.7698 16.4939 17.7698 16.9679C17.7698 17.4534 18.1584 17.8349 18.653 17.8349Z"
        fill="#8A92A6"
      />
    </svg>
  );
};

export default IconConfiguration;
