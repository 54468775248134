import {
  GET_OVERVIEW,
  GET_OVERVIEW_SUCCESS,
  GET_OVERVIEW_ERROR,
  GET_SALE,
  GET_SALE_SUCCESS,
  GET_SALE_ERROR,
  GET_BEST_PRODUCT,
  GET_BEST_PRODUCT_SUCCESS,
  GET_BEST_PRODUCT_ERROR,
  GET_PURCHASE_TYPE_SALE,
  GET_PURCHASE_TYPE_SALE_SUCCESS,
  GET_PURCHASE_TYPE_SALE_ERROR,
  GET_RECENT_PRODUCT,
  GET_RECENT_PRODUCT_SUCCESS,
  GET_RECENT_PRODUCT_ERROR,
  GET_CUSTOMER_REPEAT_RATE,
  GET_CUSTOMER_REPEAT_RATE_SUCCESS,
  GET_CUSTOMER_REPEAT_RATE_ERROR,
  GET_TOP_TENANT,
  GET_TOP_TENANT_SUCCESS,
  GET_TOP_TENANT_ERROR,
} from "../actions";

const INIT_STATE = {
  param: null,
  overviewData: null,
  saleData: null,
  bestProductData: null,
  purchaseTypeSaleData: null,
  recentProductData: null,
  customerRepeatRateData: null,
  topTenantData: null,
  success: false,
  message: null,
  loading: false,
  error: null,
};

const dashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OVERVIEW:
      return { ...state, error: null };
    case GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        overviewData: action.payload,
        error: null,
      };
    case GET_OVERVIEW_ERROR:
      return {
        ...state,
        overviewData: null,
        error: action.payload,
      };
    case GET_SALE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SALE_SUCCESS:
      return {
        ...state,
        loading: false,
        saleData: action.payload,
        error: null,
      };
    case GET_SALE_ERROR:
      return {
        ...state,
        loading: false,
        saleData: null,
        error: action.payload,
      };
    case GET_BEST_PRODUCT:
      return {
        ...state,
        error: null,
      };
    case GET_BEST_PRODUCT_SUCCESS:
      return {
        ...state,
        bestProductData: action.payload,
        error: null,
      };
    case GET_BEST_PRODUCT_ERROR:
      return {
        ...state,
        bestProductData: null,
        error: action.payload,
      };
    case GET_PURCHASE_TYPE_SALE:
      return {
        ...state,
        error: null,
      };
    case GET_PURCHASE_TYPE_SALE_SUCCESS:
      return {
        ...state,
        purchaseTypeSaleData: action.payload,
        error: null,
      };
    case GET_PURCHASE_TYPE_SALE_ERROR:
      return {
        ...state,
        purchaseTypeSaleData: null,
        error: action.payload,
      };
    case GET_RECENT_PRODUCT:
      return {
        ...state,
        error: null,
      };
    case GET_RECENT_PRODUCT_SUCCESS:
      return {
        ...state,
        recentProductData: action.payload,
        error: null,
      };
    case GET_RECENT_PRODUCT_ERROR:
      return {
        ...state,
        recentProductData: null,
        error: action.payload,
      };
    case GET_CUSTOMER_REPEAT_RATE:
      return {
        ...state,
        error: null,
      };
    case GET_CUSTOMER_REPEAT_RATE_SUCCESS:
      return {
        ...state,
        customerRepeatRateData: action.payload,
        error: null,
      };
    case GET_CUSTOMER_REPEAT_RATE_ERROR:
      return {
        ...state,
        customerRepeatRateData: null,
        error: action.payload,
      };
    case GET_TOP_TENANT:
      return {
        ...state,
        error: null,
      };
    case GET_TOP_TENANT_SUCCESS:
      return {
        ...state,
        topTenantData: action.payload,
        error: null,
      };
    case GET_TOP_TENANT_ERROR:
      return {
        ...state,
        topTenantData: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
export default dashboardReducer;
