import * as Mui from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { sortArrayByName } from "../../../helpers/sortArray";
import {
  Filter,
  GroupFilter,
  MultipleFilter,
} from "../../filter";
import Search from "../../search";
import { IconAdd, IconGear, IconXlsx } from "../../svg";
import ColumnFilter from "./column-filter";

const TableToolbar = (props) => {
  const {
    title,
    subtitle,
    numSelected,
    search,
    customFilter,
    statusFilter,
    buildingFilter,
    canteenFilter,
    kioskFilter,
    categoryFilter,
    tenantFilter,
    // dateFilter,
    handleSearch,
    handleStatusFilter,
    handleBuildingFilter,
    handleCanteenFilter,
    handleKioskFilter,
    handleCategoryFilter,
    handleTenantFilter,
    handleReset,
    // handleDateFilter,
    permission,
    route,
    createLabel,
    // handleOpenAlert1,
    handleOpenImport,
    headCells,
    enableSearch = true,
    enableStatusFilter,
    enableBuildingFilter,
    enableCanteenFilter,
    enableKioskFilter,
    enableProductCategoryFilter,
    enableCategoryFilter,
    enableTenantFilter,
    // handleBuiCanFilter,
    enableReset,
    // enableDateFilter,
    // dateFilterMinDate,
    // dateFilterMaxDate,
    enableColumnFilter,

    canteenFilterType,
    enableImport,
    customChoiceList,
    titleTrailing,
    searchPlaceholder
  } = props;
  const { t } = useTranslation();


  const [showFilter, setShowFilter] = useState(
    Mui.isWidthUp("md", props.width)
  );

  // const hasFilteredTenant = useRef(false);
  // const hasFilteredBuilding = useRef(false);

  // useEffect(() => {
  //   if (!hasFilteredTenant.current && Array.isArray(props?.tenantList) && props?.tenantList?.length === 1) {
  //     handleTenantFilter({ target: { value: props?.tenantList?.[0]?.id } });
  //     hasFilteredTenant.current = true
  //   }

  // }, [props?.tenantList?.length])

  // useEffect(() => {
  //   if (!hasFilteredBuilding.current && Array.isArray(props?.buildings) && props?.buildings?.length === 1) {
  //     const buiId = props.buildings?.[0]?.id;
  //     let canId = null;

  //     const buildingCanteen = props.buildings?.find(
  //       (x) => x.id === buiId
  //     )?.canteen || [];

  //     if (buildingCanteen?.length === 1) {
  //       canId = buildingCanteen?.[0]?.id;
  //     }
  //     handleBuiCanFilter(buiId, canId)

  //     hasFilteredBuilding.current = true;
  //   }
  // }, [props?.buildings?.length]);




  return (
    <Mui.Toolbar className="datatable-filter-bar">
      <Mui.Box className={`mb-${subtitle ? '2' : '1'}`} width="100%" display="flex" alignItems={"center"} justifyContent={'space-between'}>
        <Mui.Box>
          <Mui.Typography
            component="h3"
            variant="h3"
            className={`title ${Mui.isWidthDown("sm", props.width) ? "align-self-start" : "center"
              }`}
          >
            {title}
          </Mui.Typography>

          {subtitle && <Mui.Typography
            component="p"
            variant="body1"
            className="mt-1 text-color-grey"
          >
            {subtitle}
          </Mui.Typography>}
        </Mui.Box>

        {titleTrailing}
      </Mui.Box>

      <Mui.Box
        width="100%"
        display="flex"
        // style={{backgroundColor: "blue"}}
        // flexWrap={Mui.isWidthDown("sm", props.width) ? "no-wrap" : "no-wrap"}
        justifyContent={`${Mui.isWidthDown("xs", props.width) ? "center" : "flex-end"
          }`}
        alignItems="center"
        className={`filter-bar-right ${Mui.isWidthDown("xs", props.width) ? "mt-2" : ""
          }`}
      >
        <Mui.Collapse
          in={showFilter || Mui.isWidthUp("md", props.width)}
          classes={{
            wrapperInner: `d-flex justify-content-end align-items-center row 
            ${Mui.isWidthDown("xs", props.width) ? "mt-3" : ""}`,
          }}
        >
          {enableSearch && <Search searchPlaceholder={searchPlaceholder} value={search} onSearch={handleSearch} />}

          {customFilter}

          {enableStatusFilter ? (
            <Filter
              label={t("COMMON.FORM.STATUS")}
              value={statusFilter}
              onFilter={handleStatusFilter}
              options={customChoiceList ?? props.choiceList}
              displayEmpty={true}
            />
          ) : null}

          {enableTenantFilter ? (
            <Filter
              label={t("TENANT.TITLE")}
              value={tenantFilter}
              onFilter={handleTenantFilter}
              options={props?.tenantList ?? []}
              displayEmpty={true}
            />
          ) : null}

          {enableBuildingFilter ? (
            <Filter
              label={t("BUILDING.TITLE")}
              value={buildingFilter}
              onFilter={handleBuildingFilter}
              options={props.buildings}
              displayEmpty={true}
            />
          ) : null}

          {enableCanteenFilter && buildingFilter ? (
            canteenFilterType !== 'single' ?
              <MultipleFilter
                label={t("CANTEEN.TITLE")}
                value={canteenFilter}
                onFilter={handleCanteenFilter}
                options={sortArrayByName(
                  props.buildings?.find((x) => x.id === buildingFilter)
                    ?.canteen || []
                )}
                displayEmpty={true}
              /> :
              <Filter
                label={t("CANTEEN.TITLE")}
                value={canteenFilter}
                onFilter={handleCanteenFilter}
                options={sortArrayByName(
                  props.buildings?.find((x) => x.id.toString() === buildingFilter.toString())
                    ?.canteen || []
                )}
                displayEmpty={true}
              />
          ) : null}

          {enableKioskFilter && canteenFilter && <Filter
            label={t("KIOSK.TITLE")}
            value={kioskFilter}
            onFilter={handleKioskFilter}
            options={sortArrayByName(
              props.buildings.flatMap((x) => x.canteen).find((x) => canteenFilter && x.id.toString() === canteenFilter.toString())?.kiosk || []
            ) ?? []}
            displayEmpty={true}
          />}

          {enableProductCategoryFilter ? (
            <GroupFilter
              label={t("CONFIG_CHOICE_CATEGORY.FORM.CATEGORY")}
              value={categoryFilter}
              onFilter={handleCategoryFilter}
              options={props.productCategories}
              displayEmpty={true}
            />
          ) : null}
          {enableCategoryFilter ? (
            <Filter
              label={t("CONFIG_CHOICE_CATEGORY.FORM.CATEGORY")}
              value={categoryFilter}
              onFilter={handleCategoryFilter}
              options={props.configChoiceCategories}
              displayEmpty={true}
            />

          ) : null}
          {enableReset && (
            <Mui.Button
              size="small"
              color="primary"
              type="button"
              disableElevation
              onClick={() => { handleReset(); }}
              className={`flex-shrink-0 pl-2 pr-2 no-color-btn ${Mui.isWidthDown("xs", props.width) ? "" : "ml-2"
                }`}
            >
              {t("COMMON.RESET")}
            </Mui.Button>
          )}

          {/* {enableDateFilter ? (
            <DateFilter
              label={t("MENU.FORM.DATE")}
              value={dateFilter}
              minDate={dateFilterMinDate}
              maxDate={dateFilterMaxDate}
              onDateChange={handleDateFilter}
            />
          ) : null} */}
        </Mui.Collapse>

        <Mui.Hidden smUp>
          <Mui.Divider light={true} className="w-100 mb-1" />
        </Mui.Hidden>

        {/* Bulk Selection Action */}
        {/* {numSelected > 0 ? (
          <BulkSelection
            selected={numSelected}
            onDelete={() => handleOpenAlert1()}
          />
        ) : null} */}

        {numSelected === 0 ? (
          <>
            {/* <Mui.Tooltip
              title={t("COMMON.BUTTON.IMPORT")}
              arrow
              placement="bottom-start"
              PopperProps={{
                className:
                  "MuiTooltip-popper MuiTooltip-popperArrow tooltip-popover default-tooltip",
              }}
            >
              <Mui.IconButton
                color="primary"
                variant="outlined"
                className={`btn-icon-square ${
                  Mui.isWidthDown("xs", props.width) ? "" : "ml-2"
                }`}
                onClick={handleOpenImport}
              >
                <IconXlsx />
              </Mui.IconButton>
            </Mui.Tooltip> */}
            {enableImport ? (
              <Mui.Button
                size="small"
                disableElevation
                onClick={handleOpenImport}
                className={`flex-shrink-0 pl-1 no-color-btn ${Mui.isWidthDown("xs", props.width) ? "" : "ml-2"
                  }`}
              >
                {t("COMMON.BUTTON.IMPORT")}
                <span className="ml-1 line-height-null">
                  <IconXlsx />
                </span>
              </Mui.Button>
            ) : null}

            {permission?.add ? (
              <Mui.Button
                color="primary"
                size="small"
                disableElevation
                component={Link}
                to={`${route}/add`}
                className={`btn-default flex-shrink-0 ${Mui.isWidthDown("xs", props.width) ? "" : "ml-2"}`}
                type="submit"
                variant="contained"
              >
                {createLabel}
                <span className="ml-1 line-height-null">
                  <IconAdd />
                </span>
              </Mui.Button>
            ) : null}

            {enableColumnFilter ? <ColumnFilter headers={headCells} /> : null}
          </>
        ) : null}

        <Mui.Hidden mdUp>
          <Mui.IconButton
            color="primary"
            variant="outlined"
            className="btn-icon-square ml-2"
            onClick={() => setShowFilter(!showFilter)}
          >
            <IconGear />
          </Mui.IconButton>
        </Mui.Hidden>




        <Mui.Hidden smUp>
          <Mui.Divider light={true} className="w-100 mt-1" />
        </Mui.Hidden>
      </Mui.Box>
    </Mui.Toolbar>
  );
};
const mapStateToProps = ({ building, configChoiceCategory, shared, tenant, kiosk, productCategory }) => {
  const { choiceList } = shared;
  const { buildings: bui } = building;
  const { tenants: tnt } = tenant;
  const { kiosks: kio } = kiosk;
  const { productCategories } = productCategory
  const { configChoiceCategories } = configChoiceCategory;
  const buildings = sortArrayByName(bui ?? [])
  const kiosks = sortArrayByName(kio ?? [])
  const tenantList = sortArrayByName(tnt ?? [])

  return { choiceList, buildings, productCategories, configChoiceCategories, tenantList, kiosks };
};
export default connect(mapStateToProps, {})(Mui.withWidth()(TableToolbar));
