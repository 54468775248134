import Req from "../interceptors/token-interceptor";

const TenantService = (function () {
  function _getAllTenant() {
    return Req.get(`/api/tenants/list`);
  }
  function _getTenant(id, organizationId) {
    let el = "";
    if (organizationId) {
      el += `?organizationId=${organizationId}`;
    }
    return Req.get(`/api/tenants/${id}${el}`);
  }
  function _addTenant(data) {
    return Req.post("/api/tenants", data);
  }
  function _editTenant(data, id) {
    return Req.put(`/api/tenants/${id}`, data);
  }
  function _deleteTenant(id) {
    return Req.delete(`/api/tenants/${id}`);
  }
  function _deleteMultipleTenant(ids) {
    return Req.post("/api/tenants/delete", { ids: ids });
  }
  function _updateSubsidyPrices(data) {
    return Req.put("/api/tenants/update-subsidy-prices", data);
  }
  return {
    getAllTenant: _getAllTenant,
    getTenant: _getTenant,
    addTenant: _addTenant,
    editTenant: _editTenant,
    deleteTenant: _deleteTenant,
    deleteMultipleTenant: _deleteMultipleTenant,
    updateSubsidyPrices: _updateSubsidyPrices
  };
})();
export default TenantService;
