import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import BuildingService from "../../services/building-service";
import TableDataService from "../../services/table-data-service";
import {
  GET_ALL_BUILDING,
  GET_BUILDING_LIST,
  ADD_BUILDING,
  GET_BUILDING,
  EDIT_BUILDING,
  DELETE_BUILDING,
  DELETE_MULTIPLE_BUILDING,
} from "../actions";
import {
  getAllBuildingSuccess,
  getAllBuildingError,
  getBuildingList,
  getBuildingListSuccess,
  getBuildingListError,
  addBuildingSuccess,
  addBuildingError,
  getBuildingSuccess,
  getBuildingError,
  editBuildingSuccess,
  editBuildingError,
  deleteBuildingSuccess,
  deleteBuildingError,
  deleteMultipleBuildingSuccess,
  deleteMultipleBuildingError,
} from "./action";
import { toast } from "react-toastify";
import { parseMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";

export function* watchGetAllBuilding() {
  yield takeEvery(GET_ALL_BUILDING, getAllBuilding);
}

const getAllBuildingAsync = async () => {
  return BuildingService.getAllBuilding();
};

function* getAllBuilding() {
  try {
    const response = yield call(getAllBuildingAsync);
    if (response.data) {
      yield put(getAllBuildingSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getAllBuildingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getAllBuildingError(error.response.data.message));
  }
}

export function* watchGetBuildingList() {
  yield takeEvery(GET_BUILDING_LIST, getBuildingListAc);
}

const getBuildingListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "buildings",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null
  );
};

function* getBuildingListAc({ payload }) {
  try {
    const response = yield call(getBuildingListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getBuildingListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getBuildingListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getBuildingListError(error.response.data.message));
  }
}

export function* watchAddBuilding() {
  yield takeEvery(ADD_BUILDING, addBuilding);
}

const addBuildingAsync = async (data) => {
  return BuildingService.addBuilding(data);
};

function* addBuilding({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(addBuildingAsync, payload.buildingData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        addBuildingSuccess(response.data.success, response.data.message)
      );
      history.push(`/canteen-management/building${location?.state?.locationSearch ?? ""}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addBuildingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(addBuildingError(error.response.data.message));
  }
}

export function* watchGetBuilding() {
  yield takeEvery(GET_BUILDING, getBuilding);
}

const getBuildingAsync = async (id, organizationId) => {
  return BuildingService.getBuilding(id, organizationId);
};

function* getBuilding({ payload }) {
  try {
    const response = yield call(
      getBuildingAsync,
      payload.buildingId,
      payload.organizationId
    );
    if (response.data.success) {
      yield put(getBuildingSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getBuildingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getBuildingError(error.response.data.message));
  }
}

export function* watchEditBuilding() {
  yield takeEvery(EDIT_BUILDING, editBuilding);
}

const editBuildingAsync = async (data, id) => {
  return BuildingService.editBuilding(data, id);
};

function* editBuilding({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(
      editBuildingAsync,
      payload.buildingData,
      payload.buildingId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        editBuildingSuccess(response.data.success, response.data.message)
      );
      history.push(`/canteen-management/building${location?.state?.locationSearch ?? ""}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editBuildingError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(editBuildingError(error.response.data.message));
  }
}

export function* watchDeleteBuilding() {
  yield takeEvery(DELETE_BUILDING, deleteBuilding);
}

const deleteBuildingAsync = async (id) => {
  return BuildingService.deleteBuilding(id);
};

function* deleteBuilding({ payload }) {
  try {
    const response = yield call(deleteBuildingAsync, payload.buildingId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteBuildingSuccess(response.data.success, response.data.message)
      );
      // Fetch updated building list
      yield put(getBuildingList({}));
    } else {
      yield put(deleteBuildingError(response.data.message));
    }
  } catch (error) {
    yield put(deleteBuildingError(error.response.data.message));
  }
}

export function* watchDeleteMultipleBuilding() {
  yield takeEvery(DELETE_MULTIPLE_BUILDING, deleteMultipleBuilding);
}

const deleteMultipleBuildingAsync = async (ids) => {
  return BuildingService.deleteMultipleBuilding(ids);
};

function* deleteMultipleBuilding({ payload }) {
  try {
    const response = yield call(
      deleteMultipleBuildingAsync,
      payload.buildingIds
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteMultipleBuildingSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated building list
      yield put(getBuildingList({}));
    } else {
      yield put(deleteMultipleBuildingError(response.data.message));
    }
  } catch (error) {
    yield put(deleteMultipleBuildingError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllBuilding),
    fork(watchGetBuildingList),
    fork(watchAddBuilding),
    fork(watchGetBuilding),
    fork(watchEditBuilding),
    fork(watchDeleteBuilding),
    fork(watchDeleteMultipleBuilding),
  ]);
}
