import * as Mui from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import LogoSvg from '../../../assets/images/logo.svg';
import { formatCurrency, utcToLocal } from "../../../helpers/util";
import Action from "./action";
import StatusCell from "./status-cell";
import UserAvatarCell from "./user-avatar-cell";
import { CheckboxTable } from "../../checkbox/checkbox-table";

const TableRow = (props) => {
  const {
    isItemSelected,
    labelId,
    row,
    handleClick,
    headCells,
    index,
    handleOpenActionMenu,
    actionMenu,
    actionMenuId,
    handleCloseActionMenu,
    handleOpenAlert,
    handleOpenModal,
    handleCustomAction,
    permission,
    route,
    initialState,
    actionStyle,
    enableCheckbox,
    onRowPress,
    hideActionColumn = false
  } = props;
  const { i18n, t } = useTranslation();

  return (
    <Mui.TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
      onClick={() => onRowPress?.(row.id)}
      className={onRowPress ? 'pointer' : ''}
    >
      {enableCheckbox ? (
        <Mui.TableCell padding="checkbox">
          <CheckboxTable
            onClick={(event) => handleClick(event, row.id)}
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </Mui.TableCell>
      ) : null}

      {headCells &&
        headCells.map((column, i) => {
          switch (column.id) {
            case "userAvatar":
              return (
                <Mui.TableCell key={i}>

                  <UserAvatarCell data={row} />

                </Mui.TableCell>
              );
            case "role":
              return (
                <Mui.TableCell key={i}>
                  {row["role"]?.["name"]?.[i18n.language] || ""}
                </Mui.TableCell>
              );
            case "configChoiceCategory":
              return (
                <Mui.TableCell key={i}>
                  {row["configChoiceCategory"]?.["display_name"]?.[
                    i18n.language
                  ] || ""}
                </Mui.TableCell>
              );
            case "isActive":
              return (
                <Mui.TableCell key={i}>
                  {row["isActive"] === 1 ? t("COMMON.YES") : t("COMMON.NO")}
                </Mui.TableCell>
              );
            case "isDefault":
              return (
                <Mui.TableCell key={i}>
                  {row["isDefault"] === 1 ? t("COMMON.YES") : t("COMMON.NO")}
                </Mui.TableCell>
              );
            case "isSystemChoice":
              return (
                <Mui.TableCell key={i}>
                  {row["isSystemChoice"] === 1
                    ? t("COMMON.YES")
                    : t("COMMON.NO")}
                </Mui.TableCell>
              );
            case "displayName":
              return (
                <Mui.TableCell key={i}>
                  {row["displayName"]?.[i18n.language] || ""}
                </Mui.TableCell>
              );
            case "display_name":
              return (
                <Mui.TableCell key={i}>
                  {row["display_name"]?.[i18n.language] || ""}
                </Mui.TableCell>
              );
            case "canteen":
              return (
                <Mui.TableCell key={i}>
                  {row["canteen"]?.["name"] || ""}
                </Mui.TableCell>
              );
            case "nameObj":
              return (
                <Mui.TableCell key={i} className="text-transform-capitalize">
                  {row["name"]?.[i18n.language] || ""}
                </Mui.TableCell>
              );
            case "nameObjImg":
              return (
                <Mui.TableCell key={i} className="text-transform-capitalize">
                  <Mui.Box display={"flex"} flexDirection={'row'} alignItems={"center"} gridGap={10}>
                    {row["name"]?.[i18n.language] || ""}
                    {row["parentUuid"] !== null && <img alt="logo" src={LogoSvg} style={{ height: 30, width: 30 }} />}
                  </Mui.Box>
                </Mui.TableCell>
              );
            case "adminUserEmail":
              return (
                <Mui.TableCell key={i}>
                  {row["adminUser"]?.[0]?.email || ""}
                </Mui.TableCell>
              );
            case "statusConfigChoice":
              return (
                <Mui.TableCell key={i}>
                  <StatusCell data={row} />
                </Mui.TableCell>
              );
            case "price":
              return (
                <Mui.TableCell key={i}>
                  {formatCurrency(row[column.id], i18n.language)}
                </Mui.TableCell>
              );
            case "menuPrice":
              return (
                <Mui.TableCell key={i}>
                  {row["price"] ? formatCurrency(row["price"], i18n.language) : "-"}
                </Mui.TableCell>
              );
            case "subTotal":
              return (
                <Mui.TableCell key={i}>
                  {formatCurrency(row[column.id], i18n.language)}
                </Mui.TableCell>
              );
            case "total":
              return (
                <Mui.TableCell key={i}>
                  {formatCurrency(row[column.id], i18n.language)}
                </Mui.TableCell>
              );
            case "vat":
              return (
                <Mui.TableCell key={i}>
                  {formatCurrency(row[column.id], i18n.language)}
                </Mui.TableCell>
              );
            case "discount":
              return <Mui.TableCell key={i}>{row[column.id]}%</Mui.TableCell>;
            case "productCategory":
              return (
                <Mui.TableCell key={i}>
                  {row["productCategory"]?.["name"]}
                </Mui.TableCell>
              );
            case "building":
              return (
                <Mui.TableCell key={i}>
                  {row["building"]?.["name"] || "-"}
                </Mui.TableCell>
              );
            case "filter":
              return (
                <Mui.TableCell key={i} className="text-transform-capitalize">
                  {row["dateFilter"] || "-"}
                </Mui.TableCell>
              );
            case "multipleCanteen":
              return (
                <Mui.TableCell key={i}>
                  {row.canteens
                    ?.map((item) => item?.name)
                    ?.filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    ?.join(", ") || "-"}
                </Mui.TableCell>
              );
            case "multipleKiosk":
              return (
                <Mui.TableCell key={i}>
                  {row.kiosks
                    ?.map((item) => item?.name)
                    ?.filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    ?.join(", ") || "-"}
                </Mui.TableCell>
              );

            case "uuid":
              return (
                <Mui.TableCell key={i} className="text-transform-capitalize">
                  {/* <Mui.Tooltip arrow title={row["uuid"]} placement="top">
                    <Mui.Typography>
                      {(row["uuid"].split('-')[0] + '...' + row["uuid"].split('-').pop()) || "-"}
                    </Mui.Typography>
                  </Mui.Tooltip> */}
                  {row["uuid"]}
                </Mui.TableCell>
              );
            case "createdAt":
              return <Mui.TableCell key={i}>{row[column.id] && utcToLocal(row[column.id])}</Mui.TableCell>;
            case "orderDate":
              return <Mui.TableCell key={i}>{row[column.id] && utcToLocal(row[column.id])}</Mui.TableCell>;
            default:
              return <Mui.TableCell key={i}>{row[column.id] ?? '-'}</Mui.TableCell>;
          }
        })}

      {!hideActionColumn && <Action
        index={index}
        handleOpenActionMenu={handleOpenActionMenu}
        actionMenu={actionMenu}
        actionMenuId={actionMenuId}
        handleCloseActionMenu={handleCloseActionMenu}
        row={row}
        handleOpenAlert={handleOpenAlert}
        handleOpenModal={handleOpenModal}
        handleCustomAction={handleCustomAction}
        permission={permission}
        route={route}
        initialState={initialState}
        actionStyle={actionStyle}
        customActionComponents={props.customActionComponents}
      />}
    </Mui.TableRow>
  );
};
export default TableRow;
