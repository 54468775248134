import React from "react";

const IconBuildingManagement = () => {
  return (
    <svg
      type="stroke"
      version="1.1"
      viewBox="0 0 512 512"
      width="22"
      height="22"
    >
      <g>
        <g>
          <path
            d="M284.471,57.633h-28.755c-5.107,0-9.262,4.155-9.262,9.263v34.505c-0.001,5.107,4.154,9.262,9.262,9.262h28.755
			c5.107,0,9.263-4.155,9.263-9.263V66.896C293.734,61.789,289.579,57.633,284.471,57.633z M279.104,96.034h-18.022V72.262h18.022
			V96.034z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M341.644,57.633h-28.755c-5.107,0-9.262,4.155-9.262,9.263v34.505c0,5.107,4.154,9.262,9.262,9.262h28.755
			c5.107,0,9.262-4.155,9.262-9.263V66.896C350.906,61.789,346.751,57.633,341.644,57.633z M336.276,96.034h-18.021V72.262h18.021
			V96.034z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M398.816,57.633h-28.755c-5.107,0-9.262,4.155-9.262,9.263v34.505c0,5.107,4.155,9.262,9.262,9.262h28.755
			c5.107,0,9.263-4.155,9.263-9.263V66.896C408.079,61.789,403.924,57.633,398.816,57.633z M393.45,96.034h-18.022V72.262h18.022
			V96.034z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M284.471,143.318h-28.755c-5.107,0-9.262,4.155-9.262,9.263v34.505c-0.001,5.108,4.154,9.263,9.262,9.263h28.755
			c5.107,0,9.263-4.155,9.263-9.263v-34.505C293.734,147.474,289.579,143.318,284.471,143.318z M279.104,181.721h-18.022v-23.772
			h18.022V181.721z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M341.644,143.318h-28.755c-5.107,0-9.262,4.155-9.262,9.263v34.505c0,5.108,4.154,9.263,9.262,9.263h28.755
			c5.107,0,9.262-4.155,9.262-9.263v-34.505C350.906,147.474,346.751,143.318,341.644,143.318z M336.276,181.721h-18.021v-23.772
			h18.021V181.721z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M398.816,143.318h-28.755c-5.107,0-9.262,4.155-9.262,9.263v34.505c0,5.108,4.155,9.263,9.262,9.263h28.755
			c5.107,0,9.263-4.155,9.263-9.263v-34.505C408.079,147.474,403.924,143.318,398.816,143.318z M393.45,181.721h-18.022v-23.772
			h18.022V181.721z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M284.471,229.005h-28.755c-5.107,0-9.262,4.155-9.262,9.263v34.505c-0.001,5.107,4.154,9.263,9.262,9.263h28.755
			c5.107,0,9.263-4.155,9.263-9.263v-34.505C293.734,233.161,289.579,229.005,284.471,229.005z M279.104,267.407h-18.022v-23.772
			h18.022V267.407z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M341.644,229.005h-28.755c-5.107,0-9.262,4.155-9.262,9.263v34.505c0,5.107,4.154,9.263,9.262,9.263h28.755
			c5.107,0,9.262-4.155,9.262-9.263v-34.505C350.906,233.161,346.751,229.005,341.644,229.005z M336.276,267.407h-18.021v-23.772
			h18.021V267.407z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M398.816,229.005h-28.755c-5.107,0-9.262,4.155-9.262,9.263v34.505c0,5.107,4.155,9.263,9.262,9.263h28.755
			c5.107,0,9.263-4.155,9.263-9.263v-34.505C408.079,233.161,403.924,229.005,398.816,229.005z M393.45,267.407h-18.022v-23.772
			h18.022V267.407z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M398.816,314.692h-28.755c-5.107,0-9.262,4.155-9.262,9.262v34.505c0,5.107,4.155,9.263,9.262,9.263h28.755
			c5.107,0,9.263-4.155,9.263-9.263v-34.505C408.079,318.847,403.924,314.692,398.816,314.692z M393.45,353.092h-18.022v-23.771
			h18.022V353.092z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M494.57,347.288c-9.373-13.776-28.25-17.423-42.069-8.136l-20.111,13.459V37.963h1.458c5.296,0,9.604-4.308,9.604-9.604
			V9.604c0-5.296-4.308-9.604-9.604-9.604H387.14c-4.04,0-7.314,3.275-7.314,7.314s3.275,7.314,7.314,7.314h41.683v8.705h-205.37
			v-8.705h117.884c4.04,0,7.314-3.275,7.314-7.314S345.376,0,341.336,0H218.428c-5.296,0-9.604,4.308-9.604,9.604v18.755
			c0,5.296,4.308,9.604,9.604,9.604h3.714v93.951h-15.019V113.86c0-6.286-5.114-11.4-11.399-11.4h-55.228
			c-6.286,0-11.4,5.114-11.4,11.4v18.053h-10.51c-6.532,0-11.846,5.314-11.846,11.846v167.745c-3.096-0.197-6.201-0.303-9.313-0.303
			H85.899v-3.802c0-14.191-11.545-25.736-25.737-25.736H37.923c-14.191,0-25.737,11.545-25.737,25.736v104.45
			c0,4.04,3.275,7.314,7.314,7.314s7.314-3.275,7.314-7.314v-104.45c0-6.124,4.983-11.107,11.108-11.107h22.239
			c6.125,0,11.108,4.983,11.108,11.107v164.577c0,6.124-4.983,11.107-11.108,11.107H37.923c-6.125,0-11.108-4.983-11.108-11.107
			v-14.351c0-4.04-3.275-7.314-7.314-7.314s-7.314,3.275-7.314,7.314v14.351c0,14.191,11.545,25.736,25.737,25.736h22.239
			c14.191,0,25.737-11.546,25.737-25.736v-0.417l101.283,38.354c0.198,0.075,0.399,0.141,0.603,0.199
			c4.45,1.257,8.898,1.888,13.306,1.888c2.396,0,4.78-0.187,7.146-0.56c0.002,0,0.007-0.001,0.014-0.002l149.501-21.55
			c0.037-0.005,0.073-0.011,0.11-0.017c12.78-2.041,24.835-7.442,34.864-15.621c0.091-0.074,0.181-0.151,0.268-0.23l96.628-86.907
			C501.01,377,503.137,359.88,494.57,347.288z M236.771,37.963h180.991v324.439l-60.858,40.729l-20.404-1.145l-0.52-5.224
			c-0.381-3.837-1.241-7.528-2.521-11.011c-0.427-1.161-0.9-2.299-1.417-3.412c-0.777-1.669-1.653-3.281-2.622-4.829
			c-2.291-3.657-5.101-6.952-8.331-9.788h20.556c5.107,0,9.262-4.155,9.262-9.263v-34.505c0-5.107-4.155-9.262-9.262-9.262H312.89
			c-5.107,0-9.262,4.155-9.262,9.262v34.341c-1.848-0.51-3.745-0.909-5.686-1.184c-0.062-0.009-0.125-0.017-0.188-0.024l-4.02-0.463
			v-32.671c0-5.107-4.155-9.263-9.263-9.263h-28.755c-5.107,0-9.262,4.155-9.262,9.263v27.228l-9.683-1.114V37.963z
			 M318.255,353.093v-23.771h18.021v23.771H318.255z M279.104,329.321v23.772h-16.057l-1.965-0.226v-23.546H279.104z
			 M143.725,117.089h48.769v14.824h-48.769V117.089z M121.367,146.542L121.367,146.542h100.775v22.874h-82.606
			c-4.729,0-8.576,3.847-8.576,8.576v22.085c0,4.729,3.847,8.576,8.576,8.576h82.606v20.578h-82.606
			c-4.729,0-8.576,3.847-8.576,8.576v22.084c0,4.729,3.847,8.576,8.576,8.576h82.606v20.578h-82.606
			c-4.729,0-8.576,3.847-8.576,8.576v17.463c-1.242-0.289-2.489-0.559-3.741-0.817c-0.59-0.122-1.18-0.238-1.772-0.353
			c-0.692-0.134-1.387-0.26-2.082-0.384c-0.666-0.12-1.33-0.248-1.998-0.358V146.542z M222.142,184.045v9.979h-76.553v-9.979
			H222.142z M222.142,243.859v9.979h-76.553v-9.979H222.142z M145.589,313.653v-9.979h76.553v9.979H145.589z M222.142,328.282
			v20.101l-2.253-0.26c-4.726-0.672-9.382-1.599-13.965-2.76c-8.885-2.251-17.487-5.407-25.732-9.46
			c-1.096-0.539-2.183-1.097-3.266-1.668c-1.768-0.931-3.52-1.898-5.253-2.912c-1.276-0.747-2.564-1.465-3.857-2.171
			c-0.28-0.153-0.56-0.304-0.841-0.454c-0.257-0.138-0.513-0.28-0.77-0.416H222.142z M479.875,376.205l-96.527,86.815
			c-7.993,6.48-17.579,10.764-27.74,12.396l-149.502,21.55c-0.033,0.005-0.169,0.026-0.201,0.031
			c-4.473,0.707-9.132,0.41-13.852-0.882L85.899,455.916V325.831h11.527c5.304,0,10.587,0.337,15.826,0.969
			c1.064,0.128,2.124,0.269,3.182,0.422c0.154,0.022,0.307,0.046,0.46,0.069c2.673,0.396,5.322,0.877,7.949,1.429
			c0.817,0.173,1.635,0.347,2.449,0.535c0.407,0.094,0.812,0.193,1.218,0.29c1.061,0.256,2.12,0.522,3.175,0.805
			c0.19,0.051,0.381,0.1,0.571,0.152c8.264,2.253,16.31,5.308,24.014,9.129c0.23,0.114,0.459,0.23,0.688,0.346
			c0.98,0.493,1.953,1.001,2.921,1.518c0.295,0.158,0.591,0.313,0.885,0.473c1.165,0.635,2.324,1.283,3.471,1.954
			c0.017,0.01,0.034,0.019,0.051,0.029c3.109,1.818,6.269,3.517,9.478,5.094c13.904,6.834,28.716,11.391,44.167,13.577
			c0.063,0.009,0.125,0.017,0.188,0.024l10.501,1.209l8.552,0.985l19.723,2.271l4.894,0.564c0.001,0,0.002,0,0.003,0l34.186,3.936
			c13.624,1.968,24.081,12.894,25.444,26.6l0.254,2.549l-137.101-13.362c-4.033-0.393-7.598,2.55-7.99,6.571
			c-0.391,4.021,2.55,7.598,6.571,7.99l145.342,14.166c0.305,0.057,0.615,0.102,0.932,0.119l29.097,1.633
			c0.137,0.008,0.274,0.011,0.41,0.011c0.145,0,0.287-0.023,0.432-0.032c0.166-0.01,0.331-0.016,0.495-0.037
			c0.21-0.027,0.416-0.073,0.623-0.118c0.162-0.035,0.325-0.063,0.483-0.109c0.22-0.064,0.433-0.15,0.646-0.235
			c0.135-0.053,0.273-0.095,0.404-0.156c0.34-0.158,0.67-0.34,0.985-0.55l66.139-44.263l0.001-0.001l31.504-21.084
			c7.173-4.82,16.965-2.929,21.826,4.216C486.919,362.049,485.816,370.929,479.875,376.205z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconBuildingManagement;
