import React from "react";
import Skeleton from "react-loading-skeleton";
import * as Mui from "@material-ui/core";
const MenuSkeleton = (props) => {
  return (
    <Mui.Box
      component="li"
      className="submenu-parent"
    >
      <Skeleton height={40} />
    </Mui.Box>
  );
};

export default MenuSkeleton;
