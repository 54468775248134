import * as Mui from "@material-ui/core";
import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  IconBookProduct,
  IconFoodScan,
  IconIncome
} from "../../../components/svg";
// import { LineChart, Line, ResponsiveContainer } from "recharts";
import { formatCurrency, numberWithCommas } from "../../../helpers/util";

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
// ];
const Overview = (props) => {

  const { t, i18n } = useTranslation()
  return (
    <Mui.Paper className="widget-card">
      {/* <Mui.IconButton size="small" className="btn-menu">
        <IconMoreKebab />
      </Mui.IconButton> */}
      <Mui.Grid container spacing={2}>
        <Mui.Grid
          item
          xs={12}
          sm
          className={`${Mui.isWidthDown("xs", props.width) ? "mb-1 mt-2" : ""
            }`}
        >
          <Mui.Box display="flex" alignItems="flex-start">
            <Mui.Box
              width={25}
              height={25}
              component="span"
              className="svg-size-fill svg-color-primary-theme mr-2"
            >
              <IconIncome />
            </Mui.Box>
            <Mui.Typography
              component="h6"
              variant="h6"
              className="text-color-grey font-weight-normal"
            >
              {t("DASHBOARD.TOTAL_INCOME")}

              <Mui.Typography
                component="span"
                variant="h2"
                className="d-block text-color-default font-weight-bold title-large mt-2"
              >
                {formatCurrency(props.overviewData?.income, i18n.language)}
              </Mui.Typography>

              {/* <Mui.Typography
                  component="small"
                  variant="body1"
                  className="d-block text-color-grey-light font-weight-normal"
                >
                  35%{" "}
                  {props.filter === "today" ? t("DASHBOARD.TODAY") : null}
                  {props.filter === "thisWeek"
                    ? t("DASHBOARD.THIS_WEEK")
                    : null}
                  {props.filter === "thisMonth"
                    ? t("DASHBOARD.THIS_MONTH")
                    : null}
                  {props.filter === "thisYear"
                    ? t("DASHBOARD.THIS_YEAR")
                    : null}
                </Mui.Typography> */}
            </Mui.Typography>

            {/* <ResponsiveContainer width={100} height={50}>
                <LineChart data={data}>
                  <Line
                    type="monotone"
                    dataKey="pv"
                    stroke="#000330"
                    strokeWidth={5}
                    strokeLinecap="round"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer> */}
          </Mui.Box>
        </Mui.Grid>

        <Mui.Grid
          item
          xs={12}
          sm
          className={`${Mui.isWidthDown("xs", props.width) ? "mb-1 mt-2" : ""
            }`}
        >
          <Mui.Box display="flex" alignItems="flex-start">
            <Mui.Box
              width={25}
              height={25}
              component="span"
              className="svg-size-fill svg-color-primary-theme mr-2"
            >
              <IconFoodScan />
            </Mui.Box>

            <Mui.Typography
              component="h6"
              variant="h6"
              className="text-color-grey font-weight-normal"
            >
              {t("DASHBOARD.TOTAL_FOOD_ORDERED")}

              <Mui.Typography
                component="span"
                variant="h2"
                className="d-block text-color-default font-weight-bold title-large mt-2"
              >
                {props.overviewData?.orders
                  ? numberWithCommas(props.overviewData?.orders)
                  : 0}
              </Mui.Typography>

              {/* <Mui.Typography
                  component="small"
                  variant="body1"
                  className="d-block text-color-grey-light font-weight-normal"
                >
                  14%{" "}
                  {props.filter === "today" ? t("DASHBOARD.TODAY") : null}
                  {props.filter === "thisWeek"
                    ? t("DASHBOARD.THIS_WEEK")
                    : null}
                  {props.filter === "thisMonth"
                    ? t("DASHBOARD.THIS_MONTH")
                    : null}
                  {props.filter === "thisYear"
                    ? t("DASHBOARD.THIS_YEAR")
                    : null}
                </Mui.Typography> */}
            </Mui.Typography>

            {/* <ResponsiveContainer width={100} height={50}>
                <LineChart data={data}>
                  <Line
                    type="monotone"
                    dataKey="uv"
                    stroke="#000330"
                    strokeWidth={5}
                    strokeLinecap="round"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer> */}
          </Mui.Box>
        </Mui.Grid>

        <Mui.Grid
          item
          xs={12}
          sm
          className={`${Mui.isWidthDown("xs", props.width) ? "mb-1 mt-2" : ""
            }`}
        >
          <Mui.Box display="flex" alignItems="flex-start">
            <Mui.Box
              width={25}
              height={25}
              component="span"
              className="svg-size-fill svg-color-primary-theme mr-2"
            >
              <IconBookProduct />
            </Mui.Box>

            <Mui.Typography
              component="h6"
              variant="h6"
              className="text-color-grey font-weight-normal"
            >
              {t("DASHBOARD.AVG_ORDER_DURATION")}

              <Mui.Typography
                component="span"
                variant="h2"
                className="d-block text-color-default font-weight-bold title-large mt-2"
              >
                {props.overviewData?.duration
                  ? props.overviewData?.duration + ' s'
                  : 0 + 's'}
              </Mui.Typography>

              {/* <Mui.Typography
                  component="small"
                  variant="body1"
                  className="d-block text-color-grey-light font-weight-normal"
                >
                  35%{" "}
                  {props.filter === "today" ? t("DASHBOARD.TODAY") : null}
                  {props.filter === "thisWeek"
                    ? t("DASHBOARD.THIS_WEEK")
                    : null}
                  {props.filter === "thisMonth"
                    ? t("DASHBOARD.THIS_MONTH")
                    : null}
                  {props.filter === "thisYear"
                    ? t("DASHBOARD.THIS_YEAR")
                    : null}
                </Mui.Typography> */}
            </Mui.Typography>

            {/* <ResponsiveContainer width={100} height={50}>
                <LineChart data={data}>
                  <Line
                    type="monotone"
                    dataKey="amt"
                    stroke="#000330"
                    strokeWidth={5}
                    strokeLinecap="round"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer> */}
          </Mui.Box>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Paper>
  );
}

const mapStateToProps = ({ dashboard }) => {
  const { overviewData } = dashboard;
  return { overviewData };
};

export default connect(mapStateToProps, {})(withTranslation()(Overview));
