import * as Mui from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import AuthGraphicOne from "../../../assets/images/auth-graphics.png";
import { InputField } from "../../../components/form";
import { IconCheckAnimated, IconLogo } from "../../../components/svg";
import LanguageDropdownAlt from "../../../layouts/topnav/language-dropdown-alt";
import { forgotPassword, resetAuth } from "../../../reduxs/actions";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.validationSchema = Yup.object().shape({
      email: Yup.string()
        .email(this.props.t("AUTH.VALIDATION.INVALID_EMAIL"))
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")),
    });
    this.props.resetAuth();
  }

  onForgotPassword = (values) => {
    if (!this.props.loading) {
      this.props.forgotPassword(values);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Mui.Grid container justifyContent="space-between" className="auth-wrap">
        <Mui.Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={6}
          className="auth-message-box p-5"
        >
          <Mui.Box>
            {/* <Mui.Typography
              component="h2"
              variant="h2"
              className="title mt-5 font-weight-medium"
            >
              {t("AUTH.FORGOT.DONT_WORRY")}
            </Mui.Typography> */}
            <Mui.Typography
              component="h4"
              variant="h4"
              className="font-weight-normal mt-5"
              color="inherit"
            >
              {t("AUTH.FORGOT.HELP_RECOVER_PASSWORD")}
            </Mui.Typography>
          </Mui.Box>
          <Mui.Box className="auth-graphic-box">
            <img style={{ width: "100%" }} src={AuthGraphicOne} alt="" />
          </Mui.Box>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={5} className="auth-form-box">
          <Mui.Card className="card-box">
            <Mui.Box className="logo-icon">
              <LanguageDropdownAlt />
              <IconLogo />
            </Mui.Box>
            {!this.props.loading && this.props.success ? (
              <>
                <IconCheckAnimated />
                <Mui.Typography
                  className="mt-5 text-center auth-card__head"
                  variant="h1"
                  component="h1"
                >
                  {t("AUTH.FORGOT.LINK_SENT")}
                  <Mui.Typography
                    component="span"
                    variant="body1"
                    className="d-block mt-2 font-weight-normal text-color-grey mb-3"
                  >
                    {this.props.message}
                  </Mui.Typography>
                </Mui.Typography>

                <Mui.Box display="flex" justifyContent="center">
                  <NavLink
                    to={`/auth/login`}
                    className="font-weight-medium text-muted text-decoration-none"
                  >
                    {t("AUTH.FORGOT.RETURN_TO_LOGIN")}
                  </NavLink>
                </Mui.Box>
              </>
            ) : (
              <Mui.Box className="card-box__head" >
                <Mui.Typography
                  className="auth-card__head"
                  component="span"
                  variant="body1"
                >
                  {t("AUTH.FORGOT.TITLE")}

                  <Mui.Typography
                    component="span"
                    variant="body1"
                    className="d-block mt-1 font-weight-normal text-color-grey mb-3"
                  >
                    {t("AUTH.FORGOT.DESC")}
                  </Mui.Typography>
                </Mui.Typography>

                {this.props.error ? (
                  <Mui.Typography
                    className="d-block mt-2 text-center text-color-danger"
                    variant="body1"
                    component="span"
                  >
                    {this.props.error}
                  </Mui.Typography>
                ) : null}

                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={this.validationSchema}
                  onSubmit={this.onForgotPassword}
                >
                  {(props) => (
                    <Form className="default-form center">
                      <Mui.Grid container spacing={3}>
                        <Mui.Grid className="form-group" item xs={12}>
                          <InputField
                            name="email"
                            type="text"
                            placeholder={t("AUTH.INPUT.EMAIL")}
                            label={t("AUTH.INPUT.EMAIL")}
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Button
                        className="btn-default"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={this.props.loading}
                      >
                        {t("AUTH.GENERAL.SUBMIT_BUTTON")}
                        {this.props.loading && (
                          <Mui.CircularProgress color="secondary" size={24} />
                        )}
                      </Mui.Button>

                      <NavLink
                        to={`/auth/login`}
                        className="font-weight-medium text-muted text-decoration-none mt-3"
                      >
                        {t("AUTH.FORGOT.RETURN_TO_LOGIN")}
                      </NavLink>
                    </Form>
                  )}
                </Formik>
                <Mui.Box className="form-graphic-box">
                  <img style={{ width: "100%" }} src={AuthGraphicOne} alt="" />
                </Mui.Box>
              </Mui.Box>
            )}
          </Mui.Card>
        </Mui.Grid>
      </Mui.Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { success, message, loading, error } = auth;
  return { success, message, loading, error };
};
export default connect(mapStateToProps, { forgotPassword, resetAuth })(
  withTranslation()(ForgotPassword)
);
