export const GET_ALL_MENU = "GET_ALL_MENU";
export const GET_ALL_MENU_SUCCESS = "GET_ALL_MENU_SUCCESS";
export const GET_ALL_MENU_ERROR = "GET_ALL_MENU_ERROR";
export const GET_MENU_LIST = "GET_MENU_LIST";
export const GET_MENU_LIST_SUCCESS = "GET_MENU_LIST_SUCCESS";
export const GET_MENU_LIST_ERROR = "GET_MENU_LIST_ERROR";
export const GET_MENU = "GET_MENU";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_ERROR = "GET_MENU_ERROR";
export const EDIT_MENU = "EDIT_MENU";
export const EDIT_MENU_SUCCESS = "EDIT_MENU_SUCCESS";
export const EDIT_MENU_ERROR = "EDIT_MENU_ERROR";
export const FETCH_MENU = "FETCH_MENU";
export const FETCH_MENU_SUCCESS = "FETCH_MENU_SUCCESS";
export const FETCH_MENU_ERROR = "FETCH_MENU_ERROR";
export const ADD_MENU_CLASS = "ADD_MENU_CLASS";
export const ADD_MENU_CLASS_SUCCESS = "ADD_MENU_CLASS_SUCCESS";
export const ADD_MENU_CLASS_ERROR = "ADD_MENU_CLASS_ERROR";
export const RESET_MENU = "RESET_MENU";

export const getAllMenu = () => ({
  type: GET_ALL_MENU,
  payload: {},
});

export const getAllMenuSuccess = (menus) => ({
  type: GET_ALL_MENU_SUCCESS,
  payload: menus,
});

export const getAllMenuError = (error) => ({
  type: GET_ALL_MENU_ERROR,
  payload: error,
});

export const getMenuList = (dbParam) => ({
  type: GET_MENU_LIST,
  payload: { dbParam },
});

export const getMenuListSuccess = (menuList) => ({
  type: GET_MENU_LIST_SUCCESS,
  payload: menuList,
});

export const getMenuListError = (error) => ({
  type: GET_MENU_LIST_ERROR,
  payload: error,
});

export const getMenu = (menuId) => ({
  type: GET_MENU,
  payload: { menuId },
});

export const getMenuSuccess = (menuData) => ({
  type: GET_MENU_SUCCESS,
  payload: menuData,
});

export const getMenuError = (error) => ({
  type: GET_MENU_ERROR,
  payload: error,
});

export const editMenu = (menuId, menuData, history) => ({
  type: EDIT_MENU,
  payload: { menuId, menuData, history },
});

export const editMenuSuccess = (success, message) => ({
  type: EDIT_MENU_SUCCESS,
  payload: { success, message },
});

export const editMenuError = (error) => ({
  type: EDIT_MENU_ERROR,
  payload: error,
});

export const fetchMenu = (param) => ({
  type: FETCH_MENU,
  payload: { param },
});

export const fetchMenuSuccess = (success, message) => ({
  type: FETCH_MENU_SUCCESS,
  payload: { success, message },
});

export const fetchMenuError = (error) => ({
  type: FETCH_MENU_ERROR,
  payload: error,
});

export const addMenuClass = (menuId, param) => ({
  type: ADD_MENU_CLASS,
  payload: { menuId, param },
});

export const addMenuClassSuccess = (success, message) => ({
  type: ADD_MENU_CLASS_SUCCESS,
  payload: { success, message },
});

export const addMenuClassError = (error) => ({
  type: ADD_MENU_CLASS_ERROR,
  payload: error,
});

export const resetMenu = () => ({
  type: RESET_MENU,
  payload: {},
});
