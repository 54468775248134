export const GET_ALL_ORDER = "GET_ALL_ORDER";
export const GET_ALL_ORDER_SUCCESS = "GET_ALL_ORDER_SUCCESS";
export const GET_ALL_ORDER_ERROR = "GET_ALL_ORDER_ERROR";
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS";
export const GET_ORDER_LIST_ERROR = "GET_ORDER_LIST_ERROR";
export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_ERROR = "ADD_ORDER_ERROR";
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";
export const EDIT_ORDER = "EDIT_ORDER";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_ERROR = "EDIT_ORDER_ERROR";
export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_ERROR = "DELETE_ORDER_ERROR";
export const DELETE_MULTIPLE_ORDER = "DELETE_MULTIPLE_ORDER";
export const DELETE_MULTIPLE_ORDER_SUCCESS = "DELETE_MULTIPLE_ORDER_SUCCESS";
export const DELETE_MULTIPLE_ORDER_ERROR = "DELETE_MULTIPLE_ORDER_ERROR";
export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE";
export const DOWNLOAD_INVOICE_SUCCESS = "DOWNLOAD_INVOICE_SUCCESS";
export const DOWNLOAD_INVOICE_ERROR = "DOWNLOAD_INVOICE_ERROR";

export const DOWNLOAD_REFUND_INVOICE = "DOWNLOAD_REFUND_INVOICE";
export const DOWNLOAD_REFUND_INVOICE_SUCCESS = "DOWNLOAD_REFUND_INVOICE_SUCCESS";
export const DOWNLOAD_REFUND_INVOICE_ERROR = "DOWNLOAD_REFUND_INVOICE_ERROR";

export const EXPORT_EXCEL_REPORT = "EXPORT_EXCEL_REPORT";
export const EXPORT_EXCEL_REPORT_SUCCESS = "EXPORT_EXCEL_REPORT_SUCCESS";
export const EXPORT_EXCEL_REPORT_ERROR = "EXPORT_EXCEL_REPORT_ERROR";
export const RESET_ORDER = "RESET_ORDER";

export const getAllOrder = () => ({
  type: GET_ALL_ORDER,
  payload: {},
});

export const getAllOrderSuccess = (orders) => ({
  type: GET_ALL_ORDER_SUCCESS,
  payload: orders,
});

export const getAllOrderError = (error) => ({
  type: GET_ALL_ORDER_ERROR,
  payload: error,
});

export const getOrderList = (dbParam) => ({
  type: GET_ORDER_LIST,
  payload: { dbParam },
});

export const getOrderListSuccess = (orderList) => ({
  type: GET_ORDER_LIST_SUCCESS,
  payload: orderList,
});

export const getOrderListError = (error) => ({
  type: GET_ORDER_LIST_ERROR,
  payload: error,
});

export const addOrder = (orderData, history) => ({
  type: ADD_ORDER,
  payload: { orderData, history },
});

export const addOrderSuccess = (success, message) => ({
  type: ADD_ORDER_SUCCESS,
  payload: { success, message },
});

export const addOrderError = (error) => ({
  type: ADD_ORDER_ERROR,
  payload: error,
});

export const getOrder = (orderId) => ({
  type: GET_ORDER,
  payload: { orderId },
});

export const getOrderSuccess = (orderData) => ({
  type: GET_ORDER_SUCCESS,
  payload: orderData,
});

export const getOrderError = (error) => ({
  type: GET_ORDER_ERROR,
  payload: error,
});

export const editOrder = (orderId, orderData, history) => ({
  type: EDIT_ORDER,
  payload: { orderId, orderData, history },
});

export const editOrderSuccess = (success, message) => ({
  type: EDIT_ORDER_SUCCESS,
  payload: { success, message },
});

export const editOrderError = (error) => ({
  type: EDIT_ORDER_ERROR,
  payload: error,
});

export const deleteOrder = (orderId) => ({
  type: DELETE_ORDER,
  payload: { orderId },
});

export const deleteOrderSuccess = (success, message) => ({
  type: DELETE_ORDER_SUCCESS,
  payload: { success, message },
});

export const deleteOrderError = (error) => ({
  type: DELETE_ORDER_ERROR,
  payload: error,
});

export const deleteMultipleOrder = (orderIds) => ({
  type: DELETE_MULTIPLE_ORDER,
  payload: { orderIds },
});

export const deleteMultipleOrderSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_ORDER_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleOrderError = (error) => ({
  type: DELETE_MULTIPLE_ORDER_ERROR,
  payload: error,
});

export const downloadInvoice = (orderId) => ({
  type: DOWNLOAD_INVOICE,
  payload: { orderId },
});

export const downloadInvoiceSuccess = (success, message) => ({
  type: DOWNLOAD_INVOICE_SUCCESS,
  payload: { success, message },
});

export const downloadInvoiceError = (error) => ({
  type: DOWNLOAD_INVOICE_ERROR,
  payload: error,
});

export const downloadRefundInvoice = (orderId) => ({
  type: DOWNLOAD_REFUND_INVOICE,
  payload: { orderId },
});

export const downloadRefundInvoiceSuccess = (success, message) => ({
  type: DOWNLOAD_REFUND_INVOICE_SUCCESS,
  payload: { success, message },
});

export const downloadRefundInvoiceError = (error) => ({
  type: DOWNLOAD_REFUND_INVOICE_ERROR,
  payload: error,
});

export const exportExcelReport = (queryParam) => ({
  type: EXPORT_EXCEL_REPORT,
  payload: { queryParam },
});

export const exportExcelReportSuccess = (success, message) => ({
  type: EXPORT_EXCEL_REPORT_SUCCESS,
  payload: { success, message },
});

export const exportExcelReportError = (error) => ({
  type: EXPORT_EXCEL_REPORT_ERROR,
  payload: error,
});

export const resetOrder = () => ({
  type: RESET_ORDER,
  payload: {},
});
