import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { LayoutSplashScreen } from "../configs/core";
import {
  getPermission,
  getCurrentUser,
  getSetting,
  getBasicSetting,
  getNotificationList,
  getAnnotationUrl,
} from "../reduxs/actions";
import { restrictSubmenu } from "../helpers/restrictSubmenu";

function AuthGuard(props) {
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);


  // We should request permission by token before rendering the application
  useEffect(() => {
    // Get setting

    const requestPermission = async () => {
      try {
        if (!didRequest.current) {
          props.getPermission(restrictSubmenu);
          props.getSetting()
          props.getCurrentUser();
          // props.getNotificationList({});
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (props.user) {
      props.getBasicSetting('orgId,nets_url,nets_username,annotation_url,prediction_url,terms_conditions,mtc,otc,org_name');
      requestPermission();

    } else {
      props.getBasicSetting('orgId,terms_conditions,mtc,otc,org_name');
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.user) {
      props.getBasicSetting('orgId,nets_url,nets_username,annotation_url,prediction_url,terms_conditions,mtc,otc,org_name');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])

  useEffect(() => {
    // Set site title
    if (props.settingData?.siteTitle) {
      document.title = props.settingData.siteTitle;
    }
  }, [props.settingData]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}
const mapStateToProps = ({ auth, setting }) => {
  const { user } = auth;
  const { settingData } = setting;
  return { user, settingData };
};

export default connect(mapStateToProps, {
  getPermission,
  getCurrentUser,
  getSetting,
  getBasicSetting,
  getNotificationList,
  getAnnotationUrl
})(AuthGuard);
