export const DarkPalette = {
  type: "dark",
  primary: {
    main: "#0D47A1",
  },
  secondary: {
    main: "#FFC107",
  },
  background: {
    default: "#303030",
    paper: "#424242",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#FFFFFF",
  },
};

export const LightPalette = {
  type: "light",
  primary: {
    main: "#0D47A1",
  },
  secondary: {
    main: "#FFC107",
  },
  background: {
    default: "#FFFFFF",
    paper: "#FFFFFF",
  },
  text: {
    primary: "#000000",
    secondary: "#000000",
  },
};

