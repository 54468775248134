import React from "react";

const IconUsero = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 17.632 17.632"
    >
      <g
        id="Group_812"
        dataname="Group 812"
        transform="translate(2141.732 -52.95)"
      >
        <g id="Group_1754" dataname="Group 1754">
          <path
            id="Path_3457"
            dataname="Path 3457"
            d="M-2132.916,70.582a8.826,8.826,0,0,1-8.816-8.816,8.826,8.826,0,0,1,8.816-8.816,8.826,8.826,0,0,1,8.816,8.816A8.826,8.826,0,0,1-2132.916,70.582Zm0-16.636a7.829,7.829,0,0,0-7.82,7.82,7.829,7.829,0,0,0,7.82,7.82,7.829,7.829,0,0,0,7.82-7.82A7.829,7.829,0,0,0-2132.916,53.946Z"
          />
          <path
            id="Path_3458"
            dataname="Path 3458"
            d="M-2099.043,90.481c-2.08,0-3.518-1.675-3.848-4.482a4.276,4.276,0,0,1,1-3.4,3.831,3.831,0,0,1,2.851-1.3,3.832,3.832,0,0,1,2.852,1.3,4.274,4.274,0,0,1,1,3.4C-2095.525,88.805-2096.963,90.481-2099.043,90.481Zm0-8.187a2.833,2.833,0,0,0-2.106.969,3.3,3.3,0,0,0-.753,2.62c.27,2.289,1.312,3.6,2.859,3.6s2.59-1.313,2.858-3.6h0a3.291,3.291,0,0,0-.751-2.619A2.836,2.836,0,0,0-2099.043,82.294Z"
            transform="translate(-33.977 -24.819)"
          />
          <path
            id="Path_3461"
            dataname="Path 3461"
            d="M-2119.042,152.4a.516.516,0,0,1-.318-.109.518.518,0,0,1-.09-.726,7.129,7.129,0,0,1,5.652-2.773h1.05a7.163,7.163,0,0,1,5.437,2.51.517.517,0,0,1-.056.729.517.517,0,0,1-.729-.056,6.129,6.129,0,0,0-4.652-2.149h-1.05a6.1,6.1,0,0,0-4.836,2.374A.516.516,0,0,1-2119.042,152.4Z"
            transform="translate(-19.414 -83.914)"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconUsero;
