import React from "react";

const IconVerify = () => {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 511.375 511.375"
      height="22"
      viewBox="0 0 511.375 511.375"
      width="22"
    >
      <g>
        <g>
          <path
            d="m511.375 255.688-57.89-64.273 9.064-86.046-84.651-17.92-43.18-75.012-79.03 35.321-10.667 207.93 10.667 207.929 79.031 35.321 43.179-75.011 84.651-17.921-9.064-86.046z"
            fill="#0ed678"
          />
          <path
            d="m176.656 12.437-43.179 75.012-84.651 17.921 9.064 86.045-57.89 64.273 57.89 64.272-9.064 86.046 84.651 17.921 43.18 75.011 79.031-35.321v-415.859z"
            fill="#04eb84"
          />
        </g>
        <g>
          <path
            d="m362.878 199.702-22.381-19.977-84.809 95.016-10.667 23.613 10.667 21.439z"
            fill="#f7f0eb"
          />
          <path
            d="m166.56 233.095-21.212 21.213 89.185 89.186 21.155-23.701v-45.052l-22.393 25.088z"
            fill="#fffbf5"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconVerify;
