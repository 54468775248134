export const GET_ALL_KIOSK = "GET_ALL_KIOSK";
export const GET_ALL_KIOSK_SUCCESS = "GET_ALL_KIOSK_SUCCESS";
export const GET_ALL_KIOSK_ERROR = "GET_ALL_KIOSK_ERROR";
export const GET_KIOSK_LIST = "GET_KIOSK_LIST";
export const GET_KIOSK_LIST_SUCCESS = "GET_KIOSK_LIST_SUCCESS";
export const GET_KIOSK_LIST_ERROR = "GET_KIOSK_LIST_ERROR";
export const ADD_KIOSK = "ADD_KIOSK";
export const ADD_KIOSK_SUCCESS = "ADD_KIOSK_SUCCESS";
export const ADD_KIOSK_ERROR = "ADD_KIOSK_ERROR";
export const GET_KIOSK = "GET_KIOSK";
export const GET_KIOSK_SUCCESS = "GET_KIOSK_SUCCESS";
export const GET_KIOSK_ERROR = "GET_KIOSK_ERROR";
export const GET_KIOSK_LOG = "GET_KIOSK_LOG";
export const GET_KIOSK_LOG_SUCCESS = "GET_KIOSK_LOG_SUCCESS";
export const GET_KIOSK_LOG_ERROR = "GET_KIOSK_LOG_ERROR";
export const EDIT_KIOSK = "EDIT_KIOSK";
export const EDIT_KIOSK_SUCCESS = "EDIT_KIOSK_SUCCESS";
export const EDIT_KIOSK_ERROR = "EDIT_KIOSK_ERROR";
export const DELETE_KIOSK = "DELETE_KIOSK";
export const DELETE_KIOSK_SUCCESS = "DELETE_KIOSK_SUCCESS";
export const DELETE_KIOSK_ERROR = "DELETE_KIOSK_ERROR";
export const DELETE_MULTIPLE_KIOSK = "DELETE_MULTIPLE_KIOSK";
export const DELETE_MULTIPLE_KIOSK_SUCCESS = "DELETE_MULTIPLE_KIOSK_SUCCESS";
export const DELETE_MULTIPLE_KIOSK_ERROR = "DELETE_MULTIPLE_KIOSK_ERROR";
export const RESET_KIOSK = "RESET_KIOSK";
export const GET_NETS_TERMINALS = "GET_NETS_TERMINALS";
export const GET_NETS_TERMINALS_SUCCESS = "GET_NETS_TERMINALS_SUCCESS";
export const GET_NETS_TERMINALS_ERROR = "GET_NETS_TERMINALS_ERROR";

export const GET_TERMINAL_STATUS = "GET_TERMINAL_STATUS";
export const GET_TERMINAL_STATUS_SUCCESS = "GET_TERMINAL_STATUS_SUCCESS";
export const GET_TERMINAL_STATUS_ERROR = "GET_TERMINAL_STATUS_ERROR";


export const NETS_TERMINAL_RECONCILIATION = "NETS_TERMINAL_RECONCILIATION";
export const NETS_TERMINAL_RECONCILIATION_SUCCESS = "NETS_TERMINAL_RECONCILIATION_SUCCESS";
export const NETS_TERMINAL_RECONCILIATION_ERROR = "NETS_TERMINAL_RECONCILIATION_ERROR";

export const getAllKiosk = () => ({
  type: GET_ALL_KIOSK,
  payload: {},
});

export const getAllKioskSuccess = (kiosks) => ({
  type: GET_ALL_KIOSK_SUCCESS,
  payload: kiosks,
});

export const getAllKioskError = (error) => ({
  type: GET_ALL_KIOSK_ERROR,
  payload: error,
});

export const getKioskList = (dbParam) => ({
  type: GET_KIOSK_LIST,
  payload: { dbParam },
});

export const getKioskListSuccess = (kioskList) => ({
  type: GET_KIOSK_LIST_SUCCESS,
  payload: kioskList,
});

export const getKioskListError = (error) => ({
  type: GET_KIOSK_LIST_ERROR,
  payload: error,
});

export const addKiosk = (kioskData, history, location) => ({
  type: ADD_KIOSK,
  payload: { kioskData, history, location },
});

export const addKioskSuccess = (success, message) => ({
  type: ADD_KIOSK_SUCCESS,
  payload: { success, message },
});

export const addKioskError = (error) => ({
  type: ADD_KIOSK_ERROR,
  payload: error,
});

export const getKiosk = (kioskId, organizationId) => ({
  type: GET_KIOSK,
  payload: { kioskId, organizationId },
});

export const getKioskSuccess = (kioskData) => ({
  type: GET_KIOSK_SUCCESS,
  payload: kioskData,
});

export const getKioskError = (error) => ({
  type: GET_KIOSK_ERROR,
  payload: error,
});

export const getKioskLog = (kioskId, logParam) => ({
  type: GET_KIOSK_LOG,
  payload: { kioskId, logParam },
});

export const getKioskLogSuccess = (kioskLogData) => ({
  type: GET_KIOSK_LOG_SUCCESS,
  payload: kioskLogData,
});

export const getKioskLogError = (error) => ({
  type: GET_KIOSK_LOG_ERROR,
  payload: error,
});

export const editKiosk = (kioskId, kioskData, history, location) => ({
  type: EDIT_KIOSK,
  payload: { kioskId, kioskData, history, location },
});

export const editKioskSuccess = (success, message) => ({
  type: EDIT_KIOSK_SUCCESS,
  payload: { success, message },
});

export const editKioskError = (error) => ({
  type: EDIT_KIOSK_ERROR,
  payload: error,
});

export const deleteKiosk = (kioskId) => ({
  type: DELETE_KIOSK,
  payload: { kioskId },
});

export const deleteKioskSuccess = (success, message) => ({
  type: DELETE_KIOSK_SUCCESS,
  payload: { success, message },
});

export const deleteKioskError = (error) => ({
  type: DELETE_KIOSK_ERROR,
  payload: error,
});

export const deleteMultipleKiosk = (kioskIds) => ({
  type: DELETE_MULTIPLE_KIOSK,
  payload: { kioskIds },
});

export const deleteMultipleKioskSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_KIOSK_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleKioskError = (error) => ({
  type: DELETE_MULTIPLE_KIOSK_ERROR,
  payload: error,
});

export const resetKiosk = () => ({
  type: RESET_KIOSK,
  payload: {},
});

export const getNetsTerminals = () => ({
  type: GET_NETS_TERMINALS,
  payload: {},
});

export const getNetsTerminalsSuccess = (netsTerminals) => ({
  type: GET_NETS_TERMINALS_SUCCESS,
  payload: netsTerminals,
});

export const getNetsTerminalsError = (error) => ({
  type: GET_NETS_TERMINALS_ERROR,
  payload: error,
});

export const netsTerminalReconciliation = (kioskId, terminalId) => ({
  type: NETS_TERMINAL_RECONCILIATION,
  payload: { kioskId, terminalId },
});

export const netsTerminalReconciliationSuccess = () => ({
  type: NETS_TERMINAL_RECONCILIATION_SUCCESS,
  payload: {},
});

export const netsTerminalReconciliationError = (error) => ({
  type: NETS_TERMINAL_RECONCILIATION_ERROR,
  payload: error,
});


export const getTerminalStatus = (kioskUuid) => ({
  type: GET_TERMINAL_STATUS,
  payload: { kioskUuid },
});

export const getTerminalStatusSuccess = (isTerminalActive) => ({
  type: GET_TERMINAL_STATUS_SUCCESS,
  payload: isTerminalActive,
});

export const getTerminalStatusError = (error) => ({
  type: GET_TERMINAL_STATUS_ERROR,
  payload: error,
});


