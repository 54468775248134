import {
  GET_ALL_TENANT,
  GET_ALL_TENANT_SUCCESS,
  GET_ALL_TENANT_ERROR,
  GET_TENANT_LIST,
  GET_TENANT_LIST_SUCCESS,
  GET_TENANT_LIST_ERROR,
  ADD_TENANT,
  ADD_TENANT_SUCCESS,
  ADD_TENANT_ERROR,
  GET_TENANT,
  GET_TENANT_SUCCESS,
  GET_TENANT_ERROR,
  EDIT_TENANT,
  EDIT_TENANT_SUCCESS,
  EDIT_TENANT_ERROR,
  DELETE_TENANT,
  DELETE_TENANT_SUCCESS,
  DELETE_TENANT_ERROR,
  DELETE_MULTIPLE_TENANT,
  DELETE_MULTIPLE_TENANT_SUCCESS,
  DELETE_MULTIPLE_TENANT_ERROR,
  RESET_TENANT,
  UPDATE_SUBSIDY_PRICES,
  UPDATE_SUBSIDY_PRICES_SUCCESS,
  UPDATE_SUBSIDY_PRICES_ERROR,
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  tenants: null,
  tenantList: null,
  tenantData: null,
  tenantId: null,
  organizationId: null,
  tenantIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const tenantReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TENANT:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_TENANT_SUCCESS:
      return {
        ...state,
        tenants: action.payload,
        error: null,
      };
    case GET_ALL_TENANT_ERROR:
      return {
        ...state,
        tenants: null,
        error: action.payload,
      };
    case GET_TENANT_LIST:
      return {
        ...state,
        loading: true,
        tenantData: null,
        tenantId: null,
        tenantIds: null,
        error: null,
      };
    case GET_TENANT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantList: action.payload,
        error: null,
      };
    case GET_TENANT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        tenantList: null,
        error: action.payload,
      };
    case ADD_TENANT:
      return { ...state, loading: true, error: null };
    case ADD_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_TENANT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_TENANT:
      return { ...state, error: null };
    case GET_TENANT_SUCCESS:
      return {
        ...state,
        tenantData: action.payload,
        error: null,
      };
    case GET_TENANT_ERROR:
      return {
        ...state,
        tenantData: null,
        error: action.payload,
      };
    case EDIT_TENANT:
      return { ...state, loading: true, error: null };
    case EDIT_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_TENANT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_TENANT:
      return { ...state, loading1: true, error: null };
    case DELETE_TENANT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_TENANT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_TENANT:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_TENANT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_TENANT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case UPDATE_SUBSIDY_PRICES:
      return { ...state, loading: true, error: null };
    case UPDATE_SUBSIDY_PRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        tenantData: updateSubsidyInTenant(state, action.payload.subsidyData),
        error: null,
      };
    case UPDATE_SUBSIDY_PRICES_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_TENANT:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default tenantReducer;


const updateSubsidyInTenant = (state, subsidyData) => {

  const tenantData = { ...state.tenantData }

  const canteenData = tenantData?.tenantCanteens?.find(can => can.id === subsidyData?.tenantCanteenId)
  if (!canteenData) return state.tenantData

  const product = canteenData?.canteenProducts?.find(item => item.productId === subsidyData?.productId)?.product
  if (!product) return state.tenantData



  product.finalPrices = [...product?.finalPrices?.map(item => {
    return {
      ...item,
      subsidiaryIncludingVat: subsidyData?.prices?.find(i => i.vatCode.toString() === item.vatCode.toString()).subsidiaryIncludingVat,
      sellingPriceIncludingVat: subsidyData?.prices?.find(i => i.vatCode.toString() === item.vatCode.toString()).sellingPriceIncludingVat,
      status: subsidyData?.prices?.find(i => i.vatCode.toString() === item.vatCode.toString()).status
    }
  })]
  return tenantData



}