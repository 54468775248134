import * as Mui from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import { toolBarConfig } from "../../configs/constant";
import SideNavTriggerMobile from "./side-nav-trigger-mobile";
import UserInfoDropdown from "./user-info-dropdown";
// import NotificationDropdown from "./notification-dropdown";
import { useTheme } from "../../ThemeContext";
import LanguageDropdownAlt from "./language-dropdown-alt";

function Topnav(props) {
  const { theme } = useTheme();
  return (
    <>
      <Mui.AppBar
        className={`cms-header ${theme} ${toolBarConfig.style === "fixed" ? "clipped" : ""
          }`}
        position={toolBarConfig.style}
      >
        <Mui.Container className="container-fluid m-0" maxWidth={false}>
          <Mui.Toolbar>
            {toolBarConfig.style === "fixed" ? (
              <>
                <figure className="toolbar-logo clipped">
                  <img src={toolBarConfig.primaryLogo} alt="Logo" />
                </figure>
              </>
            ) : null}

            {/* <SideNavTriggerCollapse toggleSideNav={props.toggleSideNav} /> */}

            <SideNavTriggerMobile
              toggleMobileSideNav={props.toggleMobileSideNav}
            />

            {/* <ToolbarSearch /> */}

            <Mui.Box
              display="flex"
              alignItems="center"
              className="right-nav"
            >
              {/* <NotificationDropdown /> */}

              {/* <LanguageDropdown /> */}
              <LanguageDropdownAlt />

              <UserInfoDropdown />
            </Mui.Box>
          </Mui.Toolbar>
        </Mui.Container>
      </Mui.AppBar>
    </>
  );
}

export default withTranslation()(Mui.withWidth()(Topnav));