import React, { Component } from "react";
import * as Mui from "@material-ui/core";
import { IconCheck, IconChevronDown } from "../svg";
import { withTranslation } from "react-i18next";
class GroupFilter extends Component {
  render() {
    const { t, leftMargin = true } = this.props;
    const renderSelectGroup = (data) => {

      const items = data?.children.map((item) => {
        return (
          <Mui.MenuItem key={item.id} value={item.id}>
            {item.name}
            <span className="icon-selected">
              <IconCheck />
            </span>
          </Mui.MenuItem>
        );
      });
      return [
        // <Mui.ListSubheader >
        <Mui.MenuItem style={{ fontWeight: 'bold', background: '#f9f9f9', paddingBottom: '2rem', paddingTop: '2rem' }} key={data.id} value={data.id}>
          {data.name}
          <span className="icon-selected">
            <IconCheck />
          </span>
        </Mui.MenuItem>,
        // </Mui.ListSubheader>,
        items,
      ];
    };
    return (
      <Mui.FormControl
        variant="outlined"
        className={`default-form-field tbl-filter-field ${Mui.isWidthDown("xs", this.props.width) ? "mb-2" : leftMargin ? "ml-2" : ""
          }`}
      >
        <Mui.TextField
          select
          variant="outlined"
          InputProps={
            this.props.label
              ? {
                startAdornment: (
                  <Mui.InputAdornment position="start">{this.props.label}:</Mui.InputAdornment>
                ),
              }
              : null
          }
          onChange={this.props.onFilter}
          value={this.props.options ? this.props.value ? this.props.value : "" : ""}
          SelectProps={{
            MenuProps: {
              className: "select-dropdown",
              getContentAnchorEl: null,

              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              classes: this.props.classes || {},
            },
            IconComponent: IconChevronDown,
            displayEmpty: this.props.displayEmpty,
          }}
        >
          {this.props.displayEmpty ? (
            <Mui.MenuItem value="">{t("COMMON.ALL")}</Mui.MenuItem>
          ) : null}

          {this.props.options?.map((option) => renderSelectGroup(option))}
        </Mui.TextField>
      </Mui.FormControl>
    );
  }
}
export default withTranslation()(Mui.withWidth()(GroupFilter));
