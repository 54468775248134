import React from "react";

const IconImport = () => {
  return (
    <svg height="22" version="1.1" viewBox="-25 0 512 512.00078" width="22">
      <g>
        <path
          d="M 266.4375 104.960938 L 322.957031 104.960938 L 236.449219 13.957031 L 236.449219 74.855469 C 236.449219 91.351562 249.828125 104.960938 266.4375 104.960938 Z M 266.4375 104.960938 "
          stroke="none"
          fillRule="nonzero"
          fill="rgb(0%,0%,0%)"
          fillOpacity="1"
        />
        <path
          d="M 323.992188 236.335938 C 247.984375 236.335938 186.160156 298.15625 186.160156 374.167969 C 186.160156 450.175781 247.984375 512 323.992188 512 C 400.003906 512 461.828125 450.175781 461.828125 374.167969 C 461.828125 298.15625 400.003906 236.335938 323.992188 236.335938 Z M 398.042969 385.933594 L 330.453125 458.480469 C 328.722656 460.328125 326.300781 461.367188 323.648438 461.367188 C 320.996094 461.367188 318.6875 460.328125 316.957031 458.480469 L 249.484375 385.933594 C 246.023438 382.242188 246.253906 376.359375 249.945312 372.898438 C 253.636719 369.4375 259.632812 369.667969 263.09375 373.359375 L 314.765625 428.722656 L 314.765625 296.886719 C 314.765625 291.8125 318.917969 287.660156 323.992188 287.660156 C 329.070312 287.660156 333.222656 291.8125 333.222656 296.886719 L 333.222656 428.722656 L 384.664062 373.359375 C 388.125 369.667969 393.890625 369.4375 397.582031 372.898438 C 401.386719 376.359375 401.503906 382.125 398.042969 385.933594 Z M 398.042969 385.933594 "
          stroke="none"
          fillRule="nonzero"
          fill="rgb(0%,0%,0%)"
          fillOpacity="1"
        />
        <path
          d="M 172.667969 334.488281 L 72.433594 334.488281 C 67.359375 334.488281 63.207031 330.339844 63.207031 325.261719 C 63.207031 320.1875 67.359375 316.035156 72.433594 316.035156 L 178.78125 316.035156 C 184.660156 301.042969 192.503906 288.351562 202.195312 275.664062 L 72.433594 275.664062 C 67.359375 275.664062 63.207031 271.515625 63.207031 266.4375 C 63.207031 261.363281 67.359375 257.210938 72.433594 257.210938 L 219.726562 257.210938 C 247.40625 232.988281 284.085938 217.535156 324.109375 217.535156 C 327.914062 217.535156 331.03125 217.878906 335.644531 218.226562 L 335.644531 123.414062 L 266.4375 123.414062 C 239.679688 123.414062 217.996094 101.5 217.996094 74.855469 L 217.996094 0 L 41.0625 0 C 18.222656 0 0 18.683594 0 41.636719 L 0 393.660156 C 0 416.613281 18.222656 434.835938 41.0625 434.835938 L 180.046875 434.835938 C 172.089844 416.382812 167.707031 395.621094 167.707031 373.9375 C 167.589844 360.328125 169.4375 347.175781 172.667969 334.488281 Z M 72.433594 199.539062 L 189.277344 199.539062 C 194.351562 199.539062 198.503906 203.691406 198.503906 208.769531 C 198.503906 213.84375 194.351562 217.996094 189.277344 217.996094 L 72.433594 217.996094 C 67.359375 217.996094 63.207031 213.84375 63.207031 208.769531 C 63.207031 203.691406 67.359375 199.539062 72.433594 199.539062 Z M 72.433594 199.539062 "
          stroke="none"
          fillRule="nonzero"
          fill="rgb(0%,0%,0%)"
          fillOpacity="1"
        />
      </g>
    </svg>
  );
};

export default IconImport;
