
export const GET_BASIC_SETTING = "GET_BASIC_SETTING";
export const GET_BASIC_SETTING_SUCCESS = "GET_BASIC_SETTING_SUCCESS";
export const GET_BASIC_SETTING_ERROR = "GET_BASIC_SETTING_ERROR";
export const GET_SETTING = "GET_SETTING";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const GET_SETTING_ERROR = "GET_SETTING_ERROR";
export const EDIT_SETTING = "EDIT_SETTING";
export const EDIT_SETTING_SUCCESS = "EDIT_SETTING_SUCCESS";
export const EDIT_SETTING_ERROR = "EDIT_SETTING_ERROR";
export const GET_POS_TERMINAL = "GET_POS_TERMINAL";
export const GET_POS_TERMINAL_SUCCESS = "GET_POS_TERMINAL_SUCCESS";
export const GET_POS_TERMINAL_ERROR = "GET_POS_TERMINAL_ERROR";
export const RESET_SETTING = "RESET_SETTING";


export const getBasicSetting = (fields = null) => ({
  type: GET_BASIC_SETTING,
  payload: { fields },
});

export const getBasicSettingSuccess = (settingData) => ({
  type: GET_BASIC_SETTING_SUCCESS,
  payload: settingData,
});

export const getBasicSettingError = (error) => ({
  type: GET_BASIC_SETTING_ERROR,
  payload: error,
});

export const getSetting = (fields = null) => ({
  type: GET_SETTING,
  payload: { fields },
});

export const getSettingSuccess = (settingData) => ({
  type: GET_SETTING_SUCCESS,
  payload: settingData,
});

export const getSettingError = (error) => ({
  type: GET_SETTING_ERROR,
  payload: error,
});

export const editSetting = (settingData) => ({
  type: EDIT_SETTING,
  payload: { settingData },
});

export const editSettingSuccess = (success, message) => ({
  type: EDIT_SETTING_SUCCESS,
  payload: { success, message },
});

export const editSettingError = (error) => ({
  type: EDIT_SETTING_ERROR,
  payload: error,
});

export const getPOSTerminal = () => ({
  type: GET_POS_TERMINAL,
  payload: {},
});

export const getPOSTerminalSuccess = (posTerminalData) => ({
  type: GET_POS_TERMINAL_SUCCESS,
  payload: posTerminalData,
});

export const getPOSTerminalError = (error) => ({
  type: GET_POS_TERMINAL_ERROR,
  payload: error,
});

export const resetSetting = () => ({
  type: RESET_SETTING,
  payload: {},
});
