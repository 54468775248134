import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import * as Mui from "@material-ui/core";
import { IconCalendar } from "../svg";

class DateFilter extends Component {
  render() {
    return (
      <Mui.Box maxWidth="32rem" className="ml-2">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className="date-time-picker"
            autoOk
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="normal"
            InputProps={{
              classes: { input: "text-color-default" },
              startAdornment: (
                <Mui.InputAdornment>{this.props.label}:</Mui.InputAdornment>
              ),
            }}
            minDate={new Date(this.props.minDate)}
            maxDate={new Date(this.props.maxDate)}
            initialFocusedDate={new Date(this.props.maxDate)} // To prevent auto-selection of date when opened
            placeholder={"MM/DD/YYYY"}
            value={this.props.value || null}
            onChange={this.props.onDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            keyboardIcon={<IconCalendar />}
          />
        </MuiPickersUtilsProvider>
      </Mui.Box>
    );
  }
}

export default DateFilter;
