import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import mediaSagas from "./media/saga";
import sharedSagas from "./shared/saga";
import userSagas from "./user/saga";
import roleSagas from "./role/saga";
import organizationSagas from "./organization/saga";
import settingSagas from "./setting/saga";
import productSagas from "./product/saga";
import productCategorySagas from "./productCategory/saga";
import menuSagas from "./menu/saga";
import buildingSagas from "./building/saga";
import orderSagas from "./order/saga";
import canteenSagas from "./canteen/saga";
import tenantSagas from "./tenant/saga";
import tenantEmployeesSagas from "./tenant-employees/saga";
import kioskSagas from "./kiosk/saga";
import dashboardSagas from "./dashboard/saga";
import reportSagas from "./report/saga";
import configChoiceSagas from "./config-choice/saga";
import configChoiceCategorySagas from "./config-choice-category/saga";
import termsAndConditionSaga from "./terms-and-conditions/saga"

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    mediaSagas(),
    sharedSagas(),
    userSagas(),
    roleSagas(),
    organizationSagas(),
    settingSagas(),
    productSagas(),
    productCategorySagas(),
    menuSagas(),
    buildingSagas(),
    orderSagas(),
    canteenSagas(),
    tenantSagas(),
    tenantEmployeesSagas(),
    kioskSagas(),
    dashboardSagas(),
    reportSagas(),
    configChoiceSagas(),
    configChoiceCategorySagas(),
    termsAndConditionSaga()
  ]);
}
