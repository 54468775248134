import React, { useEffect } from "react";
import * as Mui from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { sanitize } from "dompurify";

const Alert = (props) => {
  const {
    open,
    close,
    action,
    title,
    info,
    subInfo = null,
    awaitingInfo,
    actionBtnLabel,
    loading,
    success,
    error = null,
    reset,
    disableActionBtn
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (success) {
      close();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleClose = (_, reason) => {
    if (reason === 'backdropClick') {
      return
    }
    close()
  };

  return (
    <Mui.Dialog
      // disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      onClose={handleClose}
      // fullWidth={true}
      maxWidth={"sm"}
      classes={{ paper: "default-alert-dialog" }}
    >
      <Mui.DialogContent>
        <Mui.Typography
          component="h3"
          variant="h3"
          className="default-alert-dialog__title"
        >
          {title || ""}
        </Mui.Typography>

        <Mui.Typography
          component="p"
          className={`message ${error ? "error" : null}`}
        >
          {loading ? (
            awaitingInfo
          ) : error ? (
            error
          ) : (
            <>
              <span dangerouslySetInnerHTML={{ __html: sanitize(info) }}></span> <br /> <span className="font-size-default">{subInfo}</span>
            </>
          )}
        </Mui.Typography>
      </Mui.DialogContent>

      {loading ? (
        <Mui.LinearProgress classes={{ root: "mt-4 mb-2 progressing" }} />
      ) : (
        <Mui.Divider light={true} className="mt-4 mb-2" />
      )}

      <Mui.DialogActions>
        <Mui.Button
          onClick={action}
          disabled={disableActionBtn}
          className="btn-default"
          variant="contained"
          color="primary"
          disableElevation
          autoFocus
          data-cy="dialog-action-button"
        >
          {actionBtnLabel}
        </Mui.Button>

        <Mui.Button
          onClick={() => close()}
          color="primary"
          className="btn-default no-color-btn"
          variant="outlined"
          data-cy="dialog-cancel-button"
        >
          {t("COMMON.BUTTON.CANCEL")}
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};
export default Alert;
