import {
  GET_ALL_CANTEEN,
  GET_ALL_CANTEEN_SUCCESS,
  GET_ALL_CANTEEN_ERROR,
  GET_CANTEEN_LIST,
  GET_CANTEEN_LIST_SUCCESS,
  GET_CANTEEN_LIST_ERROR,
  ADD_CANTEEN,
  ADD_CANTEEN_SUCCESS,
  ADD_CANTEEN_ERROR,
  GET_CANTEEN,
  GET_CANTEEN_SUCCESS,
  GET_CANTEEN_ERROR,
  EDIT_CANTEEN,
  EDIT_CANTEEN_SUCCESS,
  EDIT_CANTEEN_ERROR,
  DELETE_CANTEEN,
  DELETE_CANTEEN_SUCCESS,
  DELETE_CANTEEN_ERROR,
  DELETE_MULTIPLE_CANTEEN,
  DELETE_MULTIPLE_CANTEEN_SUCCESS,
  DELETE_MULTIPLE_CANTEEN_ERROR,
  RESET_CANTEEN,
  GET_CANTEEN_PREDICTION,
  GET_CANTEEN_PREDICTION_SUCCESS,
  GET_CANTEEN_PREDICTION_ERROR,
  ADD_CROCKERY,
  ADD_CROCKERY_SUCCESS,
  ADD_CROCKERY_ERROR,
  DELETE_CROCKERY,
  DELETE_CROCKERY_SUCCESS,
  DELETE_CROCKERY_ERROR,
  EDIT_CROCKERY,
  EDIT_CROCKERY_SUCCESS,
  EDIT_CROCKERY_ERROR,
  GET_WEIGHTED_LIST,
  GET_WEIGHTED_LIST_SUCCESS,
  GET_WEIGHTED_LIST_ERROR,
  EDIT_WEIGHTED_LIST,
  EDIT_WEIGHTED_LIST_ERROR,
  EDIT_WEIGHTED_LIST_SUCCESS,
  EXPORT_CANTEEN_PRODUCTS,
  EXPORT_CANTEEN_PRODUCTS_SUCCESS,
  EXPORT_CANTEEN_PRODUCTS_ERROR,
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  canteens: null,
  canteenList: null,
  canteenData: null,
  predictionData: null,
  predictionLoading: false,
  predictionError: false,
  canteenId: null,
  organizationId: null,
  canteenIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,

  crockeryLoading: false,

  weightedListLoading: false,
  weightedListLoading1: false,
  weightedList: null
};

const canteenReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CANTEEN:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_CANTEEN_SUCCESS:
      return {
        ...state,
        canteens: action.payload,
        error: null,
      };
    case GET_ALL_CANTEEN_ERROR:
      return {
        ...state,
        canteens: null,
        error: action.payload,
      };
    case GET_CANTEEN_LIST:
      return {
        ...state,
        loading: true,
        canteenId: null,
        canteenData: null,
        canteenIds: null,
        error: null,
      };
    case GET_CANTEEN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        canteenList: action.payload,
        error: null,
      };
    case GET_CANTEEN_LIST_ERROR:
      return {
        ...state,
        loading: false,
        canteenList: null,
        error: action.payload,
      };
    case ADD_CANTEEN:
      return { ...state, loading: true, error: null };
    case ADD_CANTEEN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_CANTEEN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_CANTEEN:
      return { ...state, error: null };
    case GET_CANTEEN_SUCCESS:
      return {
        ...state,
        canteenData: action.payload,
        error: null,
      };
    case GET_CANTEEN_ERROR:
      return {
        ...state,
        canteenData: null,
        error: action.payload,
      };
    case EDIT_CANTEEN:
      return { ...state, loading: true, error: null };
    case EDIT_CANTEEN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_CANTEEN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_CANTEEN:
      return { ...state, loading1: true, error: null };
    case DELETE_CANTEEN_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_CANTEEN_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_CANTEEN:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_CANTEEN_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_CANTEEN_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_CANTEEN:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    case GET_CANTEEN_PREDICTION:
      return {
        ...state,
        predictionData: null,
        predictionLoading: true,
        predictionError: false,
      };
    case GET_CANTEEN_PREDICTION_SUCCESS:
      return {
        ...state,
        predictionData: action.payload,
        predictionLoading: false,
        predictionError: false,
      };
    case GET_CANTEEN_PREDICTION_ERROR:
      return {
        ...state,
        predictionData: [],
        predictionLoading: false,
        predictionError: true,
      };

    case ADD_CROCKERY:
      return { ...state, success: false, crockeryLoading: true, error: null };
    case ADD_CROCKERY_SUCCESS:
      return {
        ...state,
        crockeryLoading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_CROCKERY_ERROR:
      return {
        ...state,
        crockeryLoading: false,
        success: false,
        message: null,
        error: action.payload,
      };


    case DELETE_CROCKERY:
      return { ...state, success: false, crockeryLoading: true, error: null };
    case DELETE_CROCKERY_SUCCESS:
      return {
        ...state,
        crockeryLoading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_CROCKERY_ERROR:
      return {
        ...state,
        crockeryLoading: false,
        success: false,
        message: null,
        error: action.payload,
      };


    case EDIT_CROCKERY:
      return { ...state, success: false, crockeryLoading: true, error: null };
    case EDIT_CROCKERY_SUCCESS:
      return {
        ...state,
        crockeryLoading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_CROCKERY_ERROR:
      return {
        ...state,
        crockeryLoading: false,
        success: false,
        message: null,
        error: action.payload,
      };


    case GET_WEIGHTED_LIST:
      return {
        ...state,
        weightedList: null,
        weightedListLoading: true,
        success: false,
        error: null
      };
    case GET_WEIGHTED_LIST_SUCCESS:
      return {
        ...state,
        weightedList: action.payload,
        weightedListLoading: false,
        success: true,
        error: null
      };
    case GET_WEIGHTED_LIST_ERROR:
      return {
        ...state,
        weightedList: null,
        weightedListLoading: false,
        success: false,
        error: action.payload
      };


    case EDIT_WEIGHTED_LIST:
      return { ...state, success: false, weightedListLoading1: true, error: null };
    case EDIT_WEIGHTED_LIST_SUCCESS:
      return {
        ...state,
        weightedListLoading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_WEIGHTED_LIST_ERROR:
      return {
        ...state,
        weightedListLoading1: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case EXPORT_CANTEEN_PRODUCTS:
      return { ...state, loading1: true, success: false, error: null };
    case EXPORT_CANTEEN_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_CANTEEN_PRODUCTS_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
export default canteenReducer;
