import {
  GET_ALL_ORGANIZATION,
  GET_ALL_ORGANIZATION_SUCCESS,
  GET_ALL_ORGANIZATION_ERROR,
  GET_ORGANIZATION_LIST,
  GET_ORGANIZATION_LIST_SUCCESS,
  GET_ORGANIZATION_LIST_ERROR,
  ADD_ORGANIZATION,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_ERROR,
  ONBOARD_ORGANIZATION,
  ONBOARD_ORGANIZATION_SUCCESS,
  ONBOARD_ORGANIZATION_ERROR,
  GET_ORGANIZATION,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_ERROR,
  EDIT_ORGANIZATION,
  EDIT_ORGANIZATION_SUCCESS,
  EDIT_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_ERROR,
  DELETE_MULTIPLE_ORGANIZATION,
  DELETE_MULTIPLE_ORGANIZATION_SUCCESS,
  DELETE_MULTIPLE_ORGANIZATION_ERROR,
  RESEND_EMAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_ERROR,
  RESET_ORGANIZATION,
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  param: null,
  organizations: null,
  organizationList: null,
  organizationData: null,
  organizationId: null,
  organizationIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  error: null,
};

const organizationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ORGANIZATION:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
        error: null,
      };
    case GET_ALL_ORGANIZATION_ERROR:
      return {
        ...state,
        organizations: null,
        error: action.payload,
      };
    case GET_ORGANIZATION_LIST:
      return {
        ...state,
        loading: true,
        organizationData: null,
        organizationId: null,
        organizationIds: null,
        error: null,
      };
    case GET_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationList: action.payload,
        error: null,
      };
    case GET_ORGANIZATION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        organizationList: null,
        error: action.payload,
      };
    case ONBOARD_ORGANIZATION:
      return { ...state, loading1: true, error: null };
    case ONBOARD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ONBOARD_ORGANIZATION_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case ADD_ORGANIZATION:
      return { ...state, loading: true, error: null };
    case ADD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_ORGANIZATION:
      return { ...state, error: null };
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationData: action.payload,
        error: null,
      };
    case GET_ORGANIZATION_ERROR:
      return {
        ...state,
        organizationData: null,
        error: action.payload,
      };
    case EDIT_ORGANIZATION:
      return { ...state, loading: true, error: null };
    case EDIT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_ORGANIZATION:
      return { ...state, loading1: true, error: null };
    case DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_ORGANIZATION_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_ORGANIZATION:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_ORGANIZATION_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESEND_EMAIL:
      return { ...state, loading1: true, error: null };
    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case RESEND_EMAIL_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_ORGANIZATION:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default organizationReducer;
