
import * as Mui from '@material-ui/core';
import IconExpired from '../../components/svg/icon-expired';

export const TokenExpired = () => {

    return <Mui.Box style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <Mui.Box className='app-container-expiry-padding' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '4rem', border: '1px solid #e9ecef', borderRadius: '3rem' }}>
            <IconExpired />
            <Mui.Box style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <Mui.Typography className='app-container-heading-1'>Oi, linken din er utløpt!</Mui.Typography>
                <Mui.Typography className='app-container-paragraph' style={{ textAlign: 'center' }}>Linken du klikket på er feil eller ikke lenger gyldig.<br />
                    Kontakt organisasjonens administrator for QR-kodelenken.
                </Mui.Typography>
            </Mui.Box>
        </Mui.Box>
    </Mui.Box>
}