import React, { useEffect } from "react";
import * as Mui from "@material-ui/core";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { IconCheck, IconChevronDown } from "../svg";
import Skeleton from "react-loading-skeleton";

const SelectField = ({
  label,
  classes = {},
  v2 = false,
  options,
  optionLabel,
  variant,
  callback,
  shrinkLabel = true,
  readonlyArr = [],
  defaultValue = "",
  loading = false,
  ...props
}) => {
  const { i18n } = useTranslation();
  const [field, meta] = useField(props);

  useEffect(() => {
    if (callback) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.value]);

  return (
    <Mui.FormControl
      error={meta.touched && meta.error ? true : false}
      variant={variant ? variant : "outlined"}
    >
      <Mui.InputLabel style={{ whiteSpace: 'nowrap' }} shrink={shrinkLabel} htmlFor="select">
        {label}
      </Mui.InputLabel>
      {loading ? (
        <Skeleton height={40} />
      ) : (
        <Mui.Select
          {...field}
          {...props}
          data-cy="select-field"
          IconComponent={IconChevronDown}
          value={field.value || defaultValue}
          MenuProps={{
            className: `select-dropdown ${v2 ? "v2" : null}`,
            getContentAnchorEl: null,

            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            classes: classes,
          }}
        >
          {options?.map((item, index) => {
            return (
              <Mui.MenuItem
                disabled={readonlyArr.indexOf(item.id) > -1}
                key={index}
                value={item.id}
                data-cy={`option-menu-${index}`}
              >
                {typeof item?.[optionLabel] === "object"
                  ? item?.[optionLabel]?.[i18n.language] || ""
                  : item?.[optionLabel]}

                <span className="icon-selected">
                  <IconCheck />
                </span>
              </Mui.MenuItem>
            );
          })}
        </Mui.Select>
      )}
      {meta.touched && meta.error ? (
        <Mui.FormHelperText error>
          {meta.touched && meta.error ? meta.error : null}
        </Mui.FormHelperText>
      ) : null}
    </Mui.FormControl>
  );
};

export default SelectField;
