export const GET_ALL_BUILDING = "GET_ALL_BUILDING";
export const GET_ALL_BUILDING_SUCCESS = "GET_ALL_BUILDING_SUCCESS";
export const GET_ALL_BUILDING_ERROR = "GET_ALL_BUILDING_ERROR";
export const GET_BUILDING_LIST = "GET_BUILDING_LIST";
export const GET_BUILDING_LIST_SUCCESS = "GET_BUILDING_LIST_SUCCESS";
export const GET_BUILDING_LIST_ERROR = "GET_BUILDING_LIST_ERROR";
export const ADD_BUILDING = "ADD_BUILDING";
export const ADD_BUILDING_SUCCESS = "ADD_BUILDING_SUCCESS";
export const ADD_BUILDING_ERROR = "ADD_BUILDING_ERROR";
export const GET_BUILDING = "GET_BUILDING";
export const GET_BUILDING_SUCCESS = "GET_BUILDING_SUCCESS";
export const GET_BUILDING_ERROR = "GET_BUILDING_ERROR";
export const EDIT_BUILDING = "EDIT_BUILDING";
export const EDIT_BUILDING_SUCCESS = "EDIT_BUILDING_SUCCESS";
export const EDIT_BUILDING_ERROR = "EDIT_BUILDING_ERROR";
export const DELETE_BUILDING = "DELETE_BUILDING";
export const DELETE_BUILDING_SUCCESS = "DELETE_BUILDING_SUCCESS";
export const DELETE_BUILDING_ERROR = "DELETE_BUILDING_ERROR";
export const DELETE_MULTIPLE_BUILDING = "DELETE_MULTIPLE_BUILDING";
export const DELETE_MULTIPLE_BUILDING_SUCCESS =
  "DELETE_MULTIPLE_BUILDING_SUCCESS";
export const DELETE_MULTIPLE_BUILDING_ERROR = "DELETE_MULTIPLE_BUILDING_ERROR";
export const RESET_BUILDING = "RESET_BUILDING";

export const getAllBuilding = () => ({
  type: GET_ALL_BUILDING,
  payload: {},
});

export const getAllBuildingSuccess = (buildings) => ({
  type: GET_ALL_BUILDING_SUCCESS,
  payload: buildings,
});

export const getAllBuildingError = (error) => ({
  type: GET_ALL_BUILDING_ERROR,
  payload: error,
});

export const getBuildingList = (dbParam) => ({
  type: GET_BUILDING_LIST,
  payload: { dbParam },
});

export const getBuildingListSuccess = (buildingList) => ({
  type: GET_BUILDING_LIST_SUCCESS,
  payload: buildingList,
});

export const getBuildingListError = (error) => ({
  type: GET_BUILDING_LIST_ERROR,
  payload: error,
});

export const addBuilding = (buildingData, history, location) => ({
  type: ADD_BUILDING,
  payload: { buildingData, history, location },
});

export const addBuildingSuccess = (success, message) => ({
  type: ADD_BUILDING_SUCCESS,
  payload: { success, message },
});

export const addBuildingError = (error) => ({
  type: ADD_BUILDING_ERROR,
  payload: error,
});

export const getBuilding = (buildingId, organizationId) => ({
  type: GET_BUILDING,
  payload: { buildingId, organizationId },
});

export const getBuildingSuccess = (buildingData) => ({
  type: GET_BUILDING_SUCCESS,
  payload: buildingData,
});

export const getBuildingError = (error) => ({
  type: GET_BUILDING_ERROR,
  payload: error,
});

export const editBuilding = (buildingId, buildingData, history, location) => ({
  type: EDIT_BUILDING,
  payload: { buildingId, buildingData, history, location },
});

export const editBuildingSuccess = (success, message) => ({
  type: EDIT_BUILDING_SUCCESS,
  payload: { success, message },
});

export const editBuildingError = (error) => ({
  type: EDIT_BUILDING_ERROR,
  payload: error,
});

export const deleteBuilding = (buildingId) => ({
  type: DELETE_BUILDING,
  payload: { buildingId },
});

export const deleteBuildingSuccess = (success, message) => ({
  type: DELETE_BUILDING_SUCCESS,
  payload: { success, message },
});

export const deleteBuildingError = (error) => ({
  type: DELETE_BUILDING_ERROR,
  payload: error,
});

export const deleteMultipleBuilding = (buildingIds) => ({
  type: DELETE_MULTIPLE_BUILDING,
  payload: { buildingIds },
});

export const deleteMultipleBuildingSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_BUILDING_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleBuildingError = (error) => ({
  type: DELETE_MULTIPLE_BUILDING_ERROR,
  payload: error,
});

export const resetBuilding = () => ({
  type: RESET_BUILDING,
  payload: {},
});
