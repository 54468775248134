import {
  RESET_CANTEEN_PRODUCT_MENU_LIST,
  UPDATE_MULTIPLE_CANTEEN_PRODUCT_LIST,
  UPDATE_CANTEEN_PRODUCT_LIST,
  DETACH_CANTEEN_PRODUCT_LIST,
  SET_CANTEEN_PRODUCT_LIST,
  GET_CANTEEN_PRODUCT_LIST,
  GET_CANTEEN_PRODUCT_LIST_SUCCESS,
  GET_CANTEEN_PRODUCT_LIST_ERROR,
  UPDATE_MULTIPLE_CANTEEN_MENU_LIST,
  UPDATE_CANTEEN_MENU_LIST,
  DETACH_CANTEEN_MENU_LIST,
  SET_CANTEEN_MENU_LIST,
  GET_CANTEEN_MENU_LIST,
  GET_CANTEEN_MENU_LIST_SUCCESS,
  GET_CANTEEN_MENU_LIST_ERROR,
  SET_TENANT_CANTEEN_PRODUCT_LIST,
  SET_TENANT_CANTEEN_MENU_LIST,
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCT_SUCCESS,
  GET_ALL_PRODUCT_ERROR,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_CATEGORY_ERROR,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
  DELETE_MULTIPLE_PRODUCT,
  DELETE_MULTIPLE_PRODUCT_SUCCESS,
  DELETE_MULTIPLE_PRODUCT_ERROR,
  IMPORT_PRODUCT,
  IMPORT_PRODUCT_SUCCESS,
  IMPORT_PRODUCT_ERROR,
  ADD_PRODUCT_CLASS,
  ADD_PRODUCT_CLASS_SUCCESS,
  ADD_PRODUCT_CLASS_ERROR,
  RESET_PRODUCT,
  UPDATE_PRODUCT_NUTRITION,
  UPDATE_PRODUCT_NUTRITION_SUCCESS,
  UPDATE_PRODUCT_NUTRITION_ERROR,
  PRODUCT_BULK_STATUS_UPDATE,
  PRODUCT_BULK_STATUS_UPDATE_SUCCESS,
  PRODUCT_BULK_STATUS_UPDATE_ERROR,
  PULL_IMAGE_FROM_ANNOTATION,
  PULL_IMAGE_FROM_ANNOTATION_SUCCESS,
  PULL_IMAGE_FROM_ANNOTATION_ERROR,
  EXPORT_PRODUCTS,
  EXPORT_PRODUCTS_SUCCESS,
  EXPORT_PRODUCTS_ERROR,
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  param: null,
  importParam: null,
  tenantCanteenProducts: [],
  tenantCanteenMenus: [],
  canteenId: null,
  canteenIds: [],
  canteenProductArr: [],
  canteenMenuArr: [],
  canteenProductList: [],
  canteenMenuList: [],
  tenantCanteenProductList: [],
  tenantCanteenMenuList: [],
  products: null,
  productList: null,
  productData: null,
  productCategoryData: null,
  productId: null,
  organizationId: null,
  productIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  error: null,
};

/** Product */

// Update canteen product list in Tenant Edit UI
function _updateCanteenProductList(canteenProductList, indices, value, key) {
  let a = indices[0];
  let b = indices[1];
  canteenProductList[a].products.forEach(function (item, i) {
    if (i === b) this[i][key] = value;
  }, canteenProductList[a].products);
  return canteenProductList;
}

// Update multiple canteen product list in Tenant Edit UI
function _updateMultipleCanteenProductList(
  canteenProductList,
  index,
  arr,
  value,
  key
) {
  canteenProductList[index].products.forEach(function (item, i) {
    if (arr.indexOf(item.productId) > -1) this[i][key] = value;
  }, canteenProductList[index].products);
  return canteenProductList;
}

// Detach canteen product list upon canteen select in Tenant Edit UI
function _detachCanteenProductList(canteenProductList, canteenIds) {
  let newCanteenProductLists;
  newCanteenProductLists = canteenProductList.filter(
    (value) => canteenIds.indexOf(value.canteenId) > -1
  );
  return newCanteenProductLists;
}

// Set tenant canteen product list for Tenant Edit UI
function _setCanteenProductList(tenantCanteenProducts, canteenIdArr) {
  let newCanteenProductLists;
  const el = tenantCanteenProducts.reduce((el, item) => {
    const canteenId = item.canteenId;
    if (!el[canteenId]) {
      el[canteenId] = [];
    }
    el[canteenId].push(item);
    return el;
  }, {});

  newCanteenProductLists = Object.keys(el).map((canteenId) => {
    return {
      canteenId: parseInt(canteenId),
      products: el[canteenId],
    };
  });
  let notIncludedCanteenId = canteenIdArr.filter((el) => {
    return !newCanteenProductLists.find((item) => {
      return el === item.canteenId;
    });
  });

  if (notIncludedCanteenId?.length > 0) {
    notIncludedCanteenId.forEach(function (el) {
      newCanteenProductLists.push({
        canteenId: parseInt(el),
        products: [],
      });
    });
  }
  return newCanteenProductLists;
}

// Append canteen product list upon canteen select in Tenant Edit UI
function _appendCanteenProductList(
  canteenProductList,
  newCanteenProductList,
  canteenId
) {
  const hasExisting = canteenProductList.some(function (o) {
    return o["canteenId"] === canteenId;
  });
  let newCanteenProductLists;

  if (hasExisting) {
    newCanteenProductLists = canteenProductList;
  } else {
    const arr = JSON.stringify(newCanteenProductList);
    const copyArr = JSON.parse(arr);
    newCanteenProductLists = [...canteenProductList, ...copyArr];
  }

  return newCanteenProductLists;
}

// Set tenant canteen product list for Tenant View UI
function _setTenantCanteenProductList(tenantCanteenProducts, canteenIdArr) {
  let newCanteenProductLists;
  const el = tenantCanteenProducts.reduce((el, item) => {
    const canteenId = item.canteenId;
    if (!el[canteenId]) {
      el[canteenId] = [];
    }
    el[canteenId].push(item);
    return el;
  }, {});

  newCanteenProductLists = Object.keys(el).map((canteenId) => {
    return {
      canteenId: parseInt(canteenId),
      products: el[canteenId],
    };
  });
  let notIncludedCanteenId = canteenIdArr.filter((el) => {
    return !newCanteenProductLists.find((item) => {
      return el === item.canteenId;
    });
  });

  if (notIncludedCanteenId?.length > 0) {
    notIncludedCanteenId.forEach(function (el) {
      newCanteenProductLists.push({
        canteenId: parseInt(el),
        products: [],
      });
    });
  }
  return newCanteenProductLists;
}

/** Menu */

// Update canteen menu list in Tenant Edit UI
function _updateCanteenMenuList(canteenMenuList, indices, value, key) {
  let a = indices[0];
  let b = indices[1];
  canteenMenuList[a].menus.forEach(function (item, i) {
    if (i === b) this[i][key] = value;
  }, canteenMenuList[a].menus);
  return canteenMenuList;
}

// Update multiple canteen menu list in Tenant Edit UI
function _updateMultipleCanteenMenuList(
  canteenMenuList,
  index,
  arr,
  value,
  key
) {
  canteenMenuList[index].menus.forEach(function (item, i) {
    if (arr.indexOf(item.menuId) > -1) this[i][key] = value;
  }, canteenMenuList[index].menus);
  return canteenMenuList;
}

// Detach canteen menu list upon canteen select in Tenant Edit UI
function _detachCanteenMenuList(canteenMenuList, canteenIds) {
  let newCanteenMenuLists;
  newCanteenMenuLists = canteenMenuList.filter(
    (value) => canteenIds.indexOf(value.canteenId) > -1
  );
  return newCanteenMenuLists;
}

// Set tenant canteen menu list for Tenant Edit UI
function _setCanteenMenuList(tenantCanteenMenus, canteenIdArr) {
  let newCanteenMenuLists;
  const el = tenantCanteenMenus.reduce((el, item) => {
    const canteenId = item.canteenId;
    if (!el[canteenId]) {
      el[canteenId] = [];
    }
    el[canteenId].push(item);
    return el;
  }, {});

  newCanteenMenuLists = Object.keys(el).map((canteenId) => {
    return {
      canteenId: parseInt(canteenId),
      menus: el[canteenId],
    };
  });
  let notIncludedCanteenId = canteenIdArr.filter((el) => {
    return !newCanteenMenuLists.find((item) => {
      return el === item.canteenId;
    });
  });

  if (notIncludedCanteenId?.length > 0) {
    notIncludedCanteenId.forEach(function (el) {
      newCanteenMenuLists.push({
        canteenId: parseInt(el),
        menus: [],
      });
    });
  }
  return newCanteenMenuLists;
}

// Append canteen menu list upon canteen select in Tenant Edit UI
function _appendCanteenMenuList(
  canteenMenuList,
  newCanteenMenuList,
  canteenId
) {
  const hasExisting = canteenMenuList.some(function (o) {
    return o["canteenId"] === canteenId;
  });
  let newCanteenMenuLists;

  if (hasExisting) {
    newCanteenMenuLists = canteenMenuList;
  } else {
    const arr = JSON.stringify(newCanteenMenuList);
    const copyArr = JSON.parse(arr);
    newCanteenMenuLists = [...canteenMenuList, ...copyArr];
  }

  return newCanteenMenuLists;
}

// Set tenant canteen menu list for Tenant View UI
function _setTenantCanteenMenuList(tenantCanteenMenus, canteenIdArr) {
  let newCanteenMenuLists;
  const el = tenantCanteenMenus.reduce((el, item) => {
    const canteenId = item.canteenId;
    if (!el[canteenId]) {
      el[canteenId] = [];
    }
    el[canteenId].push(item);
    return el;
  }, {});

  newCanteenMenuLists = Object.keys(el).map((canteenId) => {
    return {
      canteenId: parseInt(canteenId),
      menus: el[canteenId],
    };
  });
  let notIncludedCanteenId = canteenIdArr.filter((el) => {
    return !newCanteenMenuLists.find((item) => {
      return el === item.canteenId;
    });
  });

  if (notIncludedCanteenId?.length > 0) {
    notIncludedCanteenId.forEach(function (el) {
      newCanteenMenuLists.push({
        canteenId: parseInt(el),
        menus: [],
      });
    });
  }
  return newCanteenMenuLists;
}

const productReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_CANTEEN_PRODUCT_MENU_LIST:
      return {
        ...state,
        tenantCanteenProducts: [],
        tenantCanteenMenus: [],
        canteenId: null,
        canteenIds: [],
        canteenProductArr: [],
        canteenMenuArr: [],
        canteenProductList: [],
        canteenMenuList: [],
        tenantCanteenProductList: [],
        tenantCanteenMenuList: [],
        error: null,
      };
    case UPDATE_CANTEEN_PRODUCT_LIST:
      return {
        ...state,
        canteenProductList: _updateCanteenProductList(
          state.canteenProductList,
          action.payload.indices,
          action.payload.value,
          action.payload.key
        ),
        error: null,
      };
    case UPDATE_MULTIPLE_CANTEEN_PRODUCT_LIST:
      return {
        ...state,
        canteenProductList: _updateMultipleCanteenProductList(
          state.canteenProductList,
          action.payload.index,
          action.payload.arr,
          action.payload.value,
          action.payload.key
        ),
        error: null,
      };
    case DETACH_CANTEEN_PRODUCT_LIST:
      return {
        ...state,
        canteenProductList: _detachCanteenProductList(
          state.canteenProductList,
          action.payload
        ),
        error: null,
      };
    case SET_CANTEEN_PRODUCT_LIST:
      return {
        ...state,
        canteenProductList: _setCanteenProductList(
          action.payload.tenantCanteenProducts,
          action.payload.canteenIdArr
        ),
        error: null,
      };
    case GET_CANTEEN_PRODUCT_LIST:
      return {
        ...state,
        error: null,
      };
    case GET_CANTEEN_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        canteenProductList: _appendCanteenProductList(
          state.canteenProductList,
          action.payload.canteenProductArr,
          action.payload.canteenId
        ),
        error: null,
      };
    case GET_CANTEEN_PRODUCT_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_CANTEEN_MENU_LIST:
      return {
        ...state,
        canteenMenuList: _updateCanteenMenuList(
          state.canteenMenuList,
          action.payload.indices,
          action.payload.value,
          action.payload.key
        ),
        error: null,
      };
    case UPDATE_MULTIPLE_CANTEEN_MENU_LIST:
      return {
        ...state,
        canteenMenuList: _updateMultipleCanteenMenuList(
          state.canteenMenuList,
          action.payload.index,
          action.payload.arr,
          action.payload.value,
          action.payload.key
        ),
        error: null,
      };
    case DETACH_CANTEEN_MENU_LIST:
      return {
        ...state,
        canteenMenuList: _detachCanteenMenuList(
          state.canteenMenuList,
          action.payload
        ),
        error: null,
      };
    case SET_CANTEEN_MENU_LIST:
      return {
        ...state,
        canteenMenuList: _setCanteenMenuList(
          action.payload.tenantCanteenMenus,
          action.payload.canteenIdArr
        ),
        error: null,
      };
    case GET_CANTEEN_MENU_LIST:
      return {
        ...state,
        error: null,
      };
    case GET_CANTEEN_MENU_LIST_SUCCESS:
      return {
        ...state,
        canteenMenuList: _appendCanteenMenuList(
          state.canteenMenuList,
          action.payload.canteenMenuArr,
          action.payload.canteenId
        ),
        error: null,
      };
    case GET_CANTEEN_MENU_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_TENANT_CANTEEN_PRODUCT_LIST:
      return {
        ...state,
        tenantCanteenProductList: _setTenantCanteenProductList(
          action.payload.tenantCanteenProducts,
          action.payload.canteenIdArr
        ),
        error: null,
      };
    case SET_TENANT_CANTEEN_MENU_LIST:
      return {
        ...state,
        tenantCanteenMenuList: _setTenantCanteenMenuList(
          action.payload.tenantCanteenMenus,
          action.payload.canteenIdArr
        ),
        error: null,
      };
    case GET_ALL_PRODUCT:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload,
        error: null,
      };
    case GET_ALL_PRODUCT_ERROR:
      return {
        ...state,
        products: null,
        error: action.payload,
      };
    case GET_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
        productData: null,
        productId: null,
        productIds: null,
        error: null,
      };
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productList: action.payload,
        error: null,
      };
    case GET_PRODUCT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        productList: null,
        error: action.payload,
      };
    case GET_PRODUCT_CATEGORY:
      return {
        ...state,
        error: null,
      };
    case GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategoryData: action.payload,
        error: null,
      };
    case GET_PRODUCT_CATEGORY_ERROR:
      return {
        ...state,
        productCategoryData: null,
        error: action.payload,
      };
    case ADD_PRODUCT:
      return { ...state, loading: true, error: null };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_PRODUCT:
      return { ...state, error: null };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        productData: action.payload,
        error: null,
      };
    case GET_PRODUCT_ERROR:
      return {
        ...state,
        productData: null,
        error: action.payload,
      };
    case EDIT_PRODUCT:
      return { ...state, loading: true, error: null };
    case EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_PRODUCT:
      return { ...state, loading1: true, error: null };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_PRODUCT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_PRODUCT:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_PRODUCT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case IMPORT_PRODUCT:
      return { ...state, loading1: true, error: null };
    case IMPORT_PRODUCT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case IMPORT_PRODUCT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case ADD_PRODUCT_CLASS:
      return { ...state, loading: true, error: null };
    case ADD_PRODUCT_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_PRODUCT_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case UPDATE_PRODUCT_NUTRITION:
      return { ...state, loading1: true, error: null };
    case UPDATE_PRODUCT_NUTRITION_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case UPDATE_PRODUCT_NUTRITION_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case PRODUCT_BULK_STATUS_UPDATE:
      return { ...state, loading3: true, error: null };
    case PRODUCT_BULK_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        loading3: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case PRODUCT_BULK_STATUS_UPDATE_ERROR:
      return {
        ...state,
        loading3: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case PULL_IMAGE_FROM_ANNOTATION:
      return { ...state, loading2: true, error: null };
    case PULL_IMAGE_FROM_ANNOTATION_SUCCESS:
      return {
        ...state,
        loading2: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case PULL_IMAGE_FROM_ANNOTATION_ERROR:
      return {
        ...state,
        loading2: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case EXPORT_PRODUCTS:
      return { ...state, loading1: true, error: null };
    case EXPORT_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_PRODUCTS_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_PRODUCT:
      return {
        ...state,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        success: false,
        message: null,
        error: null,
      };
    default:
      return { ...state };
  }
};

export default productReducer;
