import * as Mui from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import * as Yup from "yup";
import AuthGraphicOne from "../../../assets/images/auth-graphics.png";
import { InputField, InputPasswordField } from "../../../components/form";
import { IconLogo, IconUsero } from "../../../components/svg";
import LanguageDropdownAlt from "../../../layouts/topnav/language-dropdown-alt";
import { login, resetAuth } from "../../../reduxs/actions";
class Login extends Component {
  constructor(props) {
    super(props);
    // Success/error message
    this.responseMsg = this.props.location?.state?.responseMsg || "";
    this.validationSchema = Yup.object().shape({
      email: Yup.string()
        .email(this.props.t("AUTH.VALIDATION.INVALID_EMAIL"))
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")),
      password: Yup.string().required(
        this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")
      ),
    });
    this.props.resetAuth();
  }

  onLogin = async (values) => {
    if (!this.props.loading) {
      await this.props.login(values, this.props.history);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Mui.Grid container justifyContent="space-between" className="auth-wrap">
        <Mui.Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={6}
          className="auth-message-box p-5"
        >
          <Mui.Box>
            <Mui.Typography
              component="h2"
              variant="h2"
              className="title mt-5 font-weight-medium"
            >
              {t("AUTH.LOGIN.WELCOME_BACK")}
            </Mui.Typography>
            <Mui.Typography
              component="h4"
              variant="h4"
              className="font-weight-normal mt-5"
              color="inherit"
            >
              {t("AUTH.LOGIN.INFO")}
            </Mui.Typography>
          </Mui.Box>
          <Mui.Box className="auth-graphic-box">
            <img style={{ width: "100%" }} src={AuthGraphicOne} alt="" />
          </Mui.Box>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={5} className="auth-form-box">
          <Mui.Card className="card-box">

            <Mui.Box className="logo-icon">
              <LanguageDropdownAlt />
              <IconLogo />
            </Mui.Box>
            <Mui.Typography
              component="span"
              variant="h4"
              className="mb-3 font-weight-normal"
            >
              {t("AUTH.LOGIN.TITLE")}
            </Mui.Typography>
            {this.props.error ? (
              <Mui.Typography
                className="d-block mb-5 text-center text-color-danger"
                variant="body1"
                component="span"
              >
                {this.props.error}
              </Mui.Typography>
            ) : null}

            {/* Success/error message while navigation */}
            {this.responseMsg && !this.props.error ? (
              <Mui.Typography
                className="d-block mb-5 text-center text-color-primary"
                component="span"
                variant="body1"
              >
                {this.responseMsg}
              </Mui.Typography>
            ) : null}

            <Formik
              initialValues={{
                email: localStorage.getItem("email")
                  ? localStorage.getItem("email")
                  : "",
                password: localStorage.getItem("password")
                  ? localStorage.getItem("password")
                  : "",
                remember: localStorage.getItem("remember")
                  ? parseInt(localStorage.getItem("remember"))
                  : 0,
              }}
              validationSchema={this.validationSchema}
              onSubmit={this.onLogin}
            >
              {(props) => (
                <Form className="default-form center">
                  <Mui.Grid container spacing={3}>
                    <Mui.Grid className="form-group" item xs={12}>
                      <InputField
                        name="email"
                        type="text"
                        label={t("AUTH.INPUT.EMAIL")}
                        placeholder={t("AUTH.INPUT.EMAIL")}
                        InputProps={
                          {
                            startAdornment: (
                              <Mui.InputAdornment position="start">
                                <IconUsero />
                              </Mui.InputAdornment>
                            ),
                          }
                        }
                      />
                    </Mui.Grid>

                    <Mui.Grid className="form-group mb-2" item xs={12}>
                      <InputPasswordField
                        name="password"
                        placeholder={t("AUTH.INPUT.PASSWORD")}
                        label={t("AUTH.INPUT.PASSWORD")}
                        showStartAdornment={true}
                      />
                    </Mui.Grid>
                  </Mui.Grid>

                  <Mui.Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={3}
                  >
                    <Mui.Grid item xs={6} sm={6} className="mb-3">
                      <Mui.FormControlLabel
                        className="text-muted"
                        control={
                          <Mui.Checkbox
                            color="primary"
                            className="checkbox-style-classic"
                            checked={props.values.remember === 1}
                            onChange={(event) =>
                              props.setFieldValue(
                                "remember",
                                event.target.checked ? 1 : 0
                              )
                            }
                          />
                        }
                        label={t("AUTH.GENERAL.REMEMBER_ME")}
                      />
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      xs={6}
                      sm={6}
                      className="mb-3 text-right text-muted"
                    >
                      <NavLink
                        to={`/auth/forgot-password`}
                        className="font-weight-medium text-muted text-decoration-none"
                      >
                        {t("AUTH.GENERAL.FORGOT_BUTTON")}
                      </NavLink>
                    </Mui.Grid>
                  </Mui.Grid>

                  <Mui.Button
                    className="btn-default"
                    width="100%"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={this.props.loading}
                    data-cy="login-button"
                  >
                    {t("AUTH.LOGIN.BUTTON")}
                    {this.props.loading && (
                      <Mui.CircularProgress color="secondary" size={24} />
                    )}
                  </Mui.Button>
                </Form>
              )}
            </Formik>
            <Mui.Box className="form-graphic-box">
              <img style={{ width: "100%" }} src={AuthGraphicOne} alt="" />
            </Mui.Box>
          </Mui.Card>

        </Mui.Grid>
      </Mui.Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user, loading, error } = auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, { login, resetAuth })(
  withTranslation()(Mui.withWidth()(Login))
);
