import * as Mui from "@material-ui/core";
import { toast } from "react-toastify";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { IconCheckO } from "../../components/svg";
import ToastElement from "../../components/toast";
import { parseMessage } from "../../helpers/util";
import ProductService from "../../services/product-service";
import TableDataService from "../../services/table-data-service";
import {
  ADD_PRODUCT,
  ADD_PRODUCT_CLASS,
  DELETE_MULTIPLE_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  GET_ALL_PRODUCT,
  GET_CANTEEN_MENU_LIST,
  GET_CANTEEN_PRODUCT_LIST,
  GET_PRODUCT,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_LIST,
  IMPORT_PRODUCT,
} from "../actions";
import {
  addProductClassError,
  addProductClassSuccess,
  addProductError,
  addProductSuccess,
  deleteMultipleProductError,
  deleteMultipleProductSuccess,
  deleteProductError,
  deleteProductSuccess,
  editProductError,
  editProductSuccess,
  EXPORT_PRODUCTS,
  exportProductsError,
  exportProductsSuccess,
  getAllProductError,
  getAllProductSuccess,
  getCanteenMenuListError,
  getCanteenMenuListSuccess,
  getCanteenProductListError,
  getCanteenProductListSuccess,
  getProductCategoryError,
  getProductCategorySuccess,
  getProductError,
  getProductList,
  getProductListError,
  getProductListSuccess,
  getProductSuccess,
  importProductError,
  importProductSuccess,
  PRODUCT_BULK_STATUS_UPDATE,
  productBulkStatusUpdateError,
  productBulkStatusUpdateSuccess,
  PULL_IMAGE_FROM_ANNOTATION,
  pullImageFromAnnotationError,
  pullImageFromAnnotationSuccess,
  UPDATE_PRODUCT_NUTRITION,
  updateProductNutritionError,
  updateProductNutritionSuccess
} from "./action";
import moment from "moment";
import saveAs from "file-saver";

export function* watchGetCanteenProductList() {
  yield takeEvery(GET_CANTEEN_PRODUCT_LIST, getCanteenProductList);
}

const getCanteenProductListAsync = async (canteenId) => {
  return ProductService.getCanteenProductList(canteenId);
};

function* getCanteenProductList({ payload }) {
  try {
    const response = yield call(getCanteenProductListAsync, payload.canteenId);
    if (response.data.success) {
      let returnData = [];
      let arr = [];
      response.data.data.forEach((element, index) => {
        arr.push({
          id: "",
          productId: element.id,
          canteenId: payload.canteenId,
          subsidiaryType: "amount",
          subsidiary: "",
          discountType: "amount",
          discount: "",
          price: element.price,
          product: {
            name: element.name,
            uniqueId: element.uniqueId,
            productCategoryId: element.productCategoryId,
          },
        });
      });
      returnData.push({
        canteenId: payload.canteenId,
        products: arr,
      });
      yield put(getCanteenProductListSuccess(payload.canteenId, returnData));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getCanteenProductListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getCanteenProductListError(error.response.data.message));
  }
}

export function* watchGetCanteenMenuList() {
  yield takeEvery(GET_CANTEEN_MENU_LIST, getCanteenMenuList);
}

const getCanteenMenuListAsync = async (param) => {
  return ProductService.getCanteenMenuList(param);
};

function* getCanteenMenuList({ payload }) {
  try {
    const response = yield call(getCanteenMenuListAsync, payload.param);
    if (response.data.success) {
      let returnData = [];
      let arr = [];
      response.data.data.forEach((element, index) => {
        arr.push({
          id: "",
          menuId: element.id,
          canteenId: payload.param.canteenId,
          subsidiaryType: "percent",
          subsidiary: "",
          discountType: "percent",
          discount: "",
          price: element.price,
          menu: {
            name: element.name,
            itemNumber: element.itemNumber,
            productCategoryId: element.productCategoryId,
            menuDate: element.menuDate,
          },
        });
      });
      returnData.push({
        canteenId: payload.param.canteenId,
        menus: arr,
      });
      yield put(getCanteenMenuListSuccess(payload.param.canteenId, returnData));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getCanteenMenuListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getCanteenMenuListError(error.response.data.message));
  }
}

export function* watchGetAllProduct() {
  yield takeEvery(GET_ALL_PRODUCT, getAllProduct);
}

const getAllProductAsync = async (param) => {
  return ProductService.getAllProduct(param);
};

function* getAllProduct({ payload }) {
  try {
    const response = yield call(getAllProductAsync, payload.param);
    if (response.data.success) {
      yield put(getAllProductSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getAllProductError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getAllProductError(error.response.data.message));
  }
}

export function* watchGetProductList() {
  yield takeEvery(GET_PRODUCT_LIST, getProductListAc);
}

const getProductListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "products",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortOrder || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null,
    dbParam?.buildingId || null,
    dbParam?.canteenId || null,
    dbParam?.categoryId || null,
    dbParam?.productCategoryId || null,
  );
};

function* getProductListAc({ payload }) {
  try {
    const response = yield call(getProductListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getProductListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getProductListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getProductListError(error.response.data.message));
  }
}

export function* watchGetProductCategory() {
  yield takeEvery(GET_PRODUCT_CATEGORY, getProductCategory);
}

const getProductCategoryAsync = async () => {
  return ProductService.getProductCategory();
};

function* getProductCategory() {
  try {
    const response = yield call(getProductCategoryAsync);
    if (response.data.success) {
      yield put(getProductCategorySuccess(response.data.data));
    } else {
      yield put(getProductCategoryError(response.data.message));
    }
  } catch (error) {
    yield put(getProductCategoryError(error.response.data.message));
  }
}

export function* watchAddProduct() {
  yield takeEvery(ADD_PRODUCT, addProduct);
}

const addProductAsync = async (data) => {
  return ProductService.addProduct(data);
};

function* addProduct({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(addProductAsync, payload.productData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        addProductSuccess(response.data.success, response.data.message)
      );
      history.push(`/product-management/product${location?.state?.locationSearch ?? ''}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addProductError(response.data.message));
    }
  } catch (error) {
    const respData = error?.response?.data
    const message = respData ?
      respData?.errors ? parseMessage(respData?.errors)
        : parseMessage(respData?.message) : error?.message
    toast.error(
      <ToastElement
        type="error"
        message={message}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(addProductError(message));
  }
}

export function* watchGetProduct() {
  yield takeEvery(GET_PRODUCT, getProduct);
}

const getProductAsync = async (id, organizationId) => {
  return ProductService.getProduct(id, organizationId);
};

function* getProduct({ payload }) {
  try {
    const response = yield call(
      getProductAsync,
      payload.productId,
      payload.organizationId
    );
    if (response.data.success) {
      yield put(getProductSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(getProductError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(getProductError(error.response.data.message));
  }
}

export function* watchEditProduct() {
  yield takeEvery(EDIT_PRODUCT, editProduct);
}

const editProductAsync = async (data, id) => {
  return ProductService.editProduct(data, id);
};

function* editProduct({ payload }) {
  const { history, location } = payload;
  try {
    const response = yield call(
      editProductAsync,
      payload.productData,
      payload.productId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        editProductSuccess(response.data.success, response.data.message)
      );
      history.push(`/product-management/product${location?.state?.locationSearch ?? ''}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(editProductError(response.data.message));
    }
  } catch (error) {
    const respData = error?.response?.data
    const message = respData ?
      respData?.errors ? parseMessage(respData?.errors)
        : parseMessage(respData?.message) : error?.message
    toast.error(
      <ToastElement
        type="error"
        message={message}
      />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(editProductError(message));
  }
}

export function* watchDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
}

const deleteProductAsync = async (id) => {
  return ProductService.deleteProduct(id);
};

function* deleteProduct({ payload }) {
  try {
    const response = yield call(deleteProductAsync, payload.productId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteProductSuccess(response.data.success, response.data.message)
      );
      // Fetch updated product list
      yield put(getProductList(payload.prevProductOptions));
    } else {
      yield put(deleteProductError(response.data.message));
    }
  } catch (error) {
    yield put(deleteProductError(error.response.data.message));
  }
}

export function* watchDeleteMultipleProduct() {
  yield takeEvery(DELETE_MULTIPLE_PRODUCT, deleteMultipleProduct);
}

const deleteMultipleProductAsync = async (ids) => {
  return ProductService.deleteMultipleProduct(ids);
};

function* deleteMultipleProduct({ payload }) {
  try {
    const response = yield call(deleteMultipleProductAsync, payload.productIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        deleteMultipleProductSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated product list
      yield put(getProductList({}));
    } else {
      yield put(deleteMultipleProductError(response.data.message));
    }
  } catch (error) {
    yield put(deleteMultipleProductError(error.response.data.message));
  }
}

export function* watchImportProduct() {
  yield takeEvery(IMPORT_PRODUCT, importProduct);
}

const importProductAsync = async (param) => {
  return ProductService.importProduct(param);
};

function* importProduct({ payload }) {
  try {
    const response = yield call(importProductAsync, payload.importParam);
    if (response.data.success) {
      toast.success(
        <>
          <span className="icon">
            <IconCheckO />
          </span>
          <Mui.Typography component="h5" variant="h5">
            {localStorage.getItem("i18nextLng") === "en"
              ? "Success"
              : "Velykket"}
            {response.data.message.split(".").map((item, i) => (
              <Mui.Typography
                component="span"
                variant="body1"
                className="d-block"
                key={i}
              >
                {item}
              </Mui.Typography>
            ))}
          </Mui.Typography>
        </>,
        { containerId: "default", position: "bottom-right", autoClose: 10000 }
      );
      yield put(
        importProductSuccess(response.data.success, response.data.message)
      );
      // Fetch updated product list
      yield put(getProductList({}));
    } else {
      yield put(importProductError(response.data.message));
    }
  } catch (error) {
    yield put(
      importProductError(
        parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )
      )
    );
  }
}

export function* watchAddProductClass() {
  yield takeEvery(ADD_PRODUCT_CLASS, addProductClass);
}

const addProductClassAsync = async (id) => {
  return ProductService.addProductClass(id);
};

function* addProductClass({ payload }) {
  try {
    const response = yield call(addProductClassAsync, payload.productId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        addProductClassSuccess(response.data.success, response.data.message)
      );
      // Fetch updated product list
      yield put(getProductList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(addProductClassError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(addProductClassError(error.response.data.message));
  }
}


export function* watchUpdateProductNutrition() {
  yield takeEvery(UPDATE_PRODUCT_NUTRITION, updateProductNutrition);
}

const updateProductNutritionAsync = async (ids) => {
  return ProductService.updateProductNutrition(ids);
};

function* updateProductNutrition({ payload }) {
  try {
    const response = yield call(updateProductNutritionAsync, payload.productIds);
    if (response.data.success) {
      payload?.callback()
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        updateProductNutritionSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated product list
      yield put(getProductList(payload.prevProductOptions));
    } else {
      yield put(updateProductNutritionError(response.data.message));
    }
  } catch (error) {
    yield put(updateProductNutritionError(error.response.data.message));
  }
}


export function* watchProductBulkStatusUpdate() {
  yield takeEvery(PRODUCT_BULK_STATUS_UPDATE, productBulkStatusUpdate);
}

const productBulkStatusUpdateAsync = async (payload) => {
  return ProductService.productBulkStatusUpdate(payload);
};

function* productBulkStatusUpdate({ payload }) {
  try {
    const response = yield call(productBulkStatusUpdateAsync, payload.payload);
    if (response.data.success) {
      payload.callback?.()
      const hasFailed = response.data?.data?.failedLog?.length > 0
      const failedLogs = response.data?.data?.failedLog?.join(", ")

      const successCount = response.data?.data?.successLog?.length

      const message = hasFailed ? {
        title: "COMMON.SUCCESS_FAILED_MESSAGE",
        dynamicData: {
          successCount,
          failedIds: failedLogs
        }
      } : response.data.message


      toast.success(
        <ToastElement type={hasFailed ? "warning" : "success"} message={message} messageType="object" />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        productBulkStatusUpdateSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated product list
      yield put(getProductList(payload.prevProductOptions));
    } else {
      yield put(productBulkStatusUpdateError(response.data.message));
    }
  } catch (error) {
    yield put(productBulkStatusUpdateError(error.response.data.message));
  }
}


export function* watchPullImageFromAnnotation() {
  yield takeEvery(PULL_IMAGE_FROM_ANNOTATION, pullImageFromAnnotation);
}

const pullImageFromAnnotationAsync = async (id) => {
  return ProductService.pullImageFromAnnotation(id);
};

function* pullImageFromAnnotation({ payload }) {
  try {
    const response = yield call(pullImageFromAnnotationAsync, payload.productId);
    if (response.data.success) {
      payload?.callback()
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(
        pullImageFromAnnotationSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated product list
      // yield put(getProductList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(pullImageFromAnnotationError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(pullImageFromAnnotationError(error.response.data.message));
  }
}


export function* watchExportProducts() {
  yield takeEvery(EXPORT_PRODUCTS, exportProducts);
}

const exportProductsAsync = async (payload) => {
  return ProductService.exportProducts(payload);
};

function* exportProducts({ payload }) {
  try {
    const response = yield call(exportProductsAsync, payload);
    if (response && response.data) {
      yield put(exportProductsSuccess(true, ""));
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const date = moment().format('YYYY.MM.DD HH:mm:ss')
      saveAs(blob, `Products-${date}.xlsx`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right" }
      );
      yield put(exportProductsError(response.data.message));
    }
  } catch (error) {
    const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
    toast.error(
      <ToastElement type="error" message={err.message} />,
      { containerId: "default", position: "bottom-right" }
    );
    yield put(exportProductsError(err.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCanteenProductList),
    fork(watchGetCanteenMenuList),
    fork(watchGetAllProduct),
    fork(watchGetProductList),
    fork(watchGetProductCategory),
    fork(watchAddProduct),
    fork(watchGetProduct),
    fork(watchEditProduct),
    fork(watchDeleteProduct),
    fork(watchDeleteMultipleProduct),
    fork(watchImportProduct),
    fork(watchAddProductClass),
    fork(watchUpdateProductNutrition),
    fork(watchProductBulkStatusUpdate),
    fork(watchPullImageFromAnnotation),
    fork(watchExportProducts),
  ]);
}
