import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import MenuService from "../../services/menu-service";
import TableDataService from "../../services/table-data-service";
import {
  GET_ALL_MENU,
  GET_MENU_LIST,
  GET_MENU,
  EDIT_MENU,
  FETCH_MENU,
  ADD_MENU_CLASS,
} from "../actions";
import {
  getAllMenuSuccess,
  getAllMenuError,
  getMenuList,
  getMenuListSuccess,
  getMenuListError,
  getMenuSuccess,
  getMenuError,
  editMenuSuccess,
  editMenuError,
  fetchMenuSuccess,
  fetchMenuError,
  addMenuClassSuccess,
  addMenuClassError,
} from "./action";
import { toast } from "react-toastify";
import { parseMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
import { IconCheckO } from "../../components/svg";
import * as Mui from "@material-ui/core";

export function* watchGetAllMenu() {
  yield takeEvery(GET_ALL_MENU, getAllMenu);
}

const getAllMenuAsync = async () => {
  return MenuService.getAllMenu();
};

function* getAllMenu() {
  try {
    const response = yield call(getAllMenuAsync);
    if (response.data.success) {
      yield put(getAllMenuSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right"  }
      );
      yield put(getAllMenuError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right"  }
    );
    yield put(getAllMenuError(error.response.data.message));
  }
}

export function* watchGetMenuList() {
  yield takeEvery(GET_MENU_LIST, getMenuListAc);
}

const getMenuListAsync = async (dbParam) => {
  return TableDataService.getAllData(
    "menus",
    dbParam?.orgId || "",
    dbParam?.search || "",
    dbParam?.searchFields || "",
    dbParam?.sortMenu || "",
    dbParam?.page || 1,
    dbParam?.pageSize || 10,
    dbParam?.activeCol || "",
    dbParam?.statusId || null,
    dbParam?.buildingId || null,
    dbParam?.canteenId || null,
    dbParam?.categoryId || null,
    dbParam?.productCategoryId || null,
    dbParam?.fromDate || null,
    dbParam?.toDate || null,
    dbParam?.date || null
  );
};

function* getMenuListAc({ payload }) {
  try {
    const response = yield call(getMenuListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getMenuListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right"  }
      );
      yield put(getMenuListError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right"  }
    );
    yield put(getMenuListError(error.response.data.message));
  }
}

export function* watchGetMenu() {
  yield takeEvery(GET_MENU, getMenu);
}

const getMenuAsync = async (id) => {
  return MenuService.getMenu(id);
};

function* getMenu({ payload }) {
  try {
    const response = yield call(getMenuAsync, payload.menuId);
    if (response.data.success) {
      yield put(getMenuSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right"  }
      );
      yield put(getMenuError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right"  }
    );
    yield put(getMenuError(error.response.data.message));
  }
}

export function* watchEditMenu() {
  yield takeEvery(EDIT_MENU, editMenu);
}

const editMenuAsync = async (data, id) => {
  return MenuService.editMenu(data, id);
};

function* editMenu({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(
      editMenuAsync,
      payload.menuData,
      payload.menuId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right"  }
      );
      yield put(editMenuSuccess(response.data.success, response.data.message));
      history.push({
        pathname: `/product-management/menu`,
        state: payload.menuData.initialRouteParams || {},
      });
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right"  }
      );
      yield put(editMenuError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement
        type="error"
        message={parseMessage(
          error.response.data.errors
            ? error.response.data.errors
            : error.response.data.message
        )}
      />,
      { containerId: "default", position: "bottom-right"  }
    );
    yield put(editMenuError(error.response.data.message));
  }
}

export function* watchFetchMenu() {
  yield takeEvery(FETCH_MENU, fetchMenu);
}

const fetchMenuAsync = async (canteenId) => {
  return MenuService.fetchMenu(canteenId);
};

function* fetchMenu({ payload }) {
  try {
    const response = yield call(fetchMenuAsync, payload.param?.canteenId || "");
    if (response.data.success) {
      toast.success(
        <>
          <span className="icon">
            <IconCheckO />
          </span>
          <Mui.Typography component="h5" variant="h5">
            {localStorage.getItem("i18nextLng") === "en"
              ? "Success"
              : "Velykket"}
            {response.data.message.split(".").map((item, i) => (
              <Mui.Typography
                component="span"
                variant="body1"
                className="d-block"
                key={i}
              >
                {item}
              </Mui.Typography>
            ))}
          </Mui.Typography>
        </>,
        { containerId: "default", position: "bottom-right" , autoClose: 10000 }
      );
      yield put(fetchMenuSuccess(response.data.success, response.data.message));
      // Fetch updated menu list
      yield put(getMenuList(payload.param));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right"  }
      );
      yield put(fetchMenuError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right"  }
    );
    yield put(fetchMenuError(error.response.data.message));
  }
}

export function* watchAddMenuClass() {
  yield takeEvery(ADD_MENU_CLASS, addMenuClass);
}

const addMenuClassAsync = async (id) => {
  return MenuService.addMenuClass(id);
};

function* addMenuClass({ payload }) {
  try {
    const response = yield call(addMenuClassAsync, payload.menuId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "bottom-right"  }
      );
      yield put(
        addMenuClassSuccess(response.data.success, response.data.message)
      );
      // Fetch updated menu list
      yield put(getMenuList(payload.param));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "bottom-right"  }
      );
      yield put(addMenuClassError(response.data.message));
    }
  } catch (error) {
    toast.error(
      <ToastElement type="error" message={error.response.data.message} />,
      { containerId: "default", position: "bottom-right"  }
    );
    yield put(addMenuClassError(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllMenu),
    fork(watchGetMenuList),
    fork(watchGetMenu),
    fork(watchEditMenu),
    fork(watchFetchMenu),
    fork(watchAddMenuClass),
  ]);
}
